import { Box, Grid } from "@mui/material";
import { AdminWidget } from "../../components/Admin/AdminWidget";
import { Person, Sailing } from "@mui/icons-material";

export default function Admin() {
	return (
			<Box sx={{ width: '90%', typography: 'body1' }}>
				<Grid container spacing={3} sx={{m: 2}}>
					<Grid item xs={12} spacing={3} sm={6} md={3}>
            			<AdminWidget title="Client Management" link="/clientmanagement" icon={<Person/>} />
						<AdminWidget title="Ships" link="/Ships" icon={<Sailing/>}/>
          			</Grid>
				</Grid>
    		</Box>
	);
}
