import {
  Add,
  ArrowRightAlt,
  CalendarMonth,
  Delete,
  DirectionsBoat,
  Map,
  Note,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { Formik } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import { getTripType } from "../../utilities/shipUtils";
import { Trip, TripPassenger, TripStop } from "../../types/Trip";
import { useState } from "react";
import { createTrip } from "../../services/tripservice";

export default function NewTrip() {
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [stops, setStops] = useState<TripStop[]>([]);
  const [stopsModalOpen, setStopsModalOpen] = useState(false);
  const [passengers, setPassengers] = useState<TripPassenger[]>([]);
  const [passengersModalOpen, setPassengersModalOpen] = useState(false);
  const [startDate, setStartDate] = useState<DateTime>();
  const [endDate, setEndDate] = useState<DateTime>();
  const [stopValues, setStopValues] = useState<TripStop>();

  const navigate = useNavigate();

  const initialState = {
    type: 2,
    name: "",
    isExternalCharter: false,
    nauticalMiles: 0,
    startDate: DateTime.now(),
    endDate: DateTime.now(),
    startLocation: "",
    endLocation: "",
    notes: "",
  };

  const initialPassengerValues = {
    firstName: "",
    lastName: "",
    email: "",
    notes: "",
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Modal
        open={stopsModalOpen}
        onClose={() => {
          setStopsModalOpen(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
          }}
        >
          <Paper
            sx={{
              p: 2,
              borderTopColor: "#b47f49",
              borderTopWidth: "4px",
              borderStyle: "solid",
              borderRightWidth: "0",
              borderLeftWidth: "0",
              borderBottomWidth: "0",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">New Stop</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                {stopValues !== undefined ? (
                  <Formik
                    enableReinitialize
                    initialValues={stopValues}
                    onSubmit={(values) => {
                      console.log(values);
                      var stop: TripStop = {
                        location: values.location,
                        startDate: values.startDate,
                        endDate: values.endDate,
                      };
                      stops.push(stop);
                      setStopsModalOpen(false);
                      setStartDate(undefined);
                      setEndDate(undefined);
                    }}
                  >
                    {({
                      errors,
                      handleSubmit,
                      handleBlur,
                      handleChange,
                      touched,
                      values,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Stack direction="column">

                          <TextField
                            id="location"
                            name="location"
                            label="Location"
                            value={values.location}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.location && Boolean(errors.location)}
                            helperText={touched.location && errors.location}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                          <DatePicker
                            label="Start Date"
                            value={values.startDate}
                            minDate={startDate}
                            maxDate={endDate}
                            onChange={(value: any) =>
                              setFieldValue(
                                "startDate",
                                DateTime.fromISO(value),
                                true
                              )
                            }
                            sx={{ mb: 2 }}
                          />
                          <DatePicker
                            label="End Date"
                            value={values.endDate}
                            minDate={values.startDate}
                            maxDate={endDate}
                            onChange={(value: any) =>
                              setFieldValue(
                                "endDate",
                                DateTime.fromISO(value),
                                true
                              )
                            }
                            sx={{ mb: 2 }}
                          />
                          <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Stack>
                      </form>
                    )}
                  </Formik>
                ) : (
                  <Box />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Modal>
      <Modal
        open={passengersModalOpen}
        onClose={() => {
          setPassengersModalOpen(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
          }}
        >
          <Paper
            sx={{
              p: 2,
              borderTopColor: "#b47f49",
              borderTopWidth: "4px",
              borderStyle: "solid",
              borderRightWidth: "0",
              borderLeftWidth: "0",
              borderBottomWidth: "0",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">New Passenger</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Formik
                  enableReinitialize
                  initialValues={initialPassengerValues}
                  onSubmit={(values) => {
                    console.log(values);
                    var passenger: TripPassenger = {
                      firstName: values.firstName,
                      lastName: values.lastName,
                      notes: values.notes,
                      email: values.email
                    };
                    passengers.push(passenger);
                    setPassengersModalOpen(false);
                  }}
                >
                  {({
                    errors,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    touched,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Stack direction="column">
                        <TextField
                          id="firstName"
                          name="firstName"
                          label="First Name"
                          required
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.firstName && Boolean(errors.firstName)}
                          helperText={touched.firstName && errors.firstName}
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          id="lastName"
                          name="lastName"
                          label="Last Name"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.lastName && Boolean(errors.lastName)}
                          helperText={touched.lastName && errors.lastName}
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          id="email"
                          name="email"
                          label="Email Address"
                          required
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          id="notes"
                          name="notes"
                          label="Notes"
                          value={values.notes}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.notes && Boolean(errors.notes)}
                          helperText={touched.notes && errors.notes}
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                        <Button
                          color="primary"
                          variant="contained"
                          fullWidth
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Stack>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Modal>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${selectedShip.id}/trips`)}
          startIcon={<Map sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Trips
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Add sx={{ mr: 0.5 }} fontSize="inherit" />
          New Trip
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              borderTopColor: "#b47f49",
              borderTopWidth: "4px",
              borderStyle: "solid",
              borderRightWidth: "0",
              borderLeftWidth: "0",
              borderBottomWidth: "0",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h4">
                  <Add sx={{ mr: 2 }} /> New Trip
                </Typography>
                <Divider sx={{ mt: 2, mb: 2 }} />
              </Grid>
              <Grid item xs={12}>
                <Formik
                  initialValues={initialState}
                  onSubmit={(values) => {
                    switch (values.type) {
                      case 0:
                        var portTrip: Trip = {
                          name: values.name,
                          startDate: values.startDate,
                          endDate: values.endDate,
                          type: values.type,
                          isExternalCharter: false,
                          nauticalMiles: values.nauticalMiles,
                          startLocation: values.startLocation,
                          endLocation: values.startLocation,
                          notes: values.notes,
                        };
                        createTrip(
                          selectedClient.id,
                          selectedShip.id,
                          portTrip
                        ).then((res) => {
                          console.log(res);
                          navigate(`/ships/${selectedShip.id}/trips`);
                        });
                        break;
                      case 1:
                        var transitTrip: Trip = {
                          name: values.name,
                          startDate: values.startDate,
                          endDate: values.endDate,
                          type: values.type,
                          isExternalCharter: false,
                          nauticalMiles: values.nauticalMiles,
                          startLocation: values.startLocation,
                          endLocation: values.endLocation,
                          notes: values.notes,
                        };
                        createTrip(
                          selectedClient.id,
                          selectedShip.id,
                          transitTrip
                        ).then((res) => {
                          console.log(res);
                          navigate(`/ships/${selectedShip.id}/trips`);
                        });
                        break;
                      case 2:
                        var charterTrip: Trip = {
                          name: values.name,
                          startDate: values.startDate,
                          endDate: values.endDate,
                          type: values.type,
                          isExternalCharter: false,
                          nauticalMiles: values.nauticalMiles,
                          startLocation: values.startLocation,
                          endLocation: values.endLocation,
                          notes: values.notes,
                          passengers: passengers,
                          stops: stops,
                        };
                        createTrip(
                          selectedClient.id,
                          selectedShip.id,
                          charterTrip
                        ).then((res) => {
                          console.log(res);
                          navigate(`/ships/${selectedShip.id}/trips`);
                        });
                        break;
                      case 3:
                        var serviceTrip: Trip = {
                          startDate: values.startDate,
                          name: values.name,
                          endDate: values.endDate,
                          type: values.type,
                          isExternalCharter: false,
                          nauticalMiles: values.nauticalMiles,
                          startLocation: values.startLocation,
                          endLocation: values.startLocation,
                          notes: values.notes,
                        };
                        createTrip(
                          selectedClient.id,
                          selectedShip.id,
                          serviceTrip
                        ).then((res) => {
                          console.log(res);
                          navigate(`/ships/${selectedShip.id}/trips`);
                        });
                        break;
                    }
                  }}
                >
                  {({
                    errors,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                      <TextField
                            id="name"
                            name="name"
                            label="Name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        <InputLabel id="demo-type-select-label">
                          Type
                        </InputLabel>
                        <Select
                          labelId="demo-type-select-label"
                          id="demo-type-select"
                          value={values.type}
                          onChange={(event) => {
                            setFieldValue("type", event.target.value);
                          }}
                        >
                          <MenuItem
                            id={`tripType-0`}
                            key={`tripType-0`}
                            value={0}
                          >
                            {getTripType(0)}
                          </MenuItem>
                          <MenuItem
                            id={`tripType-1`}
                            key={`tripType-1`}
                            value={1}
                          >
                            {getTripType(1)}
                          </MenuItem>
                          <MenuItem
                            id={`tripType-2`}
                            key={`tripType-2`}
                            value={2}
                          >
                            {getTripType(2)}
                          </MenuItem>
                          <MenuItem
                            id={`tripType-3`}
                            key={`tripType-3`}
                            value={3}
                          >
                            {getTripType(3)}
                          </MenuItem>
                        </Select>
                      </FormControl>
                      {values.type === 0 ? (
                        <Stack direction="column">
                          <TextField
                            id="startLocation"
                            name="startLocation"
                            label="Location"
                            value={values.startLocation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.startLocation &&
                              Boolean(errors.startLocation)
                            }
                            helperText={
                              touched.startLocation && errors.startLocation
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                          <DatePicker
                            label="Start Date"
                            value={values.startDate}
                            onChange={(value: any) => {
                              setFieldValue(
                                "startDate",
                                DateTime.fromISO(value),
                                true
                              );
                            }}
                            sx={{ mb: 2 }}
                          />
                          <DatePicker
                            label="End Date"
                            value={values.endDate}
                            onChange={(value: any) =>
                              setFieldValue(
                                "endDate",
                                DateTime.fromISO(value),
                                true
                              )
                            }
                            sx={{ mb: 2 }}
                          />
                          <TextField
                            id="notes"
                            name="notes"
                            label="Notes"
                            value={values.notes}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.notes && Boolean(errors.notes)}
                            helperText={touched.notes && errors.notes}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                          <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Stack>
                      ) : (
                        <Box />
                      )}
                      {values.type === 1 ? (
                        <Stack direction="column">
                          <DatePicker
                            label="Start Date"
                            value={values.startDate}
                            onChange={(value: any) =>
                              setFieldValue(
                                "startDate",
                                DateTime.fromISO(value),
                                true
                              )
                            }
                            sx={{ mb: 2 }}
                          />
                          <TextField
                            id="startLocation"
                            name="startLocation"
                            label="Start Location"
                            value={values.startLocation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.startLocation &&
                              Boolean(errors.startLocation)
                            }
                            helperText={
                              touched.startLocation && errors.startLocation
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                          <DatePicker
                            label="End Date"
                            value={values.endDate}
                            onChange={(value: any) =>
                              setFieldValue(
                                "endDate",
                                DateTime.fromISO(value),
                                true
                              )
                            }
                            sx={{ mb: 2 }}
                          />
                          <TextField
                            id="endLocation"
                            name="endLocation"
                            label="End Location"
                            value={values.endLocation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.endLocation && Boolean(errors.endLocation)
                            }
                            helperText={
                              touched.endLocation && errors.endLocation
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                          <TextField
                            id="nauticalMiles"
                            name="nauticalMiles"
                            label="Nautical Miles"
                            value={values.nauticalMiles}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.nauticalMiles &&
                              Boolean(errors.nauticalMiles)
                            }
                            helperText={
                              touched.nauticalMiles && errors.nauticalMiles
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                          <TextField
                            id="notes"
                            name="notes"
                            label="Notes"
                            value={values.notes}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.notes && Boolean(errors.notes)}
                            helperText={touched.notes && errors.notes}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                          <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Stack>
                      ) : (
                        <Box />
                      )}
                      {values.type === 2 ? (
                        <Stack direction="column">
                          <DatePicker
                            label="Start Date"
                            value={values.startDate}
                            onChange={(value: any) =>
                              setFieldValue(
                                "startDate",
                                DateTime.fromISO(value),
                                true
                              )
                            }
                            sx={{ mb: 2 }}
                          />
                          <TextField
                            id="startLocation"
                            name="startLocation"
                            label="Start Location"
                            value={values.startLocation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.startLocation &&
                              Boolean(errors.startLocation)
                            }
                            helperText={
                              touched.startLocation && errors.startLocation
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                          <DatePicker
                            label="End Date"
                            value={values.endDate}
                            onChange={(value: any) =>
                              setFieldValue(
                                "endDate",
                                DateTime.fromISO(value),
                                true
                              )
                            }
                            sx={{ mb: 2 }}
                          />
                          <TextField
                            id="endLocation"
                            name="endLocation"
                            label="End Location"
                            value={values.endLocation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.endLocation && Boolean(errors.endLocation)
                            }
                            helperText={
                              touched.endLocation && errors.endLocation
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                          <TextField
                            id="nauticalMiles"
                            name="nauticalMiles"
                            label="Nautical Miles"
                            value={values.nauticalMiles}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.nauticalMiles &&
                              Boolean(errors.nauticalMiles)
                            }
                            helperText={
                              touched.nauticalMiles && errors.nauticalMiles
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                          <TextField
                            id="notes"
                            name="notes"
                            label="Notes"
                            value={values.notes}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.notes && Boolean(errors.notes)}
                            helperText={touched.notes && errors.notes}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                          {stops.length === 0 ? (
                            <Button
                              color="primary"
                              variant="outlined"
                              sx={{ mb: 2 }}
                              onClick={() => {
                                setStopValues({
                                  location: "",
                                  startDate: values.startDate,
                                  endDate: values.startDate,
                                });
                                setStartDate(values.startDate);
                                setEndDate(values.endDate);
                                setStopsModalOpen(true);
                              }}
                              type="button"
                              startIcon={<Add />}
                              fullWidth
                            >
                              New Stop
                            </Button>
                          ) : (
                            <Stack direction="column">
                              <Divider sx={{ mb: 2 }} />
                              <Typography variant="h5" sx={{ mb: 1 }}>
                                Stops
                              </Typography>
                              {stops.map((st) => (
                                <Paper elevation={4} sx={{ p: 1, mb: 2 }}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={12} md={5}>
                                      <Typography variant="h6">
                                        {st.location}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                      >
                                        <Typography variant="h6" sx={{ mr: 2 }}>
                                          <CalendarMonth sx={{ mr: 2 }} />
                                          {st.startDate
                                            ?.setLocale("en-us")
                                            .toLocaleString()}
                                        </Typography>
                                        <ArrowRightAlt sx={{ mr: 2 }} />
                                        <Typography variant="h6">
                                          <CalendarMonth sx={{ mr: 2 }} />
                                          {st.endDate
                                            ?.setLocale("en-us")
                                            .toLocaleString()}
                                        </Typography>
                                      </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                      <Stack direction="row-reverse">
                                        <IconButton
                                          onClick={() => {
                                            var index = stops.findIndex((s) => {
                                              if (s.location === st.location) {
                                                return true;
                                              }
                                              return false;
                                            });
                                            if (index !== -1) {
                                              stops.splice(index, 1);
                                              setStops([...stops]);
                                              console.log(stops);
                                            }
                                          }}
                                        >
                                          <Delete />
                                        </IconButton>
                                      </Stack>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              ))}
                              <Button
                                color="primary"
                                variant="outlined"
                                sx={{ mb: 2 }}
                                onClick={() => {
                                  setStopValues({
                                    location: "",
                                    startDate: values.startDate,
                                    endDate: values.startDate,
                                  });
                                  setStartDate(values.startDate);
                                  setEndDate(values.endDate);
                                  setStopsModalOpen(true);
                                }}
                                type="button"
                                fullWidth
                                startIcon={<Add />}
                              >
                                New Stop
                              </Button>
                              <Divider sx={{ mb: 2 }} />
                            </Stack>
                          )}
                          {passengers.length === 0 ? (
                            <Button
                              color="primary"
                              variant="outlined"
                              sx={{ mb: 2 }}
                              onClick={() => setPassengersModalOpen(true)}
                              type="button"
                              startIcon={<Add />}
                              fullWidth
                            >
                              New Passenger
                            </Button>
                          ) : (
                            <Stack direction="column">
                              <Divider sx={{ mb: 2 }} />
                              <Typography variant="h5" sx={{ mb: 1 }}>
                                Passengers
                              </Typography>
                              {passengers.map((pass) => (
                                <Paper elevation={4} sx={{ p: 1, mb: 2 }}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={12} md={10}>
                                      <Typography variant="h6">
                                        {pass.firstName} {pass.lastName} {" - "}{pass.email}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                      <Stack direction="row-reverse">
                                        <IconButton
                                          onClick={() => {
                                            var index = passengers.findIndex(
                                              (s) => {
                                                if (
                                                  s.firstName ===
                                                    pass.firstName &&
                                                  s.lastName === pass.lastName
                                                ) {
                                                  return true;
                                                }
                                                return false;
                                              }
                                            );
                                            if (index !== -1) {
                                              passengers.splice(index, 1);
                                              setPassengers([...passengers]);
                                            }
                                          }}
                                        >
                                          <Delete />
                                        </IconButton>
                                      </Stack>
                                    </Grid>
                                    {pass.notes !== "" ? (
                                      <Grid item xs={12}>
                                        <Paper
                                          elevation={12}
                                          sx={{ p: 1, mb: 2 }}
                                        >
                                          <Grid container>
                                            <Grid item xs={12}>
                                              <Typography variant="body1">
                                                <Note sx={{ mr: 2 }} />
                                                {pass.notes}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Paper>
                                      </Grid>
                                    ) : (
                                      <Box />
                                    )}
                                  </Grid>
                                </Paper>
                              ))}
                              <Button
                                color="primary"
                                variant="outlined"
                                sx={{ mb: 2 }}
                                onClick={() => setPassengersModalOpen(true)}
                                type="button"
                                startIcon={<Add />}
                                fullWidth
                              >
                                New Passenger
                              </Button>
                              <Divider sx={{ mb: 2 }} />
                            </Stack>
                          )}
                          <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Stack>
                      ) : (
                        <Box />
                      )}
                      {values.type === 3 ? (
                        <Stack direction="column">
                          <TextField
                            id="startLocation"
                            name="startLocation"
                            label="Location"
                            value={values.startLocation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.startLocation &&
                              Boolean(errors.startLocation)
                            }
                            helperText={
                              touched.startLocation && errors.startLocation
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                          <DatePicker
                            label="Start Date"
                            value={values.startDate}
                            onChange={(value: any) => {
                              setFieldValue(
                                "startDate",
                                DateTime.fromISO(value),
                                true
                              );
                            }}
                            sx={{ mb: 2 }}
                          />
                          <DatePicker
                            label="End Date"
                            value={values.endDate}
                            onChange={(value: any) =>
                              setFieldValue(
                                "endDate",
                                DateTime.fromISO(value),
                                true
                              )
                            }
                            sx={{ mb: 2 }}
                          />
                          <TextField
                            id="notes"
                            name="notes"
                            label="Notes"
                            value={values.notes}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.notes && Boolean(errors.notes)}
                            helperText={touched.notes && errors.notes}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                          <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Stack>
                      ) : (
                        <Box />
                      )}
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
