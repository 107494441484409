import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import PaperSection from "../PaperSection";
import { Edit, LocalPolice, Policy } from "@mui/icons-material";
import { Formik } from "formik";
import {
  getIncidentById,
  updateIncident,
} from "../../services/incidentService";
import { useEffect, useState } from "react";
import { getEquipment } from "../../services/shipService";
import { Equipment } from "../../types/Equipment";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { useRecoilState } from "recoil";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { IncidentReport } from "../../types/IncidentReport";
import { useNavigate } from "react-router-dom";
import { getIncidentStatus } from "../../utilities/incidentUtils";
import moment from "moment";
import { getShipStatus } from "../../utilities/shipUtils";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

export type IncidentDetailsCardProps = {
  incident?: IncidentReport;
  setIncident: Function;
};
export default function IncidentDetailsCard(props: IncidentDetailsCardProps) {
  const { incident, setIncident } = props;
  const [equipment, setEquipment] = useState<Equipment[]>([]);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const navigate = useNavigate();

  useEffect(() => {
    getEquipment(selectedClient.id, selectedShip.id).then((response) => {
      setEquipment(response);
    });
  }, [selectedClient.id, selectedShip.id]);
  return (
    <Box>
      <Modal
        open={editOpen}
        onClose={() => {
          setEditOpen(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <PaperSection title="Edit" icon={<Edit />}>
            {incident !== undefined ? (
              <Formik
                initialValues={incident}
                onSubmit={(values) => {
                  updateIncident(
                    selectedClient.id,
                    selectedShip.id,
                    values
                  ).then((res) => {
                    setEditOpen(false);
                    getIncidentById(
                      selectedClient.id,
                      selectedShip.id,
                      incident.id
                    ).then((res) => {
                      setIncident(res);
                    });
                  });
                }}
              >
                {({
                  errors,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FormControlLabel
                      control={<Checkbox checked={values.isEmergency} />}
                      label="Is this an emergency?"
                      name="isEmergency"
                      onChange={handleChange}
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      id="title"
                      name="title"
                      label={`Title`}
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.title && Boolean(errors.title)}
                      helperText={touched.title && errors.title}
                      fullWidth
                      sx={{ mb: 2 }}
                    />

                    <TextField
                      id="location"
                      name="location"
                      label={`Where on the ship did the issue occur?`}
                      value={values.location}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.location && Boolean(errors.location)}
                      helperText={touched.location && errors.location}
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      id="environmentalData"
                      name="environmentalData"
                      label={`Environmental Data`}
                      value={values.environmentalData}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.environmentalData &&
                        Boolean(errors.environmentalData)
                      }
                      helperText={
                        touched.environmentalData && errors.environmentalData
                      }
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      id="manHours"
                      type="number"
                      name="manHours"
                      label={`Man Hours`}
                      value={values.manHours}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.manHours && Boolean(errors.manHours)}
                      helperText={touched.manHours && errors.manHours}
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                    <InputLabel id="shipStatus-label">Ship Status</InputLabel>
                    <Select
                      labelId="shipStatus-label"
                      id="shipStatus"
                      value={values.shipStatus}
                      onChange={(e) => {
                        setFieldValue("shipStatus", e.target.value);
                      }}
                      fullWidth
                      sx={{ mb: 2 }}
                    >
                      <MenuItem id={"status-0"} key={0} value={0}>
                        In Port
                      </MenuItem>
                      <MenuItem id={"status-1"} key={1} value={1}>
                        In Transit
                      </MenuItem>
                      <MenuItem id={"status-2"} key={2} value={2}>
                        Chartered
                      </MenuItem>
                      <MenuItem id={"status-3"} key={3} value={3}>
                        In Dry Dock
                      </MenuItem>
                    </Select>
                    {values.status === 3 ? (
                      <DatePicker
                        disablePast
                        label="Deferral Date"
                        value={DateTime.fromISO(
                          values.deferredDate !== undefined &&
                            values.deferredDate !== null
                            ? values.deferredDate.toString()
                            : DateTime.now().toString()
                        )}
                        onChange={(value: any) =>
                          setFieldValue(
                            "deferredDate",
                            DateTime.fromISO(value),
                            true
                          )
                        }
                        sx={{ mb: 2 }}
                      />
                    ) : (
                      <Box />
                    )}
                    <InputLabel id="equipment-label">Equipment</InputLabel>
                    <Select
                      labelId="equipment-label"
                      id="equipment"
                      value={values.equipmentIds}
                      multiple
                      input={
                        <OutlinedInput id="select-multiple-chip" label="Chip" />
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={
                                equipment.find((e) => e.id === value)?.name
                              }
                            />
                          ))}
                        </Box>
                      )}
                      onChange={(e) => {
                        setFieldValue("equipmentIds", e.target.value);
                      }}
                      fullWidth
                      sx={{ mb: 2 }}
                    >
                      {equipment.map((e) => (
                        <MenuItem id={e.id} key={e.id} value={e.id}>
                          {e.name}
                        </MenuItem>
                      ))}
                    </Select>

                    <Button
                      sx={{ mt: 2 }}
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Submit
                    </Button>
                  </form>
                )}
              </Formik>
            ) : (
              <Box />
            )}
          </PaperSection>
        </Box>
      </Modal>
      <PaperSection
        title={incident?.title}
        button={
          incident?.status === 0 || incident?.status === 3 ? (
            <Button
              variant="contained"
              onClick={() => setEditOpen(true)}
              startIcon={<Edit />}
            >
              Edit
            </Button>
          ) : (
            <Box />
          )
        }
        icon={incident?.isEmergency ? <LocalPolice /> : <Policy />}
      >
        <Grid container spacing={2}>
          {incident?.isEmergency ? (
            <Grid item xs={12}>
              <Chip color="error" label="Emergency" sx={{ mr: 2 }} />
              <Chip label={getIncidentStatus(incident?.status)} />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Chip label={getIncidentStatus(incident?.status)} />
            </Grid>
          )}
          {incident?.status === 3 ? (
            <Grid item xs={12} md={4}>
              <Typography variant="body1">
                <b>Deferred Date:</b>{" "}
                {moment(incident?.deferredDate).local().format("MM/DD/YYYY")}
              </Typography>
            </Grid>
          ) : (
            <Box />
          )}

          <Grid item xs={12} md={4}>
            <Typography variant="body1">
              <b>Location:</b> {incident?.location}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="body1">
              <b>Reporter:</b> {incident?.creator?.userName}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="body1">
              <b>Reported Date:</b>{" "}
              {moment(incident?.createdDate).local().format("MM/DD/YYYY")}
            </Typography>
          </Grid>
          {incident?.shipStatus !== undefined ? (
            <Grid item xs={12} md={4}>
              <Typography variant="body1">
                <b>Ship Status:</b> {getShipStatus(incident?.shipStatus)}
              </Typography>
            </Grid>
          ) : (
            <Box />
          )}
          {incident?.manHours !== undefined ? (
            <Grid item xs={12} md={4}>
              <Typography variant="body1">
                <b>Man Hours:</b> {incident?.manHours}
              </Typography>
            </Grid>
          ) : (
            <Box />
          )}
          <Grid item xs={12} md={4}>
            <Typography variant="body1">
              <b>Environmental Data:</b> {incident?.environmentalData}
            </Typography>
          </Grid>

          {incident?.status === 2 ? (
            <Grid item xs={12} md={4}>
              <Typography variant="body1">
                <b>Resolution Date:</b>{" "}
                {moment(incident?.resolutionTime).local().format("MM/DD/YYYY")}
              </Typography>
            </Grid>
          ) : (
            <Box />
          )}

          {incident?.equipment !== undefined &&
          incident?.equipment?.length > 0 ? (
            <Grid item xs={12}>
              <b>Equipment: </b>{" "}
              {incident?.equipment?.map((e) => (
                <Chip
                  sx={{ mr: 1, mt: 1, mb: 1, textTransform: "none" }}
                  color="primary"
                  label={e.name}
                  component={Button}
                  onClick={() =>
                    navigate(`/ships/${selectedShip.id}/equipment/${e.id}`)
                  }
                />
              ))}
            </Grid>
          ) : (
            <Box />
          )}
        </Grid>
      </PaperSection>
    </Box>
  );
}
