import axios from "axios";
import { Trip, TripPassenger, TripStop } from "../types/Trip";

//const API_URL: string = "https://localhost:7046/";
const API_URL: string = "https://api.zsail.io/";

export const getTrips = (clientId?: string, shipId?: string) => {
  return axios
    .get(`${API_URL}shiptrip?clientId=${clientId}&shipId=${shipId}`)
    .then((response) => {
      return response.data;
    });
};

export const getTrip = (
  clientId?: string,
  shipId?: string,
  tripId?: string
) => {
  return axios
    .get(`${API_URL}shiptrip/${tripId}?clientId=${clientId}&shipId=${shipId}`)
    .then((response) => {
      return response.data;
    });
};

export const getTripPassenger = (
  clientId?: string,
  shipId?: string,
  tripId?: string,
  passengerId?: string,
) => {
  return axios
    .get(`${API_URL}shiptrip/${tripId}/passengers/${passengerId}?clientId=${clientId}&shipId=${shipId}`)
    .then((response) => {
      return response.data;
    });
};

export const getTripTravelInformation = (
  clientId?: string,
  shipId?: string,
  tripId?: string
) => {
  return axios
    .get(
      `${API_URL}shiptrip/${tripId}/travelSummary?clientId=${clientId}&shipId=${shipId}`
    )
    .then((response) => {
      return response.data;
    });
};

export const getTripDrinkInformation = (
  clientId?: string,
  shipId?: string,
  tripId?: string
) => {
  return axios
    .get(
      `${API_URL}shiptrip/${tripId}/drinks?clientId=${clientId}&shipId=${shipId}`
    )
    .then((response) => {
      return response.data;
    });
};

export const getTripFoodInformation = (
  clientId?: string,
  shipId?: string,
  tripId?: string
) => {
  return axios
    .get(
      `${API_URL}shiptrip/${tripId}/food?clientId=${clientId}&shipId=${shipId}`
    )
    .then((response) => {
      return response.data;
    });
};


export const getTripWildlifeInformation = (
  clientId?: string,
  shipId?: string,
  tripId?: string
) => {
  return axios
    .get(
      `${API_URL}shiptrip/${tripId}/wildlife?clientId=${clientId}&shipId=${shipId}`
    )
    .then((response) => {
      return response.data;
    });
};

export const getTripActivityInformation = (
  clientId?: string,
  shipId?: string,
  tripId?: string
) => {
  return axios
    .get(
      `${API_URL}shiptrip/${tripId}/activity?clientId=${clientId}&shipId=${shipId}`
    )
    .then((response) => {
      return response.data;
    });
};

export const getTripMedicalInformation = (
  clientId?: string,
  shipId?: string,
  tripId?: string
) => {
  return axios
    .get(
      `${API_URL}shiptrip/${tripId}/medical?clientId=${clientId}&shipId=${shipId}`
    )
    .then((response) => {
      return response.data;
    });
};
export const getTripAquaticInformation = (
  clientId?: string,
  shipId?: string,
  tripId?: string
) => {
  return axios
    .get(
      `${API_URL}shiptrip/${tripId}/aquatic?clientId=${clientId}&shipId=${shipId}`
    )
    .then((response) => {
      return response.data;
    });
};

export const updateTrip = (clientId?: string, shipId?: string, trip?: Trip) => {
  return axios
    .post(
      `${API_URL}shiptrip/update?clientId=${clientId}&shipId=${shipId}`,
      trip
    )
    .then((response) => {
      return response.data;
    });
};

export const createTrip = (clientId?: string, shipId?: string, trip?: Trip) => {
  return axios
    .post(`${API_URL}shiptrip?clientId=${clientId}&shipId=${shipId}`, trip)
    .then((response) => {
      return response.data;
    });
};

export const createTripStop = (
  clientId?: string,
  shipId?: string,
  tripId?: string,
  stop?: TripStop
) => {
  return axios
    .post(
      `${API_URL}shiptrip/${tripId}/stops?clientId=${clientId}&shipId=${shipId}`,
      stop
    )
    .then((response) => {
      return response.data;
    });
};

export const deleteTripStop = (
  clientId?: string,
  shipId?: string,
  tripId?: string,
  stopId?: string
) => {
  return axios
    .delete(
      `${API_URL}shiptrip/${tripId}/stops?clientId=${clientId}&shipId=${shipId}&tripStopId=${stopId}`
    )
    .then((response) => {
      return response.data;
    });
};

export const createTripPassenger = (
  clientId?: string,
  shipId?: string,
  tripId?: string,
  passenger?: TripPassenger
) => {
  return axios
    .post(
      `${API_URL}shiptrip/${tripId}/passengers?clientId=${clientId}&shipId=${shipId}`,
      passenger
    )
    .then((response) => {
      return response.data;
    });
};

export const updateTripPassenger = (
  clientId?: string,
  shipId?: string,
  tripId?: string,
  passenger?: TripPassenger
) => {
  return axios
    .post(
      `${API_URL}shiptrip/${tripId}/passengers/${passenger?.id}?clientId=${clientId}&shipId=${shipId}`,
      passenger
    )
    .then((response) => {
      return response.data;
    });
};

export const deleteTripPassenger = (
  clientId?: string,
  shipId?: string,
  tripId?: string,
  passengerId?: string
) => {
  return axios
    .delete(
      `${API_URL}shiptrip/${tripId}/passengers?clientId=${clientId}&shipId=${shipId}&passengerId=${passengerId}`
    )
    .then((response) => {
      return response.data;
    });
};

export const sendPassengerEmail = (
  clientId?: string,
  shipId?: string,
  tripId?: string,
  passengerId?: string
) => {
  return axios
    .get(
      `${API_URL}shiptrip/${tripId}/email?clientId=${clientId}&shipId=${shipId}&passengerId=${passengerId}`
    )
    .then((res) => {
      return res.data;
    });
};
