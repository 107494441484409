import {
  ArrowCircleRight,
  CalendarMonth,
  CheckBox,
  Done,
  EditNote,
  Group,
  Person,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Chip,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import MultiProgress from "react-multi-progress";
import { useNavigate } from "react-router-dom";
import { ShipTodoList } from "../../types/TodoList";
import { Ship, ShipUserRole } from "../../types/Ship";
import { getShipUserRoleValue } from "../../utilities/shipUtils";
import { User } from "../../types/User";

export type TodoListCardParams = {
  todoList: ShipTodoList;
  ship: Ship | undefined;
  roles: ShipUserRole[];
  completed: Boolean;
  users: User[];
};
export default function TodoListCard(params: TodoListCardParams) {
  const navigate = useNavigate();
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card>
            <CardActionArea
              onClick={() => {
                if (params.todoList.status === 4) {
                  navigate(
                    `/ships/${params.ship?.id}/todolists/${params.todoList.id}/review`
                  );
                } else {
                  navigate(
                    `/ships/${params.ship?.id}/todolists/${params.todoList.id}`
                  );
                }
              }}
            >
              <Grid sx={{ p: 2 }} container spacing={2}>
                <Grid item sx={{ mr: 2 }} xs={1} sm={1} md={1} lg={1} xl={1}>
                  <CheckBox />
                </Grid>
                <Grid item xs={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography variant="body1">
                        {params.todoList.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      {params.todoList.userRoleToggle === "user" ? (
                        <Box>
                          <Box
                            sx={{
                              display: {
                                xs: "none",
                                sm: "none",
                                md: "flex",
                                lg: "flex",
                                xl: "flex",
                              },
                            }}
                            justifyContent="flex-end"
                          >
                            <Chip
                              icon={<Person />}
                              label={params.todoList.assigneeUserName}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: {
                                xs: "flex",
                                sm: "flex",
                                md: "none",
                                lg: "none",
                                xl: "none",
                              },
                            }}
                          >
                            <Chip
                              icon={<Person />}
                              label={params.todoList.assigneeUserName}
                            />
                          </Box>
                        </Box>
                      ) : (
                        <Box>
                          <Box
                            sx={{
                              display: {
                                xs: "none",
                                sm: "none",
                                md: "flex",
                                lg: "flex",
                                xl: "flex",
                              },
                            }}
                            justifyContent="flex-end"
                          >
                            <Tooltip
                              sx={{ zIndex: 999 }}
                              title={
                                <Stack direction="column">
                                  {params.todoList.assignedRoleId === 0
                                    ? params.users.map((u) => {
                                        return (
                                          <Typography variant="body1">
                                            {u.userName}
                                          </Typography>
                                        );
                                      })
                                    : params.roles
                                        .filter(
                                          (role) =>
                                            role.role ===
                                            params.todoList.assignedRoleId
                                        )
                                        .map((roleUser) => {
                                          return (
                                            <Typography variant="body1">
                                              {roleUser.userName}
                                            </Typography>
                                          );
                                        })}
                                </Stack>
                              }
                            >
                              <Chip
                                icon={<Group />}
                                label={getShipUserRoleValue(
                                  params.todoList.assignedRoleId
                                )}
                              />
                            </Tooltip>
                          </Box>
                          <Box
                            sx={{
                              display: {
                                xs: "flex",
                                sm: "flex",
                                md: "none",
                                lg: "none",
                                xl: "none",
                              },
                            }}
                          >
                            <Tooltip
                              sx={{ zIndex: 999 }}
                              title={
                                <Stack direction="column">
                                  {params.todoList.assignedRoleId === 0
                                    ? params.users.map((u) => {
                                        return (
                                          <Typography variant="body1">
                                            {u.userName}
                                          </Typography>
                                        );
                                      })
                                    : params.roles
                                        .filter(
                                          (role) =>
                                            role.role ===
                                            params.todoList.assignedRoleId
                                        )
                                        .map((roleUser) => {
                                          return (
                                            <Typography variant="body1">
                                              {roleUser.userName}
                                            </Typography>
                                          );
                                        })}
                                </Stack>
                              }
                            >
                              <Chip
                                icon={<Group />}
                                label={getShipUserRoleValue(
                                  params.todoList.assignedRoleId
                                )}
                              />
                            </Tooltip>
                          </Box>
                        </Box>
                      )}
                    </Grid>
                    {params.todoList.itemCount !== undefined &&
                    params.todoList.inProgressItemsCount !== undefined &&
                    params.todoList.todoItemsCount !== undefined &&
                    params.todoList.skippedItemsCount !== undefined &&
                    params.todoList.completedItemsCount !== undefined ? (
                      params.todoList.status === 0 ? (
                        <Grid item xs={6}>
                          <Chip icon={<EditNote />} label="In Draft" />
                        </Grid>
                      ) : (
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          lg={8}
                          xl={8}
                          sx={{ ml: 2, mr: -2, mt: 2 }}
                        >
                          <MultiProgress
                            round={true}
                            height={6}
                            elements={[
                              {
                                value:
                                  (params.todoList.completedItemsCount /
                                    params.todoList.itemCount) *
                                  100,
                                color: "green",
                              },
                              {
                                value:
                                  (params.todoList.inProgressItemsCount /
                                    params.todoList.itemCount) *
                                  100,
                                color: "#35baf6",
                              },
                              {
                                value:
                                  (params.todoList.skippedItemsCount /
                                    params.todoList.itemCount) *
                                  100,
                                color: "red",
                              },
                              {
                                value:
                                  (params.todoList.todoItemsCount /
                                    params.todoList.itemCount) *
                                  100,
                                color: "lightgray",
                              },
                            ]}
                          />
                        </Grid>
                      )
                    ) : (
                      <Grid item xs={6}>
                        <Chip icon={<EditNote />} label="In Draft" />
                      </Grid>
                    )}
                    {params.todoList.dueDate !== null &&
                    params.todoList.dueDate !== undefined &&
                    params.todoList.status === 0 ? (
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box
                          sx={{
                            display: {
                              xs: "none",
                              sm: "none",
                              md: "flex",
                              lg: "flex",
                              xl: "flex",
                            },
                          }}
                          justifyContent="flex-end"
                        >
                          {params.completed ? (
                            <Chip
                              icon={<Done />}
                              label={`${moment(
                                params.todoList.completedDate
                              ).local().format("MM/DD/YYYY")}`}
                            />
                          ) : (
                            <Chip
                              icon={<CalendarMonth />}
                              label={`Due ${moment(
                                params.todoList.dueDate
                              ).local().format("MM/DD/YYYY")}`}
                            />
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: {
                              xs: "flex",
                              sm: "flex",
                              md: "none",
                              lg: "none",
                              xl: "none",
                            },
                          }}
                        >
                          {params.completed ? (
                            <Chip
                              icon={<Done />}
                              label={`${moment(
                                params.todoList.completedDate
                              ).local().format("MM/DD/YYYY")}`}
                            />
                          ) : (
                            <Chip
                              icon={<CalendarMonth />}
                              label={`Due ${moment(
                                params.todoList.dueDate
                              ).local().format("MM/DD/YYYY")}`}
                            />
                          )}
                        </Box>
                      </Grid>
                    ) : (params.todoList.dueDate !== null &&
                        params.todoList.dueDate !== undefined &&
                        params.todoList.status !== 0) ||
                      params.todoList.dueDate !== undefined ? (
                      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <Box
                          sx={{
                            display: {
                              xs: "none",
                              sm: "none",
                              md: "flex",
                              lg: "flex",
                              xl: "flex",
                            },
                          }}
                          justifyContent="flex-end"
                        >
                          {params.completed ? (
                            <Chip
                              icon={<Done />}
                              label={`${moment(
                                params.todoList.completedDate
                              ).format("MM/DD/YYYY")}`}
                            />
                          ) : (
                            <Chip
                              icon={<CalendarMonth />}
                              label={`Due ${moment(
                                params.todoList.dueDate
                              ).format("MM/DD/YYYY")}`}
                            />
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: {
                              xs: "flex",
                              sm: "flex",
                              md: "none",
                              lg: "none",
                              xl: "none",
                            },
                          }}
                        >
                          {params.completed ? (
                            <Chip
                              icon={<Done />}
                              label={`${moment(
                                params.todoList.completedDate
                              ).local().format("MM/DD/YYYY")}`}
                            />
                          ) : (
                            <Chip
                              icon={<CalendarMonth />}
                              label={`Due ${moment(
                                params.todoList.dueDate
                              ).local().format("MM/DD/YYYY")}`}
                            />
                          )}
                        </Box>
                      </Grid>
                    ) : (
                      <Box />
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                  <Box display="flex" justifyContent="flex-end">
                    {params.todoList.status === 4 ? (
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          navigate(
                            `/ships/${params.ship?.id}/todolists/${params.todoList.id}/review`
                          );
                        }}
                      >
                        REVIEW
                      </Button>
                    ) : (
                      <IconButton
                        onClick={() =>
                          navigate(
                            `/ships/${params.ship?.id}/todolists/${params.todoList.id}`
                          )
                        }
                        edge="end"
                      >
                        <ArrowCircleRight />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
