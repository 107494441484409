import { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { userProfileState } from "../Recoil/atoms/UserProfileAtom";
import { Link, useNavigate } from "react-router-dom";
import { SideBarSelectedState } from "../Recoil/atoms/SideBarSelectedAtom";
import {
  Container,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Modal,
  Box,
} from "@mui/material";
import { ClientsState, SelectedClientState } from "../Recoil/atoms/ClientsAtom";
import EquipmentStatus from "../components/Dashboard/EquipmentStatus";
import InventoryStatus from "../components/Dashboard/InventoryStatus";
import EquipmentTasksWidget from "../components/Dashboard/EquipmentTasksWidget";
import DateTasksWidget from "../components/Dashboard/DateTasksWidget";
import { DirectionsBoat, Edit } from "@mui/icons-material";
import { SelectedShipState, ShipsState } from "../Recoil/atoms/ShipsAtom";
import IsUserElevated from "../utilities/userUtils";
import { editShip, getShips } from "../services/shipService";
import { currencyFormat, getShipStatus } from "../utilities/shipUtils";
import { LoadingState } from "../Recoil/atoms/LoadingAtom";
import TodoListCounterWidget from "../components/Dashboard/TodoListCounterWidget";
import EquipmentDueCounter from "../components/Dashboard/EquipmentDueCounter";
import LowInventoryParts from "../components/Dashboard/LowInventoryParts";
import OverdueTaskCounter from "../components/Dashboard/OverdueTaskCounter";
import TodoListCounterWidgetCrew from "../components/Dashboard/TodoListCounterWidgetCrew";
import LoadingScreen from "./LoadingScreen/LoadingScreen";
import moment from "moment";
import Invitations from "./Invitations";
import AccordianPaperSection from "../components/AccordianPaperSection";
import PaperTile from "../components/PaperTile";

export default function Dashboard() {
  const [userProfile] = useRecoilState(userProfileState);
  const [clients] = useRecoilState(ClientsState);
  const [ships, setShips] = useRecoilState(ShipsState);
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [open, setOpen] = useState(false);
  const [loading] = useRecoilState(LoadingState);
  const [dashboardShipStatus, setDashboardShipStatus] = useState<
    number | undefined
  >(0);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const setSelected = useSetRecoilState(SideBarSelectedState);
  const navigate = useNavigate();

  if (loading) {
    return <LoadingScreen />;
  } else if (clients.length > 0) {
    return (
      <Container maxWidth="xl" sx={{}}>
        {selectedShip.id === undefined || selectedShip.id === "" ? (
          <LoadingScreen />
        ) : (
          <Box>
            <Modal
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              <Box
                sx={{
                  position: "absolute" as "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  height: 200,
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Typography variant="h5" sx={{ mb: 4 }}>
                  Set Status
                </Typography>
                <TextField
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DirectionsBoat />
                      </InputAdornment>
                    ),
                  }}
                  select
                  value={dashboardShipStatus ?? ""}
                  sx={{ height: "50px", width: "200px", mr: 2 }}
                >
                  <MenuItem
                    key="status-0"
                    onClick={() => {
                      var ship = JSON.parse(
                        JSON.stringify(
                          ships.filter((s) => s.id === selectedShip.id)[0]
                        )
                      );
                      ship.status = 0;
                      editShip(ship).then((res) => {
                        setDashboardShipStatus(0);
                        getShips(selectedClient.id).then((response) =>
                          setShips(response)
                        );
                        setOpen(false);
                      });
                    }}
                    value="0"
                  >
                    In Port
                  </MenuItem>
                  <MenuItem
                    key="status-1"
                    value="1"
                    onClick={() => {
                      var ship = JSON.parse(
                        JSON.stringify(
                          ships.filter((s) => s.id === selectedShip.id)[0]
                        )
                      );
                      ship.status = 1;
                      editShip(ship).then((res) => {
                        setDashboardShipStatus(1);
                        getShips(selectedClient.id).then((response) =>
                          setShips(response)
                        );
                        setOpen(false);
                      });
                    }}
                  >
                    In Transit
                  </MenuItem>
                  <MenuItem
                    key="status-2"
                    value="2"
                    onClick={() => {
                      var ship = JSON.parse(
                        JSON.stringify(
                          ships.filter((s) => s.id === selectedShip.id)[0]
                        )
                      );
                      ship.status = 2;
                      editShip(ship).then((res) => {
                        setDashboardShipStatus(2);
                        getShips(selectedClient.id).then((response) =>
                          setShips(response)
                        );
                        setOpen(false);
                      });
                    }}
                  >
                    Chartered
                  </MenuItem>
                  <MenuItem
                    key="status-3"
                    value="3"
                    onClick={() => {
                      var ship = JSON.parse(
                        JSON.stringify(
                          ships.filter((s) => s.id === selectedShip.id)[0]
                        )
                      );
                      ship.status = 3;
                      editShip(ship).then((res) => {
                        setDashboardShipStatus(3);
                        getShips(selectedClient.id).then((response) =>
                          setShips(response)
                        );
                        setOpen(false);
                      });
                    }}
                  >
                    In Dry Dock
                  </MenuItem>
                </TextField>
              </Box>
            </Modal>
            {clients.length === 0 &&
            !loading &&
            userProfile.isSystemAdministrator === false ? (
              <Typography variant="h5">
                You are not a member of any client, please check{" "}
                <Link
                  to="/invitations"
                  onClick={() => setSelected("Invitations")}
                >
                  Invitations
                </Link>{" "}
                for more information.
              </Typography>
            ) : (
              <Box />
            )}

            {ships === undefined || ships.length === 0 ? (
              <Box />
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <AccordianPaperSection
                    title={selectedShip?.name}
                    icon={<DirectionsBoat />}
                    collapsed={true}
                    collapsedText="Information"
                    elevation={24}
                    button={
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          navigate(`/ships/${selectedShip.id}/edit`)
                        }
                        startIcon={<Edit />}
                      >
                        Edit
                      </Button>
                    }
                  >
                    <Grid container spacing={2} sx={{ p: 4 }}>
                      <Grid xs={6} item>
                        <Stack>
                          <Typography variant="h6">Make: </Typography>
                          <Typography>{selectedShip?.make}</Typography>
                        </Stack>
                      </Grid>
                      <Grid xs={6} item>
                        <Stack>
                          <Typography variant="h6">Model: </Typography>
                          <Typography>{selectedShip?.model}</Typography>
                        </Stack>
                      </Grid>
                      <Grid xs={6} item>
                        <Stack>
                          <Typography variant="h6">Year: </Typography>
                          <Typography>{selectedShip?.year}</Typography>
                        </Stack>
                      </Grid>
                      <Grid xs={6} item>
                        <Stack>
                          <Typography variant="h6">Size (In Feet): </Typography>
                          <Typography>{selectedShip?.sizeInFeet}</Typography>
                        </Stack>
                      </Grid>
                      <Grid xs={6} item>
                        <Stack>
                          <Typography variant="h6">Purchase Price: </Typography>
                          <Typography>
                            {currencyFormat(selectedShip?.purchasePrice)}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid xs={6} item>
                        <Stack>
                          <Typography variant="h6">Purchase Date: </Typography>
                          <Typography>
                            {moment(selectedShip?.purchaseDate).format(
                              "MM/DD/YYYY"
                            )}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </AccordianPaperSection>
                </Grid>
                <Grid item xs={12}>
                  <PaperTile
                    title={`The ${
                      selectedShip.name
                    } is currently ${getShipStatus(selectedShip.status)}`}
                    elevation={24}
                    button={
                      <Button
                        variant="contained"
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        Change Status
                      </Button>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  {IsUserElevated(userProfile, selectedClient.id) ? (
                    <TodoListCounterWidget selectedShipId={selectedShip.id} />
                  ) : (
                    <TodoListCounterWidgetCrew
                      selectedShipId={selectedShip.id}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <EquipmentDueCounter selectedShipId={selectedShip.id} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <LowInventoryParts selectedShipId={selectedShip.id} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <OverdueTaskCounter selectedShipId={selectedShip.id} />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <EquipmentStatus selectedShipId={selectedShip.id} />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <InventoryStatus selectedShipId={selectedShip.id} />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <EquipmentTasksWidget selectedShipId={selectedShip.id} />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DateTasksWidget selectedShipId={selectedShip.id} />
                </Grid>
              </Grid>
            )}
          </Box>
        )}
      </Container>
    );
  } else {
    return <Invitations />;
  }
}
