import { Box, Typography } from "@mui/material";
import { Trip } from "../../types/Trip";

export type TripTitleProps = {
    trip?: Trip
}
export default function TripTitle(props: TripTitleProps){
    const {trip} = props;
    switch(trip?.type){
        case 0:
            return <Typography variant="h5">Port Stay in {trip.startLocation}</Typography>;
        case 1:
            return <Typography variant="h5">Transit from {trip.startLocation} to {trip.endLocation}</Typography> 
        case 2:
            return <Typography variant="h5">Charter from {trip.startLocation} to {trip.endLocation}</Typography>
        case 3:
            return <Typography variant="h5">Maintenance Stay in {trip.startLocation}</Typography>;
        default:
            return <Box/>;
    }
}