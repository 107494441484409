import Intercom from '@intercom/messenger-js-sdk';
import { useRecoilState } from 'recoil';
import { userProfileState } from '../../Recoil/atoms/UserProfileAtom';
import { Box } from '@mui/material';

export default function IntercomComponent() {
    const [userProfile] = useRecoilState(userProfileState);

  Intercom({
    app_id: 'tgk326gc',
    user_id: userProfile.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: userProfile.userName, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: userProfile.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
  });

  return <Box/>;
}