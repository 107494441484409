import {
  Box,
  Modal,
  Typography,
} from "@mui/material";
import { ShipTodoListItem } from "../../types/TodoList";
import TodoComment from "./TodoComment";

export type TodoListItemCommentModalProps = {
  todoListItem: ShipTodoListItem | undefined;
  setShipTodoListItem: Function;
  open: boolean;
  onClose: Function;
};
export default function TodoListItemCommentModal(
  props: TodoListItemCommentModalProps
) {
  const { todoListItem, setShipTodoListItem, open, onClose } = props;
  return (
    <Modal
      onClose={() => {
        setShipTodoListItem(undefined);
        onClose(false);
      }}
      open={open}
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          sx={{ mb: 3 }}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Comments
        </Typography>
        {todoListItem !== undefined && todoListItem.comments !== undefined ? (
          todoListItem.comments?.map((comment) => {
            return (
              <TodoComment elevation={1} comment={comment}/>
            );
          })
        ) : (
          <Box />
        )}
      </Box>
    </Modal>
  );
}
