import { DirectionsBoat, Map, Route } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { useEffect, useState } from "react";
import { Trip } from "../../types/Trip";
import { getTrip } from "../../services/tripservice";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import TripNotesCard from "../../components/Trip/TripNotesCard";
import TripPassengersCard from "../../components/Trip/TripPassengersCard";
import TripStopsCard from "../../components/Trip/TripStopsCard";
import TripInformationSection from "../../components/Trip/TripInformationSection";
import ShipTravelCard from "../../components/Trip/ShipTravelCard";

export default function TripView() {
  const navigate = useNavigate();
  const params = useParams();
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [trip, setTrip] = useState<Trip>();

  useEffect(() => {
    getTrip(selectedClient.id, selectedShip.id, params.tripid).then((res) => {
      setTrip(res);
    });
  }, [selectedClient, selectedShip, params.tripid]);

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${selectedShip.id}/trips`)}
          startIcon={<Map sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Trips
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Route sx={{ mr: 0.5 }} fontSize="inherit" />
          Trip
        </Typography>
      </Breadcrumbs>
      <Grid container>
        <Grid item xs={12}>
          <TripInformationSection trip={trip} setTrip={setTrip} />
        </Grid>
        {trip?.type === 2 ? (
          <Grid item xs={12}>
            <TripStopsCard trip={trip} setTrip={setTrip} />
          </Grid>
        ) : (
          <Box />
        )}
        {trip?.type === 2 ? (
          <Grid item xs={12}>
            <TripPassengersCard trip={trip} setTrip={setTrip} />
          </Grid>
        ) : (
          <Box />
        )}
        {trip?.type === 2 ? (
          <Grid item xs={12}>
            <ShipTravelCard trip={trip} />
          </Grid>
        ) : (
          <Box />
        )}
        <Grid item xs={12}>
          <TripNotesCard trip={trip} />
        </Grid>
      </Grid>
    </Container>
  );
}
