import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useRecoilState } from "recoil";
import { ShipTodoList, ShipTodoListItem, ShipTodoListItemComment } from "../../../types/TodoList";
import { SelectedClientState } from "../../../Recoil/atoms/ClientsAtom";
import { getShipTodoList, updateTodoListItemStatus } from "../../../services/todoListService";

export type SendBackModalProps = {
  open: boolean;
  onClose: Function;
  todoList: ShipTodoList | undefined;
  todoListItem: ShipTodoListItem | undefined;
  setTodoList: Function;
  setTodoListItem: Function;
};

export default function SendBackModal(props: SendBackModalProps) {
  const { todoList, todoListItem, setTodoList, setTodoListItem } = props;
  const [selectedClient] = useRecoilState(SelectedClientState);
  var initialState = {
    comment: "",
  };
  const validationSchema = yup.object({
    comment: yup.string().required("Comment is required"),
  });
  return (
    <Modal open={props.open} onClose={() => props.onClose(false)}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          sx={{ mb: 3 }}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Review Notes
        </Typography>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            var comment: ShipTodoListItemComment = {
              comment: values.comment,
            };
            if(todoList === undefined || todoListItem === undefined){
                return;
            }
            updateTodoListItemStatus(
              todoList.id,
              todoListItem.id,
              selectedClient.id,
              0,
              comment
            ).then((res) => {
              getShipTodoList(todoList.id, selectedClient.id).then(
                (response) => {
                  setTodoList(response);
                  setTodoListItem(undefined);
                  props.onClose(false);
                }
              );
            });
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                id="comment"
                name="comment"
                label="Comment"
                value={values.comment}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.comment && Boolean(errors.comment)}
                helperText={touched.comment && errors.comment}
                fullWidth
                sx={{ mb: 2 }}
              />

              {values.comment === "" ? (
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled
                  type="submit"
                >
                  Submit
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Submit
                </Button>
              )}
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}
