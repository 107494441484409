import { LocalPolice, Policy } from "@mui/icons-material";
import { Button, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

export type IncidentCardProps = {
  title?: string;
  isEmergency?: boolean;
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined,
};
export default function IncidentCard(props: IncidentCardProps) {
  const { title, isEmergency, children, onClick } = props;
  return (
    <Paper
      sx={{
        p: 1,
        borderLeftColor: "#b47f49",
        borderTopWidth: "0px",
        borderStyle: "solid",
        borderRightWidth: "0px",
        borderLeftWidth: "4px",
        borderBottomWidth: "0",
        textTransform: "none",
        mb: 2,
        pl: 2,
        pr: 2
      }}
      component={Button}
      onClick={onClick}
      fullWidth
      elevation={12}
    >
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <Stack
            direction="column"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            {isEmergency ? (
              <LocalPolice sx={{ fontSize: 44, color: "red" }} />
            ) : (
              <Policy sx={{ fontSize: 44, color: "white" }} />
            )}
          </Stack>
        </Grid>
        <Grid item xs={11}>
          <Grid container spacing={1}>
            <Grid item xs={12} textAlign="start">
              <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
