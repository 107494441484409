import { IconGlassCocktail } from "@tabler/icons-react";
import { ShipDrinkInformationSummary } from "../../../types/PassengerTypes";
import AccordianPaperSection from "../../AccordianPaperSection";
import { Scrollbar } from "react-scrollbars-custom";
import { Paper, Stack, Typography } from "@mui/material";

export type AlcoholicCannotHaveCardProps = {
  drinks: ShipDrinkInformationSummary;
};

export default function AlcoholicCannotHaveCard(
  props: AlcoholicCannotHaveCardProps
) {
  const { drinks } = props;

  return (
    <AccordianPaperSection
      title="Alcohol - Cannot Have"
      icon={<IconGlassCocktail />}
    >
        <Scrollbar style={{ height: 300 }}>
        {drinks.cannotHaveAlcoholic?.map((mh) => (
          <Paper
            sx={{
              p: 1,
              borderLeftColor: "white",
              borderTopWidth: "0px",
              borderStyle: "solid",
              borderRightWidth: "0px",
              borderLeftWidth: "4px",
              borderBottomWidth: "0",
              mr: 1,
              mb: 2
            }}
            elevation={24}
          >
            <Stack direction="row" spacing={2}>
              <IconGlassCocktail />
              <Typography variant="body1">{mh}</Typography>
            </Stack>
          </Paper>
        ))}
        </Scrollbar>
    </AccordianPaperSection>
  );
}
