import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  createTask,
  getEquipment,
  getShip,
  getShipParts,
} from "../../services/shipService";
import { useNavigate, useParams } from "react-router-dom";
import { Ship } from "../../types/Ship";
import { useRecoilState } from "recoil";
import * as yup from "yup";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { Formik } from "formik";
import { Equipment } from "../../types/Equipment";
import { Part } from "../../types/Part";
import { Task, TaskPart } from "../../types/Task";
import { Add, DirectionsBoat, Info, TaskAlt } from "@mui/icons-material";
import { Editor } from "@tinymce/tinymce-react";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";

export default function NewTask() {
  const params = useParams();
  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [equipment, setEquipment] = useState<Equipment[]>([]);
  const [parts, setParts] = useState<Part[]>([]);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [open, setOpen] = useState<boolean>(false);
  const [preModalTask, setPreModalTask] = useState<Task>();
  const [selectedShip] = useRecoilState(SelectedShipState);

  const initialState = {
    name: "",
    description: "",
    forEquipment: false,
    equipmentId: "",
    instructions: "",
    priority: 0,
    dateFrequencyInterval: 0,
    dateFrequency: 0,
    unitFrequencyInterval: 0,
    requiresPart: false,
    parts: [],
  };

  const validationSchema = yup.object({
    name: yup.string().required("Name is required."),
    description: yup.string().required("Description is required."),
    forEquipment: yup.boolean().required("For Equipment is required."),
    equipmentId: yup.string(),
    priority: yup.number().required("Priority is required."),
    dateFrequencyInterval: yup
      .number()
      .required("Date Frequency Interval Required"),
    dateFrequency: yup.number().required("Date Frequency Required"),
    unitFrequencyInterval: yup
      .number()
      .required("Unit Frequency Interval required."),
    requiresPart: yup.boolean().required("Requires Part is required."),
    parts: yup.array(),
  });

  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
      getEquipment(selectedClient.id, params.id).then((response) => {
        setEquipment(response);
      });
      getShipParts(params.id, selectedClient.id).then((partsResponse) => {
        setParts(partsResponse);
      });
    });
  }, [selectedClient, params.id]);

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            sx={{ mb: 3 }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Set Part Quantity
          </Typography>
          {preModalTask === undefined ||
          preModalTask.parts === undefined ||
          preModalTask.parts.length === 0 ? (
            <Typography variant="h6">No Parts Required.</Typography>
          ) : (
            preModalTask?.parts.map((t) => (
              <TextField
                fullWidth
                type="number"
                defaultValue={t.partQuantity}
                label={parts.find((p) => p.id === t.shipPartId)?.name}
                onChange={(event) => {
                  t.partQuantity = parseInt(event.target.value);
                }}
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
              />
            ))
          )}
          <Stack spacing={2} direction="row-reverse">
            <Button
              variant="contained"
              onClick={() => {
                console.log(preModalTask);
                createTask(preModalTask, selectedClient.id, ship?.id).then(
                  (res) => {
                    navigate(`/ships/${ship?.id}`);
                  }
                );
              }}
            >
              Submit
            </Button>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </Stack>
        </Box>
      </Modal>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}`)}
          startIcon={<TaskAlt sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Tasks
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Add sx={{ mr: 0.5 }} fontSize="inherit" />
          New Task
        </Typography>
      </Breadcrumbs>
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (
            values.forEquipment &&
            values.equipmentId !== undefined &&
            values.equipmentId !== ""
          ) {
            var task: Task = {
              name: values.name,
              description: values.description,
              instructions: values.instructions,
              forEquipment: values.forEquipment,
              dateFrequency: values.dateFrequency,
              equipmentId: values.equipmentId,
              dateFrequencyInterval: values.dateFrequencyInterval,
              unitFrequencyInterval: values.unitFrequencyInterval,
              requiresPart: values.requiresPart,
              priority: values.priority,
              parts: values.parts.map((part) => {
                var taskPart: TaskPart = { shipPartId: part, partQuantity: 0 };
                return taskPart;
              }),
            };
            setPreModalTask(task);
          } else {
            var task2: Task = {
              name: values.name,
              description: values.description,
              instructions: values.instructions,
              forEquipment: values.forEquipment,
              dateFrequency: values.dateFrequency,
              dateFrequencyInterval: values.dateFrequencyInterval,
              unitFrequencyInterval: values.unitFrequencyInterval,
              requiresPart: values.requiresPart,
              priority: values.priority,
              parts: values.parts.map((part) => {
                var taskPart: TaskPart = { shipPartId: part, partQuantity: 0 };
                return taskPart;
              }),
            };
            setPreModalTask(task2);
          }
          setOpen(true);
        }}
      >
        {({
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <TextField
                id="name"
                name="name"
                label="Name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                id="description"
                name="description"
                label="Description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
                fullWidth
                sx={{ mb: 2 }}
              />
              <Typography variant="subtitle1">Instructions:</Typography>
              <Editor
                apiKey="s7hflnfnqq2c0nxj75udyor3kobji9x4jv2xgc4xvsdhtju5"
                init={{
                  height: 500,
                  menubar: true,
                  skin: "oxide-dark",
                  content_css: "dark",
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent| " +
                    "removeformat",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                textareaName="instructions"
                onEditorChange={(stringifiedHTML) => {
                  setFieldValue("instructions", stringifiedHTML);
                }}
              />
              <FormControlLabel
                control={<Checkbox checked={values.forEquipment} />}
                label="Is the Task For Equipment?"
                name="forEquipment"
                onChange={handleChange}
                sx={{ mb: 2 }}
              />
              {values.forEquipment === false ? (
                <React.Fragment />
              ) : (
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="demo-simple-select-label">
                    Equipment
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values.equipmentId}
                    onChange={(event) => {
                      setFieldValue("equipmentId", event.target.value);
                    }}
                  >
                    {equipment.map((e) => (
                      <MenuItem id={e.id} key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <Alert
                variant="outlined"
                severity="info"
                title="Task Priority"
                sx={{ mb: 2 }}
              >
                <List>
                  <ListItem>Task Priority</ListItem>
                  <ListItem>
                    Critical - This task needs to be completed as soon as
                    possible.
                  </ListItem>
                  <ListItem>
                    Required - This task needs to be completed.
                  </ListItem>
                  <ListItem>
                    Normal - This task should be completed when someone
                    regularly when someone is available.
                  </ListItem>
                </List>
              </Alert>
              <FormControl sx={{ mb: 2 }} fullWidth>
                <InputLabel id="priority-simple-select-label">
                  Priority
                </InputLabel>
                <Select
                  labelId="priority-simple-select-label"
                  id="priority-simple-select"
                  value={values.priority}
                  onChange={(event) => {
                    setFieldValue("priority", event.target.value);
                  }}
                >
                  <MenuItem id="priority-1" key="priority-1" value={1}>
                    Critical
                  </MenuItem>
                  <MenuItem id="priority-3" key="priority-3" value={3}>
                    Required
                  </MenuItem>
                  <MenuItem id="priority-2" key="priority-2" value={2}>
                    Normal
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="dateFrequency-simple-select-label">
                  Date Frequency Interval
                </InputLabel>
                <Select
                  labelId="dateFrequency-simple-select-label"
                  id="dateFrequency-simple-select"
                  value={values.dateFrequencyInterval}
                  onChange={(event) => {
                    setFieldValue("dateFrequencyInterval", event.target.value);
                  }}
                >
                  <MenuItem
                    id="dateFrequency-1"
                    key="dateFrequency-1"
                    value={0}
                  >
                    Not A Date Based Task
                  </MenuItem>
                  <MenuItem
                    id="dateFrequency-2"
                    key="dateFrequency-2"
                    value={1}
                  >
                    Every X Days
                  </MenuItem>
                  <MenuItem
                    id="dateFrequency-3"
                    key="dateFrequency-3"
                    value={2}
                  >
                    Fixed Day of the Month
                  </MenuItem>
                </Select>
              </FormControl>
              {values.dateFrequencyInterval === 0 ? (
                values.forEquipment === true ? (
                  values.equipmentId !== "" ? (
                    <TextField
                      id="unitFrequencyInterval"
                      name="unitFrequencyInterval"
                      label={`Unit Frequency Interval (in ${
                        equipment.find((e) => e.id === values.equipmentId)
                          ?.usageUnits
                      })`}
                      value={values.unitFrequencyInterval}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.unitFrequencyInterval &&
                        Boolean(errors.unitFrequencyInterval)
                      }
                      helperText={
                        touched.unitFrequencyInterval &&
                        errors.unitFrequencyInterval
                      }
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                  ) : (
                    <React.Fragment />
                  )
                ) : (
                  <React.Fragment />
                )
              ) : (
                <TextField
                  id="dateFrequency"
                  name="dateFrequency"
                  label="Date Frequency"
                  value={values.dateFrequency}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.dateFrequency && Boolean(errors.dateFrequency)}
                  helperText={touched.dateFrequency && errors.dateFrequency}
                  fullWidth
                  sx={{ mb: 2 }}
                />
              )}

              <FormControlLabel
                control={<Checkbox checked={values.requiresPart} />}
                label="Requires Part(s)?"
                name="requiresPart"
                onChange={handleChange}
                sx={{ mb: 2 }}
              />
              {values.requiresPart === false ? (
                <React.Fragment />
              ) : (
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="part-simple-select-label">Parts</InputLabel>
                  <Select
                    labelId="part-simple-select-label"
                    id="part-simple-select"
                    multiple
                    value={values.parts}
                    onChange={(event) => {
                      setFieldValue("parts", event.target.value);
                    }}
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={parts.find((p) => p.id === value)?.name}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {parts.map((p) => (
                      <MenuItem id={p.id} key={p.id} value={p.id}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {values.dateFrequencyInterval === 0 &&
              values.forEquipment === false ? (
                <Box>
                  <Typography variant="body1" sx={{ color: "red" }}>
                    <Info /> Equipment is required.
                  </Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
              ) : values.forEquipment ? (
                values.equipmentId === "" ? (
                  <Box>
                    <Typography variant="body1" sx={{ color: "red" }}>
                      <Info /> Equipment is required.
                    </Typography>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      disabled
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Box>
                ) : values.requiresPart ? (
                  values.parts.length < 1 ? (
                    <Box>
                      <Typography variant="body1" sx={{ color: "red" }}>
                        <Info /> Parts are required.
                      </Typography>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        disabled
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Box>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Submit
                    </Button>
                  )
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                )
              ) : values.requiresPart ? (
                values.parts.length < 1 ? (
                  <Box>
                    <Typography variant="body1" sx={{ color: "red" }}>
                      <Info /> Parts are required.
                    </Typography>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      disabled
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Box>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                )
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Submit
                </Button>
              )}
            </form>
          );
        }}
      </Formik>
    </Container>
  );
}
