import { AttachMoney, MonetizationOn } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Card,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import PaperSection from "../../components/PaperSection";
import { useEffect, useState } from "react";
import { getBilling } from "../../services/adminservice";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { BillingInfo } from "../../types/Client";
import BillingQouta from "../../components/Billing/BillingQouta";

export default function Billing() {
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [billing, setBilling] = useState<BillingInfo>();
  useEffect(() => {
    getBilling(selectedClient.id).then((res) => setBilling(res));
  }, [selectedClient.id]);

  function getEquipmentOverageCost(equipment: number){
    let total = 10;
    let cost = 5;
    if(equipment - total >= 0){
        return (equipment - total) * cost;
    } else{
        return 0;
    }
  }
  function getPartsOverageCost(parts: number){
    let total = 20;
    let cost = 5;
    if(parts - total >= 0){
        return (parts - total) * cost;
    } else{
        return 0;
    }
  }
  function getTasksOverageCost(tasks: number){
    let total = 10;
    let cost = 5;
    if(tasks - total >= 0){
        return (tasks - total) * cost;
    } else{
        return 0;
    }
  }
  function getUsersOverageCost(users: number){
    let total = 10;
    let cost = 15;
    if(users - total >= 0){
        return (users - total) * cost;
    } else{
        return 0;
    }
  }
  function getTotal(bill: BillingInfo){
    return getUsersOverageCost(bill.users) + getTasksOverageCost(bill.tasks) + getPartsOverageCost(bill.parts) + getEquipmentOverageCost(bill.equipment) + 75; 
  }
  return (
    <Container maxWidth={"xl"}>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <MonetizationOn sx={{ mr: 0.5 }} fontSize="inherit" />
          Billing
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {billing !== undefined ? (
            <PaperSection title={"Billing Summary"} icon={<MonetizationOn />}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Card elevation={12}>
                    <Grid container spacing={1} height={312}>
                      <Grid item xs={1}>
                        <Stack
                          direction="column"
                          height="100%"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <AttachMoney sx={{ fontSize: 44 }} />
                        </Stack>
                      </Grid>
                      <Grid item xs={11}>
                        <Grid container spacing={1}>
                          <Grid
                            xs={12}
                            item
                            justifyContent="center"
                            textAlign="center"
                            alignItems="center"
                            width="100%"
                          >
                            <Typography variant="h5">Summary</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            justifyContent="center"
                            textAlign="center"
                            alignItems="center"
                            width="100%"
                          >
                            <Divider />
                          </Grid>
                          <Grid
                            xs={10}
                            item
                          >
                            <Typography variant="body1">Cruiser Subscription:</Typography>
                          </Grid>
                          <Grid
                            xs={2}
                            item
                          >
                            <Typography variant="body1">$75</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            justifyContent="center"
                            textAlign="center"
                            alignItems="center"
                            width="100%"
                          >
                            <Divider />
                          </Grid>
                          <Grid
                            xs={10}
                            item
                          >
                            <Typography variant="body1">Equipment:</Typography>
                          </Grid>
                          <Grid
                            xs={2}
                            item
                          >
                            <Typography variant="body1">${getEquipmentOverageCost(billing.equipment)}</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            justifyContent="center"
                            textAlign="center"
                            alignItems="center"
                            width="100%"
                          >
                            <Divider />
                          </Grid>
                          <Grid
                            xs={10}
                            item
                          >
                            <Typography variant="body1">Parts:</Typography>
                          </Grid>
                          <Grid
                            xs={2}
                            item
                          >
                            <Typography variant="body1">${getPartsOverageCost(billing.parts)}</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            justifyContent="center"
                            textAlign="center"
                            alignItems="center"
                            width="100%"
                          >
                            <Divider />
                          </Grid>
                          <Grid
                            xs={10}
                            item
                          >
                            <Typography variant="body1">Tasks:</Typography>
                          </Grid>
                          <Grid
                            xs={2}
                            item
                          >
                            <Typography variant="body1">${getTasksOverageCost(billing.tasks)}</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            justifyContent="center"
                            textAlign="center"
                            alignItems="center"
                            width="100%"
                          >
                            <Divider />
                          </Grid>
                          <Grid
                            xs={10}
                            item
                          >
                            <Typography variant="body1">Users:</Typography>
                          </Grid>
                          <Grid
                            xs={2}
                            item
                          >
                            <Typography variant="body1">${getUsersOverageCost(billing.users)}</Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            justifyContent="center"
                            textAlign="center"
                            alignItems="center"
                            width="100%"
                          >
                            <Divider />
                          </Grid>
                          <Grid
                            xs={10}
                            item
                          >
                            <Typography variant="body1" sx={{fontWeight: "bold"}}>Total:</Typography>
                          </Grid>
                          <Grid
                            xs={2}
                            item
                          >
                            <Typography variant="body1"sx={{fontWeight: "bold"}}>${getTotal(billing)}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card elevation={12} sx={{ p: 4 }}>
                    <BillingQouta
                      metric={billing.ships}
                      maxMetric={1}
                      metricName={"ship"}
                      pluralMetricName={"ships"}
                      costPerAdditional={75}
                      displayMetricName={true}
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card elevation={12} sx={{ p: 4 }}>
                    <BillingQouta
                      metric={billing.equipment}
                      maxMetric={10}
                      metricName={"equipment"}
                      pluralMetricName={"equipment"}
                      costPerAdditional={5}
                      displayMetricName={true}
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card elevation={12} sx={{ p: 4 }}>
                    <BillingQouta
                      metric={billing.parts}
                      maxMetric={20}
                      metricName={"part"}
                      pluralMetricName={"parts"}
                      costPerAdditional={5}
                      displayMetricName={true}
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card elevation={12} sx={{ p: 4 }}>
                    <BillingQouta
                      metric={billing.tasks}
                      maxMetric={10}
                      metricName={"task"}
                      pluralMetricName={"tasks"}
                      costPerAdditional={5}
                      displayMetricName={true}
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card elevation={12} sx={{ p: 4 }}>
                    <BillingQouta
                      metric={billing.users}
                      maxMetric={5}
                      metricName={"user"}
                      pluralMetricName={"users"}
                      costPerAdditional={15}
                      displayMetricName={true}
                    />
                  </Card>
                </Grid>
              </Grid>
            </PaperSection>
          ) : (
            <Box />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
