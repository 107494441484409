import { Stack } from "@mui/material";
import PriorityIcon from "./PriorityIcon";

export type TodoListPriorityIconProps = {
  priority?: number;
};

export default function TodoListPriorityIcon(props: TodoListPriorityIconProps) {
  const { priority } = props;
  return (
    <Stack alignItems="center" sx={{mr:2}}>
      <PriorityIcon priority={priority} />
    </Stack>
  );
}
