import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { IconSquare, IconSquareCheckFilled } from "@tabler/icons-react";
import AccordianPaperSection from "../AccordianPaperSection";
import { Anchor } from "@mui/icons-material";
import {
  GetDivingCertificationType,
  GetSwimmingLevel,
} from "../../utilities/tripUtils";
import { useEffect, useState } from "react";
import { PassengerAquaticInfo } from "../../types/PassengerTypes";
import { getTripAquaticInformation } from "../../services/tripservice";
import { useParams } from "react-router-dom";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";

export default function TripAquaticSummaryCard() {
  const [aquatic, setAquatic] = useState<PassengerAquaticInfo[]>([]);
  const params = useParams();
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  useEffect(() => {
    getTripAquaticInformation(
      selectedClient.id,
      selectedShip.id,
      params.tripid
    ).then((res) => {
      setAquatic(res);
    });
  }, [params.tripid, selectedClient.id, selectedShip.id]);
  const columns = [
    {
      field: "firstName",
      headerName: `First Name`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.firstName;
      },
    },
    {
      field: "lastName",
      headerName: `Last Name`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.lastName;
      },
    },
    {
      field: "swimmingLevel",

      headerName: `Swimming Level`,
      width: 150,
      valueGetter: (params: any) => {
        return GetSwimmingLevel(params.row.swimmingLevel);
      },
    },
    {
      field: "isCertifiedDiver",
      headerName: `Certified Diver`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.isCertifiedDiver;
      },
      renderCell: (params: GridRenderCellParams<any, Date>) =>
        params.row.isCertifiedDiver ? (
          <IconSquareCheckFilled style={{ color: "green" }} />
        ) : (
          <IconSquare />
        ),
    },
    {
      field: "divingCertificationType",
      headerName: `Diving Certification Type`,
      width: 150,
      valueGetter: (params: any) => {
        if(!params.row.isCertifiedDiver){
            return "N/A";
        }
        return GetDivingCertificationType(params.row.divingCertificationType);
      },
    },
    {
      field: "certificationNumber",
      headerName: `Certification Number`,
      width: 150,
      valueGetter: (params: any) => {
        if(!params.row.isCertifiedDiver){
            return "N/A";
        }
        return params.row.certificationNumber;
      },
    },
    {
      field: "lastDiveDetails",
      headerName: `Last Dive Details`,
      width: 150,
      valueGetter: (params: any) => {
        if(!params.row.isCertifiedDiver){
            return "N/A";
        }
        return params.row.lastDiveDetails;
      },
    },
  ];
  return (
    <AccordianPaperSection icon={<Anchor />} title="Aquatic Proficiency">
      <DataGrid
        rows={aquatic}
        sx={{ minHeight: "300px", mb: 4 }}
        columns={columns}
      />
    </AccordianPaperSection>
  );
}
