import { Replay, SkipNext } from "@mui/icons-material";
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import TodoComment from "../TodoComment";
import { ShipTodoListItem } from "../../../types/TodoList";
import { useNavigate } from "react-router-dom";
import PriorityIcon from "../PriorityIcon";

export type SkipTodoItemReviewProps = {
  item: ShipTodoListItem;
  shipId: string | undefined;
  setSelectedTodoListItem: Function;
  setOpen: Function;
};
export default function SkipTodoItemReview(props: SkipTodoItemReviewProps) {
  const navigate = useNavigate();
  const { item, setSelectedTodoListItem, setOpen, shipId } = props;
  return (
    <Paper elevation={4} sx={{ mb: 2, p: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}>
          <SkipNext sx={{ fontSize: 32 }} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {item.isTask ? (
                <Stack direction="row" alignItems={"center"} spacing={1}>
                  <Button
                    variant="text"
                    sx={{ pt: 0, pl: 0, mr: 2 }}
                    onClick={() => {
                      navigate(`/Ships/${shipId}/tasks/time/${item.taskId}`);
                    }}
                  >
                    {item.taskName}
                  </Button>
                  <PriorityIcon priority={item.priority} />
                </Stack>
              ) : (
                <Stack direction="row" alignItems={"center"} spacing={1}>
                  <PriorityIcon priority={item.priority} />
                  <Typography variant="h5">{item.value}</Typography>
                </Stack>
              )}
            </Grid>
            {item.comments !== undefined && item.comments.length > 0 ? (
              <Grid item xs={12}>
                {item.comments?.map((comment) => (
                  <TodoComment elevation={8} comment={comment} />
                ))}
              </Grid>
            ) : (
              <Box />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              startIcon={<Replay />}
              color="error"
              variant="contained"
              onClick={() => {
                setSelectedTodoListItem(item);
                setOpen(true);
              }}
              fullWidth
            >
              Send Back
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
