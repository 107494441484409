import { atom } from "recoil";
import { Client } from "../../types/Client";

export const ClientsState = atom<Client[]>({
    key: "clients",
    default: []
})

export const SelectedClientState = atom<Client>({
    key: "selectedClient",
    default: {
        id: "",
        name: "",
        billingTier: 0,
    }
})