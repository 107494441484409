import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { IncidentPart, IncidentReport } from "../../types/IncidentReport";
import PaperSection from "../PaperSection";
import { Add, Category, Delete, Edit, Upload } from "@mui/icons-material";
import { useRecoilState } from "recoil";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { useEffect, useState } from "react";
import {
  createIncidentPart,
  deleteIncidentPart,
  getIncidentById,
  updateIncidentPart,
} from "../../services/incidentService";
import {
  DataGrid,
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { getShipParts } from "../../services/shipService";
import { Part } from "../../types/Part";
import { Formik } from "formik";
import { IconTruckDelivery } from "@tabler/icons-react";
export type IncidentPartsCardProps = {
  incident?: IncidentReport;
  setIncident: Function;
};
export default function IncidentPartsCard(props: IncidentPartsCardProps) {
  const { setIncident, incident } = props;
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [createOpen, setCreateOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [parts, setParts] = useState<Part[]>([]);
  const [editPart, setEditPart] = useState<IncidentPart>();

  function filterParts(parts: IncidentPart[], partId: string) {
    if (parts.length < 1) {
      return true;
    } else {
      for (let i = 0; i < parts.length; i++) {
        if (parts[i].shipPart?.id === partId) {
          return false;
        }
      }
      return true;
    }
  }

  const columns = [
    {
      field: "part",
      headerName: `Part`,
      width: 300,
      valueGetter: (params: any) => {
        return params.row.shipPart.name;
      },
    },
    {
      field: "quantity",
      headerName: `Quantity`,
      width: 300,
      valueGetter: (params: any) => {
        return params.row.quantity;
      },
    },
    {
      field: "trackingNumber",
      headerName: `Tracking Number`,
      width: 300,
      valueGetter: (params: any) => {
        return params.row.trackingNumber;
      },
      renderCell: (params: GridRenderCellParams<any, Date>) => {
        return params.row.trackingNumber !== "" &&
          params.row.trackingNumber !== null &&
          params.row.trackingNumber !== undefined ? (
          <Button
            variant="contained"
            endIcon={<IconTruckDelivery />}
            color="secondary"
            onClick={() => {
              window.open(
                `https://www.packagetrackr.com/track/${params.row.trackingNumber}`,
                "_blank"
              );
            }}
          >
            {params.row.trackingNumber}
          </Button>
        ) : (
          <Typography variant="body1">N/A</Typography>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: (params: GridRowParams): any => {
        return [
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            onClick={() => {
              setEditPart(params.row);
              setEditOpen(true);
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={() => {
              deleteIncidentPart(
                selectedClient.id,
                selectedShip.id,
                incident?.id,
                params.row.id
              ).then((res) =>
                getIncidentById(
                  selectedClient.id,
                  selectedShip.id,
                  incident?.id
                ).then((inRes) => setIncident(inRes))
              );
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const initialValues = {
    quantity: 0,
    trackingNumber: "",
    partid: "",
  };
  useEffect(() => {
    getShipParts(selectedShip.id, selectedClient.id).then((res) =>
      setParts(res)
    );
  }, [selectedClient.id, selectedShip.id]);
  return (
    <Box>
      <Modal
        open={createOpen}
        onClose={() => {
          setCreateOpen(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <PaperSection title="Add Part:" icon={<Upload />}>
            {incident !== undefined ? (
              <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                  var part: IncidentPart = {
                    partId: values.partid,
                    incidentId: incident.id,
                    trackingNumber: values.trackingNumber,
                    quantity: values.quantity,
                  };
                  createIncidentPart(
                    selectedClient.id,
                    selectedShip.id,
                    incident.id,
                    part
                  ).then((res) => {
                    getIncidentById(
                      selectedClient.id,
                      selectedShip.id,
                      incident.id
                    ).then((inRes) => {
                      setCreateOpen(false);
                      setIncident(inRes);
                    });
                  });
                }}
              >
                {({
                  values,
                  handleSubmit,
                  setFieldValue,
                  handleChange,
                  touched,
                  errors,
                  handleBlur,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel id="part-simple-select-label">
                        Parts
                      </InputLabel>
                      <Select
                        labelId="part-simple-select-label"
                        id="part-simple-select"
                        value={values.partid}
                        onChange={(event) => {
                          setFieldValue("partid", event.target.value);
                        }}
                      >
                        {parts
                          .filter((p) => {
                            return filterParts(
                              incident.shipParts !== undefined
                                ? incident.shipParts
                                : [],
                              p.id !== undefined ? p.id : ""
                            );
                          })
                          .map((p) => (
                            <MenuItem id={p.id} key={p.id} value={p.id}>
                              {p.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <TextField
                      id="quantity"
                      name="quantity"
                      type="number"
                      label="Quantity"
                      value={values.quantity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.quantity && Boolean(errors.quantity)}
                      helperText={touched.quantity && errors.quantity}
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      id="trackingNumber"
                      name="trackingNumber"
                      label="Tracking Number"
                      value={values.trackingNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.trackingNumber && Boolean(errors.trackingNumber)
                      }
                      helperText={
                        touched.trackingNumber && errors.trackingNumber
                      }
                      fullWidth
                      sx={{ mb: 2 }}
                    />

                    <Button
                      sx={{ mt: 2 }}
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Submit
                    </Button>
                  </form>
                )}
              </Formik>
            ) : (
              <Box />
            )}
          </PaperSection>
        </Box>
      </Modal>
      <Modal
        open={editOpen}
        onClose={() => {
          setEditOpen(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <PaperSection title="Edit Part:" icon={<Upload />}>
            {incident !== undefined && editPart !== undefined ? (
              <Formik
                initialValues={editPart}
                onSubmit={(values) => {
                  updateIncidentPart(
                    selectedClient.id,
                    selectedShip.id,
                    incident.id,
                    values.id,
                    values.quantity,
                    values.trackingNumber
                  ).then((res) => {
                    getIncidentById(
                      selectedClient.id,
                      selectedShip.id,
                      incident.id
                    ).then((inRes) => {
                      setEditOpen(false);
                      setEditPart(undefined);
                      setIncident(inRes);
                    });
                  });
                }}
              >
                {({
                  values,
                  handleSubmit,
                  handleChange,
                  touched,
                  errors,
                  handleBlur,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      id="quantity"
                      name="quantity"
                      type="number"
                      label="Quantity"
                      value={values.quantity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.quantity && Boolean(errors.quantity)}
                      helperText={touched.quantity && errors.quantity}
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      id="trackingNumber"
                      name="trackingNumber"
                      label="Tracking Number"
                      value={values.trackingNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.trackingNumber && Boolean(errors.trackingNumber)
                      }
                      helperText={
                        touched.trackingNumber && errors.trackingNumber
                      }
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                    <Button
                      sx={{ mt: 2 }}
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Submit
                    </Button>
                  </form>
                )}
              </Formik>
            ) : (
              <Box />
            )}
          </PaperSection>
        </Box>
      </Modal>
      <PaperSection
        title="Parts"
        icon={<Category />}
        button={
          incident?.status === 0 ||
          incident?.status === 1 ||
          incident?.status === 3 ? (
            <Button
              startIcon={<Add />}
              onClick={() => setCreateOpen(true)}
              variant="contained"
            >
              Add
            </Button>
          ) : (
            <Box />
          )
        }
      >
        {incident?.shipParts !== undefined ? (
          <DataGrid
            sx={{ minHeight: "300px", mb: 4 }}
            columns={columns}
            rows={incident?.shipParts}
          />
        ) : (
          <Box />
        )}
      </PaperSection>
    </Box>
  );
}
