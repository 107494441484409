import { IncidentReport } from "../../types/IncidentReport";
import PaperSection from "../PaperSection";
import { Gavel } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

export type IncidentResolutionDetailsProps = {
  incident?: IncidentReport;
};

export default function IncidentResolutionDetails(
  props: IncidentResolutionDetailsProps
) {
  const { incident } = props;
  return (
    <Box>
      <PaperSection title="Resolution" icon={<Gavel />}>
        <Typography variant="body1">{incident?.resolution}</Typography>
      </PaperSection>
    </Box>
  );
}
