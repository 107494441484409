import { Grid, Paper, Stack, Typography } from "@mui/material";
import { Trip } from "../../types/Trip";
import { getTripType } from "../../utilities/shipUtils";
import moment from "moment";

export type TripInfoCardProps = {
  trip: Trip;
};

export default function TripInfoCard(props: TripInfoCardProps) {
  const { trip } = props;
  return (
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Paper
                    sx={{
                      p: 1,
                      borderLeftColor: "white",
                      borderTopWidth: "0px",
                      borderStyle: "solid",
                      borderRightWidth: "0px",
                      borderLeftWidth: "4px",
                      borderBottomWidth: "0",
                    }}
                    elevation={24}
                  >
                    <Stack direction="column">
                      <Typography variant="h5">Nautical Miles:</Typography>
                      <Typography variant="body1">
                        {trip.nauticalMiles}
                      </Typography>
                    </Stack>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper
                    sx={{
                      p: 1,
                      borderLeftColor: "white",
                      borderTopWidth: "0px",
                      borderStyle: "solid",
                      borderRightWidth: "0px",
                      borderLeftWidth: "4px",
                      borderBottomWidth: "0",
                    }}
                    elevation={24}
                  >
                    <Stack direction="column">
                      <Typography variant="h5">Type:</Typography>
                      <Typography variant="body1">
                        {getTripType(trip.type)}
                      </Typography>
                    </Stack>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper
                    sx={{
                      p: 1,
                      borderLeftColor: "white",
                      borderTopWidth: "0px",
                      borderStyle: "solid",
                      borderRightWidth: "0px",
                      borderLeftWidth: "4px",
                      borderBottomWidth: "0",
                    }}
                    elevation={24}
                  >
                    <Stack direction="column">
                      <Typography variant="h5">Created By:</Typography>
                      <Typography variant="body1">
                        {trip.creatorUserName}
                      </Typography>
                    </Stack>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper
                    sx={{
                      p: 1,
                      borderLeftColor: "white",
                      borderTopWidth: "0px",
                      borderStyle: "solid",
                      borderRightWidth: "0px",
                      borderLeftWidth: "4px",
                      borderBottomWidth: "0",
                    }}
                    elevation={24}
                  >
                    <Stack direction="column">
                      <Typography variant="h5">Created Date:</Typography>
                      <Typography variant="body1">
                        {moment(trip.createdDate).format("MM/DD/YYYY")}
                      </Typography>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  );
}
