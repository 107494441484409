import {atom} from "recoil";
import { UserProfile } from "../../types/UserProfile";

export const userProfileState = atom<UserProfile>({
    key: "userProfile",
    default: {
        id: "",
        auth0Id: "",
        userName: "",
        email:"",
        createdDate: "",
        preferences: [],
        roles: [],
        isSystemAdministrator: false,
    }
});