import {  ShipFoodItemScoreDTO } from "../../../types/PassengerTypes";
import AccordianPaperSection from "../../AccordianPaperSection";
import { Scrollbar } from "react-scrollbars-custom";
import { Paper, Stack } from "@mui/material";
import FixedFormRatingControl from "../FixedFormRatingControl";
import { ReactNode } from "react";

export type FoodScoresCardProps = {
  title: string;
  icon: ReactNode;
list: ShipFoodItemScoreDTO[];
};

export default function FoodScoresCard(
  props: FoodScoresCardProps
) {
  const { title, icon, list } = props;
    function compareAverage(a: ShipFoodItemScoreDTO, b: ShipFoodItemScoreDTO){
        if(a.average >= b.average){
            return -1;
        }
        else{
            return 1;
        }
    }
  return (
    <AccordianPaperSection
      title={title}
      icon={icon}
    >
        <Scrollbar style={{ height: 300 }}>
        {list.sort(compareAverage).map((mh) => (
          <Paper
            sx={{
              p: 1,
              borderLeftColor: "white",
              borderTopWidth: "0px",
              borderStyle: "solid",
              borderRightWidth: "0px",
              borderLeftWidth: "4px",
              borderBottomWidth: "0",
              mr: 1,
              mb: 2
            }}
            elevation={24}
          >
            <Stack direction="row" spacing={2}>
              {icon}
              <FixedFormRatingControl name={mh.name} value={mh.average} label={mh.name} precision={0.2}/>
            </Stack>
          </Paper>
        ))}
        </Scrollbar>

    </AccordianPaperSection>
  );
}
