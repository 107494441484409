import {
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { getMyTodoLists } from "../../services/todoListService";
import { ShipTodoList } from "../../types/TodoList";
import { userProfileState } from "../../Recoil/atoms/UserProfileAtom";

export type TodoListCounterWidgetCrewPropsType = {
  selectedShipId?: string;
};

export default function TodoListCounterWidgetCrew(
  props: TodoListCounterWidgetCrewPropsType
) {
  const navigate = useNavigate();
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [activeTodoListCount, setActiveTodoListCount] = useState(0);
  const [userProfile] = useRecoilState(userProfileState);

  useEffect(() => {
    if (props.selectedShipId !== undefined && selectedClient.id !== undefined) {
      getMyTodoLists(selectedClient.id).then((res: ShipTodoList[]) => {
        setActiveTodoListCount(
          res.filter(
            (tl) =>
              tl.status !== 0 &&
              tl.status !== 2 &&
              tl.status !== 3 &&
              tl.status !== 4 &&
              tl.shipId === props.selectedShipId
          ).length
        );
      });
    }
  }, [selectedClient.id, props.selectedShipId, userProfile.id]);

  return (
    <Paper
      sx={{
        p: 2,
        height: "100%",
        width: "100%",
        borderTopColor: "#b47f49",
        borderTopWidth: "4px",
        borderStyle: "solid",
        borderRightWidth: "0",
        borderLeftWidth: "0",
        borderBottomWidth: "0",
      }}
      elevation={24}
      component={Button}
      onClick={() => navigate(`/mylists`)}
    >
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="column" alignItems="center">
            <Typography variant="h5">My Work</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="column" alignItems="center">
            <Typography variant="h1">{activeTodoListCount}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}
