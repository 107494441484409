import { Box, Button, Modal, TextField } from "@mui/material";
import {
  IncidentReport,
  IncidentReportComment,
} from "../../types/IncidentReport";
import PaperSection from "../PaperSection";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { IconMessage, IconMessagePlus } from "@tabler/icons-react";
import { Formik } from "formik";
import {
  createIncidentComment,
  getIncidentById,
} from "../../services/incidentService";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import IncidentCommentCard from "./IncidentCommentCard";
import moment from "moment";

export type IncidentCommentsCardProps = {
  incident?: IncidentReport;
  setIncident: Function;
};
export default function IncidentsCommentCard(props: IncidentCommentsCardProps) {
  const { incident, setIncident } = props;
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [openCommentModal, setOpenCommentModal] = useState(false);
  var initialState = { comment: "" };
  function compareCreateddDate(
    a: IncidentReportComment,
    b: IncidentReportComment
  ) {
    if (a.createdDate !== undefined && b.createdDate !== undefined) {
      return moment(b.createdDate).valueOf() - moment(a.createdDate).valueOf();
    }
    return 0;
  }
  return (
    <Box sx={{ mb: 5 }}>
      <Modal
        onClose={() => {
          setOpenCommentModal(false);
        }}
        open={openCommentModal}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <PaperSection title="New Comment" icon={<IconMessagePlus />}>
            <Formik
              initialValues={initialState}
              onSubmit={(values) => {
                createIncidentComment(incident?.id, selectedClient.id, {
                  comment: values.comment,
                  commentStatus: incident?.status,
                }).then((res) => {
                  getIncidentById(
                    selectedClient.id,
                    selectedShip.id,
                    incident?.id
                  ).then((res) => {
                    setOpenCommentModal(false);
                    setIncident(res);
                  });
                });
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    id="comment"
                    name="comment"
                    label="Comment"
                    value={values.comment}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.comment && Boolean(errors.comment)}
                    helperText={touched.comment && errors.comment}
                    fullWidth
                    sx={{ mb: 2 }}
                  />

                  {values.comment === "" ? (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      disabled
                      type="submit"
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Submit
                    </Button>
                  )}
                </form>
              )}
            </Formik>
          </PaperSection>
        </Box>
      </Modal>
      <PaperSection
        title="Comments"
        icon={<IconMessage />}
        button={
          incident?.status === 0 || incident?.status === 1|| incident?.status === 3 ? (
            <Button
              variant="contained"
              onClick={() => {
                setOpenCommentModal(true);
              }}
              startIcon={<Add />}
            >
              Add
            </Button>
          ) : (
            <Box />
          )
        }
      >
        {incident?.comments?.sort(compareCreateddDate).map((comment) => (
          <IncidentCommentCard comment={comment} />
        ))}
      </PaperSection>
    </Box>
  );
}
