import { Box, Button, Card, Typography} from "@mui/material";
import { useNavigate } from "react-router-dom";

export type AdminWidgetProps = {
    title: string,
    link: string,
    icon: JSX.Element
}
export function AdminWidget(props: AdminWidgetProps){
    const navigate = useNavigate();
    return (
        <Card 
            component={Button}
            onClick={() => navigate(props.link)}
            sx={{
                p: 5,
                mr: 3,
                boxShadow: 0,
                textAlign: 'center',
        }}>
            <Box 
                sx={{
                    margin: 'auto',
                    display: 'flex',
                    borderRadius: '50%',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                >
                    <Box sx={{ width: 24, height: 24}} >
                        {props.icon}
                    </Box>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                        {props.title}
                    </Typography>
                </Box>
        </Card>
    );
}