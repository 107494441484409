import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  InvitationsState,
  PendingInvitationsState,
} from "../Recoil/atoms/InvitationsAtom";
import { DataGrid, GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import { Add, Check, Mail, ScheduleSend } from "@mui/icons-material";
import moment from "moment";
import {
  acceptInvites,
  createInvite,
  extendUserInvite,
  getInvites,
  getPendingInvites,
  getRoles,
} from "../services/invitationservice";
import { getClients } from "../services/adminservice";
import { ClientsState, SelectedClientState } from "../Recoil/atoms/ClientsAtom";
import { getClientUsers, getUserProfile } from "../services/userservice";
import { userProfileState } from "../Recoil/atoms/UserProfileAtom";
import { RolesState } from "../Recoil/atoms/RolesAtom";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { InvitationCreateDTO } from "../types/Invitation";
import React from "react";
import IsUserElevated from "../utilities/userUtils";
import { getShips } from "../services/shipService";
import { ShipsState } from "../Recoil/atoms/ShipsAtom";
import { LoadingState } from "../Recoil/atoms/LoadingAtom";
import PaperSection from "../components/PaperSection";
import BillingQouta from "../components/Billing/BillingQouta";
import { User } from "../types/User";

export default function Invitations() {
  const [invitations, setInvitations] = useRecoilState(InvitationsState);
  const [pendingInvites, setPendingInvites] = useRecoilState(
    PendingInvitationsState
  );
  const [roles, setRoles] = useRecoilState(RolesState);
  const setClients = useSetRecoilState(ClientsState);
  const [userProfile, setUserProfile] = useRecoilState(userProfileState);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [loading] = useRecoilState(LoadingState);
  const [selectedClient, setSelectedClient] =
    useRecoilState(SelectedClientState);
  const setShips = useSetRecoilState(ShipsState);
  useEffect(() => {
    if (userProfile.auth0Id !== "") {
      getInvites().then((res) => {
        setInvitations(res);
      });
      if (selectedClient !== undefined) {
        if (IsUserElevated(userProfile, selectedClient.id)) {
          getClientUsers(selectedClient.id).then((res) => {
            setUsers(res);
          });
          getPendingInvites(selectedClient.id).then((res) => {
            setPendingInvites(res);
          });
        }
      }
    }
  }, [
    selectedClient,
    setInvitations,
    setPendingInvites,
    userProfile,
    selectedClient.id,
    loading,
  ]);
  const validationSchema = yup.object({
    email: yup
      .string()
      .email()
      .min(5, "Email must be atleast 5 characters long")
      .required("Email is Required"),
    role: yup.string().required("Role is Required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      role: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      var invite: InvitationCreateDTO = {
        clientId: selectedClient.id,
        roleId: values.role,
        emailAddress: values.email,
      };
      createInvite(invite).then((response) => {
        getUserProfile()
          .then((response) => {
            setUserProfile(response);
          })
          .then(() => {
            getInvites().then((response) => setInvitations(response));
            if (IsUserElevated(userProfile, selectedClient.id)) {
              getPendingInvites(selectedClient.id).then((response) =>
                setPendingInvites(response)
              );
            }
            getClients().then((response) => setClients(response));
            getRoles().then((response) => setRoles(response));
            values.email = "";
            values.role = "";
          });
      });
      setOpen(false);
    },
  });

  const handleAcceptInvite = (id: any) => () => {
    acceptInvites(id).then((response) => {
      getUserProfile()
        .then((response) => {
          setUserProfile(response);
        })
        .then(() => {
          getClients().then((response) => {
            setClients(response);
            if (response.length > 0) {
              setSelectedClient(response[0]);
              getShips(response[0].id).then((response) => setShips(response));
            }
          });
          getInvites().then((response) => setInvitations(response));
          if (IsUserElevated(userProfile, selectedClient.id)) {
            getPendingInvites(selectedClient.id).then((response) =>
              setPendingInvites(response)
            );
          }
          getClients().then((response) => setClients(response));
          getRoles().then((response) => setRoles(response));
        });
    });
  };

  const handleExtendInvite = (id: any) => () => {
    if (IsUserElevated(userProfile, selectedClient.id)) {
      extendUserInvite(id, selectedClient.id).then((res) => {
        getUserProfile()
          .then((response) => {
            setUserProfile(response);
          })
          .then(() => {
            getClients().then((response) => {
              setClients(response);
              if (response.length > 0) {
                getShips(selectedClient.id).then((response) =>
                  setShips(response)
                );
              }
            });
            getInvites().then((response) => setInvitations(response));
            if (IsUserElevated(userProfile, selectedClient.id)) {
              getPendingInvites(selectedClient.id).then((response) =>
                setPendingInvites(response)
              );
            }
            getClients().then((response) => setClients(response));
            getRoles().then((response) => setRoles(response));
          });
      });
    }
  };

  const columns = [
    {
      field: "email",
      headerName: "From",
      valueGetter: (params: any) => {
        return params.row.creator.userName;
      },
      width: 300,
    },
    {
      field: "name",
      headerName: "Client Name",
      valueGetter: (params: any) => {
        return params.row.client.name;
      },
      width: 300,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      valueFormatter: (params: any) => {
        return moment(params.value).format("MM/DD/YYYY hh:mm A");
      },
      width: 300,
    },
    {
      field: "expirationDate",
      headerName: "Expiration Date",
      valueFormatter: (params: any) => {
        return moment(params.value).format("MM/DD/YYYY hh:mm A");
      },
      width: 300,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      width: 50,
      cellClassName: "actions",
      getActions: (params: GridRowParams): any => {
        return [
          <GridActionsCellItem
            icon={<Check />}
            label="Accept"
            onClick={handleAcceptInvite(params.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const pendingInvitesColumns = [
    {
      field: "fromAccount",
      headerName: "From",
      valueGetter: (params: any) => {
        return params.row.creator.userName;
      },
      width: 300,
    },
    {
      field: "toEmail",
      headerName: "To",
      valueGetter: (params: any) => {
        return params.row.email;
      },
      width: 300,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      valueFormatter: (params: any) => {
        return moment(params.value).format("MM/DD/YYYY hh:mm A");
      },
      width: 300,
    },
    {
      field: "expirationDate",
      headerName: "Expiration Date",
      valueFormatter: (params: any) => {
        return moment(params.value).format("MM/DD/YYYY hh:mm A");
      },
      width: 300,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      width: 50,
      cellClassName: "actions",
      getActions: (params: GridRowParams): any => {
        return [
          <GridActionsCellItem
            icon={<ScheduleSend />}
            label="Accept"
            onClick={handleExtendInvite(params.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            sx={{ mb: 3 }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Create New Invite
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="User Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              value={formik.values.role}
              select
              name="role"
              label="Role"
              onBlur={formik.handleBlur}
              error={formik.touched.role && Boolean(formik.errors.role)}
              helperText={formik.touched.role && formik.errors.role}
              onChange={formik.handleChange}
              sx={{ mb: 2 }}
            >
              {roles.map((r) => (
                <MenuItem value={r.id} key={r.id}>
                  {r.name}
                </MenuItem>
              ))}
            </TextField>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
      <Breadcrumbs sx={{ mb: 2 }}>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Mail sx={{ mr: 0.5 }} fontSize="inherit" />
          Invitations
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {selectedClient.id !== "" && selectedClient.billingTier === 0  ? (
            <BillingQouta
              metric={users.length}
              displayMetricName={true}
              maxMetric={5}
              metricName={"user"}
              pluralMetricName={"users"}
              costPerAdditional={15}
            />
          ) : (
            <Box />
          )}
        </Grid>
        <Grid item xs={12}>
          <PaperSection
            title="My Invitations"
            icon={<Mail />}
            button={
              (userProfile.isSystemAdministrator ||
                IsUserElevated(userProfile, selectedClient.id)) &&
              selectedClient !== undefined ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpen(true)}
                  startIcon={<Add />}
                >
                  New Invite
                </Button>
              ) : (
                <Box />
              )
            }
          >
            <DataGrid
              sx={{ height: "35%", minHeight: "300px" }}
              columns={columns}
              rows={invitations}
            />
          </PaperSection>
        </Grid>
        {IsUserElevated(userProfile, selectedClient.id) ? (
          <Grid item xs={12}>
            <PaperSection
              title={`Pending Invitations for ${selectedClient.name}`}
            >
              <DataGrid
                sx={{ height: "35%", minHeight: "300px" }}
                columns={pendingInvitesColumns}
                rows={pendingInvites}
              />
            </PaperSection>
          </Grid>
        ) : (
          <Box />
        )}
      </Grid>
    </Container>
  );
}
