import { Box } from "@mui/material"

import { MedicalInformation } from "@mui/icons-material"
import { PassengerMedicalInfo } from "../../types/PassengerTypes";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { IconSquare, IconSquareCheckFilled } from "@tabler/icons-react";
import AccordianPaperSection from "../AccordianPaperSection";

export type TripMedicalSummaryCardProps = {
    medical?: PassengerMedicalInfo[];
}

export default function TripMedicalSummaryCard(props: TripMedicalSummaryCardProps){
    const {medical} = props;

    const columns = [
        {
          field: "firstName",
          headerName: `First Name`,
          width: 150,
          valueGetter: (params: any) => {
            return params.row.firstName;
          },
        },
        {
          field: "lastName",
          headerName: `Last Name`,
          width: 150,
          valueGetter: (params: any) => {
            return params.row.lastName;
          },
        },
        {
          field: "allergies",
          headerName: `Allergies`,
          width: 150,
          valueGetter: (params: any) => {
            return params.row.allergies;
          },
        },
        {
          field: "foodAllergies",
          headerName: `Food Allergies`,
          width: 150,
          valueGetter: (params: any) => {
            return params.row.foodAllergies;
          },
        },
        {
          field: "medicalConditionsOrDisabilities",
          headerName: `Medical Conditions Or Disabilities`,
          width: 150,
          valueGetter: (params: any) => {
            return params.row.medicalConditionsOrDisabilities;
          },
        },
        {
          field: "useMobilityAids",
          headerName: `Use Mobility Aids`,
          width: 150,
          valueGetter: (params: any) => {
            return params.row.useMobilityAids;
          },
          renderCell: (params: GridRenderCellParams<any, Date>) =>
            params.row.useMobilityAids ? (
              <IconSquareCheckFilled style={{ color: "green" }} />
            ) : (
              <IconSquare />
            ),
        },
        {
            field: "useHearingAidsorCochlearImplants",
            headerName: `Use Hearing Aids or Cochlear Implants`,
            width: 150,
            valueGetter: (params: any) => {
              return params.row.useHearingAidsorCochlearImplants;
            },
            renderCell: (params: GridRenderCellParams<any, Date>) =>
              params.row.useHearingAidsorCochlearImplants ? (
                <IconSquareCheckFilled style={{ color: "green" }} />
              ) : (
                <IconSquare />
              ),
          },
          {
            field: "haveSeasickness",
            headerName: `Has Seasickness`,
            width: 150,
            valueGetter: (params: any) => {
              return params.row.haveSeasickness;
            },
            renderCell: (params: GridRenderCellParams<any, Date>) =>
              params.row.haveSeasickness ? (
                <IconSquareCheckFilled style={{ color: "green" }} />
              ) : (
                <IconSquare />
              ),
          },
          {
            field: "useSeasicknessMedicine",
            headerName: `Requires Seasickness Medicine`,
            width: 150,
            valueGetter: (params: any) => {
              return params.row.useSeasicknessMedicine;
            },
            renderCell: (params: GridRenderCellParams<any, Date>) =>
              params.row.useSeasicknessMedicine ? (
                <IconSquareCheckFilled style={{ color: "green" }} />
              ) : (
                <IconSquare />
              ),
          },
      ];
    return <AccordianPaperSection
    icon={<MedicalInformation />}
    title="Medical Information"
  >
    {medical !== undefined ?     <DataGrid
        rows={medical}
        sx={{ minHeight: "300px", mb: 4 }}
        columns={columns}
      />: <Box/>}

  </AccordianPaperSection>
}