import {
  CheckBox,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Chip,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getMyTodoLists } from "../../services/todoListService";
import { ShipTodoList } from "../../types/TodoList";
import { useRecoilState, useSetRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { MyListsState } from "../../Recoil/atoms/MyListsAtom";
import MyListTodoItemCard from "../../components/TodoList/MyListTodoItemCard";
import moment from "moment";

export default function MyLists() {
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [todoLists, setTodoLists] = useState<ShipTodoList[]>([]);
  const setMyTodoLists = useSetRecoilState(MyListsState);

  useEffect(() => {
    getMyTodoLists(selectedClient.id).then((res) => {
      setTodoLists(res);
      setMyTodoLists(res);
    });
  }, [selectedClient.id, setMyTodoLists]);

  function compareDates(a: ShipTodoList, b: ShipTodoList) {
    if (a.daysTillDue !== undefined && b.daysTillDue !== undefined) {
      return a.daysTillDue - b.daysTillDue;
    }
    return 0;
  }

  function compareCompletedDate(a: ShipTodoList, b: ShipTodoList){
    if(a.completedDate !== undefined && b.completedDate !== undefined){
            return  moment(b.completedDate).valueOf() - moment(a.completedDate).valueOf();
    }
    return 0;
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <CheckBox sx={{ mr: 0.5 }} fontSize="inherit" />
          My Work
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        {todoLists.filter(
          (tl) =>
            tl.status !== 2 &&
            tl.status !== 3 &&
            tl.status !== 4 &&
            tl.daysTillDue !== undefined &&
            tl.daysTillDue < 0
        ).length > 0 ? (
          <Grid item xs={12}>
            <Divider sx={{ mt: 2, mb: 2 }} variant="fullWidth">
              <Chip label="OVERDUE" size="medium" color="warning" />
            </Divider>
          </Grid>
        ) : (
          <Box />
        )}
        {todoLists.filter(
          (tl) =>
            tl.status !== 2 &&
            tl.status !== 3 &&
            tl.status !== 4 &&
            tl.daysTillDue !== undefined &&
            tl.daysTillDue < 0
        ).length > 0 ? (
          todoLists
            .filter(
              (tl) =>
                tl.status !== 0 &&
                tl.status !== 2 &&
                tl.status !== 3 &&
                tl.status !== 4 &&
                tl.daysTillDue !== undefined &&
                tl.daysTillDue < 0
            )
            .sort(compareDates)
            .map((todoList) => {
              return (
                <MyListTodoItemCard todoList={todoList} completed={false}/>
              );
            })
        ) : (
          <Box />
        )}
        <Grid item xs={12}>
          <Divider sx={{ mt: 2, mb: 2 }} variant="fullWidth">
            <Chip label="MY WORK" size="medium" color="primary" />
          </Divider>
        </Grid>
        {todoLists?.length === 0 ? (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 10 }}
            >
              <Typography variant="h5">No Lists Found</Typography>
            </Stack>
          </Grid>
        ) : (
          todoLists
            .filter(
              (tl) =>
                tl.status !== 0 &&
                tl.status !== 2 &&
                tl.status !== 3 &&
                tl.status !== 4 &&
                tl.daysTillDue !== undefined &&
                tl.daysTillDue >= 0
            )
            .sort(compareDates)
            .map((todoList) => {
              return (
                <MyListTodoItemCard todoList={todoList} completed={false}/>
              );
            })
        )}
        <Grid item xs={12}>
          <Divider sx={{ mt: 2, mb: 2 }} variant="fullWidth">
            <Chip label="COMPLETE" size="medium" color="success" />
          </Divider>
        </Grid>
        {todoLists.filter((tl) => tl.status === 2).length > 0 ? (
          todoLists
            .filter((tl) => tl.status === 2)
            .sort(compareCompletedDate)
            .map((todoList) => {
              return (
                <MyListTodoItemCard todoList={todoList} completed={true}/>
              );
            })
        ) : (
          <Box />
        )}
      </Grid>
    </Container>
  );
}
