import {
  Box,
  Typography,
  Button,
  CssBaseline,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

export default function LoginPage() {
  const { loginWithRedirect } = useAuth0();
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#b47f49",
      },
      secondary: {
        main: "#7fdcfd",
      },
      background: {
        default: "#011828",
        paper: "#011828",
      },
    },
  });
  return (
    <Box>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            minHeight: "100vh",
            position: "relative",
            overflow: "hidden",
          }}
        >
          {/* Background Image (always present, visibility controlled by CSS) */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage:
                "url(https://cdn.zsail.io/zsailassets/zsailprimary.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              transition: "all 0.3s ease-in-out",
              "@media (max-width: 899px)": {
                filter: "blur(5px)",
              },
              "@media (min-width: 900px)": {
                left: "60%",
              },
            }}
          />

          {/* Login Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              padding: { xs: 2, sm: 4, md: 8 },
              position: "relative",
              zIndex: 1,
              transition: "all 0.3s ease-in-out",
              "@media (max-width: 899px)": {
                width: "100%",
                backgroundColor: "rgba(01, 18, 28, 0.7)",
              },
              "@media (min-width: 900px)": {
                width: "60%",
                backgroundColor: "#011828",
              },
            }}
          >
            {/* Top spacer */}
            <Box flexGrow={1} />

            {/* Login content */}
            <Box
              sx={{
                width: "100%",
                maxWidth: "400px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
                <Typography
                  variant="h3"
                  component="h1"
                  fontFamily="Baskervville"
                  sx={{
                    fontWeight: "bold",
                    mb: 2,
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  ZeaFarer
                </Typography>
                <Typography
                  variant="h5"
                  component="h1"
                  sx={{
                    mb: 2,
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Ship Manager
                </Typography>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => loginWithRedirect()}
                sx={{
                  py: 1,
                  px: 4,
                  fontSize: "1rem",
                  fontWeight: "normal",
                  textTransform: "none",
                  borderColor: "white",
                  color: "white",
                }}
              >
                Login
              </Button>
            </Box>

            {/* Bottom spacer */}
            <Box flexGrow={1} />

            {/* Footer */}
            <Box
              component="footer"
              sx={{
                pt: 4,
                textAlign: "center",
              }}
            >
              <Typography
                variant="body2"
                fontFamily="Baskervville"
                sx={{ color: "rgba(255, 255, 255, 0.7)" }}
              >
                © ZeaFarer {new Date().getFullYear()}
              </Typography>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </Box>
  );
}
