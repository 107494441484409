import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  createEquipmentLog,
  getEquipmentById,
  getShip,
} from "../../services/shipService";
import { useNavigate, useParams } from "react-router-dom";
import { Ship } from "../../types/Ship";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { Equipment } from "../../types/Equipment";
import * as yup from "yup";
import { Formik } from "formik";
import { EquipmentLogItem } from "../../types/EquipmentLogItem";
import {
  Add,
  Construction,
  DirectionsBoat,
} from "@mui/icons-material";
import { Editor } from "@tinymce/tinymce-react";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";

export default function NewEquipmentLog() {
  const params = useParams();
  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [equipment, setEquipment] = useState<Equipment>();
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [EquipmentLogItem, setEquipmentLogItem] = useState<EquipmentLogItem>();
  const [open, setOpen] = useState(false);
  const [selectedShip] = useRecoilState(SelectedShipState);

  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
      getEquipmentById(selectedClient.id, params.id, params.equipmentid).then(
        (equipmentRes) => {
          setEquipment(equipmentRes);
        }
      );
    });
  }, [selectedClient, params.id, params.equipmentid]);
  const validationSchema = yup.object({
    value: yup
      .number()
      .moreThan(0)
      .required("Usage cannot be lower than zero."),
  });
  const initialState = {
    value: equipment?.actualUsageMeter,
    notes: "",
  };
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Alert variant="outlined" severity="warning">
            New Value: {EquipmentLogItem?.value} {`(${equipment?.usageUnits})`}{" "}
            is less than the previous value: {equipment?.actualUsageMeter}{" "}
            {`${equipment?.usageUnits}`}. Do you want to proceed?
          </Alert>
          <Stack direction="row-reverse" spacing={2} sx={{ mt: 4 }}>
            <Button
              variant="outlined"
              onClick={() => {
                var eql: EquipmentLogItem = {
                  equipmentId: EquipmentLogItem?.equipmentId,
                  value: EquipmentLogItem?.value,
                  notes: EquipmentLogItem?.notes,
                };
                createEquipmentLog(eql, ship?.id, selectedClient.id).then(
                  (response) => {
                    navigate(`/Ships/${ship?.id}/equipment/${equipment?.id}`);
                  }
                );
              }}
            >
              Proceed
            </Button>
            <Button variant="contained" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}/equipment`)}
          startIcon={<Construction sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Equipment
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() =>
            navigate(`/ships/${ship?.id}/equipment/${equipment?.id}`)
          }
          startIcon={<Construction sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {equipment?.name}
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Add sx={{ mr: 0.5 }} fontSize="inherit" />
          Log Usage
        </Typography>
      </Breadcrumbs>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Current Usage:{" "}
        {`${equipment?.actualUsageMeter} (${equipment?.usageUnits})`}
      </Typography>
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          var equipmentLog: EquipmentLogItem = {
            equipmentId: equipment?.id,
            value: values.value,
            notes: values.notes,
          };
          if (
            equipmentLog?.value !== undefined &&
            equipment?.actualUsageMeter !== undefined &&
            equipmentLog?.value < equipment?.actualUsageMeter
          ) {
            setEquipmentLogItem(equipmentLog);
            setOpen(true);
          } else {
            createEquipmentLog(equipmentLog, ship?.id, selectedClient.id).then(
              (response) => {
                navigate(`/Ships/${ship?.id}/equipment/${equipment?.id}`);
              }
            );
          }
        }}
      >
        {({
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              id="value"
              name="value"
              label={`Update Usage (in ${equipment?.usageUnits})`}
              value={values.value}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.value && Boolean(errors.value)}
              helperText={touched.value && errors.value}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Typography variant="subtitle1">Notes:</Typography>
            <Editor
              apiKey="s7hflnfnqq2c0nxj75udyor3kobji9x4jv2xgc4xvsdhtju5"
              init={{
                height: 500,
                menubar: true,
                skin: "oxide-dark",
                content_css: "dark",
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent| " +
                  "removeformat",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
              textareaName="notes"
              onEditorChange={(stringifiedHTML) => {
                setFieldValue("notes", stringifiedHTML);
              }}
            />
            <Button
              sx={{ mt: 2 }}
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
            >
              Submit
            </Button>
          </form>
        )}
      </Formik>
    </Container>
  );
}
