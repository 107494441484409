import {
  Breadcrumbs,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPartById, getShip, updatePart } from "../../services/shipService";
import { Ship } from "../../types/Ship";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { Formik } from "formik";
import { Part } from "../../types/Part";
import { Category, DirectionsBoat, Edit } from "@mui/icons-material";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { Editor } from "@tinymce/tinymce-react";

export default function EditPartItem() {
  const params = useParams();
  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [part, setPart] = useState<Part>();
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [selectedShip] = useRecoilState(SelectedShipState);

  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
      getPartById(selectedClient.id, params.id, params.partid).then((res) => {
        setPart(res);
      });
    });
  }, [selectedClient, params.id, params.partid]);

  const initialState = {
    name: part !== undefined ? part.name : "",
    description: part !== undefined ? part.description : "",
    maker: part !== undefined ? part.maker : "",
    modelNumber: part !== undefined ? part.modelNumber : "",
    marketsPartNumber: part !== undefined ? part.marketsPartNumber : "",
    minimumInventory: part !== undefined ? part.minimumInventory : 0,
    storageLocation: part !== undefined ? part.storageLocation : "",
    quantity: part !== undefined ? part.quantity : 0,
  };
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}/parts`)}
          startIcon={<Category sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Parts
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}/parts/${part?.id}`)}
          startIcon={<Category sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {part?.name}
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Edit sx={{ mr: 0.5 }} fontSize="inherit" />
          Edit
        </Typography>
      </Breadcrumbs>
      <Formik
        initialValues={initialState}
        enableReinitialize
        onSubmit={(values) => {
          var partdto: Part = {
            id: part?.id,
            name: values.name,
            description: values.description,
            maker: values.maker,
            modelNumber: values.modelNumber,
            marketsPartNumber: values.marketsPartNumber,
            minimumInventory: values.minimumInventory,
            storageLocation: values.storageLocation,
            quantity: values.quantity,
          };
          updatePart(partdto, ship?.id, selectedClient.id).then((response) => {
            navigate(`/ships/${ship?.id}/Parts`);
          });
        }}
      >
        {({
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              id="name"
              name="name"
              label="Name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Typography variant="subtitle1">Description:</Typography>
            <Editor
              apiKey="s7hflnfnqq2c0nxj75udyor3kobji9x4jv2xgc4xvsdhtju5"
              value={values.description}
              init={{
                height: 200,
                menubar: true,
                skin: "oxide-dark",
                content_css: "dark",
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent| " +
                  "removeformat",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
              textareaName="description"
              onEditorChange={(stringifiedHTML) => {
                setFieldValue("description", stringifiedHTML);
              }}
            />
            <TextField
              id="maker"
              name="maker"
              label="Maker"
              value={values.maker}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.maker && Boolean(errors.maker)}
              helperText={touched.maker && errors.maker}
              fullWidth
              sx={{ mb: 2, mt: 2 }}
            />
            <TextField
              id="modelNumber"
              name="modelNumber"
              label="Model Number"
              value={values.modelNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.modelNumber && Boolean(errors.modelNumber)}
              helperText={touched.modelNumber && errors.modelNumber}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              id="marketsPartNumber"
              name="marketsPartNumber"
              label="Markets Part Number"
              value={values.marketsPartNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.marketsPartNumber && Boolean(errors.marketsPartNumber)
              }
              helperText={touched.marketsPartNumber && errors.marketsPartNumber}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              id="minimumInventory"
              name="minimumInventory"
              label="Minimum Inventory"
              value={values.minimumInventory}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.minimumInventory && Boolean(errors.minimumInventory)
              }
              helperText={touched.minimumInventory && errors.minimumInventory}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              id="storageLocation"
              name="storageLocation"
              label="Storage Location"
              value={values.storageLocation}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.storageLocation && Boolean(errors.storageLocation)}
              helperText={touched.storageLocation && errors.storageLocation}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              id="quantity"
              name="quantity"
              label="Quantity"
              value={values.quantity}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.quantity && Boolean(errors.quantity)}
              helperText={touched.quantity && errors.quantity}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Button color="primary" variant="contained" fullWidth type="submit">
              Submit
            </Button>
          </form>
        )}
      </Formik>
    </Container>
  );
}
