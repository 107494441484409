import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts";
import { ShipTodoList } from "../../../types/TodoList";

export type TodoListReviewOverviewProps = {
  todoList: ShipTodoList | undefined;
};

export default function TodoListReviewOverview(
  props: TodoListReviewOverviewProps
) {
  const { todoList } = props;
  return (
    <Paper
      sx={{
        p: 2,
        borderTopColor: "gray",
        borderTopWidth: "4px",
        borderStyle: "solid",
        borderRightWidth: "0",
        borderLeftWidth: "0",
        borderBottomWidth: "0",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Item Summary</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {todoList?.items !== undefined ? (
            <PieChart
              margin={{ right: 0, top: 0, bottom: 40 }}
              slotProps={{
  
                legend: {
                  padding: { top: 40 },
                  labelStyle: {
                    tableLayout: "auto",
                  },
                  direction: "row",
                  position: {
                    horizontal: "middle",
                    vertical: "bottom",
                  },
                },
              }}
              series={[
                {
                  data: [
                    {
                      id: 0,
                      value: todoList?.items?.filter(
                        (item) => item.status === 2
                      ).length,
                      color: "#b47f49",
                      label: "Completed",
                    },
                    {
                      id: 1,
                      value: todoList?.items?.filter(
                        (item) => item.status === 3
                      ).length,
                      color: "purple",
                      label: "Skipped",
                    },
                    {
                      id: 2,
                      value: todoList?.items?.filter(
                        (item) => item.status === 0
                      ).length,
                      color: "lightblue",
                      label: "TO DO",
                    },
                  ],
                  highlightScope: {
                    faded: "global",
                    highlighted: "item",
                  },
                  faded: {
                    innerRadius: 15,
                    additionalRadius: -15,
                    color: "gray",
                  },
                },
              ]}
              width={500}
              height={200}
            />
          ) : (
            <Box />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
