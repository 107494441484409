import {
  Box,
  Button,
  CircularProgress,
  Input,
  LinearProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { IncidentReport } from "../../types/IncidentReport";
import PaperSection from "../PaperSection";
import { Add, Download, Upload } from "@mui/icons-material";
import { useRecoilState } from "recoil";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { useState } from "react";
import {
  getIncidentById,
  getIncidentFileBlob,
  uploadIncidentFile,
} from "../../services/incidentService";
import { IconFiles } from "@tabler/icons-react";
import moment from "moment";
import { DataGrid, GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
export type IncidentFilesCardProps = {
  incident?: IncidentReport;
  setIncident: Function;
};
export default function IncidentFilesCard(props: IncidentFilesCardProps) {
  const { setIncident, incident } = props;
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [helperName, setHelperName] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [file, setFile] = useState<any>();
  const onUploadProgress = (progressevent: ProgressEvent) => {
    const percentCompleted = Math.round(
      (progressevent.loaded * 100) / progressevent.total
    );
    setUploadProgress(percentCompleted);
    if (percentCompleted === 100) {
      getIncidentById(selectedClient.id, selectedShip.id, incident?.id).then(
        (res) => {
          setIncident(res);
          setHelperName("");
          setUploadProgress(0);
          setUploading(false);
          setUploadOpen(false);
          setFile(null);
        }
      );
    }
  };
  function handleUploadSubmit(event: any) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.Name);
    setUploading(true);
    uploadIncidentFile(
      onUploadProgress,
      selectedClient.id,
      selectedShip.id,
      incident?.id,
      formData,
      helperName
    ).then((res) => {
      getIncidentById(selectedClient.id, selectedShip.id, incident?.id).then(
        (res) => {
          setIncident(res);
          setHelperName("");
          setUploadProgress(0);
          setUploading(false);
          setUploadOpen(false);
          setFile(null);
        }
      );
    });
  }
  function handleFileChange(event: any) {
    setFile(event.target.files[0]);
  }
  const columnsFile = [
    {
      field: "helperName",
      headerName: `Name`,
      width: 300,
      valueGetter: (params: any) => {
        return params.row.helperName;
      },
    },
    {
      field: "userName",
      headerName: `Uploaded By`,
      width: 300,
      valueGetter: (params: any) => {
        return params.row.user.userName;
      },
    },
    {
      field: "createdDate",
      headerName: "Upload Date",
      width: 300,
      valueFormatter: (params: any) => {
        return moment(params.value).format("MM/DD/YYYY HH:MM:SS");
      },
    },
    {
      field: "download",
      type: "actions",
      headerName: "Download",
      width: 150,
      cellClassName: "actions",
      getActions: (params: GridRowParams): any => {
        return [
          <GridActionsCellItem
            icon={<Download />}
            label="Download"
            onClick={() => {
              setDownloading(true);
              getIncidentFileBlob(
                incident?.id,
                params.row.id,
                selectedClient.id
              ).then((response) => {
                setDownloading(false);
              });
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <Box>
      <Modal
        open={uploadOpen}
        onClose={() => {
          setUploadOpen(false);
          setUploading(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <PaperSection title="Upload File:" icon={<Upload />}>
            {!uploading ? (
              <Box>
                <form onSubmit={handleUploadSubmit}>
                  <TextField
                    label="Name"
                    value={helperName}
                    onChange={(event) => setHelperName(event.target.value)}
                    fullWidth
                  />
                  <Input
                    sx={{ mb: 2 }}
                    type="file"
                    onChange={handleFileChange}
                  />
                  <Button type="submit" variant="contained" fullWidth>
                    Upload
                  </Button>
                </form>
              </Box>
            ) : (
              <Box>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Uploading...{" "}
                </Typography>
                <LinearProgress variant="determinate" value={uploadProgress} />
              </Box>
            )}
          </PaperSection>
        </Box>
      </Modal>
      <Modal open={downloading} onClose={() => {}}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            height: 200,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
          textAlign="center"
        >
          <CircularProgress />
          <Typography variant="h5" sx={{ mb: 4 }}>
            Downloading...
          </Typography>
        </Box>
      </Modal>
      <PaperSection
        title="Files"
        icon={<IconFiles />}
        button={
          incident?.status === 0 || incident?.status === 1|| incident?.status === 3 ? (
            <Button
              startIcon={<Add />}
              onClick={() => setUploadOpen(true)}
              variant="contained"
            >
              Add
            </Button>
          ) : (
            <Box />
          )
        }
      >
        {incident?.files !== undefined ? (
          <DataGrid
            sx={{ minHeight: "300px", mb: 4 }}
            columns={columnsFile}
            rows={incident?.files}
          />
        ) : (
          <Box />
        )}
      </PaperSection>
    </Box>
  );
}
