import {
  Box,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import PaperSection from "../PaperSection";
import { Article, Edit, } from "@mui/icons-material";
import { Formik } from "formik";
import {
  getIncidentById,
  updateIncident,
} from "../../services/incidentService";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { useRecoilState } from "recoil";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { IncidentReport } from "../../types/IncidentReport";
import parse from "html-react-parser";
import { Editor } from "@tinymce/tinymce-react";
import { useState } from "react";

export type IncidentSummaryCardProps = {
  incident?: IncidentReport;
  setIncident: Function;
};
export default function IncidentSummaryCard(props: IncidentSummaryCardProps) {
  const { incident, setIncident } = props;
  const [editDescriptionOpen, setEditDescriptionOpen] = useState(false);
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);

  return (
    <Box>
      <Modal
        open={editDescriptionOpen}
        onClose={() => {
            setEditDescriptionOpen(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <PaperSection title="Edit" icon={<Edit />}>
            {incident !== undefined ? (
              <Formik
                initialValues={incident}
                onSubmit={(values) => {
                  updateIncident(
                    selectedClient.id,
                    selectedShip.id,
                    values
                  ).then((res) => {
                    setEditDescriptionOpen(false);
                    getIncidentById(
                      selectedClient.id,
                      selectedShip.id,
                      incident.id
                    ).then((res) => {
                      setIncident(res);
                    });
                  });
                }}
              >
                {({
                    values,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Typography variant="subtitle1">Description:</Typography>
                    <Editor
                      apiKey="s7hflnfnqq2c0nxj75udyor3kobji9x4jv2xgc4xvsdhtju5"
                      init={{
                        height: 500,
                        menubar: true,
                        skin: "oxide-dark",
                        content_css: "dark",
                        plugins: [
                          "advlist",
                          "autolink",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "preview",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          "code",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          "table",
                          "code",
                          "wordcount",
                        ],
                        toolbar:
                          "undo redo | blocks | " +
                          "bold italic forecolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent| " +
                          "removeformat",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                      textareaName="description"
                      value={values.description}
                      onEditorChange={(stringifiedHTML) => {
                        setFieldValue("description", stringifiedHTML);
                      }}
                    />

                    <Button
                      sx={{ mt: 2 }}
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Submit
                    </Button>
                  </form>
                )}
              </Formik>
            ) : (
              <Box />
            )}
          </PaperSection>
        </Box>
      </Modal>
      <PaperSection
            title="Summary"
            icon={<Article />}
            button={
                incident?.status === 0 || incident?.status === 3? 
              <Button variant="contained" onClick={() => {setEditDescriptionOpen(true);}} startIcon={<Edit />}>
                Edit
              </Button> : <Box/>
            }
          >
            {parse(
              incident?.description !== undefined ? incident?.description : ""
            )}
          </PaperSection>
    </Box>
  );
}
