import {
  Add,
  DirectionsBoat,
  Lock,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { useEffect, useState } from "react";
import { Ship, ShipUserRole } from "../../types/Ship";
import {
  createShipUserRole,
  getShip,
  getShipUserRoles,
} from "../../services/shipService";
import { userManagementState } from "../../Recoil/atoms/UserManagementAtom";
import { getShipUserRoleValue } from "../../utilities/shipUtils";
import { DataGrid } from "@mui/x-data-grid";
import { Formik } from "formik";
import { getClientUsers } from "../../services/userservice";
import * as yup from "yup";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";

export default function ShipRoles() {
  const params = useParams();
  const [selectedClient] = useRecoilState(SelectedClientState);
  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useRecoilState(userManagementState);
  const [roles, setRoles] = useState<ShipUserRole[]>([]);
  const [selectedShip] = useRecoilState(SelectedShipState);

  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
      getShipUserRoles(selectedClient.id, params.id).then((res) =>
        setRoles(res)
      );
    });
    getClientUsers(selectedClient.id).then((res) => {
      setUsers(res);
    });
  }, [selectedClient, selectedClient.id, params.id, setUsers]);

  const initialState = {
    userId: "",
    role: 0,
  };

  const validationSchema = yup.object({
    userId: yup.string().required("User is required"),
    role: yup.number().required("Role is required"),
  });

  const columns = [
    {
      field: "userName",
      headerName: "User Name",
      valueGetter: (params: any) => {
        return params.row.userName;
      },
      width: 300,
    },
    {
      field: "emailAddress",
      headerName: "Email Address",
      valueGetter: (params: any) => {
        return params.row.emailAddress;
      },
      width: 300,
    },
    {
      field: "role",
      headerName: "Role",
      valueGetter: (params: any) => {
        return getShipUserRoleValue(params.row.role);
      },
      width: 300,
    },
  ];

  return (
    <Container sx={{ mb: 4 }}>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Card sx={{ mb: 4 }}>
            <CardContent sx={{ background: "rgba(255, 255, 255, 0.12)" }}>
              <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  var shipRole: ShipUserRole = {
                    userId: values.userId,
                    role: values.role,
                  };
                  createShipUserRole(
                    selectedClient.id,
                    ship?.id,
                    shipRole
                  ).then((res) => {
                    getShipUserRoles(selectedClient.id, ship?.id).then(
                      (res) => {
                        setRoles(res);
                        setOpen(false);
                      }
                    );
                  });
                }}
              >
                {({ handleSubmit, values, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    
                    <InputLabel id="roleId">Role</InputLabel>
                    <Select
                      labelId="roleId"
                      id="roleSelect"
                      value={values.role}
                      onChange={(e) => {
                        setFieldValue("role", e.target.value);
                      }}
                      fullWidth
                      sx={{ mb: 2 }}
                    >
                      <MenuItem value={1}>{getShipUserRoleValue(1)}</MenuItem>
                      <MenuItem value={2}>{getShipUserRoleValue(2)}</MenuItem>
                      <MenuItem value={3}>{getShipUserRoleValue(3)}</MenuItem>
                      <MenuItem value={4}>{getShipUserRoleValue(4)}</MenuItem>
                    </Select>
                    <InputLabel id="userId">User</InputLabel>
                    <Select
                      labelId="userId"
                      id="userSelect"
                      value={values.userId}
                      onChange={(e) => {
                        setFieldValue("userId", e.target.value);
                      }}
                      fullWidth
                      sx={{ mb: 2 }}
                    >
                      {users
                        .filter(
                          (u) =>
                            roles.filter((r) => r.userId === u.id && r.role === values.role).length === 0
                        )
                        .map((u) => {
                          return <MenuItem value={u.id}>{u.userName}</MenuItem>;
                        })}
                    </Select>
                    {values.userId === "" ? (
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        type="submit"
                        disabled
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        type="submit"
                      >
                        Submit
                      </Button>
                    )}
                  </form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Box>
      </Modal>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Lock sx={{ mr: 0.5 }} fontSize="inherit" />
          Roles
        </Typography>
      </Breadcrumbs>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          {ship?.name} Role Assignments
        </Typography>
        <Button
          variant="contained"
          onClick={() => setOpen(true)}
          startIcon={<Add />}
        >
          Assign Role
        </Button>
      </Stack>
      <DataGrid
        sx={{ height: "80%", minHeight: "300px" }}
        columns={columns}
        rows={roles}
      />
    </Container>
  );
}
