import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getTasks } from "../../services/shipService";
import { useRecoilState, useSetRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import Scrollbar from "react-scrollbars-custom";
import { ShipsState } from "../../Recoil/atoms/ShipsAtom";
import { SideBarSelectedState } from "../../Recoil/atoms/SideBarSelectedAtom";
import { useNavigate } from "react-router-dom";
import { Group, Person, PersonAdd, TaskAlt } from "@mui/icons-material";
import { Task } from "../../types/Task";
import { userProfileState } from "../../Recoil/atoms/UserProfileAtom";
import IsUserElevated from "../../utilities/userUtils";
import React from "react";
import { ShipTodoList, ShipTodoListTemplate } from "../../types/TodoList";
import { Formik } from "formik";
import { User } from "../../types/User";
import { getClientUsers } from "../../services/userservice";
import { getTodoListTemplates } from "../../services/todoListTemplateService";
import { createTodoList } from "../../services/todoListService";
import { DateTime } from "luxon";
import { getShipUserRoleValue } from "../../utilities/shipUtils";
import { DatePicker } from "@mui/x-date-pickers";

export type EquipmentTaskWidgetType = {
  selectedShipId?: string;
};

export default function EquipmentTasksWidget(props: EquipmentTaskWidgetType) {
  const [ships] = useRecoilState(ShipsState);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [userProfile] = useRecoilState(userProfileState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const setSelected = useSetRecoilState(SideBarSelectedState);
  const [users, setUsers] = useState<User[]>([]);
  const [open, setOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [todoListAssign, setTodoListAssign] = useState<ShipTodoList>();
  const [templates, setTemplates] = useState<ShipTodoListTemplate[]>([]);
  const [localLoading, setLocalLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.selectedShipId !== undefined && selectedClient.id !== undefined) {
      setLocalLoading(true);
      getTasks(selectedClient.id, props.selectedShipId).then((taskRes) => {
        setTasks(
          taskRes.filter(
            (t: Task) =>
              t.dateFrequencyInterval === 0 &&
              t.usageSinceLastUpdate !== undefined &&
              t.unitFrequencyInterval !== undefined &&
              t.usageSinceLastUpdate / t.unitFrequencyInterval >= 0.75
          )
        );
        setLocalLoading(false);
      });
      if (IsUserElevated(userProfile, selectedClient.id)) {
        getClientUsers(selectedClient.id).then((response) =>
          setUsers(response)
        );
        getTodoListTemplates(props.selectedShipId, selectedClient.id).then(
          (res) => {
            setTemplates(res);
          }
        );
      }
    }
  }, [selectedClient.id, props.selectedShipId, userProfile]);

  const initialState = {
    assigneeId: "",
    isTemplate: false,
    templateId: "",
    assignedRoleId: 0,
    userRoleToggle: "role",
    dueDate: DateTime.now(),
  };

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        open={snackOpen}
        autoHideDuration={5000}
      >
        <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
          Task has been assigned!
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            height: 600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h5" sx={{ mb: 4 }}>
            Assign {todoListAssign?.taskName}
          </Typography>
          <Formik
            initialValues={initialState}
            onSubmit={(values) => {
              if (values.userRoleToggle === "user") {
                if (values.isTemplate) {
                  var templateTodoList: ShipTodoList = {
                    isTemplate: true,
                    templateId: values.templateId,
                    assigneeId: values.assigneeId,
                    userRoleToggle: values.userRoleToggle,
                    dueDate: values.dueDate,
                    fromTask: true,
                    taskId: todoListAssign?.taskId,
                    taskName: todoListAssign?.taskName,
                  };
                  createTodoList(
                    props.selectedShipId,
                    selectedClient.id,
                    templateTodoList
                  ).then((res) => {
                    var blankTodoList: ShipTodoList = {};
                    setTodoListAssign(blankTodoList);
                    setSnackOpen(true);
                    setOpen(false);
                  });
                } else {
                  var todoList: ShipTodoList = {
                    isTemplate: false,
                    assigneeId: values.assigneeId,
                    fromTask: true,
                    userRoleToggle: values.userRoleToggle,
                    dueDate: values.dueDate,
                    taskId: todoListAssign?.taskId,
                    taskName: todoListAssign?.taskName,
                  };
                  createTodoList(
                    props.selectedShipId,
                    selectedClient.id,
                    todoList
                  ).then((res) => {
                    var blankTodoList: ShipTodoList = {};
                    setTodoListAssign(blankTodoList);
                    setSnackOpen(true);
                    setOpen(false);
                  });
                }
              } else {
                if (values.isTemplate) {
                  var templateTodoListRole: ShipTodoList = {
                    isTemplate: true,
                    templateId: values.templateId,
                    assignedRoleId: values.assignedRoleId,
                    userRoleToggle: values.userRoleToggle,
                    dueDate: values.dueDate,
                    fromTask: true,
                    taskId: todoListAssign?.taskId,
                    taskName: todoListAssign?.taskName,
                  };
                  createTodoList(
                    props.selectedShipId,
                    selectedClient.id,
                    templateTodoListRole
                  ).then((res) => {
                    var blankTodoList: ShipTodoList = {};
                    setTodoListAssign(blankTodoList);
                    setSnackOpen(true);
                    setOpen(false);
                  });
                } else {
                  var todoListRole: ShipTodoList = {
                    isTemplate: false,
                    fromTask: true,
                    assignedRoleId: values.assignedRoleId,
                    userRoleToggle: values.userRoleToggle,
                    dueDate: values.dueDate,
                    taskId: todoListAssign?.taskId,
                    taskName: todoListAssign?.taskName,
                  };
                  createTodoList(
                    props.selectedShipId,
                    selectedClient.id,
                    todoListRole
                  ).then((res) => {
                    var blankTodoList: ShipTodoList = {};
                    setTodoListAssign(blankTodoList);
                    setSnackOpen(true);
                    setOpen(false);
                  });
                }
              }
            }}
          >
            {({
              errors,
              handleSubmit,
              handleChange,
              touched,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <DatePicker
                  disablePast
                  label="Due Date"
                  value={values.dueDate}
                  onChange={(value: any) =>
                    setFieldValue("dueDate", DateTime.fromISO(value), true)
                  }
                  sx={{ mb: 2 }}
                />
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <ToggleButtonGroup
                    value={values.userRoleToggle}
                    exclusive
                    onChange={(e: any) => {
                      setFieldValue("userRoleToggle", e.target.value, true);
                    }}
                  >
                    <ToggleButton value="role">
                      <Group sx={{ mr: 2 }} /> Role
                    </ToggleButton>
                    <ToggleButton value="user">
                      <Person sx={{ mr: 2 }} /> User
                    </ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
                {values.userRoleToggle === "role" ? (
                  <Box>
                    <InputLabel id="roleId">Role</InputLabel>
                    <Select
                      labelId="roleId"
                      id="roleSelect"
                      value={values.assignedRoleId}
                      onChange={(e) => {
                        setFieldValue("assignedRoleId", e.target.value);
                      }}
                      fullWidth
                      sx={{ mb: 2 }}
                    >
                      <MenuItem value={0}>{getShipUserRoleValue(0)}</MenuItem>
                      <MenuItem value={1}>{getShipUserRoleValue(1)}</MenuItem>
                      <MenuItem value={2}>{getShipUserRoleValue(2)}</MenuItem>
                      <MenuItem value={3}>{getShipUserRoleValue(3)}</MenuItem>
                      <MenuItem value={4}>{getShipUserRoleValue(4)}</MenuItem>
                    </Select>
                  </Box>
                ) : (
                  <Box>
                    <InputLabel id="userId">User</InputLabel>
                    <Select
                      labelId="userId"
                      id="userSelect"
                      value={values.assigneeId}
                      onChange={(e) => {
                        setFieldValue("assigneeId", e.target.value);
                      }}
                      error={touched.assigneeId && Boolean(errors.assigneeId)}
                      fullWidth
                      sx={{ mb: 2 }}
                    >
                      {users.map((u) => {
                        return <MenuItem value={u.id}>{u.userName}</MenuItem>;
                      })}
                    </Select>
                  </Box>
                )}
                {selectedClient.billingTier !== 0 ? (
                  <FormControlLabel
                    control={<Checkbox checked={values.isTemplate} />}
                    label="Apply To Do List Template?"
                    name="isTemplate"
                    onChange={handleChange}
                    sx={{ mb: 2 }}
                  />
                ) : (
                  <Box />
                )}

                {values.isTemplate ? (
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="demo-simple-select-label">
                      Template
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.templateId}
                      onChange={(event) => {
                        setFieldValue("templateId", event.target.value);
                      }}
                    >
                      {templates.map((t) => (
                        <MenuItem id={t.id} key={t.id} value={t.id}>
                          {t.name} - {t.items?.length} items
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <React.Fragment />
                )}
                {values.assigneeId !== "" || values.assignedRoleId !== -1 ? (
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    disabled
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                )}
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
      <Card elevation={2}>
        <CardContent sx={{ background: "rgba(255, 255, 255, 0.12)" }}>
          <Typography variant="h5">Equipment Task Status</Typography>
          <Scrollbar style={{ height: 200 }}>
            {localLoading ? (
              <Box sx={{ width: 450 }}>
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} animation="wave" />
              </Box>
            ) : tasks.length > 0 ? (
              <List sx={{ height: 200 }} disablePadding dense>
                {tasks.map((tsk) => {
                  return (
                    <ListItem
                      key={tsk.id}
                      divider
                      secondaryAction={
                        IsUserElevated(userProfile, selectedClient.id) && selectedClient.billingTier !== 0 ? (
                          <Tooltip title="Assign task">
                            <IconButton
                              onClick={() => {
                                var todoList: ShipTodoList = {
                                  taskName: tsk.name,
                                  taskId: tsk.id,
                                  fromTask: true,
                                };
                                setTodoListAssign(todoList);
                                setOpen(true);
                              }}
                              edge="end"
                            >
                              <PersonAdd />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <React.Fragment />
                        )
                      }
                    >
                      <ListItemButton
                        onClick={() => {
                          var name = ships.filter(
                            (sh) => sh.id === props.selectedShipId
                          )[0].name;
                          setSelected(name);
                          navigate(
                            `/Ships/${props.selectedShipId}/tasks/equipment/${tsk.id}`
                          );
                        }}
                      >
                        <ListItemAvatar>
                          {tsk.usageSinceLastUpdate !== undefined &&
                          tsk.unitFrequencyInterval !== undefined &&
                          tsk.lastPerformedUsage !== undefined &&
                          (tsk.usageSinceLastUpdate >=
                            tsk.unitFrequencyInterval ||
                            tsk.lastPerformedUsage === 0) ? (
                            <TaskAlt sx={{ color: "red" }} />
                          ) : tsk.usageSinceLastUpdate !== undefined &&
                            tsk.unitFrequencyInterval !== undefined &&
                            tsk.usageSinceLastUpdate /
                              tsk.unitFrequencyInterval >=
                              0.75 ? (
                            <TaskAlt sx={{ color: "yellow" }} />
                          ) : (
                            <TaskAlt />
                          )}
                        </ListItemAvatar>
                        {tsk.usageSinceLastUpdate !== undefined &&
                        tsk.unitFrequencyInterval !== undefined &&
                        tsk.lastPerformedUsage !== undefined &&
                        (tsk.usageSinceLastUpdate >=
                          tsk.unitFrequencyInterval ||
                          tsk.lastPerformedUsage === 0) ? (
                          <ListItemText
                            primary={tsk.name}
                            secondary={`Overdue by ${
                              tsk.usageSinceLastUpdate -
                              tsk.unitFrequencyInterval
                            } (${tsk.equipment?.usageUnits})`}
                            secondaryTypographyProps={{ color: "red" }}
                          ></ListItemText>
                        ) : tsk.usageSinceLastUpdate !== undefined &&
                          tsk.unitFrequencyInterval !== undefined &&
                          tsk.usageSinceLastUpdate /
                            tsk.unitFrequencyInterval >=
                            0.75 ? (
                          <ListItemText
                            primary={tsk.name}
                            secondary={`Due in ${
                              tsk.unitFrequencyInterval -
                              tsk.usageSinceLastUpdate
                            } (${tsk.equipment?.usageUnits})`}
                            secondaryTypographyProps={{ color: "yellow" }}
                          ></ListItemText>
                        ) : (
                          <ListItemText
                            primary={tsk.name}
                            secondary={
                              tsk.usageSinceLastUpdate !== undefined &&
                              tsk.unitFrequencyInterval !== undefined
                                ? `Due in ${
                                    tsk.unitFrequencyInterval -
                                    tsk.usageSinceLastUpdate
                                  } (${tsk.equipment?.usageUnits})`
                                : " "
                            }
                          ></ListItemText>
                        )}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              <Button
                variant="contained"
                sx={{ top: "80px", left: "30%" }}
                onClick={() => {
                  var name = ships.filter(
                    (sh) => sh.id === props.selectedShipId
                  )[0].name;
                  setSelected(name);
                  navigate(`/Ships/${props.selectedShipId}`);
                }}
              >
                No Pending Usage Tasks
              </Button>
            )}
          </Scrollbar>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
