import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getShip, getTasks } from "../../services/shipService";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { Ship } from "../../types/Ship";
import {
  Add,
  CalendarMonth,
  DirectionsBoat,
  Error,
  NoteAdd,
  TaskAlt,
  Warning,
} from "@mui/icons-material";
import moment from "moment";
import { Task } from "../../types/Task";
import { DataGrid, GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import PaperSection from "../../components/PaperSection";
import { IconEngine } from "@tabler/icons-react";
import BillingQouta from "../../components/Billing/BillingQouta";

export default function Tasks() {
  const params = useParams();
  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [selectedShip] = useRecoilState(SelectedShipState);

  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
      getTasks(selectedClient.id, params.id).then((taskRes) => {
        setTasks(taskRes);
      });
    });
  }, [selectedClient, selectedClient.id, params.id]);

  const columnsUsageBased = [
    {
      field: "name",
      headerName: "Name",
      valueGetter: (params: any) => {
        return params.row.name;
      },
      width: 150,
    },
    {
      field: "equipment",
      headerName: "Required Equipment",
      valueGetter: (params: any) => {
        return params.row.equipment.name;
      },
      width: 150,
    },
    {
      field: "priority",
      headerName: "Priority",
      valueGetter: (params: any) => {
        var priority = params.row.priority;
        if (priority === 1) {
          return "Critical";
        } else if (priority === 2) {
          return "Important";
        } else {
          return "Required";
        }
      },
      width: 150,
    },
    {
      field: "lastUsageUpdate",
      headerName: "Last Performed Date",
      valueFormatter: (params: any) => {
        if (params.value === "0001-01-01T00:00:00") {
          return "Never";
        }
        return moment(params.value).format("MM/DD/YYYY");
      },
      width: 150,
    },
    {
      field: "lastPerformedUsage",
      headerName: "Last Performed Usage",
      valueGetter: (params: any) => {
        if (params.row.lastPerformedUsage === 0) {
          return "No Completed Tasks";
        } else {
          return params.row.lastPerformedUsage;
        }
      },
      width: 150,
    },
    {
      field: "usageSinceLastUpdate",
      headerName: "Usage Since Last Update",
      valueGetter: (params: any) => {
        if (params.row.equipment === undefined) {
          return `${params.row.usageSinceLastUpdate}`;
        }
        return `${params.row.usageSinceLastUpdate} (${params.row.equipment.usageUnits})`;
      },
      width: 150,
    },
    {
      field: "unitFrequencyInterval",
      headerName: "Unit Frequency Interval",
      valueGetter: (params: any) => {
        return `${params.row.unitFrequencyInterval} (${params.row.equipment.usageUnits})`;
      },
      width: 150,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Log",
      width: 25,
      cellClassName: "actions",
      getActions: (params: GridRowParams): any => {
        if (
          params.row.usageSinceLastUpdate >= params.row.unitFrequencyInterval ||
          params.row.lastPerformedUsage === 0
        ) {
          return [
            <GridActionsCellItem
              icon={<Error sx={{ color: "red" }} />}
              label="Log Usage"
              onClick={() => {
                navigate(
                  `/Ships/${ship?.id}/tasks/equipment/${params.row.id}/complete`
                );
              }}
              color="inherit"
            />,
          ];
        } else if (
          params.row.usageSinceLastUpdate / params.row.unitFrequencyInterval >=
          0.75
        ) {
          return [
            <GridActionsCellItem
              icon={<Warning sx={{ color: "yellow" }} />}
              label="Log Usage"
              onClick={() => {
                navigate(
                  `/Ships/${ship?.id}/tasks/equipment/${params.row.id}/complete`
                );
              }}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<NoteAdd />}
            label="Log Usage"
            onClick={() => {
              navigate(
                `/Ships/${ship?.id}/tasks/equipment/${params.row.id}/complete`
              );
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const columnsDateBased = [
    {
      field: "name",
      headerName: "Name",
      valueGetter: (params: any) => {
        return params.row.name;
      },
      width: 150,
    },
    {
      field: "equipment",
      headerName: "Required Equipment",
      valueGetter: (params: any) => {
        if (params.row.forEquipment === false) {
          return "N/A";
        } else {
          return params.row.equipment.name;
        }
      },
      width: 150,
    },
    {
      field: "priority",
      headerName: "Priority",
      valueGetter: (params: any) => {
        var priority = params.row.priority;
        if (priority === 1) {
          return "Critical";
        } else if (priority === 2) {
          return "Important";
        } else {
          return "Required";
        }
      },
      width: 150,
    },
    {
      field: "dateFrequencyInterval",
      headerName: "Date Frequency Interval",
      valueGetter: (params: any) => {
        if (params.row.dateFrequencyInterval === 1) {
          return `Set Interval`;
        } else if (params.row.dateFrequencyInterval === 2) {
          return `Monthly`;
        } else {
          return "Not Date Based";
        }
      },
      width: 150,
    },
    {
      field: "dateFrequency",
      headerName: "Date Frequency",
      valueGetter: (params: any) => {
        return params.row.dateFrequency;
      },
      width: 150,
    },
    {
      field: "lastUsageUpdate",
      headerName: "Last Performed Date",
      valueFormatter: (params: any) => {
        if (params.value === "0001-01-01T00:00:00") {
          return "Never";
        }
        return moment(params.value).format("MM/DD/YYYY");
      },
      width: 150,
    },
    {
      field: "daysUntilNextUpdate",
      headerName: "Days Until Next Update",
      valueGetter: (params: any) => {
        if (params.row.daysUntilNextUpdate < 0) {
          return 0;
        }
        return params.row.daysUntilNextUpdate;
      },
      width: 150,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Log",
      width: 25,
      cellClassName: "actions",
      getActions: (params: GridRowParams): any => {
        if (params.row.daysUntilNextUpdate <= 0) {
          return [
            <GridActionsCellItem
              icon={<Error sx={{ color: "red" }} />}
              label="Log Usage"
              onClick={() => {
                navigate(
                  `/Ships/${ship?.id}/tasks/time/${params.row.id}/complete`
                );
              }}
              color="inherit"
            />,
          ];
        } else if (
          params.row.daysUntilNextUpdate / params.row.dateFrequency <=
          0.25
        ) {
          return [
            <GridActionsCellItem
              icon={<Warning sx={{ color: "yellow" }} />}
              label="Log Usage"
              onClick={() => {
                navigate(
                  `/Ships/${ship?.id}/tasks/time/${params.row.id}/complete`
                );
              }}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<NoteAdd />}
            label="Log Usage"
            onClick={() => {
              navigate(
                `/Ships/${ship?.id}/tasks/time/${params.row.id}/complete`
              );
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Container maxWidth="xl" sx={{ mb: "150px" }}>
      <Breadcrumbs>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <TaskAlt sx={{ mr: 0.5 }} fontSize="inherit" />
          Tasks
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          {selectedClient.billingTier === 0 ? (
            <BillingQouta
              metric={tasks.length}
              maxMetric={10}
              metricName={"task"}
              displayMetricName={false}
              pluralMetricName={"tasks"}
              costPerAdditional={5}
            />
          ) : (
            <Box />
          )}
        </Grid>

        <Grid item xs={12} md={5} textAlign="end">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate(`/ships/${ship?.id}/newtask`);
            }}
            startIcon={<Add />}
          >
            New Task
          </Button>
        </Grid>
        <Grid item xs={12}>
          <PaperSection title="Usage Based Tasks" icon={<IconEngine />}>
            {tasks === undefined ? (
              <Box />
            ) : (
              <DataGrid
                sx={{ height: "300px", minHeight: "300px",}}
                onRowClick={(value) =>
                  navigate(`/Ships/${ship?.id}/tasks/equipment/${value.id}`)
                }
                columns={columnsUsageBased}
                rows={tasks.filter((t) => t.dateFrequencyInterval === 0)}
              />
            )}
          </PaperSection>
        </Grid>
        <Grid item xs={12}>
          <PaperSection title="Date Based Tasks" icon={<CalendarMonth />}>
            {tasks === undefined ? (
              <Box />
            ) : (
              <DataGrid
                sx={{ minHeight: "300px", maxHeight: "300px" }}
                onRowClick={(value) =>
                  navigate(`/Ships/${ship?.id}/tasks/time/${value.id}`)
                }
                columns={columnsDateBased}
                rows={tasks.filter((t) => t.dateFrequencyInterval !== 0)}
              />
            )}
          </PaperSection>
        </Grid>
      </Grid>
    </Container>
  );
}
