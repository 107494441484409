import { Box, Grid } from "@mui/material";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { ShipFoodInformationSummaryDTO } from "../../types/PassengerTypes";
import { useEffect, useState } from "react";
import { getTripFoodInformation } from "../../services/tripservice";
import { useParams } from "react-router-dom";
import FoodPrefCard from "./Food/FoodPrefCard";
import {
  IconApple,
  IconCake,
  IconCancel,
  IconCheese,
  IconFish,
  IconMeat,
  IconSalt,
  IconSeeding,
  IconSoup,
  IconSunrise,
  IconToolsKitchen,
} from "@tabler/icons-react";
import FoodScoresCard from "./Food/FoodScoresCard";
import FoodRequestsCard from "./Food/FoodRequestCard";
import DietaryRestrictionsCard from "./Food/DietaryRestrictions";
import VeganVegetarianCard from "./Food/VeganVegetarianCard";

export default function FoodSummary() {
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [food, setFood] = useState<ShipFoodInformationSummaryDTO>();
  const params = useParams();

  useEffect(() => {
    getTripFoodInformation(
      selectedClient.id,
      selectedShip.id,
      params.tripid
    ).then((res) => {
      setFood(res);
    });
  }, [selectedClient.id, selectedShip.id, params.tripid]);
  return (
    <Box>
      {food !== undefined ? (
        <Grid spacing={2} container>
          {food.vegans.length > 0 ||
          food.eggDairyVegetarians.length > 0 ||
          food.vegetarians.length > 0 ? (
            <Grid item xs={12}>
              <VeganVegetarianCard
                vegans={food.vegans}
                vegetarians={food.vegetarians}
                eggDairyVegetarians={food.eggDairyVegetarians}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.dietaryRestrictions.length > 0 ? (
            <Grid item xs={12} md={6}>
              <DietaryRestrictionsCard
                title="Dietary Restrictions"
                icon={<IconCancel />}
                list={food.dietaryRestrictions}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.otherDishesRequests.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodRequestsCard
                title="Requested Dishes"
                icon={<IconToolsKitchen />}
                list={food.otherDishesRequests}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.cannotHaveBreakfast.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodPrefCard
                title="Breakfast - Cannot Have"
                icon={<IconSunrise />}
                list={food.cannotHaveBreakfast}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.mustHaveBreakfast.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodPrefCard
                title="Breakfast - Preferred"
                icon={<IconSunrise />}
                list={food.mustHaveBreakfast}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.breakfastScores.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodScoresCard
                title="Breakfast - Scores"
                icon={<IconSunrise />}
                list={food.breakfastScores}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.breakfastRequests.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodRequestsCard
                title="Breakfast - Requests"
                icon={<IconSunrise />}
                list={food.breakfastRequests}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.cannotHaveCheese.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodPrefCard
                title="Cheese - Cannot Have"
                icon={<IconCheese />}
                list={food.cannotHaveCheese}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.mustHaveCheese.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodPrefCard
                title="Cheese - Preferred"
                icon={<IconCheese />}
                list={food.mustHaveCheese}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.cheeseScores.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodScoresCard
                title="Cheese - Scores"
                icon={<IconCheese />}
                list={food.cheeseScores}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.cheeseRequests.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodRequestsCard
                title="Cheese - Requests"
                icon={<IconCheese />}
                list={food.cheeseRequests}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.cannotHaveDessert.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodPrefCard
                title="Dessert - Cannot Have"
                icon={<IconCake />}
                list={food.cannotHaveDessert}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.mustHaveDessert.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodPrefCard
                title="Dessert - Preferred"
                icon={<IconCake />}
                list={food.mustHaveDessert}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.dessertScores.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodScoresCard
                title="Dessert - Scores"
                icon={<IconCake />}
                list={food.dessertScores}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.dessertRequests.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodRequestsCard
                title="Dessert - Requests"
                icon={<IconCake />}
                list={food.dessertRequests}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.cannotHaveFruit.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodPrefCard
                title="Fruit - Cannot Have"
                icon={<IconApple />}
                list={food.cannotHaveFruit}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.mustHaveFruit.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodPrefCard
                title="Fruit - Preferred"
                icon={<IconApple />}
                list={food.mustHaveFruit}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.fruitScores.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodScoresCard
                title="Fruit - Scores"
                icon={<IconApple />}
                list={food.fruitScores}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.fruitRequests.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodRequestsCard
                title="Fruit - Requests"
                icon={<IconApple />}
                list={food.fruitRequests}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.cannotHaveMeat.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodPrefCard
                title="Meat - Cannot Have"
                icon={<IconMeat />}
                list={food.cannotHaveMeat}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.mustHaveMeat.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodPrefCard
                title="Meat - Preferred"
                icon={<IconMeat />}
                list={food.mustHaveMeat}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.meatScores.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodScoresCard
                title="Meat - Scores"
                icon={<IconMeat />}
                list={food.meatScores}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.meatRequests.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodRequestsCard
                title="Meat - Requests"
                icon={<IconMeat />}
                list={food.meatRequests}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.cannotHaveSauce.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodPrefCard
                title="Sauce/Spice - Cannot Have"
                icon={<IconSalt />}
                list={food.cannotHaveSauce}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.mustHaveSauce.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodPrefCard
                title="Sauce/Spice  - Preferred"
                icon={<IconSalt />}
                list={food.mustHaveSauce}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.sauceScores.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodScoresCard
                title="Sauce/Spice  - Scores"
                icon={<IconSalt />}
                list={food.sauceScores}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.sauceRequests.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodRequestsCard
                title="Sauce/Spice  - Requests"
                icon={<IconSalt />}
                list={food.sauceRequests}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.cannotHaveSeafood.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodPrefCard
                title="Seafood - Cannot Have"
                icon={<IconFish />}
                list={food.cannotHaveSeafood}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.mustHaveSeafood.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodPrefCard
                title="Seafood  - Preferred"
                icon={<IconFish />}
                list={food.mustHaveSeafood}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.seafoodScores.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodScoresCard
                title="Seafood  - Scores"
                icon={<IconFish />}
                list={food.seafoodScores}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.seafoodRequests.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodRequestsCard
                title="Seafood  - Requests"
                icon={<IconFish />}
                list={food.seafoodRequests}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.cannotHaveSoup.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodPrefCard
                title="Soup/Rice/Pasta - Cannot Have"
                icon={<IconSoup />}
                list={food.cannotHaveSoup}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.mustHaveSoup.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodPrefCard
                title="Soup/Rice/Pasta  - Preferred"
                icon={<IconSoup />}
                list={food.mustHaveSoup}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.soupScores.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodScoresCard
                title="Soup/Rice/Pasta  - Scores"
                icon={<IconSoup />}
                list={food.soupScores}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.soupRequests.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodRequestsCard
                title="Soup/Rice/Pasta  - Requests"
                icon={<IconSoup />}
                list={food.soupRequests}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.cannotHaveVegetables.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodPrefCard
                title="Vegetables - Cannot Have"
                icon={<IconSeeding />}
                list={food.cannotHaveVegetables}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.mustHaveVegetables.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodPrefCard
                title="Vegetables  - Preferred"
                icon={<IconSeeding />}
                list={food.mustHaveVegetables}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.vegetableScores.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodScoresCard
                title="Vegetables  - Scores"
                icon={<IconSeeding />}
                list={food.vegetableScores}
              />
            </Grid>
          ) : (
            <Box />
          )}
          {food.vegetableRequests.length > 0 ? (
            <Grid item xs={12} md={6}>
              <FoodRequestsCard
                title="Vegetables  - Requests"
                icon={<IconSeeding />}
                list={food.vegetableRequests}
              />
            </Grid>
          ) : (
            <Box />
          )}
        </Grid>
      ) : (
        <Box />
      )}
    </Box>
  );
}
