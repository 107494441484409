import axios from "axios";
import { IncidentPart, IncidentReport, IncidentReportComment } from "../types/IncidentReport";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";

//const API_URL: string = "https://localhost:7046/";
const API_URL: string = "https://api.zsail.io/";

export const createIncident = (
  clientId?: string,
  shipId?: string,
  dto?: IncidentReport
) => {
  return axios
    .post(`${API_URL}ShipIncident?clientId=${clientId}&shipId=${shipId}`, dto)
    .then((response) => {
      return response.data;
    });
};

export const updateIncident = (
  clientId?: string,
  shipId?: string,
  dto?: IncidentReport
) => {
  return axios
    .post(
      `${API_URL}ShipIncident/incident/${dto?.id}?clientId=${clientId}&shipId=${shipId}`,
      dto
    )
    .then((response) => {
      return response.data;
    });
};

export const getIncidents = (clientId?: string, shipId?: string) => {
  return axios
    .get(`${API_URL}ShipIncident?clientId=${clientId}&shipId=${shipId}`)
    .then((response) => {
      return response.data;
    });
};
export const getIncidentById = (
  clientId?: string,
  shipId?: string,
  incidentId?: string
) => {
  return axios
    .get(
      `${API_URL}ShipIncident/incident/${incidentId}?clientId=${clientId}&shipId=${shipId}`
    )
    .then((response) => {
      return response.data;
    });
};

export const createIncidentPart = (
  clientId?: string,
  shipId?: string,
  incidentId?: string,
  dto?: IncidentPart
) => {
  return axios
    .post(
      `${API_URL}ShipIncident/incident/${incidentId}/parts?clientId=${clientId}&shipId=${shipId}`, dto
    )
    .then((response) => {
      return response.data;
    });
};

export const updateIncidentPart = (
  clientId?: string,
  shipId?: string,
  incidentId?: string,
  partId?: string,
  quantity?: number,
  trackingNumber?: string,
) => {
  return axios
    .post(
      `${API_URL}ShipIncident/incident/${incidentId}/parts/${partId}?clientId=${clientId}&shipId=${shipId}&quantity=${quantity}&trackingNumber=${trackingNumber}`
    )
    .then((response) => {
      return response.data;
    });
};

export const deleteIncidentPart = (
  clientId?: string,
  shipId?: string,
  incidentId?: string,
  partId?: string,
) => {
  return axios
    .delete(
      `${API_URL}ShipIncident/incident/${incidentId}/parts/${partId}?clientId=${clientId}&shipId=${shipId}`
    )
    .then((response) => {
      return response.data;
    });
};

export const getActiveIncidents = (clientId?: string, shipId?: string) => {
  return axios
    .get(`${API_URL}ShipIncident/active?clientId=${clientId}&shipId=${shipId}`)
    .then((response) => {
      return response.data;
    });
};

export const getInReviewIncidents = (clientId?: string, shipId?: string) => {
  return axios
    .get(`${API_URL}ShipIncident/inreview?clientId=${clientId}&shipId=${shipId}`)
    .then((response) => {
      return response.data;
    });
};

export const getDeferredIncidents = (clientId?: string, shipId?: string) => {
  return axios
    .get(`${API_URL}ShipIncident/deferred?clientId=${clientId}&shipId=${shipId}`)
    .then((response) => {
      return response.data;
    });
};

export const getClosedIncidents = (clientId?: string, shipId?: string) => {
  return axios
    .get(`${API_URL}ShipIncident/closed?clientId=${clientId}&shipId=${shipId}`)
    .then((response) => {
      return response.data;
    });
};
export const uploadIncidentImage = (
  uploadProgressFunction: Function,
  clientId?: string,
  shipId?: string,
  incidentId?: string,
  formData?: FormData,
  helperName?: string
) => {
  return axios
    .post(
      `${API_URL}ShipIncident/image/${incidentId}?clientId=${clientId}&shipId=${shipId}&helperName=${helperName}`,
      formData,
      {
        onUploadProgress: (progressEvent) => {
          uploadProgressFunction(progressEvent);
        },
      }
    )
    .then((res) => {
      return res.data;
    });
};

export const uploadIncidentFile = (
  uploadProgressFunction: Function,
  clientId?: string,
  shipId?: string,
  incidentId?: string,
  formData?: FormData,
  helperName?: string
) => {
  return axios
    .post(
      `${API_URL}ShipIncident/file/${incidentId}?clientId=${clientId}&shipId=${shipId}&helperName=${helperName}`,
      formData,
      {
        onUploadProgress: (progressEvent) => {
          uploadProgressFunction(progressEvent);
        },
      }
    )
    .then((res) => {
      return res.data;
    });
};

export const getIncidentImageBlob = (
  incidentId?: string,
  fileId?: string,
  clientId?: string
) => {
  return axios
    .get(
      `${API_URL}ShipIncident/image/${incidentId}/download/${fileId}?clientId=${clientId}`,
      { responseType: "blob", withCredentials: true }
    )
    .then((response) => {
      return response.data;
    });
};

export const getIncidentFileBlob = (
  incidentId?: string,
  fileId?: string,
  clientId?: string
) => {
  return axios
    .get(
      `${API_URL}ShipIncident/file/${incidentId}/download/${fileId}?clientId=${clientId}`,
      { responseType: "blob", withCredentials: true }
    )
    .then((response) => {
      var filename = "";
      var disposition = response.headers["content-disposition"];
      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }
      saveAs(response.data, filename);
      return;
    });
};

export const deleteIncidentImage = (
  incidentId?: string,
  fileId?: string,
  clientId?: string
) => {
  return axios
    .delete(
      `${API_URL}ShipIncident/image/${incidentId}/delete/${fileId}?clientId=${clientId}`
    )
    .then((response) => {
      return response.data;
    });
};
export const deleteIncidentFile = (
  incidentId?: string,
  fileId?: string,
  clientId?: string
) => {
  return axios
    .delete(
      `${API_URL}ShipIncident/file/${incidentId}/delete/${fileId}?clientId=${clientId}`
    )
    .then((response) => {
      return response.data;
    });
};

export const createIncidentComment = (
  incidentId?: string,
  clientId?: string,
  comment?: IncidentReportComment
) => {
  return axios
    .post(
      `${API_URL}ShipIncident/comments/${incidentId}?clientId=${clientId}`,
      comment
    )
    .then((response) => {
      return response.data;
    });
};

export const closeIncident = (incidentId?: string, clientId?: string) => {
  return axios
    .get(`${API_URL}ShipIncident/close/${incidentId}?clientId=${clientId}`)
    .then((res) => {
      return res.data;
    });
};
export const resumeIncident = (incidentId?: string, clientId?: string) => {
  return axios
    .get(`${API_URL}ShipIncident/resume/${incidentId}?clientId=${clientId}`)
    .then((res) => {
      return res.data;
    });
};
export const deferIncident = (incidentId?: string, clientId?: string, deferDate?: DateTime) => {
  return axios
    .post(`${API_URL}ShipIncident/defer/${incidentId}?clientId=${clientId}`, deferDate)
    .then((res) => {
      return res.data;
    });
};

export const failIncident = (incidentId?: string, clientId?: string) => {
  return axios
    .get(`${API_URL}ShipIncident/fail/${incidentId}?clientId=${clientId}`)
    .then((res) => {
      return res.data;
    });
};
