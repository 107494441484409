import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { PassengerProps } from "../../../types/PassengerTypes";

export default function MedicalInfo(props: PassengerProps) {
  const { passenger } = props;
  return passenger.passengerMedicalInformation !== undefined ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 4,
            borderTopColor: "#b47f49",
            borderTopWidth: "4px",
            borderStyle: "solid",
            borderRightWidth: "0px",
            borderLeftWidth: "0px",
            borderBottomWidth: "0",
          }}
          elevation={12}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4">Medical Information</Typography>
            </Grid>
            <Grid item xs={12}>
              <Formik
                initialValues={passenger.passengerMedicalInformation}
                onSubmit={() => {}}
              >
                {({
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  touched,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="allergies"
                          name="allergies"
                          label={`Do you have any allergies you would like us to know about?`}
                          value={values.allergies}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{shrink: true,}}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.allergies && Boolean(errors.allergies)}
                          helperText={touched.allergies && errors.allergies}
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="foodAllergies"
                          name="foodAllergies"
                          label={`Do you have any food allergies or sensitivities you would like us to know about?`}
                          value={values.foodAllergies}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{shrink: true,}}
                          error={
                            touched.foodAllergies &&
                            Boolean(errors.foodAllergies)
                          }
                          helperText={
                            touched.foodAllergies && errors.foodAllergies
                          }
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="medicalConditionsOrDisabilities"
                          name="medicalConditionsOrDisabilities"
                          label={`Do you have any medical conditions or disabilities that we should know about?`}
                          value={values.medicalConditionsOrDisabilities}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputProps={{
                            readOnly: true,
                          }}
                          error={
                            touched.medicalConditionsOrDisabilities &&
                            Boolean(errors.medicalConditionsOrDisabilities)
                          }
                          helperText={
                            touched.medicalConditionsOrDisabilities &&
                            errors.medicalConditionsOrDisabilities
                          }
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={values.useMobilityAids} />
                          }
                          label="Do you use mobility aids?"
                          disabled
                          name="useMobilityAids"
                          onChange={handleChange}
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.useHearingAidsorCochlearImplants}
                            />
                          }
                          disabled
                          label="Do you use Hearing Aids or Cochlear Implants?"
                          name="useHearingAidsorCochlearImplants"
                          onChange={handleChange}
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={values.haveSeasickness} />
                          }
                          disabled
                          label="Do you have seasickness?"
                          name="haveSeasickness"
                          onChange={handleChange}
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      {values.haveSeasickness ? (
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.useSeasicknessMedicine}
                              />
                            }
                            disabled
                            label="Would you like to be provided with seasickness treatments including dramamine, seasickness wristbands, etc?"
                            name="useSeasicknessMedicine"
                            onChange={handleChange}
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  ) : (
    <Box />
  );
}
