import axios from "axios";
import { Ship, ShipUserRole } from "../types/Ship";
import { Equipment } from "../types/Equipment";
import { Part } from "../types/Part";
import { EquipmentLogItem } from "../types/EquipmentLogItem";
import { PartLog } from "../types/PartLog";
import { EquipmentTaskDTO, Task } from "../types/Task";
import { saveAs } from "file-saver";

var API_URL = "https://api.zsail.io/";
//var API_URL = "https://localhost:7046/";

export const getShips = (clientId?: string) => {
  return axios
    .get<Ship[]>(`${API_URL}Ship/${clientId}`)
    .then((res) => {
      return res.data;
    });
};

export const createShip = (ship: Ship) => {
  return axios.post(`${API_URL}Ship`, ship).then((response) => {
    return response.data;
  });
};

export const editShip = (ship: Ship) => {
  return axios
    .post(`${API_URL}Ship/Update`, ship)
    .then((response) => {
      return response.data;
    });
};
export const createPart = (part: Part, shipId?: string, clientId?: string) => {
  return axios
    .post(
      `${API_URL}Ship/Create/${shipId}/Part?clientId=${clientId}`,
      part
    )
    .then((response) => {
      return response.data;
    });
};

export const updatePart = (part: Part, shipId?: string, clientId?: string) => {
  return axios
    .post(
      `${API_URL}Ship/Update/${shipId}/Part/${part.id}?clientId=${clientId}`,
      part
    )
    .then((response) => {
      return response.data;
    });
};

export const createEquipment = (
  equipment: Equipment,
  shipId?: string,
  clientId?: string
) => {
  return axios
    .post(
      `${API_URL}Ship/Create/${shipId}/Equipment?clientId=${clientId}`,
      equipment
    )
    .then((response) => {
      return response.data;
    });
};

export const updateEquipment = (
  equipment: Equipment,
  shipId?: string,
  clientId?: string
) => {
  return axios
    .post(
      `${API_URL}Ship/Update/${shipId}/Equipment/${equipment.id}?clientId=${clientId}`,
      equipment
    )
    .then((response) => {
      return response.data;
    });
};

export const getShip = (shipId?: string, clientId?: string) => {
  return axios
    .get(`${API_URL}Ship/Detail/${shipId}?clientId=${clientId}`)
    .then((response) => {
      return response.data;
    });
};

export const getShipParts = (shipId?: string, clientId?: string) => {
  return axios
    .get(
      `${API_URL}Ship/Detail/${shipId}/parts?clientId=${clientId}`
    )
    .then((response) => {
      return response.data;
    });
};

export const getEquipment = (clientId?: string, shipId?: string) => {
  return axios
    .get<Equipment[]>(
      `${API_URL}Ship/Detail/${shipId}/equipment?clientId=${clientId}`
    )
    .then((res) => {
      return res.data;
    });
};

export const getEquipmentById = (
  clientId?: string,
  shipId?: string,
  equipmentId?: string
) => {
  return axios
    .get<Equipment>(
      `${API_URL}Ship/Detail/${shipId}/equipment/${equipmentId}?clientId=${clientId}`
    )
    .then((res) => {
      return res.data;
    });
};

export const uploadEquipmentFile = (
  uploadProgressFunction: Function,
  clientId?: string,
  shipId?: string,
  equipmentId?: string,
  formData?: FormData,
  helperName?: string
) => {
  return axios
    .post(
      `${API_URL}Ship/upload/${shipId}/equipment/${equipmentId}?clientId=${clientId}&helperName=${helperName}`,
      formData,
      {
        onUploadProgress: (progressEvent) => {
          uploadProgressFunction(progressEvent);
        },
      }
    )
    .then((res) => {
      return res.data;
    });
};

export const uploadTaskFile = (
  uploadProgressFunction: Function,
  clientId?: string,
  shipId?: string,
  taskId?: string,
  formData?: FormData,
  helperName?: string
) => {
  return axios
    .post(
      `${API_URL}Ship/upload/${shipId}/task/${taskId}?clientId=${clientId}&helperName=${helperName}`,
      formData,
      {
        onUploadProgress: (progressEvent) => {
          uploadProgressFunction(progressEvent);
        },
      }
    )
    .then((res) => {
      return res.data;
    });
};

export const downloadEquipmentFile = (fileId?: string, clientId?: string) => {
  return axios
    .get(
      `${API_URL}Ship/download/equipment/${fileId}?clientId=${clientId}`,
      { responseType: "blob", withCredentials: true }
    )
    .then((response) => {
      var filename = "";
      var disposition = response.headers["content-disposition"];
      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }
      saveAs(response.data, filename);
      return;
    });
};

export const downloadTaskFile = (fileId?: string, clientId?: string) => {
  return axios
    .get(
      `${API_URL}Ship/download/task/${fileId}?clientId=${clientId}`,
      { responseType: "blob", withCredentials: true }
    )
    .then((response) => {
      console.log(response);
      var filename = "";
      var disposition = response.headers["content-disposition"];
      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }
      console.log(disposition);
      saveAs(response.data, filename);
      return;
    });
};

export const createEquipmentLog = (
  equipmentLog: EquipmentLogItem,
  shipId?: string,
  clientId?: string
) => {
  return axios
    .post(
      `${API_URL}Ship/Log/${shipId}/Equipment?clientId=${clientId}`,
      equipmentLog
    )
    .then((res) => {
      return res.data;
    });
};

export const getPartById = (
  clientId?: string,
  shipId?: string,
  partId?: string
) => {
  return axios
    .get<Part>(
      `${API_URL}Ship/Detail/${shipId}/parts/${partId}?clientId=${clientId}`
    )
    .then((res) => {
      return res.data;
    });
};

export const createPartsLog = (
  partLog: PartLog,
  clientId?: string,
  shipId?: string
) => {
  return axios
    .post(
      `${API_URL}Ship/Log/${shipId}/parts?clientId=${clientId}`,
      partLog
    )
    .then((res) => {
      return res.data;
    });
};

export const createTask = (task?: Task, clientId?: string, shipId?: string) => {
  return axios
    .post(
      `${API_URL}Ship/Create/${shipId}/Task?clientId=${clientId}`,
      task
    )
    .then((res) => {
      return res.data;
    });
};

export const updateTask = (task?: Task, clientId?: string, shipId?: string) => {
  return axios
    .post(
      `${API_URL}Ship/Update/${shipId}/Task/${task?.id}?clientId=${clientId}`,
      task
    )
    .then((res) => {
      return res.data;
    });
};

export const getTasks = (clientId?: string, shipId?: string) => {
  return axios
    .get(
      `${API_URL}Ship/Detail/${shipId}/Tasks?clientId=${clientId}`
    )
    .then((res) => {
      return res.data;
    });
};

export const getTask = (
  clientId?: string,
  shipId?: string,
  taskId?: string
) => {
  return axios
    .get(
      `${API_URL}Ship/Detail/${shipId}/tasks/${taskId}?clientId=${clientId}`
    )
    .then((res) => {
      return res.data;
    });
};

export const completeEquipmentTask = (
  clientId?: string,
  shipId?: string,
  equipmentTask?: EquipmentTaskDTO
) => {
  return axios
    .post(
      `${API_URL}Ship/Complete/${shipId}/Task/${equipmentTask?.taskId}?clientId=${clientId}`,
      equipmentTask
    )
    .then((res) => {
      return res.data;
    });
};

export const getShipUserRoles = (clientId?: string, shipId?: string) => {
  return axios
    .get(
      `${API_URL}Ship/Detail/${shipId}/Roles?clientId=${clientId}`
    )
    .then((res) => {
      return res.data;
    });
};

export const createShipUserRole = (
  clientId?: string,
  shipId?: string,
  shipRole?: ShipUserRole
) => {
  return axios
    .post(
      `${API_URL}Ship/Detail/${shipId}/Roles?clientId=${clientId}`,
      shipRole
    )
    .then((res) => {
      return res.data;
    });
};

export const uploadTaskImage = (
  uploadProgressFunction: Function,
  clientId?: string,
  shipId?: string,
  taskId?: string,
  helperName?: string,
  description?: string,
  formData?: FormData
) => {
  return axios
    .post(
      `${API_URL}Ship/Detail/${shipId}/Tasks/${taskId}/image?clientId=${clientId}&helperName=${helperName}&description=${description}`,
      formData,
      {
        onUploadProgress: (progressEvent) => {
          uploadProgressFunction(progressEvent);
        },
      }
    )
    .then((res) => res.data);
};

export const downloadTaskImage = (
  fileId?: string,
  shipId?: string,
  taskId?: string,
  clientId?: string
) => {
  return axios
    .get(
      `${API_URL}Ship/Detail/${shipId}/Tasks/${taskId}/image/${fileId}?clientId=${clientId}`,
      { responseType: "blob", withCredentials: true }
    )
    .then((res) => res.data);
};

export const deleteTaskImage = (
  taskId?: string,
  shipId?: string,
  fileId?: string,
  clientId?: string
) => {
  return axios
    .delete(
      `${API_URL}Ship/Detail/${shipId}/Tasks/${taskId}/image/${fileId}?clientId=${clientId}`
    )
    .then((response) => {
      return response.data;
    });
};
