import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Add, ArrowRightAlt, Group } from "@mui/icons-material";
import { Trip, TripPassenger } from "../../types/Trip";
import PassengerCard from "./PassengerCard";
import { Formik } from "formik";
import { useState } from "react";
import { createTripPassenger, getTrip } from "../../services/tripservice";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import AccordianPaperSection from "../AccordianPaperSection";
import { useNavigate } from "react-router-dom";

export type TripPassengersCardProps = {
  trip: Trip;
  setTrip: Function;
};

export default function TripPassengersCard(props: TripPassengersCardProps) {
  const navigate = useNavigate();
  const { trip, setTrip } = props;
  const [passengersModalOpen, setPassengersModalOpen] = useState(false);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [selectedShip] = useRecoilState(SelectedShipState);
  const initialPassengerValues = {
    firstName: "",
    lastName: "",
    notes: "",
    email: "",
  };
  return (
    <Box>
      <Modal
        open={passengersModalOpen}
        onClose={() => {
          setPassengersModalOpen(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
          }}
        >
          <Paper
            sx={{
              p: 2,
              borderTopColor: "#b47f49",
              borderTopWidth: "4px",
              borderStyle: "solid",
              borderRightWidth: "0",
              borderLeftWidth: "0",
              borderBottomWidth: "0",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">New Passenger</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Formik
                  enableReinitialize
                  initialValues={initialPassengerValues}
                  onSubmit={(values) => {
                    console.log(values);
                    var passenger: TripPassenger = {
                      firstName: values.firstName,
                      lastName: values.lastName,
                      email: values.email,
                      notes: values.notes,
                    };
                    createTripPassenger(
                      selectedClient.id,
                      selectedShip.id,
                      trip.id,
                      passenger
                    ).then((createRes) => {
                      getTrip(selectedClient.id, selectedShip.id, trip.id).then(
                        (res) => {
                          setTrip(res);
                          setPassengersModalOpen(false);
                        }
                      );
                    });
                  }}
                >
                  {({
                    errors,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    touched,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Stack direction="column">
                        <TextField
                          id="firstName"
                          name="firstName"
                          label="First Name"
                          required
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.firstName && Boolean(errors.firstName)}
                          helperText={touched.firstName && errors.firstName}
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          id="lastName"
                          name="lastName"
                          label="Last Name"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.lastName && Boolean(errors.lastName)}
                          helperText={touched.lastName && errors.lastName}
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          id="email"
                          name="email"
                          label="Email Address"
                          required
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          id="notes"
                          name="notes"
                          label="Notes"
                          value={values.notes}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.notes && Boolean(errors.notes)}
                          helperText={touched.notes && errors.notes}
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                        <Button
                          color="primary"
                          variant="contained"
                          fullWidth
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Stack>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Modal>
      <AccordianPaperSection
        title="Passengers"
        icon={<Group sx={{ fontSize: 24 }} />}
        button={
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              setPassengersModalOpen(true);
              setPassengersModalOpen(true);
            }}
          >
            Add
          </Button>
        }
        elevation={12}
        collapsed={false}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button variant="contained" onClick={() => navigate(`/ships/${trip?.shipId}/trips/${trip?.id}/summary`)} fullWidth endIcon={<ArrowRightAlt/>}>Summary</Button>
          </Grid>
          <Grid item xs={12}>
            {trip.passengers?.map((passenger) => (
              <PassengerCard
                key={passenger.id}
                passenger={passenger}
                trip={trip}
                setTrip={setTrip}
              />
            ))}
          </Grid>
        </Grid>

      </AccordianPaperSection>
    </Box>
  );
}
