import {
  Add,
  Assignment,
  CheckBox,
  Delete,
  DirectionsBoat,
  Done,
  Edit,
  Extension,
  HistoryEdu,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Ship } from "../../../types/Ship";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../../Recoil/atoms/ClientsAtom";
import { getShip, getTasks } from "../../../services/shipService";
import {
  createTodoListTemplateItem,
  deleteTodoListTemplateItem,
  getShipTodoListTemplate,
  updateTodoListTemplateItem,
} from "../../../services/todoListTemplateService";
import {
  ShipTodoListTemplate,
  ShipTodoListTemplateItem,
} from "../../../types/TodoList";
import { Task } from "../../../types/Task";
import { Formik } from "formik";
import React from "react";
import { SelectedShipState } from "../../../Recoil/atoms/ShipsAtom";
import moment from "moment";

export default function TodoListTemplate() {
  const params = useParams();
  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [template, setTemplate] = useState<ShipTodoListTemplate>();
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [editTodoModalOpen, setEditTodoModalOpen] = useState(false);
  const [editTodoListItem, setEditTodoListItem] =
    useState<ShipTodoListTemplateItem>();

  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
      getShipTodoListTemplate(
        params.todolisttemplateid,
        selectedClient.id
      ).then((res) => setTemplate(res));
      getTasks(selectedClient.id, params.id).then((taskres) => {
        setTasks(taskres);
      });
    });
  }, [selectedClient, params.id, params.todolisttemplateid]);

  const initialState = {
    isTask: false,
    taskId: "",
    value: "",
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            sx={{ mb: 3 }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Create New Todo Template Item
          </Typography>
          <Formik
            initialValues={initialState}
            onSubmit={(values) => {
              if (values.isTask) {
                var todoListItem: ShipTodoListTemplateItem = {
                  value: values.value,
                  isTask: values.isTask,
                  taskId: values.taskId,
                  shipTodoListTemplateId: template?.id,
                };
                createTodoListTemplateItem(
                  template?.id,
                  selectedClient.id,
                  todoListItem
                ).then(() => {
                  getShipTodoListTemplate(
                    params.todolisttemplateid,
                    selectedClient.id
                  ).then((res) => {
                    setTemplate(res);
                    setOpen(false);
                  });
                });
              } else {
                var todoListItemNoTask: ShipTodoListTemplateItem = {
                  value: values.value,
                  isTask: values.isTask,
                };
                createTodoListTemplateItem(
                  template?.id,
                  selectedClient.id,
                  todoListItemNoTask
                ).then(() => {
                  getShipTodoListTemplate(
                    params.todolisttemplateid,
                    selectedClient.id
                  ).then((res) => {
                    setTemplate(res);
                    setOpen(false);
                  });
                });
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                {!values.isTask ? (
                  <TextField
                    id="value"
                    name="value"
                    label="Name"
                    multiline
                    value={values.value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                ) : (
                  <Box />
                )}

                <FormControlLabel
                  control={<Checkbox checked={values.isTask} />}
                  label="Is this an existing task?"
                  name="isTask"
                  onChange={handleChange}
                  sx={{ mb: 2 }}
                />
                {values.isTask ? (
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="demo-simple-select-label">Task</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.taskId}
                      onChange={(event) => {
                        setFieldValue("taskId", event.target.value);
                      }}
                    >
                      {tasks.map((t) => (
                        <MenuItem id={t.id} key={t.id} value={t.id}>
                          {t.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <React.Fragment />
                )}
                {values.value === "" && !values.isTask ? (
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled
                    type="submit"
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                )}
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
      <Modal
        open={editTodoModalOpen}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            sx={{ mb: 3 }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Edit Todo Template Item
          </Typography>
          {editTodoListItem !== undefined ? (
            <Formik
              initialValues={editTodoListItem}
              onSubmit={(values) => {
                if (values.isTask) {
                  var todoListItem: ShipTodoListTemplateItem = {
                    id: values.id,
                    value: values.value,
                    isTask: values.isTask,
                    taskId: values.taskId,
                    shipTodoListTemplateId: template?.id,
                  };
                  updateTodoListTemplateItem(
                    template?.id,
                    selectedClient.id,
                    todoListItem
                  ).then(() => {
                    getShipTodoListTemplate(
                      params.todolisttemplateid,
                      selectedClient.id
                    ).then((res) => {
                      setTemplate(res);
                      setEditTodoListItem(undefined);
                      setEditTodoModalOpen(false);
                    });
                  });
                } else {
                  var todoListItemNoTask: ShipTodoListTemplateItem = {
                    id: values.id,
                    value: values.value,
                    isTask: values.isTask,
                  };
                  updateTodoListTemplateItem(
                    template?.id,
                    selectedClient.id,
                    todoListItemNoTask
                  ).then(() => {
                    getShipTodoListTemplate(
                      params.todolisttemplateid,
                      selectedClient.id
                    ).then((res) => {
                      setTemplate(res);
                      setEditTodoListItem(undefined);
                      setEditTodoModalOpen(false);
                    });
                  });
                }
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  {!values.isTask ? (
                    <TextField
                      id="value"
                      name="value"
                      label="Name"
                      multiline
                      value={values.value}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                  ) : (
                    <Box />
                  )}

                  <FormControlLabel
                    control={<Checkbox checked={values.isTask} />}
                    label="Is this an existing task?"
                    name="isTask"
                    onChange={handleChange}
                    sx={{ mb: 2 }}
                  />
                  {values.isTask ? (
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel id="demo-simple-select-label">
                        Task
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.taskId}
                        onChange={(event) => {
                          setFieldValue("taskId", event.target.value);
                        }}
                      >
                        {tasks.map((t) => (
                          <MenuItem id={t.id} key={t.id} value={t.id}>
                            {t.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <React.Fragment />
                  )}
                  {values.value === "" && !values.isTask ? (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      disabled
                      type="submit"
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Submit
                    </Button>
                  )}
                </form>
              )}
            </Formik>
          ) : (
            <Box />
          )}
        </Box>
      </Modal>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}/todolists`)}
          startIcon={<CheckBox sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Todo Lists
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}/todolists/templates`)}
          startIcon={<HistoryEdu sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Todo Templates
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Extension sx={{ mr: 0.5 }} fontSize="inherit" />
          {template?.name}
        </Typography>
      </Breadcrumbs>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="h4" gutterBottom>
            {template?.name} Template
          </Typography>
          <Chip
            label={
              moment.utc(template?.dateLastAssigned).year() === 1806
                ? "Never Assigned"
                : `Last Assigned: ${moment
                    .utc(template?.dateLastAssigned)
                    .local()
                    .format("MM/DD/YYYY")}`
            }
            icon={<Assignment />}
          />
        </Stack>

        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              navigate(`/ships/${ship?.id}/todolists/templates`);
            }}
            startIcon={<Done />}
          >
            Done
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
            startIcon={<Add />}
          >
            New Item
          </Button>
        </Stack>
      </Stack>

      <List key={template?.id} dense>
        {template?.items?.map((item) => {
          return (
            <ListItem
              key={`li-${item.id}`}
              divider
              secondaryAction={
                <Stack direction="row" spacing={1}>
                  <IconButton
                    onClick={() => {
                      setEditTodoListItem(item);
                      setEditTodoModalOpen(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      deleteTodoListTemplateItem(
                        template.id,
                        selectedClient.id,
                        item.id
                      ).then((res) => {
                        getShipTodoListTemplate(
                          params.todolisttemplateid,
                          selectedClient.id
                        ).then((res) => {
                          setTemplate(res);
                        });
                      });
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              }
            >
              <ListItemAvatar>
                <Assignment />
              </ListItemAvatar>
              {item.isTask ? (
                <ListItemText
                  primary={
                    <Stack direction="row" alignItems="center">
                      <Button
                        variant="text"
                        onClick={() => {
                          navigate(
                            `/Ships/${ship?.id}/tasks/time/${item.taskId}`
                          );
                        }}
                      >
                        {item.taskName}
                      </Button>
                    </Stack>
                  }
                />
              ) : (
                <ListItemText primary={item.value} />
              )}
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
}
