import { Add, DirectionsBoat, LocalPolice } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import ActiveIncidents from "../../components/IncidentReports/ActiveIncidents";
import InReviewIncidents from "../../components/IncidentReports/InReviewIncidents";
import ClosedIncidents from "../../components/IncidentReports/ClosedIncidents";
import IsUserElevated from "../../utilities/userUtils";
import { userProfileState } from "../../Recoil/atoms/UserProfileAtom";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import DeferredIncidents from "../../components/IncidentReports/DeferredIncidents";

export default function IncidentHome() {
  const navigate = useNavigate();
  const [userProfile] = useRecoilState(userProfileState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [selectedShip] = useRecoilState(SelectedShipState);
  return (
    <Container>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <LocalPolice sx={{ mr: 0.5 }} fontSize="inherit" />
          Logbook
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Typography variant="h4">Logbook</Typography>
        </Grid>
        <Grid item xs={12} md={3} textAlign="end">
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => navigate(`/ships/${selectedShip.id}/logbook/new`)}
          >
            Report Fault
          </Button>
        </Grid>
        <Grid item xs={12}>
          <ActiveIncidents />
        </Grid>
        <Grid item xs={12}>
          <DeferredIncidents />
        </Grid>
        {IsUserElevated(userProfile, selectedClient.id) ? (
          <Grid item xs={12}>
            <InReviewIncidents />
          </Grid>
        ) : (
          <Box />
        )}

        <Grid item xs={12}>
          <ClosedIncidents />
        </Grid>
      </Grid>
    </Container>
  );
}
