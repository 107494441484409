import { useRecoilState } from "recoil";
import PaperSection from "../PaperSection";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { useEffect, useState } from "react";
import { IncidentReport } from "../../types/IncidentReport";
import { getInReviewIncidents } from "../../services/incidentService";
import IncidentCard from "./IncidentCard";
import moment from "moment";
import { Chip, Grid, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { IconSearch } from "@tabler/icons-react";

export default function InReviewIncidents() {
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [incidents, setIncidents] = useState<IncidentReport[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    getInReviewIncidents(selectedClient.id, selectedShip.id).then((res) => {
      setIncidents(res);
    });
  }, [selectedClient.id, selectedShip.id, setIncidents]);

  function daysSince(date: DateTime | undefined){
    const today = moment(moment.utc());
    const a =  today.diff(moment(date), 'days');
    var days = a + 1;
    if(days === 1){
        return "Open 1 Day";
    }
    else{
        return `Open ${days} Days`;
    }
  }

  return (
    <PaperSection title="In Review Faults" icon={<IconSearch/>}>
      {incidents.map((i) => (
        <IncidentCard title={i.title} isEmergency={i.isEmergency} onClick={() => navigate(`/ships/${selectedShip.id}/logbook/${i.id}`)}>
          <Grid container>
            <Grid item xs={12} md={8} textAlign="start">
              <Typography variant="body1">{`Reported by ${
                i.creator?.userName
              } on ${moment(i.createdDate)
                .local()
                .format("MM/DD/YYYY")}`}</Typography>
            </Grid>
            <Grid item xs={12} md={2} textAlign="end">
              <Chip label={i.location} />
            </Grid>
            <Grid item xs={12} md={2} textAlign="end">
              <Chip label={daysSince(i.createdDate)} />
            </Grid>
          </Grid>
        </IncidentCard>
      ))}
    </PaperSection>
  );
}
