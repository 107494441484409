import axios from "axios";
import { ShipTodoListTemplate, ShipTodoListTemplateItem } from "../types/TodoList";

export const getTodoListTemplates = (shipId?: string, clientId?: string) => {
    return axios
      .get(
        `https://api.zsail.io/shiptodotemplate?clientId=${clientId}&shipId=${shipId}`
      )
      .then((response) => {
        return response.data;
      });
  };

  export const createTodoListTemplate = (
    shipId?: string,
    clientId?: string,
    shipTodoListTemplate?: ShipTodoListTemplate
  ) => {
    return axios
      .post(
        `https://api.zsail.io/shiptodotemplate?clientId=${clientId}&shipId=${shipId}`,
        shipTodoListTemplate
      )
      .then((response) => {
        return response.data;
      });
  };

  export const getShipTodoListTemplate = (todoListTemplateId?: string, clientId?: string) => {
    return axios
      .get(
        `https://api.zsail.io/shiptodotemplate/${todoListTemplateId}/items?clientId=${clientId}`
      )
      .then((response) => {
        return response.data;
      });
  };

  export const createTodoListTemplateItem = (todoListTemplateId?: string, clientId?: string, todoListTemplateItem?: ShipTodoListTemplateItem) => {
    return axios
      .post(
        `https://api.zsail.io/shiptodotemplate/${todoListTemplateId}/items?clientId=${clientId}`, todoListTemplateItem
      )
      .then((response) => {
        return response.data;
      });
  };

  export const updateTodoListTemplateItem = (todoListTemplateId?: string, clientId?: string, todoListTemplateItem?: ShipTodoListTemplateItem) => {
    return axios
      .post(
        `https://api.zsail.io/shiptodotemplate/${todoListTemplateId}/items/${todoListTemplateItem?.id}?clientId=${clientId}`, todoListTemplateItem
      )
      .then((response) => {
        return response.data;
      });
  };

  export const deleteTodoListTemplateItem = (todoListTemplateId?: string, clientId?: string, todoListTemplateItemId?: string) => {
    return axios
      .delete(
        `https://api.zsail.io/shiptodotemplate/${todoListTemplateId}/items/${todoListTemplateItemId}?clientId=${clientId}`
      )
      .then((response) => {
        return response.data;
      });
  };