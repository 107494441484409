import {
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import { getShipParts } from "../../services/shipService";
import { Part } from "../../types/Part";
import { useRecoilState, useSetRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { ShipsState } from "../../Recoil/atoms/ShipsAtom";
import { SideBarSelectedState } from "../../Recoil/atoms/SideBarSelectedAtom";
import { useNavigate } from "react-router-dom";
import { Category, Error } from "@mui/icons-material";

export type InventoryStatusType = {
  selectedShipId?: string;
};

export default function InventoryStatus(props: InventoryStatusType) {
  const [parts, setParts] = useState<Part[]>([]);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [ships] = useRecoilState(ShipsState);
  const setSelected = useSetRecoilState(SideBarSelectedState);
  const [localLoading, setLocalLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.selectedShipId !== undefined && selectedClient.id !== undefined) {
      setLocalLoading(true);
      getShipParts(props.selectedShipId, selectedClient.id).then(
        (partsResponse) => {
          setParts(partsResponse);
          setLocalLoading(false);
        }
      );
    }
  }, [selectedClient.id, props.selectedShipId]);

  function compareParts(partA: Part, partB: Part) {
    return (
      partA.quantity -
      partA.minimumInventory -
      (partB.quantity - partB.minimumInventory)
    );
  }
  return (
    <Card elevation={2}>
      <CardContent sx={{ background: "rgba(255, 255, 255, 0.12)" }}>
        <Typography variant="h5">Inventory Status</Typography>
        <Scrollbar style={{ height: 200 }}>
        {localLoading ? (
            <Box sx={{ width: 450 }}>
              <Skeleton height={50} animation="wave" />
              <Skeleton height={50} animation="wave" />
              <Skeleton height={50} animation="wave" />
              <Skeleton height={50} animation="wave" />
              <Skeleton height={50} animation="wave" />
            </Box>
          ) :
          <List sx={{ height: 200 }} disablePadding dense>
            {parts.sort(compareParts).map((part) => {
              return (
                <ListItem key={part.id} divider>
                  <ListItemButton
                    onClick={() => {
                      var name = ships.filter(
                        (sh) => sh.id === props.selectedShipId
                      )[0].name;
                      setSelected(name);
                      navigate(
                        `/Ships/${props.selectedShipId}/parts/${part.id}`
                      );
                    }}
                  >
                    <ListItemAvatar>
                      {part.quantity <= part.minimumInventory ? (
                        <Error sx={{ color: "red" }} />
                      ) : (
                        <Category />
                      )}
                    </ListItemAvatar>

                    {part.quantity <= part.minimumInventory ? (
                      <ListItemText
                        primary={part.name}
                        secondary={`${part.quantity} in inventory (Minimum Inventory: ${part.minimumInventory})`}
                        secondaryTypographyProps={{color: "red"}}
                      ></ListItemText>
                    ) : (
                      <ListItemText
                        primary={part.name}
                        secondary={`${part.quantity} in inventory`}
                      ></ListItemText>
                    )}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>}
        </Scrollbar>
      </CardContent>
    </Card>
  );
}
