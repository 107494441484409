import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import ShipLoader from "../../components/ShipLoader/ShipLoader";

export default function LoadingScreen() {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#b47f49",
      },
      secondary: {
        main: "#7fdcfd",
      },
      background: {
        default: "#011828",
        paper: "#011828",
      },
    },
  });
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'primary',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <ShipLoader />
      </Box>
    </ThemeProvider>
  );
}
