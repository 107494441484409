import { ArrowRightAlt, CalendarMonth } from "@mui/icons-material";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import moment from "moment";
import { ShipTodoList } from "../../../types/TodoList";

export type TodoListReviewDateOverviewProps = {
  todoList: ShipTodoList | undefined;
};
export default function TodoListReviewDateOverview(
  props: TodoListReviewDateOverviewProps
) {
  const { todoList } = props;
  return (
    <Paper
      sx={{
        p: 2,
        backgroundColor:
          todoList?.dueDate !== undefined &&
          todoList.completedDate !== undefined &&
          moment(todoList?.dueDate).add(1, 'day').valueOf() < moment(todoList?.completedDate).valueOf()
            ? "darkgoldenrod"
            : "#011828",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <Stack spacing={1} direction="row">
            <CalendarMonth sx={{ fontSize: 64 }} />
            <Stack direction="column" spacing={1}>
              <Typography variant="h5">Due Date</Typography>
              <Typography variant="h5">
                {moment(todoList?.dueDate).format("MM/DD/YYYY")}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <ArrowRightAlt sx={{ fontSize: 64 }} />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Stack spacing={1} direction="row">
            <CalendarMonth sx={{ fontSize: 64 }} />
            <Stack direction="column" spacing={1}>
              <Typography variant="h5">Completed Date</Typography>
              <Typography variant="h5">
                {moment(todoList?.completedDate).format("MM/DD/YYYY")}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}
