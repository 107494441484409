import AccordianPaperSection from "../../AccordianPaperSection";
import { Scrollbar } from "react-scrollbars-custom";
import { Paper, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

export type FoodPrefProps = {
    title: string;
    icon: ReactNode;
  list: string[];
};

export default function FoodPrefCard(
  props: FoodPrefProps
) {
  const { list, title, icon } = props;

  return (
    <AccordianPaperSection
      title={title}
      icon={icon}
    >
        <Scrollbar style={{ height: 300 }}>
        {list.map((mh) => (
          <Paper
            sx={{
              p: 1,
              borderLeftColor: "white",
              borderTopWidth: "0px",
              borderStyle: "solid",
              borderRightWidth: "0px",
              borderLeftWidth: "4px",
              borderBottomWidth: "0",
              mr: 1,
              mb: 2
            }}
            elevation={24}
          >
            <Stack direction="row" spacing={2}>
              {icon}
              <Typography variant="body1">{mh}</Typography>
            </Stack>
          </Paper>
        ))}
        </Scrollbar>
    </AccordianPaperSection>
  );
}
