import {
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { getTasks } from "../../services/shipService";
import { Task } from "../../types/Task";

export type OverdueTasksPropsType = {
  selectedShipId?: string;
};

export default function OverdueTaskCounter(props: OverdueTasksPropsType) {
  const navigate = useNavigate();
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [overdueTasksCount, setOverdueTasksCount] = useState(0);

  useEffect(() => {
    if (props.selectedShipId !== undefined && selectedClient.id !== undefined) {
      getTasks(selectedClient.id, props.selectedShipId).then(
        (tasks: Task[]) => {
          setOverdueTasksCount(
            tasks.filter(
              (task) =>
                task.usageSinceLastUpdate !== undefined &&
                task.unitFrequencyInterval !== undefined &&
                task.lastPerformedUsage !== undefined &&
                task.daysUntilNextUpdate !== undefined &&
                (task.usageSinceLastUpdate >= task.unitFrequencyInterval ||
                  task.lastPerformedUsage === 0) && 
                  task.daysUntilNextUpdate <= 0
            ).length
          );
        }
      );
    }
  }, [selectedClient.id, props.selectedShipId]);

  return (
    <Paper
    sx={{
      p: 2,
      height: "100%",
      width: "100%",
      borderTopColor: "#b47f49",
      borderTopWidth: "4px",
      borderStyle: "solid",
      borderRightWidth: "0",
      borderLeftWidth: "0",
      borderBottomWidth: "0",
    }}
    elevation={24}
    component={Button}
    onClick={() => navigate(`/ships/${props.selectedShipId}`)}
  >
    <Grid container>
      <Grid item xs={12}>
        <Stack direction="column" alignItems="center">
        <Typography variant="h6">Overdue Tasks</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column" alignItems="center">
        <Typography variant="h1">{overdueTasksCount}</Typography>
        </Stack>
      </Grid>
    </Grid>
  </Paper>
  );
}
