import axios from "axios";
import { PreferredShipDTO, UserProfile } from "../types/UserProfile";
import { User } from "../types/User";
import { UserUpdateRole } from "../types/UserUpdateRole";
import { DeleteUser } from "../types/DeleteUser";

export const getUserProfile = () => {
  return axios
    .get<UserProfile>("https://api.zsail.io/Users/Profile")
    .then((res) => {
      return res.data;
    });
};

export const getClientUsers = (clientId?: string) => {
  return axios
    .get<User[]>(`https://api.zsail.io/Users/client/${clientId}`)
    .then((res) => {
      return res.data;
    });
};
export const updateUserRole = (updateUserDTO: UserUpdateRole) => {
  return axios
    .patch(`https://api.zsail.io/Users`, updateUserDTO)
    .then((res) => {
      return res.data;
    });
};

export const deleteUserFromClient = (deleteUserDTO: DeleteUser) => {
  return axios
    .post(`https://api.zsail.io/Users/Delete`, deleteUserDTO)
    .then((res) => {
      return res.data;
    });
};

export const updateUserName = (
  userId?: string,
  iamId?: string,
  userName?: string
) => {
  return axios
    .get(
      `https://api.zsail.io/Users/Profile/Preferences/UserName?userId=${userId}&iamId=${iamId}&userName=${userName}`
    )
    .then((res) => {
      return res.data;
    });
};

export const addPreferredShip = (
  preferredShipDTO: PreferredShipDTO,
  clientId?: string
) => {
  return axios
    .post(
      `https://api.zsail.io/Users/Profile/Preferences/PreferredShip?clientId=${clientId}`,
      preferredShipDTO
    )
    .then((res) => {
      return res.data;
    });
};

export const setPreferredClient = (clientId?: string) => {
  return axios
    .post(
      `https://api.zsail.io/Users/Profile/Preferences/PreferredClient?clientId=${clientId}`
    )
    .then((res) => {
      return res.data;
    });
};
