import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Input,
  LinearProgress,
  Modal,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { useState } from "react";
import PaperSection from "../PaperSection";
import { Add, Close, Delete, Fullscreen, Upload } from "@mui/icons-material";
import {
  deleteIncidentImage,
  getIncidentById,
  uploadIncidentImage,
} from "../../services/incidentService";
import {
  IncidentReport,
  IncidentReportImage,
} from "../../types/IncidentReport";
import { IconPhoto } from "@tabler/icons-react";
import Scrollbar from "react-scrollbars-custom";
import IsUserElevated from "../../utilities/userUtils";
import { userProfileState } from "../../Recoil/atoms/UserProfileAtom";

export type IncidentImagesCardProps = {
  setIncident: Function;
  incident?: IncidentReport;
};

export default function IncidentImagesCard(props: IncidentImagesCardProps) {
  const { setIncident, incident } = props;
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [helperName, setHelperName] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [file, setFile] = useState<any>();
  const [fullScreenOpen, setFullScreenOpen] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState<IncidentReportImage>();
  const [userProfile] = useRecoilState(userProfileState);

  const onUploadProgress = (progressevent: ProgressEvent) => {
    const percentCompleted = Math.round(
      (progressevent.loaded * 100) / progressevent.total
    );
    setUploadProgress(percentCompleted);
    if (percentCompleted === 100) {
      getIncidentById(selectedClient.id, selectedShip.id, incident?.id).then(
        (res) => {
          setIncident(res);
          setHelperName("");
          setUploadProgress(0);
          setUploading(false);
          setUploadOpen(false);
          setFile(null);
        }
      );
    }
  };
  function handleUploadSubmit(event: any) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.Name);
    setUploading(true);
    uploadIncidentImage(
      onUploadProgress,
      selectedClient.id,
      selectedShip.id,
      incident?.id,
      formData,
      helperName
    ).then((res) => {
      getIncidentById(selectedClient.id, selectedShip.id, incident?.id).then(
        (res) => {
          setIncident(res);
          setHelperName("");
          setUploadProgress(0);
          setUploading(false);
          setUploadOpen(false);
          setFile(null);
        }
      );
    });
  }
  function handleFileChange(event: any) {
    setFile(event.target.files[0]);
  }
  return (
    <Box>
      <Modal
        open={uploadOpen}
        onClose={() => {
          setUploadOpen(false);
          setUploading(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <PaperSection title="Upload Image:" icon={<Upload />}>
            {!uploading ? (
              <Box>
                <form onSubmit={handleUploadSubmit}>
                  <TextField
                    label="Name"
                    value={helperName}
                    onChange={(event) => setHelperName(event.target.value)}
                    fullWidth
                  />
                  <Input
                    sx={{ mb: 2 }}
                    type="file"
                    inputProps={{ accept: "image/png, image/jpeg" }}
                    onChange={handleFileChange}
                  />
                  <Button type="submit" variant="contained" fullWidth>
                    Upload
                  </Button>
                </form>
              </Box>
            ) : (
              <Box>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Uploading...{" "}
                </Typography>
                <LinearProgress variant="determinate" value={uploadProgress} />
              </Box>
            )}
          </PaperSection>
        </Box>
      </Modal>
      <Dialog
        fullScreen
        open={fullScreenOpen}
        onClose={() => {
          setFullScreenOpen(false);
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setFullScreenOpen(false)}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {fullScreenImage?.helperName}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box textAlign="center" sx={{ width: "100%" }}>
          <img
            src={fullScreenImage?.imageUrl}
            alt={fullScreenImage?.helperName}
            style={{
              maxWidth: "100%",
              height: "auto",
              padding: 0,
              margin: 0,
            }}
          />
        </Box>
      </Dialog>
      <PaperSection
        title="Images"
        icon={<IconPhoto />}
        button={
          incident?.status === 0 || incident?.status === 1 || incident?.status === 3? (
            <Button
              variant="contained"
              onClick={() => setUploadOpen(true)}
              startIcon={<Add />}
            >
              Add
            </Button>
          ) : (
            <Box />
          )
        }
      >
        {incident?.images?.filter((i) => i.loaded).length !==
        incident?.images?.length ? (
          <Grid container>
            <Grid item xs={12} textAlign="center">
              <CircularProgress />
            </Grid>
          </Grid>
        ) : incident?.images !== undefined ? (
          <Scrollbar style={{ height: incident.images.length === 0 ? 0 : 300 }}>
            <Typography variant="body1">{`${incident.images.length} image(s)`}</Typography>

            <ImageList rowHeight={250} variant="quilted">
              {incident.images.map((i) => (
                <ImageListItem key={i.id}>
                  <img
                    src={i.imageUrl}
                    alt={i.helperName}
                    height={50}
                    width={50}
                  />
                  <ImageListItemBar
                    title={i.helperName}
                    actionIcon={
                      <Stack direction="row" spacing={1}>
                        <IconButton
                          sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                          aria-label={`info about ${i.helperName}`}
                          onClick={() => {
                            setFullScreenImage(i);
                            setFullScreenOpen(true);
                          }}
                        >
                          <Fullscreen />
                        </IconButton>
                        {IsUserElevated(userProfile, selectedClient.id) &&
                        (incident.status === 0 || incident.status === 1 || incident.status === 3)? (
                          <IconButton
                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                            aria-label={`info about ${i.helperName}`}
                            onClick={() =>
                              deleteIncidentImage(
                                incident.id,
                                i.id,
                                selectedClient.id
                              ).then((res) =>
                                getIncidentById(
                                  selectedClient.id,
                                  selectedShip.id,
                                  incident.id
                                ).then((res) => {
                                  setIncident(res);
                                })
                              )
                            }
                          >
                            <Delete />
                          </IconButton>
                        ) : (
                          <Box />
                        )}
                      </Stack>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Scrollbar>
        ) : (
          <Box />
        )}
      </PaperSection>
    </Box>
  );
}
