import {
  Breadcrumbs,
  Button,
  Container,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getEquipmentById,
  getShip,
  updateEquipment,
} from "../../services/shipService";
import { Ship } from "../../types/Ship";
import * as yup from "yup";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { Formik } from "formik";
import { DateTime } from "luxon";
import { Equipment } from "../../types/Equipment";
import { DatePicker } from "@mui/x-date-pickers";
import { Construction, DirectionsBoat, Edit } from "@mui/icons-material";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";

export default function EditEquipmentItem() {
  const params = useParams();
  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [equipment, setEquipment] = useState<Equipment>();
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [selectedShip] = useRecoilState(SelectedShipState);

  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
      getEquipmentById(selectedClient.id, params.id, params.equipmentid).then(
        (equipData) => {
          setEquipment(equipData);
        }
      );
    });
  }, [selectedClient, params.id, params.equipmentid]);

  const validationSchema = yup.object({
    name: yup.string().required("name is required."),
    manufacturer: yup.string().required("manufacturer is required."),
    modelNumber: yup.string().required("model number is required."),
    manufacturedDate: yup.date().required("manufactured date is required"),
    serialNumber: yup.string().required("serial number is required"),
    locationInstalled: yup.string().required("location installed is required"),
    notes: yup.string(),
    usageUnits: yup.string().required("usage units are required"),
    updateFrequency: yup.number().required("update frequency is required"),
    dailyUsageInPort: yup.number(),
    dailyUsageInTransit: yup.number(),
    dailyUsageChartered: yup.number(),
    dailyUsageDryDock: yup.number(),
    warranty: yup.string(),
  });

  const initialState = {
    name: equipment !== undefined ? equipment.name : "",
    manufacturer: equipment !== undefined ? equipment.manufacturer : "",
    modelNumber: equipment !== undefined ? equipment.modelNumber : "",
    manufacturedDate:
      equipment !== undefined
        ? DateTime.fromISO(equipment.manufacturedDate.toString())
        : DateTime.now(),
    serialNumber: equipment !== undefined ? equipment.serialNumber : "",
    locationInstalled:
      equipment !== undefined ? equipment.locationInstalled : "",
    notes: equipment !== undefined ? equipment.notes : "",
    usageUnits: equipment !== undefined ? equipment.usageUnits : "",
    updateFrequency: equipment !== undefined ? equipment.updateFrequency : 0,
    dailyUsageInPort: equipment !== undefined ? equipment.dailyUsageInPort : 0,
    dailyUsageInTransit:
      equipment !== undefined ? equipment.dailyUsageInTransit : 0,
    dailyUsageChartered:
      equipment !== undefined ? equipment.dailyUsageChartered : 0,
    dailyUsageDryDock:
      equipment !== undefined ? equipment.dailyUsageDryDock : 0,
    warranty: equipment !== undefined ? equipment.warranty : "",
    warrantyExpiry:
      equipment !== undefined &&
      equipment.warrantyExpiry !== undefined &&
      equipment.warrantyExpiry !== null
        ? DateTime.fromISO(equipment.warrantyExpiry.toString())
        : null,
  };
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}/equipment`)}
          startIcon={<Construction sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Equipment
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() =>
            navigate(`/ships/${ship?.id}/equipment/${equipment?.id}`)
          }
          startIcon={<Construction sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {equipment?.name}
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Edit sx={{ mr: 0.5 }} fontSize="inherit" />
          Edit
        </Typography>
      </Breadcrumbs>
      {equipment !== undefined ? (
        <Formik
          initialValues={initialState}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values) => {
            var equipmentdto: Equipment = {
              id: equipment.id,
              name: values.name,
              manufacturer: values.manufacturer,
              modelNumber: values.modelNumber,
              manufacturedDate: values.manufacturedDate,
              serialNumber: values.serialNumber,
              locationInstalled: values.locationInstalled,
              notes: values.notes,
              usageUnits: values.usageUnits,
              updateFrequency: values.updateFrequency,
              createdDate: equipment?.createdDate,
              dailyUsageChartered: values.dailyUsageChartered,
              dailyUsageDryDock: values.dailyUsageDryDock,
              dailyUsageInPort: values.dailyUsageInPort,
              dailyUsageInTransit: values.dailyUsageInTransit,
              warranty: values.warranty,
              warrantyExpiry: values.warrantyExpiry
            };
            updateEquipment(equipmentdto, ship?.id, selectedClient.id).then(
              (response) => {
                navigate(`/ships/${ship?.id}/Equipment/${equipment.id}`);
              }
            );
          }}
        >
          {({
            errors,
            handleSubmit,
            handleChange,
            handleBlur,
            touched,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                id="name"
                name="name"
                label="Name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                id="manufacturer"
                name="manufacturer"
                label="Manufacturer"
                value={values.manufacturer}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.manufacturer && Boolean(errors.manufacturer)}
                helperText={touched.manufacturer && errors.manufacturer}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                id="modelNumber"
                name="modelNumber"
                label="Model Number"
                value={values.modelNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.modelNumber && Boolean(errors.modelNumber)}
                helperText={touched.modelNumber && errors.modelNumber}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                id="serialNumber"
                name="serialNumber"
                label="Serial Number"
                value={values.serialNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.serialNumber && Boolean(errors.serialNumber)}
                helperText={touched.serialNumber && errors.serialNumber}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                id="locationInstalled"
                name="locationInstalled"
                label="Location Installed"
                value={values.locationInstalled}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.locationInstalled && Boolean(errors.locationInstalled)
                }
                helperText={
                  touched.locationInstalled && errors.locationInstalled
                }
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                id="warranty"
                name="warranty"
                label="Warranty Information"
                value={values.warranty}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.warranty && Boolean(errors.warranty)}
                helperText={touched.warranty && errors.warranty}
                fullWidth
                sx={{ mb: 2 }}
              />
              <DatePicker
                label="Warranty Expiration Date"
                value={values.warrantyExpiry}
                onChange={(value: any) =>
                  setFieldValue("warrantyExpiry", DateTime.fromISO(value), true)
                }
                sx={{ mb: 2 }}
              />

              <TextField
                id="notes"
                name="notes"
                label="Notes"
                value={values.notes}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.notes && Boolean(errors.notes)}
                helperText={touched.notes && errors.notes}
                fullWidth
                sx={{ mb: 2 }}
              />
              <DatePicker
                disableFuture
                label="manufacturedDate"
                value={values.manufacturedDate}
                onChange={(value: any) =>
                  setFieldValue(
                    "manufacturedDate",
                    DateTime.fromISO(value),
                    true
                  )
                }
                sx={{ mb: 2 }}
              />
              <InputLabel id="usageUnits">Usage Units</InputLabel>
              <Select
                labelId="usageUnits"
                id="usageUnits"
                value={values.usageUnits}
                onChange={(e) => {
                  setFieldValue("usageUnits", e.target.value);
                }}
                fullWidth
                sx={{ mb: 2 }}
              >
                <MenuItem value={"Days"}>Days</MenuItem>
                <MenuItem value={"Hours"}>Hours</MenuItem>
                <MenuItem value={"Gallons"}>Gallons</MenuItem>
                <MenuItem value={"Quarts"}>Quarts</MenuItem>
                <MenuItem value={"Liters"}>Liters</MenuItem>
              </Select>
              <TextField
                id="updateFrequency"
                name="updateFrequency"
                label="Update Frequency (Days)"
                value={values.updateFrequency}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.updateFrequency && Boolean(errors.updateFrequency)
                }
                helperText={touched.updateFrequency && errors.updateFrequency}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                id="dailyUsageInPort"
                name="dailyUsageInPort"
                label={`Daily Usage In Port (In ${values.usageUnits})`}
                value={values.dailyUsageInPort}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.dailyUsageInPort && Boolean(errors.dailyUsageInPort)
                }
                helperText={touched.dailyUsageInPort && errors.dailyUsageInPort}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                id="dailyUsageInTransit"
                name="dailyUsageInTransit"
                label={`Daily Usage In Transit (In ${values.usageUnits})`}
                value={values.dailyUsageInTransit}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.dailyUsageInTransit &&
                  Boolean(errors.dailyUsageInTransit)
                }
                helperText={
                  touched.dailyUsageInTransit && errors.dailyUsageInTransit
                }
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                id="dailyUsageChartered"
                name="dailyUsageChartered"
                label={`Daily Usage While Chartered (In ${values.usageUnits})`}
                value={values.dailyUsageChartered}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.dailyUsageChartered &&
                  Boolean(errors.dailyUsageChartered)
                }
                helperText={
                  touched.dailyUsageChartered && errors.dailyUsageChartered
                }
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                id="dailyUsageDryDock"
                name="dailyUsageDryDock"
                label={`Daily Usage In Dry Dock (In ${values.usageUnits})`}
                value={values.dailyUsageDryDock}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.dailyUsageDryDock && Boolean(errors.dailyUsageDryDock)
                }
                helperText={
                  touched.dailyUsageDryDock && errors.dailyUsageDryDock
                }
                fullWidth
                sx={{ mb: 2 }}
              />
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </form>
          )}
        </Formik>
      ) : (
        <React.Fragment />
      )}
    </Container>
  );
}
