import { Theme } from "@mui/material";

export function getGaugeColor(theme: Theme, maxValue: number, value: number){
    let percent = (value/maxValue)*100;
    if(percent < 34){
      return theme.palette.secondary.main;
    }
    else if(percent < 75){
      return theme.palette.primary.main;
    }
    else{
      return theme.palette.error.main;
    }
  }