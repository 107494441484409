export function getIncidentStatus(status?: number): string{
    switch(status){
        case 0:
            return "Open";
        case 1:
            return "In Review";
        case 2:
            return "Closed";
        case 3:
            return "Deferred";
        case 7:
            return "In Review";
        default:
            return "Open";
    }
}