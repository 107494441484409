import { Box } from "@mui/material";

import {
  PassengerActivityInfo,
} from "../../types/PassengerTypes";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { IconPaw, IconSquare, IconSquareCheckFilled } from "@tabler/icons-react";
import AccordianPaperSection from "../AccordianPaperSection";
import { useEffect, useState } from "react";
import { getTripWildlifeInformation } from "../../services/tripservice";
import { useParams } from "react-router-dom";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";

export default function TripWildlifeSummaryCard() {
  const params = useParams();
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [wildlife, setWildlife] = useState<PassengerActivityInfo[]>([]);
  useEffect(() => {
    getTripWildlifeInformation(
      selectedClient.id,
      selectedShip.id,
      params.tripid
    ).then((res) => {
      setWildlife(res);
    });
  }, [selectedClient.id, selectedShip.id, params.tripid]);
  const columns = [
    {
      field: "firstName",
      headerName: `First Name`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.firstName;
      },
    },
    {
      field: "lastName",
      headerName: `Last Name`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.lastName;
      },
    },
    {
      field: "isWildlifeSighting",
      headerName: `Wake Up`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.isWildlifeSighting;
      },
      renderCell: (params: GridRenderCellParams<any, Date>) =>
        params.row.isWildlifeSighting === 1 ? (
          <IconSquareCheckFilled style={{ color: "green" }} />
        ) : (
          <IconSquare />
        ),
    },
    {
        field: "isWildlifeBird",
        headerName: `Birds`,
        width: 150,
        valueGetter: (params: any) => {
          return params.row.isWildlifeBird;
        },
        renderCell: (params: GridRenderCellParams<any, Date>) =>
          params.row.isWildlifeBird  ? (
            <IconSquareCheckFilled style={{ color: "green" }} />
          ) : (
            <IconSquare />
          ),
      },
      {
        field: "isWildlifeFish",
        headerName: `Fish`,
        width: 150,
        valueGetter: (params: any) => {
          return params.row.isWildlifeFish;
        },
        renderCell: (params: GridRenderCellParams<any, Date>) =>
          params.row.isWildlifeFish ? (
            <IconSquareCheckFilled style={{ color: "green" }} />
          ) : (
            <IconSquare />
          ),
      },
      {
        field: "isWildlifeSeaMammals",
        headerName: `Sea Mammals`,
        width: 150,
        valueGetter: (params: any) => {
          return params.row.isWildlifeSeaMammals;
        },
        renderCell: (params: GridRenderCellParams<any, Date>) =>
          params.row.isWildlifeSeaMammals ? (
            <IconSquareCheckFilled style={{ color: "green" }} />
          ) : (
            <IconSquare />
          ),
      },
      {
        field: "isWildlifeAmphibiansAndLizards",
        headerName: `Amphibians and Lizards`,
        width: 150,
        valueGetter: (params: any) => {
          return params.row.isWildlifeAmphibiansAndLizards;
        },
        renderCell: (params: GridRenderCellParams<any, Date>) =>
          params.row.isWildlifeAmphibiansAndLizards ? (
            <IconSquareCheckFilled style={{ color: "green" }} />
          ) : (
            <IconSquare />
          ),
      },
      {
        field: "wildlifeOther",
        headerName: `Other`,
        width: 150,
        valueGetter: (params: any) => {
          return params.row.wildlifeOther;
        },
      },
  ];
  return (
    <AccordianPaperSection
      icon={<IconPaw />}
      title="Wildlife Sightings"
    >
      {wildlife !== undefined ? (
        <DataGrid
          rows={wildlife}
          sx={{ minHeight: "300px", mb: 4 }}
          columns={columns}
        />
      ) : (
        <Box />
      )}
    </AccordianPaperSection>
  );
}
