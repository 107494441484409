import { Done, Person } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { ShipTodoListItem } from "../../../types/TodoList";
import { useNavigate } from "react-router-dom";
import TodoComment from "../TodoComment";
import PriorityIcon from "../PriorityIcon";

export type CompletedTodoItemReviewProps = {
  item: ShipTodoListItem;
  shipId: string | undefined;
};
export default function CompletedTodoItemReview(
  props: CompletedTodoItemReviewProps
) {
  const navigate = useNavigate();
  const { item, shipId } = props;
  return (
    <Paper elevation={4} sx={{ mb: 2, p: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}>
          <Done sx={{ fontSize: 32 }} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {item.isTask ? (
                <Button
                  variant="text"
                  sx={{ pt: 0, pl: 0 }}
                  onClick={() => {
                    navigate(`/Ships/${shipId}/tasks/time/${item.taskId}`);
                  }}
                >
                  {item.taskName}
                </Button>
              ) : (
                <Typography variant="h5">{item.value}</Typography>
              )}
            </Grid>
            {item.comments !== undefined && item.comments.length > 0 ? (
              <Grid item xs={12}>
                {item.comments?.map((comment) => (
                  <TodoComment elevation={8} comment={comment} />
                ))}
              </Grid>
            ) : (
              <Box />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box display="flex" justifyContent="flex-end">
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <PriorityIcon priority={item.priority} />
              <Chip
                icon={<Person />}
                sx={{ mr: 2 }}
                label={item.assigneeUserName}
              />
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
