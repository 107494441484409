import { Add, DirectionsBoat } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { SelectedShipState, ShipsState } from "../../Recoil/atoms/ShipsAtom";
import { useRecoilState, useSetRecoilState } from "recoil";
import moment from "moment";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { getShips } from "../../services/shipService";
import { useNavigate } from "react-router-dom";
import { SideBarSelectedState } from "../../Recoil/atoms/SideBarSelectedAtom";
import { userProfileState } from "../../Recoil/atoms/UserProfileAtom";
import React from "react";
import { getShipStatus } from "../../utilities/shipUtils";

export default function Ships() {
  const [ships, setShips] = useRecoilState(ShipsState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [userProfile] = useRecoilState(userProfileState);
  const navigate = useNavigate();
  const setSelected = useSetRecoilState(SideBarSelectedState);
  const setSelectedShip = useSetRecoilState(SelectedShipState);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      valueGetter: (params: any) => {
        return params.row.name;
      },
      width: 150,
    },
    {
      field: "make",
      headerName: "Make",
      valueGetter: (params: any) => {
        return params.row.make;
      },
      width: 150,
    },
    {
      field: "model",
      headerName: "Model",
      valueGetter: (params: any) => {
        return params.row.model;
      },
      width: 150,
    },
    {
      field: "year",
      headerName: "Year",
      valueGetter: (params: any) => {
        return params.row.year;
      },
      width: 150,
    },
    {
      field: "sizeInFeet",
      headerName: "Size In Feet",
      valueGetter: (params: any) => {
        return params.row.sizeInFeet;
      },
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: (params: any) => {
        return getShipStatus(params.row.status);
      },
      width: 150,
    },
    {
      field: "type",
      headerName: "Type",
      valueGetter: (params: any) => {
        return params.row.type;
      },
      width: 150,
    },
    {
      field: "purchaseDate",
      headerName: "Purchase Date",
      valueFormatter: (params: any) => {
        return moment(params.value).format("MM/DD/YYYY");
      },
      width: 150,
    },
  ];

  useEffect(() => {
    if (selectedClient !== undefined) {
      getShips(selectedClient.id).then((response) => {
        setShips(response);
      });
    }
  }, [selectedClient, setShips]);
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />
          Fleet Management
        </Typography>
      </Breadcrumbs>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Ships
        </Typography>
        {userProfile.isSystemAdministrator ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelected("Admin");
              navigate("/newShip");
            }}
            startIcon={<Add />}
          >
            New Ship
          </Button>
        ) : (
          <React.Fragment />
        )}
      </Stack>
      <DataGrid
        sx={{ height: "80%", minHeight: "300px" }}
        onRowClick={(row) => {
          setSelectedShip(ships.filter((s)=> s.id === row.id)[0])
          navigate(`/`);
        }}
        columns={columns}
        rows={ships}
      />
    </Container>
  );
}
