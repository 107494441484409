import {
    Box,
    Divider,
    Grid,
    Paper,
    Stack,
    TextField,
    Typography,
  } from "@mui/material";
  import { Formik } from "formik";
  import { DateTime } from "luxon";
  import { DatePicker } from "@mui/x-date-pickers";
import { PassengerProps } from "../../../types/PassengerTypes";
  
  export default function ContactInfo(props: PassengerProps) {
    const {passenger}= props;
  
    return (
        passenger.passengerContactInformation !== undefined ? 
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 4,
                borderTopColor: "#b47f49",
                borderTopWidth: "4px",
                borderStyle: "solid",
                borderRightWidth: "0px",
                borderLeftWidth: "0px",
                borderBottomWidth: "0",
              }}
              elevation={12}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h4">Contact Information</Typography>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Formik
                    initialValues={passenger.passengerContactInformation}
                    onSubmit={() =>{}}
                  >
                    {({
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      touched,
                      values,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              id="firstName"
                              name="firstName"
                              label={`First Name`}
                              value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}
                              error={
                                touched.firstName && Boolean(errors.firstName)
                              }
                              helperText={touched.firstName && errors.firstName}
                              fullWidth
                              
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              id="lastName"
                              name="lastName"
                              required
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}

                              label={`Last Name`}
                              value={values.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.lastName && Boolean(errors.lastName)}
                              helperText={touched.lastName && errors.lastName}
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                           
                              <TextField
                                id="proNouns"
                                name="proNouns"
                                label={`Pronouns`}
                                value={values.proNouns}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{shrink: true,}}

                                error={
                                  touched.proNouns &&
                                  Boolean(errors.proNouns)
                                }
                                helperText={
                                  touched.proNouns && errors.proNouns
                                }
                                fullWidth
                                sx={{ mb: 2 }}
                              />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Stack direction="row" spacing={2}>
                              <TextField
                                id="dialCode"
                                name="dialCode"
                                label={`Dial Code`}
                                value={values.dialCode}
                                required
                                InputProps={{
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{shrink: true,}}

                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched.dialCode &&
                                  Boolean(errors.dialCode)
                                }
                                helperText={
                                  touched.dialCode && errors.dialCode
                                }
                                sx={{ mb: 2 }}
                              />
                              <TextField
                                id="phoneNumber"
                                name="phoneNumber"
                                label={`Phone Number`}
                                value={values.phoneNumber}
                                required
                                InputProps={{
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{shrink: true,}}

                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched.phoneNumber &&
                                  Boolean(errors.phoneNumber)
                                }
                                helperText={
                                  touched.phoneNumber && errors.phoneNumber
                                }
                                fullWidth
                                sx={{ mb: 2 }}
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              id="email"
                              name="email"
                              label={`Email Address`}
                              value={values.email}
                              required
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}

                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.email && Boolean(errors.email)}
                              helperText={touched.email && errors.email}
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Stack>
                              <DatePicker
                                disableFuture
                                readOnly
                                label="Date of Birth"
                                value={DateTime.fromISO(
                                    values.dateOfBirth !== undefined
                                      ? values.dateOfBirth.toString()
                                      : DateTime.now().toString()
                                  )}
                                onChange={(value: any) =>
                                  setFieldValue(
                                    "dateOfBirth",
                                    DateTime.fromISO(value),
                                    true
                                  )
                                }
                                sx={{ mb: 2 }}
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12}>
                            <Stack direction="column">
                              <Typography variant="h6">
                                Mailing Address
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="addressLine1"
                              name="addressLine1"
                              required
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}

                              label={`Address Line 1`}
                              value={values.addressLine1}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.addressLine1 &&
                                Boolean(errors.addressLine1)
                              }
                              helperText={
                                touched.addressLine1 && errors.addressLine1
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="addressLine2"
                              name="addressLine2"
                              label={`Address Line 2`}
                              value={values.addressLine2}
                              onChange={handleChange}
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}

                              onBlur={handleBlur}
                              error={
                                touched.addressLine2 &&
                                Boolean(errors.addressLine2)
                              }
                              helperText={
                                touched.addressLine2 && errors.addressLine2
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              id="city"
                              name="city"
                              required
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}

                              label={`City`}
                              value={values.city}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.city && Boolean(errors.city)}
                              helperText={touched.city && errors.city}
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              id="region"
                              name="region"
                              label={`State/Province/Region`}
                              required
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}

                              value={values.region}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.region && Boolean(errors.region)}
                              helperText={touched.region && errors.region}
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              id="postalCode"
                              name="postalCode"
                              label={`Postal Code`}
                              required
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}

                              value={values.postalCode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.postalCode && Boolean(errors.postalCode)
                              }
                              helperText={touched.postalCode && errors.postalCode}
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              id="country"
                              name="country"
                              label={`Country`}
                              required
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}

                              value={values.country}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.country && Boolean(errors.country)}
                              helperText={touched.country && errors.country}
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h6">
                              Emergency Contact
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              id="emergencyFirstName"
                              name="emergencyFirstName"
                              label={`First Name`}
                              required
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}

                              value={values.emergencyFirstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.emergencyFirstName &&
                                Boolean(errors.emergencyFirstName)
                              }
                              helperText={
                                touched.emergencyFirstName &&
                                errors.emergencyFirstName
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              id="emergencyLastName"
                              name="emergencyLastName"
                              label={`Last Name`}
                              required
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}

                              value={values.emergencyLastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.emergencyLastName &&
                                Boolean(errors.emergencyLastName)
                              }
                              helperText={
                                touched.emergencyLastName &&
                                errors.emergencyLastName
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Stack direction="row" spacing={2}>
                            <TextField
                                id="emergencyDialCode"
                                name="emergencyDialCode"
                                label={`Emergency Dial Code`}
                                required
                                InputProps={{
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{shrink: true,}}

                                value={values.emergencyDialCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched.emergencyDialCode &&
                                  Boolean(errors.emergencyDialCode)
                                }
                                helperText={
                                  touched.emergencyDialCode &&
                                  errors.emergencyDialCode
                                }
                                fullWidth
                                sx={{ mb: 2 }}
                              />
                              <TextField
                                id="emergencyPhone"
                                name="emergencyPhone"
                                label={`Phone Number`}
                                required
                                InputProps={{
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{shrink: true,}}

                                value={values.emergencyPhone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched.emergencyPhone &&
                                  Boolean(errors.emergencyPhone)
                                }
                                helperText={
                                  touched.emergencyPhone &&
                                  errors.emergencyPhone
                                }
                                fullWidth
                                sx={{ mb: 2 }}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid> : <Box/>
    );
  }
  