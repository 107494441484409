export function getShipStatus(shipStatus?: number){
    if(shipStatus !== undefined){
        switch(shipStatus){
            case 0:
                return "In Port";
            case 1:
                return "In Transit";
            case 2:
                return "Chartered";
            case 3:
                return "In Dry Dock";
        }
    }
    return "In Port";
}

export function getTripType(tripType?: number){
    if(tripType !== undefined){
        switch(tripType){
            case 0:
                return "Port Stay";
            case 1:
                return "Transit";
            case 2:
                return "Charter";
            case 3:
                return "Maintenance";
        }
    }
    return "Port Stay";
}

export function getTripTypeColor(tripType?: number){
    if(tripType !== undefined){
        switch(tripType){
            case 0:
                return "green";
            case 1:
                return "blue";
            case 2:
                return "#b47f49";
            case 3:
                return "yellow";
        }
    }
    return "Port Stay";
}
export function getTripTypeTextColor(tripType?: number){
    if(tripType !== undefined){
        switch(tripType){
            case 0:
                return "white";
            case 1:
                return "white";
            case 2:
                return "white";
            case 3:
                return "black";
        }
    }
    return "Port Stay";
}

export function getShipUserRoleValue(role?: number){
    if(role !==undefined){
        switch(role){
            case 0:
                return "General";
            case 1:
                return "Interior";
            case 2:
                return "Exterior";
            case 3:
                return "Chef";
            case 4:
                return "Captain";
        }
    }
    return "General";
}

export function currencyFormat(num?: number) {
    return "$" + num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }