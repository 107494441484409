import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  Container,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { useNavigate } from "react-router-dom";
import { Add, DirectionsBoat, LocalPolice } from "@mui/icons-material";
import { Formik } from "formik";
import { DateTime } from "luxon";
import { Editor } from "@tinymce/tinymce-react";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { getEquipment } from "../../services/shipService";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { Equipment } from "../../types/Equipment";
import { IncidentReport } from "../../types/IncidentReport";
import { createIncident } from "../../services/incidentService";

export default function NewIncident() {
  const navigate = useNavigate();
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [equipment, setEquipment] = useState<Equipment[]>([]);
  var initialState = {
    title: "",
    description: "",
    location: "",
    resolution: "",
    environmentalData: "",
    deferredDate: DateTime.now(),
    shipStatus: 0,
    manHours: 0,
    isEmergency: false,
    status: 0,
    resolutionTime: DateTime.now(),
    equipment: [],
  };

  useEffect(() => {
    getEquipment(selectedClient.id, selectedShip.id).then((response) => {
      setEquipment(response);
    });
  }, [selectedClient.id, selectedShip.id]);
  return (
    <Container>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${selectedShip.id}/logbook`)}
          startIcon={<LocalPolice sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Logbook
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Add sx={{ mr: 0.5 }} fontSize="inherit" />
          New
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">New Report</Typography>
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={initialState}
            onSubmit={(values) => {
              var incident: IncidentReport = {
                title: values.title,
                description: values.description,
                location: values.location,
                manHours: values.manHours,
                resolution: values.resolution,
                shipStatus: values.shipStatus,
                environmentalData: values.environmentalData,
                isEmergency: values.isEmergency,
                status: values.status,
                equipment: [],
              };
              if(incident.status === 3){
                incident.deferredDate = values.deferredDate;
              }
              values.equipment.forEach((v) => {
                var equip = equipment.find((eq) => eq.id === v);
                if (equip !== undefined) {
                  incident.equipment?.push(equip);
                }
              });
              if (values.status === 2) {
                incident.resolutionTime = values.resolutionTime;
              }
              createIncident(selectedClient.id, selectedShip.id, incident).then(
                (res) => navigate(`/ships/${selectedShip.id}/logbook`)
              );
            }}
          >
            {({
              errors,
              handleSubmit,
              handleChange,
              handleBlur,
              touched,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormControlLabel
                  control={<Checkbox checked={values.isEmergency} />}
                  label="Is this an emergency?"
                  name="isEmergency"
                  onChange={handleChange}
                  sx={{ mb: 2 }}
                />
                <TextField
                  id="title"
                  name="title"
                  label={`Title`}
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.title && Boolean(errors.title)}
                  helperText={touched.title && errors.title}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  id="manHours"
                  type="number"
                  name="manHours"
                  label={`Man Hours`}
                  value={values.manHours}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.manHours && Boolean(errors.manHours)}
                  helperText={touched.manHours && errors.manHours}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  id="location"
                  name="location"
                  label={`Where on the ship did the issue occur?`}
                  value={values.location}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.location && Boolean(errors.location)}
                  helperText={touched.location && errors.location}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  id="environmentalData"
                  name="environmentalData"
                  label={`Environmental Data`}
                  value={values.environmentalData}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.environmentalData &&
                    Boolean(errors.environmentalData)
                  }
                  helperText={
                    touched.environmentalData && errors.environmentalData
                  }
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <InputLabel id="shipStatus-label">Ship Status</InputLabel>
                <Select
                  labelId="shipStatus-label"
                  id="shipStatus"
                  value={values.shipStatus}
                  onChange={(e) => {
                    setFieldValue("shipStatus", e.target.value);
                  }}
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  <MenuItem id={"status-0"} key={0} value={0}>
                    In Port
                  </MenuItem>
                  <MenuItem id={"status-1"} key={1} value={1}>
                    In Transit
                  </MenuItem>
                  <MenuItem id={"status-2"} key={2} value={2}>
                    Chartered
                  </MenuItem>
                  <MenuItem id={"status-3"} key={3} value={3}>
                    In Dry Dock
                  </MenuItem>
                </Select>
                <InputLabel id="equipment-label">Equipment</InputLabel>
                <Select
                  labelId="equipment-label"
                  id="equipment"
                  value={values.equipment}
                  multiple
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={equipment.find((e) => e.id === value)?.name}
                        />
                      ))}
                    </Box>
                  )}
                  onChange={(e) => {
                    setFieldValue("equipment", e.target.value);
                  }}
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  {equipment.map((e) => (
                    <MenuItem id={e.id} key={e.id} value={e.id}>
                      {e.name}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="subtitle1">Description:</Typography>
                <Editor
                  apiKey="s7hflnfnqq2c0nxj75udyor3kobji9x4jv2xgc4xvsdhtju5"
                  init={{
                    height: 500,
                    menubar: true,
                    skin: "oxide-dark",
                    content_css: "dark",
                    plugins: [
                      "advlist",
                      "autolink",
                      "lists",
                      "link",
                      "image",
                      "charmap",
                      "preview",
                      "anchor",
                      "searchreplace",
                      "visualblocks",
                      "code",
                      "fullscreen",
                      "insertdatetime",
                      "media",
                      "table",
                      "code",
                      "wordcount",
                    ],
                    toolbar:
                      "undo redo | blocks | " +
                      "bold italic forecolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent| " +
                      "removeformat",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                  textareaName="description"
                  onEditorChange={(stringifiedHTML) => {
                    setFieldValue("description", stringifiedHTML);
                  }}
                />

                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  id="status"
                  value={values.status}
                  onChange={(e) => {
                    setFieldValue("status", e.target.value);
                  }}
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  <MenuItem value={0}>Open</MenuItem>
                  <MenuItem value={3}>Deferred</MenuItem>
                  <MenuItem value={1}>In Review</MenuItem>
                  <MenuItem value={2}>Closed</MenuItem>
                </Select>
                {values.status === 2 ? (
                  <DatePicker
                    disableFuture
                    label="When was this incident resolved?"
                    value={values.resolutionTime}
                    onChange={(value: any) =>
                      setFieldValue(
                        "resolutionTime",
                        DateTime.fromISO(value),
                        true
                      )
                    }
                    sx={{ mb: 2 }}
                  />
                ) : (
                  <Box />
                )}
                {values.status === 3 ? (
                  <DatePicker
                    disablePast
                    label="Deferral Date"
                    value={values.deferredDate}
                    onChange={(value: any) =>
                      setFieldValue(
                        "deferredDate",
                        DateTime.fromISO(value),
                        true
                      )
                    }
                    sx={{ mb: 2 }}
                  />
                ) : (
                  <Box />
                )}
                {values.status === 1 || values.status === 2 ? (
                  <TextField
                    id="resolution"
                    name="resolution"
                    label={`Resolution`}
                    value={values.resolution}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.resolution && Boolean(errors.resolution)}
                    helperText={touched.resolution && errors.resolution}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                ) : (
                  <Box />
                )}
                <Button
                  sx={{ mt: 2 }}
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Submit
                </Button>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Container>
  );
}
