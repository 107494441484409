import {
  Alert,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import {
  IconBeer,
  IconBottle,
  IconCup,
  IconGlassCocktail,
} from "@tabler/icons-react";
import { PassengerProps } from "../../../types/PassengerTypes";
import FixedFormRatingControl from "../FixedFormRatingControl";

export default function DrinkPreferences(props: PassengerProps) {
  const { passenger } = props;
  return passenger.passengerDrinkInformation !== undefined ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 4,
            borderTopColor: "#b47f49",
            borderTopWidth: "4px",
            borderStyle: "solid",
            borderRightWidth: "0px",
            borderLeftWidth: "0px",
            borderBottomWidth: "0",
          }}
          elevation={12}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4">Drink Preferences</Typography>
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Formik
                initialValues={passenger.passengerDrinkInformation}
                onSubmit={() => {}}
              >
                {({
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  touched,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Alert severity="info" variant="outlined">
                          <Stack direction="column">
                            <Typography variant="body1">
                              Please rate the following drink preferences. We
                              will do our best to accommodate everyone’s
                              preferences, but please understand that some items
                              may not always be available.
                            </Typography>
                            <List>
                              <ListItem>1 - Cannot Have</ListItem>
                              <ListItem>2 - Dislike</ListItem>
                              <ListItem>3 - Neutral</ListItem>
                              <ListItem>4 - Like</ListItem>
                              <ListItem>5 - Must Have</ListItem>
                            </List>
                          </Stack>
                        </Alert>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          <IconCup /> Non-Alcoholic Drinks
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Coke"
                          name="isDrinkCoke"
                          value={values.isDrinkCoke}
                          precision={1}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Diet Coke"
                          name="isDrinkDietCoke"
                          value={values.isDrinkDietCoke}
                          precision={1}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Sprite"
                          name="isDrinkSprite"
                          value={values.isDrinkSprite}
                          precision={1}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Diet Sprite"
                          name="isDrinkDietSprite"
                          value={values.isDrinkDietSprite}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Ginger Ale"
                          name="isGingerAle"
                          value={values.isGingerAle}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Coffee"
                          name="isCoffee"
                          value={values.isCoffee}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Iced Coffee"
                          name="isIcedCoffee"
                          value={values.isIcedCoffee}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Black Tea"
                          name="isBlackTea"
                          value={values.isBlackTea}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Green Tea"
                          name="isGreenTea"
                          value={values.isGreenTea}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Floral Tea"
                          name="isFloralTea"
                          value={values.isFloralTea}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Iced Tea"
                          name="isIcedTea"
                          value={values.isIcedTea}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Whole Milk"
                          name="isWholeMilk"
                          value={values.isWholeMilk}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Milk"
                          name="isMilk"
                          value={values.isMilk}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Skim Milk"
                          name="isSkimMilk"
                          value={values.isSkimMilk}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Tap Water"
                          name="isTapWater"
                          value={values.isTapWater}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Bottled Water"
                          name="isBottledWater"
                          value={values.isBottledWater}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Flavored Water"
                          name="isFlavoredWater"
                          value={values.isFlavoredWater}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Gatorade"
                          name="isGatorade"
                          value={values.isGatorade}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Orange Juice"
                          name="isOrangeJuice"
                          value={values.isOrangeJuice}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Apple Juice"
                          name="isAppleJuice"
                          value={values.isAppleJuice}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Pineapple Juice"
                          name="isPineappleJuice"
                          value={values.isPineappleJuice}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Cranberry Juice"
                          name="isCranberryJuice"
                          value={values.isCranberryJuice}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Tonic Water"
                          name="isTonicWater"
                          value={values.isTonicWater}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FixedFormRatingControl
                          label="Club Soda"
                          name="isClubSoda"
                          value={values.isClubSoda}
                          precision={1}

                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="otherNonAlcoholicDrinks"
                          name="otherNonAlcoholicDrinks"
                          label={`Are there any other drinks you would like us to try to provide?`}
                          value={values.otherNonAlcoholicDrinks}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{shrink: true,}}
                          error={
                            touched.otherNonAlcoholicDrinks &&
                            Boolean(errors.otherNonAlcoholicDrinks)
                          }
                          helperText={
                            touched.otherNonAlcoholicDrinks &&
                            errors.otherNonAlcoholicDrinks
                          }
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h4">
                          <IconBeer /> Alcoholic Drinks
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Checkbox checked={values.isNoAlchohol} />}
                          label="I do not drink. Please do not offer me alcohol"
                          disabled
                          name="isNoAlchohol"
                          onChange={handleChange}
                        />
                      </Grid>
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            <IconBeer />
                            {" Beer and Wine and Lighter Alcoholic Drinks"}
                          </Typography>
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Light Beer"
                            name="isLightBeer"
                            value={values.isLightBeer}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Dark Beer"
                            name="isDarkBeer"
                            value={values.isDarkBeer}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="IPA"
                            name="isIPA"
                            value={values.isIPA}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Corona"
                            name="isCorona"
                            value={values.isCorona}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="White Wine"
                            name="isWhiteWine"
                            value={values.isWhiteWine}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Red Wine"
                            name="isRedWine"
                            value={values.isRedWine}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Rosé"
                            name="isRose"
                            value={values.isRose}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Sweet Red Wine"
                            name="isSweetRedWine"
                            value={values.isSweetRedWine}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Port Wine"
                            name="isPort"
                            value={values.isPort}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Sparkling Wine / Champagne"
                            name="isSparklingChampagne"
                            value={values.isSparklingChampagne}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Hard Teas / Lemonades"
                            name="isHardTeaLemonade"
                            value={values.isHardTeaLemonade}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Seltzers"
                            name="isSeltzers"
                            value={values.isSeltzers}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            <IconBottle />
                            {" Liquors"}
                          </Typography>
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Gin"
                            name="isGin"
                            value={values.isGin}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Vodka"
                            name="isVodka"
                            value={values.isVodka}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Flavored Vodka"
                            name="isFlavoredVodka"
                            value={values.isFlavoredVodka}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Rum"
                            name="isRum"
                            value={values.isRum}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Flavored Rum"
                            name="isFlavoredRum"
                            value={values.isFlavoredRum}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Tequila"
                            name="isTequila"
                            value={values.isTequila}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Whiskey"
                            name="isWhiskey"
                            value={values.isWhiskey}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Scotch"
                            name="isScotch"
                            value={values.isScotch}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Liqueurs"
                            name="isLiqueurs"
                            value={values.isLiqueurs}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            <IconGlassCocktail />
                            {" Mixers"}
                          </Typography>
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Bitters"
                            name="isBitters"
                            value={values.isBitters}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="SimpleSyrup"
                            name="isSimpleSyrup"
                            value={values.isSimpleSyrup}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Triple Sec"
                            name="isTripleSec"
                            value={values.isTripleSec}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            label="Grenadine"
                            name="isGrenadine"
                            value={values.isGrenadine}
                            precision={1}

                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12}>
                          <TextField
                            id="otherAlcoholicDrinks"
                            name="otherAlcoholicDrinks"
                            label={`Are there any other alcohols you would like us to provide?`}
                            value={values.otherAlcoholicDrinks}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}
                            error={
                              touched.otherAlcoholicDrinks &&
                              Boolean(errors.otherAlcoholicDrinks)
                            }
                            helperText={
                              touched.otherAlcoholicDrinks &&
                              errors.otherAlcoholicDrinks
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12}>
                          <TextField
                            id="otherMixers"
                            name="otherMixers"
                            label={`Are there any other mixers you would like us to provide?`}
                            value={values.otherMixers}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}
                            error={
                              touched.otherMixers && Boolean(errors.otherMixers)
                            }
                            helperText={
                              touched.otherMixers && errors.otherMixers
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      {!values.isNoAlchohol ? (
                        <Grid item xs={12}>
                          <TextField
                            id="drinkAndCocktailRequests"
                            name="drinkAndCocktailRequests"
                            label={`Are there any specific drinks or cocktails you would like us to make?`}
                            value={values.drinkAndCocktailRequests}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}
                            error={
                              touched.drinkAndCocktailRequests &&
                              Boolean(errors.drinkAndCocktailRequests)
                            }
                            helperText={
                              touched.drinkAndCocktailRequests &&
                              errors.drinkAndCocktailRequests
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  ) : (
    <Box />
  );
}
