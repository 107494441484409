import { Divider, Grid, Paper, Typography } from "@mui/material";
import { ReactNode } from "react";

export type PaperSectionProps = {
  children: ReactNode[] | React.ReactNode;
  title: string | undefined;
  icon?: ReactNode;
  button?: ReactNode;
};

export default function PaperSection(props: PaperSectionProps) {
  const { icon, children, title, button } = props;
  return (
    <Paper
      sx={{
        p: 2,
        borderTopColor: "#b47f49",
        borderTopWidth: "4px",
        borderStyle: "solid",
        borderRightWidth: "0",
        borderLeftWidth: "0",
        borderBottomWidth: "0",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <Typography variant="h4">{icon} {title}</Typography>
        </Grid>
        <Grid item xs={12} md={2} textAlign="end">
          {button}
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mb: 2 }} />
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Paper>
  );
}
