import { Button, ButtonGroup } from "@mui/material";
import { ShipTodoList } from "../../../types/TodoList";
import { Done, Replay } from "@mui/icons-material";
import { updateTodoListStatus } from "../../../services/todoListService";
import { useNavigate } from "react-router-dom";

export type TodoListReviewActionButtonGroupProps = {
    todoList: ShipTodoList | undefined,
    selectedClientId: string | undefined,
    shipId: string | undefined
}

export default function TodoListReviewActionButtonGroup(props: TodoListReviewActionButtonGroupProps){
    const navigate = useNavigate();
    const {todoList, selectedClientId, shipId} = props;
    return  <ButtonGroup variant="contained" color="error">
    {todoList?.items !== undefined &&
    todoList?.items?.filter((item) => item.status === 0).length >
      0 ? (
      <Button
        startIcon={<Replay />}
        color="error"
        onClick={() => {
          updateTodoListStatus(
            todoList.id,
            selectedClientId,
            1
          ).then((res) => navigate(`/ships/${shipId}/todolists`));
        }}
      >
        Send Back
      </Button>
    ) : (
      <Button startIcon={<Replay />} color="error" disabled>
        Send Back
      </Button>
    )}
    {todoList?.items !== undefined &&
    todoList?.items?.filter((item) => item.status === 0).length ===
      0 ? (
      <Button
        startIcon={<Done />}
        color="success"
        onClick={() => {
          updateTodoListStatus(
            todoList.id,
            selectedClientId,
            2
          ).then((res) => navigate(`/ships/${shipId}/todolists`));
        }}
      >
        Complete
      </Button>
    ) : (
      <Button startIcon={<Done />} color="success" disabled>
        Complete
      </Button>
    )}
  </ButtonGroup>;
}