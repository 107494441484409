import { IconCancel } from "@tabler/icons-react";
import { ShipDrinkInformationSummary } from "../../../types/PassengerTypes";
import AccordianPaperSection from "../../AccordianPaperSection";
import { Paper, Stack, Typography } from "@mui/material";
import { Person } from "@mui/icons-material";

export type DoNotServeAlcoholCardProps = {
  drinks: ShipDrinkInformationSummary;
};

export default function DoNotServeAlcoholCard(
  props: DoNotServeAlcoholCardProps
) {
  const { drinks } = props;

  return (
    <AccordianPaperSection title="Do Not Serve Alcohol" icon={<IconCancel />}>
      <Stack direction="row" spacing={2}>
        {drinks.doesNotDrink?.map((mh) => (
          <Paper
            sx={{
              p: 1,
              borderLeftColor: "white",
              borderTopWidth: "0px",
              borderStyle: "solid",
              borderRightWidth: "0px",
              borderLeftWidth: "4px",
              borderBottomWidth: "0",
              mr: 1,
              mb: 2,
            }}
            elevation={24}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Person />
              <Typography variant="h5">{`${mh}`}</Typography>
            </Stack>
          </Paper>
        ))}
      </Stack> 
    </AccordianPaperSection>
  );
}
