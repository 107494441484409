import {
  Alert,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { Star } from "@mui/icons-material";
import {
  IconCheese,
  IconCherry,
  IconCookie,
  IconFish,
  IconMeat,
  IconSalt,
  IconSeeding,
  IconSunrise,
  IconToolsKitchen2,
} from "@tabler/icons-react";
import { PassengerProps } from "../../../types/PassengerTypes";
import FixedFormRatingControl from "../FixedFormRatingControl";
import ConditionalFixedFormRatingControl from "../ConditionalFixedFormRatingControl";
export default function FoodPreferences(props: PassengerProps) {
  const { passenger } = props;

  return passenger.passengerFoodInformation !== undefined &&
    passenger.passengerFoodInformation.fruitInformation !== undefined &&
    passenger.passengerFoodInformation.cheeseInformation !== undefined &&
    passenger.passengerFoodInformation.dessertInformation !== undefined &&
    passenger.passengerFoodInformation.breakfastInformation !== undefined &&
    passenger.passengerFoodInformation.meatInformation !== undefined &&
    passenger.passengerFoodInformation.sauceSpiceInformation !== undefined &&
    passenger.passengerFoodInformation.seafoodInformation !== undefined &&
    passenger.passengerFoodInformation.soupRiceInformation !== undefined &&
    passenger.passengerFoodInformation.vegetablesInformation !== undefined  ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 4,
            borderTopColor: "#b47f49",
            borderTopWidth: "4px",
            borderStyle: "solid",
            borderRightWidth: "0px",
            borderLeftWidth: "0px",
            borderBottomWidth: "0",
          }}
          elevation={12}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4">Food Preferences</Typography>
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Formik
                initialValues={passenger.passengerFoodInformation}
                onSubmit={() => {}}
              >
                {({
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                }) =>
                  values.fruitInformation !== undefined &&
                  values.cheeseInformation !== undefined &&
                  values.dessertInformation !== undefined &&
                  values.breakfastInformation !== undefined &&
                  values.meatInformation !== undefined &&
                  values.sauceSpiceInformation !== undefined &&
                  values.seafoodInformation !== undefined &&
                  values.soupRiceInformation !== undefined &&
                  values.vegetablesInformation !== undefined &&
                  values.isVegan !== undefined &&
                  values.isVegetarian !== undefined &&
                  values.isEggDairy !== undefined  ? (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={<Checkbox checked={values.isVegetarian} />}
                            label="Are you vegetarian?"
                            disabled
                            name="isVegetarian"
                            onChange={handleChange}
                          />
                        </Grid>
                        {values.isVegetarian ? (
                          <Grid item xs={12} md={6}>
                            <FormControlLabel
                              control={<Checkbox checked={values.isEggDairy} />}
                              label="Do you eat Eggs or Dairy?"
                              disabled
                              name="isEggDairy"
                              onChange={handleChange}
                            />
                          </Grid>
                        ) : (
                          <Box />
                        )}
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={<Checkbox checked={values.isVegan} />}
                            label="Are you vegan?"
                            disabled
                            name="isVegan"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="dietaryRestrictions"
                            name="dietaryRestrictions"
                            label={`Do you have any other dietary restrictions we should know about?`}
                            value={values.dietaryRestrictions}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              readOnly: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                            error={
                              touched.dietaryRestrictions &&
                              Boolean(errors.dietaryRestrictions)
                            }
                            helperText={
                              touched.dietaryRestrictions &&
                              errors.dietaryRestrictions
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Alert severity="info" variant="outlined">
                            <Stack direction="column">
                              <Typography variant="body1">
                                Please rate the following food preferences. We
                                will do our best to accommodate everyone’s
                                preferences, but please understand that some
                                items may not always be available.
                              </Typography>
                              <List>
                                <ListItem>
                                  1<Star /> - Cannot Have
                                </ListItem>
                                <ListItem>
                                  2<Star />- Dislike
                                </ListItem>
                                <ListItem>
                                  3<Star /> - Neutral
                                </ListItem>
                                <ListItem>
                                  4<Star /> - Like
                                </ListItem>
                                <ListItem>
                                  5<Star /> - Must Have
                                </ListItem>
                              </List>
                            </Stack>
                          </Alert>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h4">
                            <IconCherry /> Fruit
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Apples"
                            name="apples"
                            value={values.fruitInformation.apples}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Bananas"
                            name="bananas"
                            value={values.fruitInformation.bananas}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Oranges"
                            name="oranges"
                            value={values.fruitInformation.oranges}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Grapes"
                            name="grapes"
                            value={values.fruitInformation.grapes}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Melons"
                            name="melons"
                            value={values.fruitInformation.melons}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Strawberries"
                            name="strawberries"
                            value={values.fruitInformation.strawberries}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Blueberries"
                            name="blueBerries"
                            value={values.fruitInformation.blueBerries}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Raspberries"
                            name="raspberries"
                            value={values.fruitInformation.raspberries}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Peaches"
                            name="peaches"
                            value={values.fruitInformation.peaches}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Plums"
                            name="plums"
                            value={values.fruitInformation.plums}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Pineapple"
                            name="pineapple"
                            value={values.fruitInformation.pineapple}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="other"
                            name="other"
                            label={`Are there any specific Fruits you would like us to get?`}
                            InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}
                            value={values.fruitInformation.other}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h4">
                            <IconSeeding /> Vegetables
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Asparagus"
                            name="asparagus"
                            value={values.vegetablesInformation.asparagus}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Avocado"
                            name="avocado"
                            value={values.vegetablesInformation.avocado}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Beets"
                            name="beets"
                            value={values.vegetablesInformation.beets}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Bell Peppers"
                            name="bellPeppers"
                            value={values.vegetablesInformation.bellPeppers}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Hot Peppers"
                            name="hotPeppers"
                            value={values.vegetablesInformation.hotPeppers}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Broccoli"
                            name="broccoli"
                            value={values.vegetablesInformation.broccoli}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Black Beans"
                            name="blackBeans"
                            value={values.vegetablesInformation.blackBeans}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Pinto Beans"
                            name="pintoBeans"
                            value={values.vegetablesInformation.pintoBeans}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Garbanzo Beans"
                            name="garbanzoBeans"
                            value={values.vegetablesInformation.garbanzoBeans}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Other Beans"
                            name="otherBeans"
                            value={values.vegetablesInformation.otherBeans}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Cauliflower"
                            name="cauliflower"
                            value={values.vegetablesInformation.cauliflower}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Cabbage"
                            name="cabbage"
                            value={values.vegetablesInformation.cabbage}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Cucumbers"
                            name="cucumbers"
                            value={values.vegetablesInformation.cucumbers}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Carrots"
                            name="carrots"
                            value={values.vegetablesInformation.carrots}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Corn"
                            name="corn"
                            value={values.vegetablesInformation.corn}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Eggplant"
                            name="eggplant"
                            value={values.vegetablesInformation.eggplant}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Lettuce"
                            name="lettuce"
                            value={values.vegetablesInformation.lettuce}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Onion"
                            name="onion"
                            value={values.vegetablesInformation.onions}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Mashed Potatoes"
                            name="mashedPotatoes"
                            value={values.vegetablesInformation.mashedPotatoes}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Fried Potatoes"
                            name="friedPotatoes"
                            value={values.vegetablesInformation.friedPotatoes}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Baked Potatoes"
                            name="bakedPotatoes"
                            value={values.vegetablesInformation.bakedPotatoes}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Mashed Sweet Potatoes"
                            name="mashedSweetPotatoes"
                            value={values.vegetablesInformation.mashedSweetPotatoes}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Fried Sweet Potatoes"
                            name="friedSweetPotatoes"
                            value={values.vegetablesInformation.friedSweetPotatoes}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Baked Sweet Potatoes"
                            name="bakedSweetPotatoes"
                            value={values.vegetablesInformation.bakedSweetPotatoes}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Spinach"
                            name="spinach"
                            value={values.vegetablesInformation.spinach}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Squash"
                            name="squash"
                            value={values.vegetablesInformation.squash}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Tomatoes"
                            name="tomatoes"
                            value={values.vegetablesInformation.tomatoes}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Zucchini"
                            name="zucchini"
                            value={values.vegetablesInformation.zucchini}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="vegetableOther"
                            name="vegetableOther"
                            InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}
                            label={`Are there any specific vegetables you would like us to get?`}
                            value={values.vegetablesInformation.other}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h4">
                            <IconSalt /> Spices and Sauces
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Ginger"
                            name="ginger"
                            value={values.sauceSpiceInformation.ginger}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Garlic"
                            name="garlic"
                            value={values.sauceSpiceInformation.garlic}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Onion"
                            name="onion"
                            value={values.sauceSpiceInformation.onion}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Oregano"
                            name="oregano"
                            value={values.sauceSpiceInformation.oregano}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Thyme"
                            name="thyme"
                            value={values.sauceSpiceInformation.thyme}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Rosemary"
                            name="rosemary"
                            value={values.sauceSpiceInformation.rosemary}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Paprika"
                            name="papkrika"
                            value={values.sauceSpiceInformation.paprika}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Black Pepper"
                            name="blackPepper"
                            value={values.sauceSpiceInformation.blackPepper}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Soy Sauce"
                            name="soySauce"
                            value={values.sauceSpiceInformation.soySauce}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="A1 Sauce"
                            name="aOneSauce"
                            value={values.sauceSpiceInformation.aOneSauce}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Salt"
                            name="salt"
                            value={values.sauceSpiceInformation.salt}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Cumin"
                            name="cumin"
                            value={values.sauceSpiceInformation.cumin}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Barbeque Sauce"
                            name="barbecueSauce"
                            value={values.sauceSpiceInformation.barbecueSauce}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Chipotle"
                            name="chipotle"
                            value={values.sauceSpiceInformation.chipotle}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Chili Powder"
                            name="chiliPowder"
                            value={values.sauceSpiceInformation.chiliPowder}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Cayenne"
                            name="cayanne"
                            value={values.sauceSpiceInformation.cayanne}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Nutmeg"
                            name="nutmeg"
                            value={values.sauceSpiceInformation.nutmeg}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Cilantro"
                            name="cilantro"
                            value={values.sauceSpiceInformation.cilantro}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Teriyaki"
                            name="teriyaki"
                            value={values.sauceSpiceInformation.teriyaki}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Ranch"
                            name="ranch"
                            value={values.sauceSpiceInformation.ranch}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Ketchup"
                            name="ketchup"
                            value={values.sauceSpiceInformation.ketchup}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Mustard"
                            name="mustard"
                            value={values.sauceSpiceInformation.mustard}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="otherSauce"
                            name="otherSauce"
                            label={`Are there any specific sauces or spices you would like us to get?`}
                            value={values.sauceSpiceInformation.other}
                            InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        {(values.isVegetarian && !values.isEggDairy) ||
                        values.isVegan ? (
                          <Box />
                        ) : (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )}
                        {(values.isVegetarian && !values.isEggDairy) ||
                        values.isVegan ? (
                          <Box />
                        ) : (
                          <Grid item xs={12}>
                            <Typography variant="h4">
                              <IconCheese /> Cheeses
                            </Typography>
                          </Grid>
                        )}

                        <ConditionalFixedFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Blue Cheese"
                          name="blueCheese"
                          value={values.cheeseInformation.blueCheese}
                          onChange={(e, val) => {
                            setFieldValue("blueCheese", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Cheddar"
                          name="chedddar"
                          value={values.cheeseInformation.cheddar}
                          onChange={(e, val) => {
                            setFieldValue("cheddar", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Provolone"
                          name="provolone"
                          value={values.cheeseInformation.provolone}
                          onChange={(e, val) => {
                            setFieldValue("provolone", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Swiss"
                          name="swiss"
                          value={values.cheeseInformation.swiss}
                          onChange={(e, val) => {
                            setFieldValue("swiss", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="American"
                          name="american"
                          value={values.cheeseInformation.american}
                          onChange={(e, val) => {
                            setFieldValue("american", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Brie"
                          name="brie"
                          value={values.cheeseInformation.brie}
                          onChange={(e, val) => {
                            setFieldValue("brie", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Feta"
                          name="feta"
                          value={values.cheeseInformation.feta}
                          onChange={(e, val) => {
                            setFieldValue("feta", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Goat"
                          name="goat"
                          value={values.cheeseInformation.goat}
                          onChange={(e, val) => {
                            setFieldValue("goat", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Colby"
                          name="colby"
                          value={values.cheeseInformation.colby}
                          onChange={(e, val) => {
                            setFieldValue("colby", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Parmesan"
                          name="parmesan"
                          value={values.cheeseInformation.parmesan}
                          onChange={(e, val) => {
                            setFieldValue("parmesan", val);
                          }}
                        />
                        {(values.isVegetarian && !values.isEggDairy) ||
                        values.isVegan ? (
                          <Box />
                        ) : (
                          <Grid item xs={12}>
                            <TextField
                              id="cheeseOther"
                              name="cheeseOther"
                              label={`Are there any specific cheese you would like us to get?`}
                              value={values.cheeseInformation.other}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h4">
                            <IconSunrise /> Breakfast
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Pancakes"
                            name="pancakes"
                            value={values.breakfastInformation.pancakes}
                            onChange={(e, val) => {
                              setFieldValue("pancakes", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Waffles"
                            name="waffles"
                            value={values.breakfastInformation.waffles}
                            onChange={(e, val) => {
                              setFieldValue("waffles", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Toast"
                            name="toast"
                            value={values.breakfastInformation.toast}
                            onChange={(e, val) => {
                              setFieldValue("toast", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Muffins"
                            name="muffins"
                            value={values.breakfastInformation.muffins}
                            onChange={(e, val) => {
                              setFieldValue("muffins", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Pastries"
                            name="pastries"
                            value={values.breakfastInformation.pastries}
                            onChange={(e, val) => {
                              setFieldValue("pastries", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Fruit"
                            name="fruit"
                            value={values.breakfastInformation.fruit}
                            onChange={(e, val) => {
                              setFieldValue("fruit", val);
                            }}
                          />
                        </Grid>
                        <ConditionalFixedFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Hard Cheese"
                          name="hardCheese"
                          value={values.breakfastInformation.hardCheese}
                          onChange={(e, val) => {
                            setFieldValue("hardCheese", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Eggs"
                          name="eggs"
                          value={values.breakfastInformation.eggs}
                          onChange={(e, val) => {
                            setFieldValue("eggs", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegetarian || values.isVegan}
                          label="Bacon"
                          name="bacon"
                          value={values.breakfastInformation.bacon}
                          onChange={(e, val) => {
                            setFieldValue("bacon", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegetarian || values.isVegan}
                          label="Sausage"
                          name="breakfastSausage"
                          value={values.breakfastInformation.sausage}
                          onChange={(e, val) => {
                            setFieldValue("breakfastSausage", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={
                            (values.isVegetarian && !values.isEggDairy) ||
                            values.isVegan
                          }
                          label="Omelet"
                          name="omelet"
                          value={values.breakfastInformation.omelet}
                          onChange={(e, val) => {
                            setFieldValue("omelet", val);
                          }}
                        />
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Cereal"
                            name="cereal"
                            value={values.breakfastInformation.cereal}
                            onChange={(e, val) => {
                              setFieldValue("cereal", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Biscuits"
                            name="biscuits"
                            value={values.breakfastInformation.biscuits}
                            onChange={(e, val) => {
                              setFieldValue("biscuits", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Yogurt"
                            name="yogurt"
                            value={values.breakfastInformation.yogurt}
                            onChange={(e, val) => {
                              setFieldValue("yogurt", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="breakfastOther"
                            name="breakfastOther"
                            label={`Are there any specific breakfast options you would like us to get?`}
                            value={values.breakfastInformation.other}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        {values.isVegetarian || values.isVegan ? (
                          <Box />
                        ) : (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )}
                        {values.isVegetarian || values.isVegan ? (
                          <Box />
                        ) : (
                          <Grid item xs={12}>
                            <Typography variant="h4">
                              <IconMeat /> Meat
                            </Typography>
                          </Grid>
                        )}
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegetarian || values.isVegan}
                          label="Beef"
                          name="beef"
                          value={values.meatInformation.beef}
                          onChange={(e, val) => {
                            setFieldValue("beef", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegetarian || values.isVegan}
                          label="Chicken"
                          name="chicken"
                          value={values.meatInformation.chicken}
                          onChange={(e, val) => {
                            setFieldValue("chicken", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegetarian || values.isVegan}
                          label="Pork"
                          name="pork"
                          value={values.meatInformation.pork}
                          onChange={(e, val) => {
                            setFieldValue("pork", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegetarian || values.isVegan}
                          label="Sausage"
                          name="Sausage"
                          value={values.meatInformation.sausage}
                          onChange={(e, val) => {
                            setFieldValue("sausage", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegetarian || values.isVegan}
                          label="Lamb"
                          name="lamb"
                          value={values.meatInformation.lamb}
                          onChange={(e, val) => {
                            setFieldValue("lamb", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegetarian || values.isVegan}
                          label="Turkey"
                          name="turkey"
                          value={values.meatInformation.turkey}
                          onChange={(e, val) => {
                            setFieldValue("turkey", val);
                          }}
                        />
                        {values.isVegetarian || values.isVegan ? (
                          <Box />
                        ) : (
                          <Grid item xs={12}>
                            <TextField
                              id="meatOther"
                              name="meatOther"
                              label={`Are there any other meat you would like us to get?`}
                              value={values.meatInformation.other}
                              onChange={handleChange}
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}
                              onBlur={handleBlur}
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                        )}
                        {values.isVegan ? (
                          <Box />
                        ) : (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )}
                        {values.isVegan ? (
                          <Box />
                        ) : (
                          <Grid item xs={12}>
                            <Typography variant="h4">
                              <IconFish /> Seafood
                            </Typography>
                          </Grid>
                        )}
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegan}
                          label="Ceviche"
                          name="ceviche"
                          value={values.seafoodInformation.ceviche}
                          onChange={(e, val) => {
                            setFieldValue("ceviche", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegan}
                          label="Sushi"
                          name="sushi"
                          value={values.seafoodInformation.sushi}
                          onChange={(e, val) => {
                            setFieldValue("sushi", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegan}
                          label="Cod"
                          name="cod"
                          value={values.seafoodInformation.cod}
                          onChange={(e, val) => {
                            setFieldValue("cod", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegan}
                          label="Mahi Mahi"
                          name="mahiMahi"
                          value={values.seafoodInformation.mahiMahi}
                          onChange={(e, val) => {
                            setFieldValue("mahiMahi", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegan}
                          label="Salmon"
                          name="salmon"
                          value={values.seafoodInformation.salmon}
                          onChange={(e, val) => {
                            setFieldValue("salmon", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegan}
                          label="Sea Bass"
                          name="seaBass"
                          value={values.seafoodInformation.seaBass}
                          onChange={(e, val) => {
                            setFieldValue("seaBass", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegan}
                          label="Snapper"
                          name="snapper"
                          value={values.seafoodInformation.snapper}
                          onChange={(e, val) => {
                            setFieldValue("snapper", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegan}
                          label="Swordfish"
                          name="swordfish"
                          value={values.seafoodInformation.swordfish}
                          onChange={(e, val) => {
                            setFieldValue("swordfish", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegan}
                          label="Tuna"
                          name="tuna"
                          value={values.seafoodInformation.tuna}
                          onChange={(e, val) => {
                            setFieldValue("tuna", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegan}
                          label="Shrimp"
                          name="shrimp"
                          value={values.seafoodInformation.shrimp}
                          onChange={(e, val) => {
                            setFieldValue("shrimp", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegan}
                          label="Lobster"
                          name="lobster"
                          value={values.seafoodInformation.lobster}
                          onChange={(e, val) => {
                            setFieldValue("lobster", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegan}
                          label="Oysters"
                          name="oysters"
                          value={values.seafoodInformation.oysters}
                          onChange={(e, val) => {
                            setFieldValue("oysters", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegan}
                          label="Clams"
                          name="clams"
                          value={values.seafoodInformation.clams}
                          onChange={(e, val) => {
                            setFieldValue("clams", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegan}
                          label="Mussels"
                          name="mussels"
                          value={values.seafoodInformation.mussels}
                          onChange={(e, val) => {
                            setFieldValue("mussels", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegan}
                          label="Calamari"
                          name="calamari"
                          value={values.seafoodInformation.calamari}
                          onChange={(e, val) => {
                            setFieldValue("calamari", val);
                          }}
                        />
                        <ConditionalFixedFormRatingControl
                          isHidden={values.isVegan}
                          label="Octopus"
                          name="octopus"
                          value={values.seafoodInformation.octopus}
                          onChange={(e, val) => {
                            setFieldValue("octopus", val);
                          }}
                        />
                        {values.isVegan ? (
                          <Box />
                        ) : (
                          <Grid item xs={12}>
                            <TextField
                              id="seafoodOther"
                              name="seafoodOther"
                              label={`Are there any other seafood you would like us to get?`}
                              value={values.seafoodInformation.other}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h4">
                            <IconToolsKitchen2 /> Soup / Rice / Pasta
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Curry"
                            name="curry"
                            value={values.soupRiceInformation.curry}
                            onChange={(e, val) => {
                              setFieldValue("curry", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Hot Soup"
                            name="hotSoup"
                            value={values.soupRiceInformation.hotSoup}
                            onChange={(e, val) => {
                              setFieldValue("hotSoup", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Cold Soup"
                            name="coldSoup"
                            value={values.soupRiceInformation.coldSoup}
                            onChange={(e, val) => {
                              setFieldValue("coldSoup", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Rice"
                            name="rice"
                            value={values.soupRiceInformation.rice}
                            onChange={(e, val) => {
                              setFieldValue("rice", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Pasta"
                            name="pasta"
                            value={values.soupRiceInformation.pasta}
                            onChange={(e, val) => {
                              setFieldValue("pasta", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Bread"
                            name="bread"
                            value={values.soupRiceInformation.bread}
                            onChange={(e, val) => {
                              setFieldValue("bread", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Risotto"
                            name="risotto"
                            value={values.soupRiceInformation.risotto}
                            onChange={(e, val) => {
                              setFieldValue("risotto", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="pastaOther"
                            name="pastaOther"
                            label={`Are there any other Soup / Rice / Pasta you would like us to make?`}
                            value={values.soupRiceInformation.other}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h4">
                            <IconCookie /> Desserts
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Cookies"
                            name="cookie"
                            value={values.dessertInformation.cookie}
                            onChange={(e, val) => {
                              setFieldValue("cookie", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Cake"
                            name="cake"
                            value={values.dessertInformation.cake}
                            onChange={(e, val) => {
                              setFieldValue("cake", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Pastries"
                            name="dessertPastries"
                            value={values.dessertInformation.pastries}
                            onChange={(e, val) => {
                              setFieldValue("dessertPastries", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Ice Cream"
                            name="iceCream"
                            value={values.dessertInformation.iceCream}
                            onChange={(e, val) => {
                              setFieldValue("iceCream", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Donuts"
                            name="donuts"
                            value={values.dessertInformation.donuts}
                            onChange={(e, val) => {
                              setFieldValue("donuts", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Brownies"
                            name="brownies"
                            value={values.dessertInformation.brownies}
                            onChange={(e, val) => {
                              setFieldValue("brownies", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FixedFormRatingControl
                            precision={1}
                            label="Confectionaries"
                            name="confectionery"
                            value={values.dessertInformation.confectionery}
                            onChange={(e, val) => {
                              setFieldValue("confectionery", val);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="dessertOther"
                            name="dessertOther"
                            label={`Are there any other Desserts you would like us to make?`}
                            value={values.dessertInformation.other}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="otherDishes"
                            name="otherDishes"
                            label={`Are there any specific dishes you would like us to try to make?`}
                            value={values.otherDishes}
                            InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{shrink: true,}}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                      </Grid>
                    </form>
                  ) : (
                    <Box />
                  )
                }
              </Formik>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  ) : (
    <Box />
  );
}
