import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getShip, getShipParts } from "../../services/shipService";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { Ship } from "../../types/Ship";
import { Add, Category, DirectionsBoat, Iso } from "@mui/icons-material";
import { DataGrid, GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import { Part } from "../../types/Part";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import PaperSection from "../../components/PaperSection";
import BillingQouta from "../../components/Billing/BillingQouta";

export default function Parts() {
  const params = useParams();
  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [parts, setParts] = useState<Part[]>([]);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [selectedShip] = useRecoilState(SelectedShipState);

  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
      getShipParts(params.id, selectedClient.id).then((partsResponse) => {
        setParts(partsResponse);
      });
    });
  }, [selectedClient, params.id]);
  const columns = [
    {
      field: "name",
      headerName: "Name",
      valueGetter: (params: any) => {
        return params.row.name;
      },
      width: 150,
    },
    {
      field: "maker",
      headerName: "Maker",
      valueGetter: (params: any) => {
        return params.row.maker;
      },
      width: 150,
    },
    {
      field: "minimumInventory",
      headerName: "Minimum Inventory",
      valueGetter: (params: any) => {
        return params.row.minimumInventory;
      },
      width: 150,
    },
    {
      field: "storageLocation",
      headerName: "Storage Location",
      valueGetter: (params: any) => {
        return params.row.storageLocation;
      },
      width: 150,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      valueGetter: (params: any) => {
        return params.row.quantity;
      },
      width: 150,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (params: GridRowParams): any => {
        return [
          <GridActionsCellItem
            icon={<Iso />}
            label="Update Quantity"
            onClick={() => {
              navigate(`/Ships/${ship?.id}/parts/${params.id}/newlog`);
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Category sx={{ mr: 0.5 }} fontSize="inherit" />
          Parts
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {selectedClient.billingTier === 0 ? (
            <BillingQouta
              metric={parts.length}
              maxMetric={20}
              displayMetricName={false}
              metricName={"part"}
              pluralMetricName={"parts"}
              costPerAdditional={5}
            />
          ) : (
            <Box />
          )}
        </Grid>
        <Grid item xs={12}>
          <PaperSection
            title="Parts"
            icon={<Category />}
            button={
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate(`/Ships/${ship?.id}/parts/newpart`);
                }}
                startIcon={<Add />}
              >
                New Part
              </Button>
            }
          >
            <DataGrid
              sx={{ height: "80%", minHeight: "500px" }}
              onRowClick={(value) =>
                navigate(`/Ships/${ship?.id}/parts/${value.id}`)
              }
              columns={columns}
              rows={parts}
            />
          </PaperSection>
        </Grid>
      </Grid>
    </Container>
  );
}
