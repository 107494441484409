import { IconLeaf } from "@tabler/icons-react";
import AccordianPaperSection from "../../AccordianPaperSection";
import { Box, Chip, Divider, Paper, Stack, Typography } from "@mui/material";
import { Person } from "@mui/icons-material";

export type VeganVegetarianCardProps = {
  vegetarians: string[];
  vegans: string[];
  eggDairyVegetarians: string[];
};

export default function VeganVegetarianCard(props: VeganVegetarianCardProps) {
  const { vegetarians, vegans, eggDairyVegetarians } = props;

  return (
    <AccordianPaperSection title="Vegans and Vegetarians" icon={<IconLeaf />}>
      {vegetarians.length > 0 ? (
        <Box>
          <Divider>
            <Chip label="Vegetarians" size="small" />
          </Divider>
          <Stack direction="row" spacing={2}>
            {vegetarians?.map((mh) => (
              <Paper
                sx={{
                  p: 1,
                  borderLeftColor: "white",
                  borderTopWidth: "0px",
                  borderStyle: "solid",
                  borderRightWidth: "0px",
                  borderLeftWidth: "4px",
                  borderBottomWidth: "0",
                  mr: 1,
                  mb: 2,
                }}
                elevation={24}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Person />
                  <Typography variant="h5">{`${mh}`}</Typography>
                </Stack>
              </Paper>
            ))}
          </Stack>
        </Box>
      ) : (
        <Box />
      )}
      {eggDairyVegetarians.length > 0 ? (
        <Box>
          <Divider>
            <Chip label="Vegetarians With Egg and Dairy" size="small" />
          </Divider>
          <Stack direction="row" spacing={2}>
            {eggDairyVegetarians?.map((mh) => (
              <Paper
                sx={{
                  p: 1,
                  borderLeftColor: "white",
                  borderTopWidth: "0px",
                  borderStyle: "solid",
                  borderRightWidth: "0px",
                  borderLeftWidth: "4px",
                  borderBottomWidth: "0",
                  mr: 1,
                  mb: 2,
                }}
                elevation={24}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Person />
                  <Typography variant="h5">{`${mh}`}</Typography>
                </Stack>
              </Paper>
            ))}
          </Stack>
        </Box>
      ) : (
        <Box />
      )}
      {vegans.length > 0 ? (
        <Box>
          <Divider>
            <Chip label="Vegans" size="small" />
          </Divider>
          <Stack direction="row" spacing={2}>
            {vegans?.map((mh) => (
              <Paper
                sx={{
                  p: 1,
                  borderLeftColor: "white",
                  borderTopWidth: "0px",
                  borderStyle: "solid",
                  borderRightWidth: "0px",
                  borderLeftWidth: "4px",
                  borderBottomWidth: "0",
                  mr: 1,
                  mb: 2,
                }}
                elevation={24}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Person />
                  <Typography variant="h5">{`${mh}`}</Typography>
                </Stack>
              </Paper>
            ))}
          </Stack>
        </Box>
      ) : (
        <Box />
      )}
    </AccordianPaperSection>
  );
}
