import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { Formik } from "formik";
import { PassengerProps } from "../../../types/PassengerTypes";
import {
  GetDivingCertificationType,
  GetSwimmingLevel,
} from "../../../utilities/tripUtils";

export default function AquaticProficiency(props: PassengerProps) {
  const { passenger } = props;
  return passenger.passengerAquaticProficiency !== undefined ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 4,
            borderTopColor: "#b47f49",
            borderTopWidth: "4px",
            borderStyle: "solid",
            borderRightWidth: "0px",
            borderLeftWidth: "0px",
            borderBottomWidth: "0",
          }}
          elevation={12}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4">
                Swimming and Diving Proficiency
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Formik
                initialValues={passenger.passengerAquaticProficiency}
                onSubmit={() => {}}
              >
                {({
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="swimmingLevel"
                          name="swimmingLevel"
                          label={`Swimming Level`}
                          value={GetSwimmingLevel(values.swimmingLevel)}
                          onChange={handleChange}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{ shrink: true }}
                          onBlur={handleBlur}
                          error={
                            touched.swimmingLevel &&
                            Boolean(errors.swimmingLevel)
                          }
                          helperText={
                            touched.swimmingLevel && errors.swimmingLevel
                          }
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={values.isCertifiedDiver} />
                          }
                          disabled
                          label="Are you a certified diver?"
                          name="isCertifiedDiver"
                          onChange={handleChange}
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      {values.isCertifiedDiver ? (
                        <Grid item xs={12}>
                          <TextField
                            id="divingCertificationType"
                            name="divingCertificationType"
                            label={`Diving Certification Type  Level`}
                            value={GetDivingCertificationType(
                              values.divingCertificationType
                            )}
                            onChange={handleChange}
                            InputProps={{
                              readOnly: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onBlur={handleBlur}
                            error={
                              touched.divingCertificationType &&
                              Boolean(errors.divingCertificationType)
                            }
                            helperText={
                              touched.divingCertificationType &&
                              errors.divingCertificationType
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        </Grid>
                      ) : (
                        <Box />
                      )}
                      <Grid item xs={12} md={6}>
                        {values.isCertifiedDiver ? (
                          <TextField
                            id="certificationNumber"
                            name="certificationNumber"
                            label={`Certification Number`}
                            value={values.certificationNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              readOnly: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                            error={
                              touched.certificationNumber &&
                              Boolean(errors.certificationNumber)
                            }
                            helperText={
                              touched.certificationNumber &&
                              errors.certificationNumber
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        ) : (
                          <Box />
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {values.isCertifiedDiver ? (
                          <TextField
                            id="lastDiveDetails"
                            name="lastDiveDetails"
                            label={`When and where was the last time you went on a dive?`}
                            value={values.lastDiveDetails}
                            onChange={handleChange}
                            InputProps={{
                              readOnly: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onBlur={handleBlur}
                            error={
                              touched.lastDiveDetails &&
                              Boolean(errors.lastDiveDetails)
                            }
                            helperText={
                              touched.lastDiveDetails && errors.lastDiveDetails
                            }
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                        ) : (
                          <Box />
                        )}
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  ) : (
    <Box />
  );
}
