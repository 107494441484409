import axios from "axios";
import { Invitation, InvitationCreateDTO } from "../types/Invitation";
import { Role } from "../types/Role";

export const getInvites = () => {
  return axios
    .get<Invitation[]>("https://api.zsail.io/Invites")
    .then((response) => {
      return response.data;
    });
};

export const getPendingInvites = (clientId?: string) => {
  return axios
    .get<Invitation[]>(`https://api.zsail.io/Invites/pending?clientId=${clientId}`)
    .then((response) => {
      return response.data;
    });
};

export const extendUserInvite = (inviteId: string, clientId?: string) => {
  return axios
    .post<Invitation>(
      `https://api.zsail.io/Invites/extend/${inviteId}?clientId=${clientId}`,
      null
    )
    .then((response) => {
      return response.data;
    });
};

export const acceptInvites = (inviteId: string) => {
  return axios
    .post<Invitation>(`https://api.zsail.io/Invites/accept/${inviteId}`, null)
    .then((response) => {
      return response.data;
    });
};

export const getRoles = () => {
  return axios
    .get<Role[]>("https://api.zsail.io/Invites/availableroles")
    .then((response) => {
      return response.data;
    });
};

export const createInvite = (invite: InvitationCreateDTO) => {
  return axios.post("https://api.zsail.io/Invites", invite).then((response) => {
    return response.data;
  });
};
