import {Grid, Paper, Typography } from "@mui/material";
import { ReactNode } from "react";

export type PaperTileProps = {
  title: string | undefined;
  icon?: ReactNode;
  button?: ReactNode;
  elevation?: number;
};

export default function PaperTile(props: PaperTileProps) {
  const { icon, title, button, elevation } = props;
  return (
    <Paper
      sx={{
        p: 2,
        borderTopColor: "#b47f49",
        borderTopWidth: "4px",
        borderStyle: "solid",
        borderRightWidth: "0",
        borderLeftWidth: "0",
        borderBottomWidth: "0",
      }}
      elevation={elevation}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <Typography variant="h5">{icon} {title}</Typography>
        </Grid>
        <Grid item xs={12} md={2} textAlign="end">
          {button}
        </Grid>
      </Grid>
    </Paper>
  );
}
