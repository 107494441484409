export function getTodoListStatus(status?: number) {
  switch (status) {
    case 0:
      return "In Draft";
    case 1:
      return "In Progress";
    case 2:
      return "Completed";
    case 3:
      return "Archived";
    case 4:
      return "In Review";
  }
}

export function getTodoListItemStatus(status?: number) {
  switch (status) {
    case 0:
      return "To Do";
    case 1:
      return "In Progress";
    case 2:
      return "Done";
    case 3:
      return "Skipped";
  }
}