import { IconBeach } from "@tabler/icons-react";
import AccordianPaperSection from "../AccordianPaperSection";
import { BarChart } from "@mui/x-charts";
import { Box } from "@mui/material";

export type TripActivitySummaryCardProps  = {
  data: number[];
  labels: string[];
}

export default function TripActivitySummaryCard(props: TripActivitySummaryCardProps) {
  const{data, labels} = props;

  return (
    <AccordianPaperSection icon={<IconBeach />} title="Activities">
      {data !== undefined && labels.length > 0 ? (
        <BarChart
          height={368}
          series={[{ data: data, label: "Votes", id: "pvId" }]}
          xAxis={[
            {
              data: labels,
              scaleType: "band",
              tickLabelStyle: {
                angle: -25,
                textAnchor: "end",
                fontSize: 12,
              },
            },
          ]}
        />
      ) : (
        <Box />
      )}
    </AccordianPaperSection>
  );
}
