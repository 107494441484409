import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { TripPassenger } from "../../types/Trip";
import { DirectionsBoat, Map, Person, Route } from "@mui/icons-material";
import { getTripPassenger } from "../../services/tripservice";
import ContactInfo from "../../components/Trip/Passenger/ContactInfo";
import TravelInfo from "../../components/Trip/Passenger/TravelInfo";
import MedicalInfo from "../../components/Trip/Passenger/MedicalInfo";
import AquaticProficiency from "../../components/Trip/Passenger/AquaticInfo";
import Activities from "../../components/Trip/Passenger/Activities";
import DrinkPreferences from "../../components/Trip/Passenger/DrinkPreferences";
import FoodPreferences from "../../components/Trip/Passenger/FoodInfo";
import LinearProgressWithLabel from "../../components/Progress/LinearProgressWithLabel";

export default function PassengerSummary() {
  const navigate = useNavigate();
  const params = useParams();
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [value, setValue] = useState(0);
  const [passenger, setPassenger] = useState<TripPassenger>();

  useEffect(() => {
    getTripPassenger(
      selectedClient.id,
      selectedShip.id,
      params.tripid,
      params.passengerid
    ).then((res) => {
      setPassenger(res);
    });
  }, [selectedClient, selectedShip, params.tripid, params.passengerid]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${selectedShip.id}/trips`)}
          startIcon={<Map sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Trips
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() =>
            navigate(`/ships/${selectedShip.id}/trips/${params.tripid}`)
          }
          startIcon={<Route sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Trip
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Person sx={{ mr: 0.5 }} fontSize="inherit" />
          {passenger?.firstName} {passenger?.lastName}
        </Typography>
      </Breadcrumbs>
      {passenger !== undefined &&
      passenger.firstName !== undefined &&
      passenger.lastName !== undefined &&
      passenger.onBoardingStatus !== undefined ? (
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4">
                {passenger.firstName} {passenger.lastName} Responses
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <LinearProgressWithLabel
                color="secondary"
                value={
                  passenger.onBoardingStatus !== undefined
                    ? (passenger?.onBoardingStatus / 7) * 100
                    : 0
                }
              />
            </Grid>
            <Grid item xs={12} sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                visibleScrollbar
                onChange={handleChange}
                aria-label="basic tabs example"
                allowScrollButtonsMobile
                sx={{
                  "& .MuiTabs-flexContainer": {
                    flexWrap: "wrap",
                  },
                }}
              >
                <Tab label="Contact" {...a11yProps(0)} />
                {passenger.onBoardingStatus > 1 ? (
                  <Tab label="Travel" {...a11yProps(1)} />
                ) : (
                  <Tab label="Travel" {...a11yProps(1)} disabled />
                )}
                {passenger.onBoardingStatus > 2 ? (
                  <Tab label="Medical" {...a11yProps(2)} />
                ) : (
                  <Tab label="Medical" {...a11yProps(2)} disabled />
                )}
                {passenger.onBoardingStatus > 3 ? (
                  <Tab label="Aquatic" {...a11yProps(3)} />
                ) : (
                  <Tab label="Aquatic" {...a11yProps(3)} disabled />
                )}
                {passenger.onBoardingStatus > 4 ? (
                  <Tab label="Activities" {...a11yProps(4)} />
                ) : (
                  <Tab label="Activities" {...a11yProps(4)} disabled />
                )}
                {passenger.onBoardingStatus > 5 ? (
                  <Tab label="Drinks" {...a11yProps(5)} />
                ) : (
                  <Tab label="Drinks" {...a11yProps(5)} disabled />
                )}
                {passenger.onBoardingStatus > 6 ? (
                  <Tab label="Food" {...a11yProps(6)} />
                ) : (
                  <Tab label="Food" {...a11yProps(6)} disabled />
                )}
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              <CustomTabPanel value={value} index={0}>
                <ContactInfo passenger={passenger} />
              </CustomTabPanel>
            </Grid>
            {passenger.onBoardingStatus > 1 ? (
              <Grid item xs={12}>
                <CustomTabPanel value={value} index={1}>
                  <TravelInfo passenger={passenger} />
                </CustomTabPanel>
              </Grid>
            ) : (
              <Box />
            )}

            {passenger.onBoardingStatus > 2 ? (
              <Grid item xs={12}>
                <CustomTabPanel value={value} index={2}>
                  <MedicalInfo passenger={passenger} />
                </CustomTabPanel>
              </Grid>
            ) : (
              <Box />
            )}
            {passenger.onBoardingStatus > 3 ? (
              <Grid item xs={12}>
                <CustomTabPanel value={value} index={3}>
                  <AquaticProficiency passenger={passenger} />
                </CustomTabPanel>
              </Grid>
            ) : (
              <Box />
            )}
            {passenger.onBoardingStatus > 4 ? (
              <Grid item xs={12}>
                <CustomTabPanel value={value} index={4}>
                  <Activities passenger={passenger} />
                </CustomTabPanel>
              </Grid>
            ) : (
              <Box />
            )}
            {passenger.onBoardingStatus > 5 ? (
              <Grid item xs={12}>
                <CustomTabPanel value={value} index={5}>
                  <DrinkPreferences passenger={passenger} />
                </CustomTabPanel>
              </Grid>
            ) : (
              <Box />
            )}
            {passenger.onBoardingStatus > 6 ? (
              <Grid item xs={12}>
                <CustomTabPanel value={value} index={6}>
                  <FoodPreferences passenger={passenger} />
                </CustomTabPanel>
              </Grid>
            ) : (
              <Box />
            )}
          </Grid>
        </Box>
      ) : (
        <Box />
      )}
    </Container>
  );
}
