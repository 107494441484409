import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { ReactNode, useState } from "react";

export type AccordianPaperSectionProps = {
  children: ReactNode[] | React.ReactNode;
  title?: string | undefined;
  useHeader?: boolean;
  header?: ReactNode;
  icon?: ReactNode;
  button?: ReactNode;
  collapsed?: boolean;
  collapsedText?: string;
  isStatic?: boolean;
  elevation?: number;
};

export default function AccordianPaperSection(
  props: AccordianPaperSectionProps
) {
  const {
    icon,
    header,
    useHeader,
    collapsed,
    isStatic,
    children,
    title,
    elevation,
    button,
    collapsedText,
  } = props;
  const [open, setOpen] = useState(!collapsed);
  return (
    <Paper
      elevation={elevation}
      sx={{
        p: 2,
        borderTopColor: "#b47f49",
        borderTopWidth: "4px",
        borderStyle: "solid",
        borderRightWidth: "0",
        borderLeftWidth: "0",
        borderBottomWidth: "0",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          {useHeader ? (
            header
          ) : (
            <Typography variant="h4">
              {icon} {title}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={2} textAlign="end">
          {button}
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mb: 2 }} />
        </Grid>
        {open ? (
          <Grid item xs={12}>
            {children}
          </Grid>
        ) : (
          <Box />
        )}
        {!isStatic ? (
          open ? (
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => setOpen(!open)}
                startIcon={<ArrowUpward />}
              >
                {collapsedText}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => setOpen(!open)}
                startIcon={<ArrowDownward />}
              >
                {collapsedText}
              </Button>
            </Grid>
          )
        ) : (
          <Box />
        )}
      </Grid>
    </Paper>
  );
}
