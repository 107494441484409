import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";

import { IconBeach, IconPawFilled, IconSwimming } from "@tabler/icons-react";
import { PassengerProps } from "../../../types/PassengerTypes";

export default function Activities(props: PassengerProps) {
  const { passenger } = props;
  return passenger.passengerActivityInformation !== undefined ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 4,
            borderTopColor: "#b47f49",
            borderTopWidth: "4px",
            borderStyle: "solid",
            borderRightWidth: "0px",
            borderLeftWidth: "0px",
            borderBottomWidth: "0",
          }}
          elevation={12}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4">Activities of Interest</Typography>
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Formik
                initialValues={passenger.passengerActivityInformation}
                onSubmit={() => {}}
              >
                {({
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          <IconPawFilled /> Wildlife
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel id="wildlifelabel">
                          Your Response to a wildlife sighting
                        </InputLabel>
                        <Select
                          labelId="wildlifelabel"
                          id="isWildlifeSighting"
                          required
                          readOnly
                          value={values.isWildlifeSighting}
                          onChange={(e) => {
                            setFieldValue("isWildlifeSighting", e.target.value);
                          }}
                          fullWidth
                          sx={{ mb: 2 }}
                        >
                          <MenuItem value={1}>
                            {
                              "Wake me up, I am dying to see wildlife and I can sleep later!"
                            }
                          </MenuItem>
                          <MenuItem value={0}>
                            {
                              "I’m on vacation to sleep, I’ll see wildlife if I’m around."
                            }
                          </MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Checkbox checked={values.isWildlifeBird} />}
                          label="Birds (Seahawks, Finches, Puffins, Pelicans, Penguins, etc)"
                          name="isWildlifeBird"
                          disabled
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Checkbox checked={values.isWildlifeFish} />}
                          label="Fish (Tuna, Barracudas, Schools of Fish, etc)"
                          name="isWildlifeFish"
                          disabled
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={values.isWildlifeSeaMammals} />
                          }
                          disabled
                          label="Sea Mammals (Seals, Walruses, Otters, Whales, Dolphins, etc)"
                          name="isWildlifeSeaMammals"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.isWildlifeAmphibiansAndLizards}
                            />
                          }
                          disabled
                          label="Amphibians and Lizards (Snakes, Turtles, etc)"
                          name="isWildlifeAmphibiansAndLizards"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="wildlifeOther"
                          name="wildlifeOther"
                          label={`Other`}
                          value={values.wildlifeOther}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{shrink: true,}}
                          error={
                            touched.wildlifeOther &&
                            Boolean(errors.wildlifeOther)
                          }
                          helperText={
                            touched.wildlifeOther && errors.wildlifeOther
                          }
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          Tell us what other activities you are interested in:
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          <IconSwimming /> Water Activities
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={values.isWaterBeachCombing} />
                          }
                          label="Beach Combing"
                          name="isWaterBeachCombing"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={<Checkbox checked={values.isWaterFishing} />}
                          disabled
                          label="Fishing*"
                          name="isWaterFishing"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={<Checkbox checked={values.isWaterSailing} />}
                          disabled
                          label="Sailing"
                          name="isWaterSailing"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={values.isWaterScubaDiving} />
                          }
                          disabled
                          label="Scuba Diving**"
                          name="isWaterScubaDiving"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={values.isWaterSnorkeling} />
                          }
                          disabled
                          label="Snorkeling"
                          name="isWaterSnorkeling"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={values.isWaterSunbathing} />
                          }
                          disabled

                          label="Sunbathing"
                          name="isWaterSunbathing"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={<Checkbox checked={values.isWaterSurfing} />}
                          disabled
                          label="Surfing"
                          name="isWaterSurfing"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={values.isWaterSwimming} />
                          }
                          disabled
                          label="Swimming"
                          name="isWaterSwimming"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body2">
                          {"*Fishing License may be required in some locations"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body2">
                          {
                            "**The ship has its own Scuba Equipment and Diving will be conducted on board if available or we will arrange rendezvous diving if necessary."
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          <IconBeach /> Island Activities
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={<Checkbox checked={values.isIslandBars} />}
                          label="Bars"
                          disabled
                          name="isIslandBars"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={values.isIslandCycling} />
                          }
                          disabled
                          label="Cycling"
                          name="isIslandCycling"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={<Checkbox checked={values.isIslandDining} />}
                          disabled
                          label="Dining Ashore"
                          name="isIslandDining"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={<Checkbox checked={values.isIslandGolf} />}
                          label="Golf*"
                          disabled
                          name="isIslandGolf"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={<Checkbox checked={values.isIslandHiking} />}
                          label="Hiking"
                          disabled
                          name="isIslandHiking"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={<Checkbox checked={values.isIslandTours} />}
                          label="Island Tours"
                          disabled
                          name="isIslandTours"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={values.isIslandKaraoke} />
                          }
                          disabled
                          label="Karaoke"
                          name="isIslandKaraoke"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.isIslandMuseumsHeritage}
                            />
                          }
                          disabled
                          label="Museums and Cultural Heritage Sites"
                          name="isIslandMuseumsHeritage"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={values.isIslandMusicDancing} />
                          }
                          disabled
                          label="Music/Dancing"
                          name="isIslandMusicDancing"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={values.isIslandNightClubs} />
                          }
                          disabled
                          label="Night Clubs"
                          name="isIslandNightClubs"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={values.isIslandShopping} />
                          }
                          disabled
                          label="Shopping"
                          name="isIslandShopping"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={values.isIslandSportingEvents} />
                          }
                          disabled
                          label="Sporting Events"
                          name="isIslandSportingEvents"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body2">
                          {
                            "*We would check availability of location and dates."
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="passengerEquipment"
                          name="passengerEquipment"
                          label={`Do you plan on bringing any specialized equipment for activities during your trip?`}
                          value={values.passengerEquipment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{shrink: true,}}
                          error={
                            touched.passengerEquipment &&
                            Boolean(errors.passengerEquipment)
                          }
                          helperText={
                            touched.passengerEquipment &&
                            errors.passengerEquipment
                          }
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  ) : (
    <Box />
  );
}
