import { Tooltip } from "@mui/material";
import {
  IconSquareChevronDownFilled,
  IconSquareChevronsUpFilled,
  IconSquareMinusFilled,
} from "@tabler/icons-react";

export type PriorityIconProps = {
  priority?: number;
};

export default function PriorityIcon(props: PriorityIconProps) {
  const { priority } = props;

  switch (priority) {
    case 0:
      return (
        <Tooltip title="Low">
          <IconSquareChevronDownFilled style={{color: "green"}} />
        </Tooltip>
      );
    case 1:
      return (
        <Tooltip title="Normal">
          <IconSquareMinusFilled />
        </Tooltip>
      );
    case 2:
      return (
        <Tooltip title="High">
          <IconSquareChevronsUpFilled style={{color: "red"}}/>
        </Tooltip>
      );
    default:
      return (
        <Tooltip title="Normal">
          <IconSquareMinusFilled />
        </Tooltip>
      );
  }
}
