import { Anchor, Sailing } from "@mui/icons-material";
import { IconBuildingLighthouse, IconSpeedboat } from "@tabler/icons-react";

export type TripCardIconProps = {
    type: number | undefined
    iconSize: number;
}

export default function TripCardIcon(props: TripCardIconProps){
    const {type, iconSize} = props;
    switch(type) {
        case 0:
            return <IconBuildingLighthouse size={iconSize}/>;
        case 1:
            return <IconSpeedboat size={iconSize}/>;
        case 2:
            return <Sailing sx={{fontSize: iconSize}}/>;
        case 3:
            return <Anchor sx={{fontSize: iconSize}}/>;
        default:
            return <IconBuildingLighthouse size={iconSize}/>;
    }
}