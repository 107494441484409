import { Grid, Typography } from "@mui/material";
import { ReactNode } from "react";

export type PassengerDetailItemProps = {
  title: string | undefined;
  value: string | undefined;
  actionButton?: ReactNode;
};

export default function PassengerDetailItem(props: PassengerDetailItemProps) {
  const { title, value, actionButton } = props;
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12} md={3}>
        <Typography
          variant="body1"
          sx={{ fontWeight: "bold" }}
        >{`${title}:`}</Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography
          variant="body1"
        >{`${value}`}{actionButton}</Typography>
      </Grid>
    </Grid>
  );
}
