import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  createPartsLog,
  getPartById,
  getShip,
} from "../../services/shipService";
import { useNavigate, useParams } from "react-router-dom";
import { Ship } from "../../types/Ship";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import * as yup from "yup";
import { Formik } from "formik";
import { Part } from "../../types/Part";
import { PartLog } from "../../types/PartLog";
import {
  Add,
  Category,
  DirectionsBoat,
} from "@mui/icons-material";
import { Editor } from "@tinymce/tinymce-react";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";

export default function NewPartLog() {
  const params = useParams();
  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [part, setPart] = useState<Part>();
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [PartLogItem, setPartLogItem] = useState<PartLog>();
  const [open, setOpen] = useState(false);
  const [selectedShip] = useRecoilState(SelectedShipState);

  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
      getPartById(selectedClient.id, params.id, params.partid).then(
        (partRes) => {
          setPart(partRes);
        }
      );
    });
  }, [selectedClient, params.id, params.partid]);
  const validationSchema = yup.object({
    quantity: yup
      .number()
      .moreThan(0)
      .required("Quantity cannot be lower than zero."),
  });
  const initialState = {
    quantity: part?.quantity,
    notes: "",
  };
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Alert variant="outlined" severity="error">
            New Quantity: {PartLogItem?.quantity} is less than the minimum
            inventory: {part?.minimumInventory}.
          </Alert>
          <Stack direction="row-reverse" spacing={2} sx={{ mt: 4 }}>
            <Button
              variant="outlined"
              onClick={() => {
                var pl: PartLog = {
                  shipPartId: PartLogItem?.shipPartId,
                  quantity: PartLogItem?.quantity,
                  notes: PartLogItem?.notes,
                };
                createPartsLog(pl, selectedClient.id, ship?.id).then(
                  (response) => {
                    navigate(`/Ships/${ship?.id}/parts/${part?.id}`);
                  }
                );
              }}
            >
              Proceed
            </Button>
            <Button variant="contained" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}/parts`)}
          startIcon={<Category sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Parts
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}/parts/${part?.id}`)}
          startIcon={<Category sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {part?.name}
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Add sx={{ mr: 0.5 }} fontSize="inherit" />
          Update Inventory
        </Typography>
      </Breadcrumbs>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Current Quantity: {`${part?.quantity}`}
      </Typography>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Minimum Inventory: {`${part?.minimumInventory}`}
      </Typography>
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          var partLog: PartLog = {
            shipPartId: part?.id,
            quantity: values.quantity,
            notes: values.notes,
          };
          if (
            partLog?.quantity !== undefined &&
            part?.minimumInventory !== undefined &&
            partLog?.quantity < part?.minimumInventory
          ) {
            setPartLogItem(partLog);
            setOpen(true);
          } else {
            createPartsLog(partLog, selectedClient.id, ship?.id).then(
              (response) => {
                navigate(`/Ships/${ship?.id}/parts/${part?.id}`);
              }
            );
          }
        }}
      >
        {({
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              id="quantity"
              name="quantity"
              label={`Update Quantity`}
              value={values.quantity}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.quantity && Boolean(errors.quantity)}
              helperText={touched.quantity && errors.quantity}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Typography variant="subtitle1">Notes:</Typography>
            <Editor
              apiKey="s7hflnfnqq2c0nxj75udyor3kobji9x4jv2xgc4xvsdhtju5"
              init={{
                height: 500,
                menubar: true,
                skin: "oxide-dark",
                content_css: "dark",
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent| " +
                  "removeformat",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
              textareaName="notes"
              onEditorChange={(stringifiedHTML) => {
                setFieldValue("notes", stringifiedHTML);
              }}
            />
            <Button
              sx={{ mt: 2 }}
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
            >
              Submit
            </Button>
          </form>
        )}
      </Formik>
    </Container>
  );
}
