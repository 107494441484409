import { Chip, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import TodoCommentStatusHeader from "./TodoCommentStatusHeader";
import { Person } from "@mui/icons-material";
import moment from "moment";
import { ShipTodoListItemComment } from "../../types/TodoList";

export type TodoCommentProps = {
    comment: ShipTodoListItemComment,
    elevation: number | undefined,
}
export default function TodoComment(props: TodoCommentProps){
    const {elevation, comment} = props;
    return <Paper elevation={elevation} sx={{ pt: 3, pb: 3, mb: 2 }}>
    <Grid container spacing={1} sx={{ pl: 2, pr: 2 }}>
      <Grid item xs={12} md={6}>
        <TodoCommentStatusHeader todoListComment={comment} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack direction="row-reverse">
          <Chip
          icon={<Person/>}
            label={`${comment.userName} @ ${moment(comment.createdDate).format(
              "MM/DD/YYYY hh:mm A"
            )}`}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mt: 1, mb: 1 }} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{comment.comment}</Typography>
      </Grid>

    </Grid>
  </Paper>;
}