import { Icon } from "@mui/material";

export type ZSailLogoProps = {
    width: number,
    height: number,
    sx?: object,
}
export default function ZSailLogo(props: ZSailLogoProps){
    return(
            <Icon sx={props.sx}>
                <img src={require('../assets/Zsail.png')} alt="zsail logo"  width={props.width} height={props.height}/>
            </Icon>
    )
}