import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getIncidentById,
  getIncidentImageBlob,
} from "../../services/incidentService";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { useRecoilState } from "recoil";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { IncidentReport } from "../../types/IncidentReport";
import { DirectionsBoat, LocalPolice } from "@mui/icons-material";
import IncidentImagesCard from "../../components/IncidentReports/IncidentImagesCard";
import IncidentDetailsCard from "../../components/IncidentReports/IncidentDetailsCard";
import IncidentSummaryCard from "../../components/IncidentReports/IncidentSummaryCard";
import IncidentFilesCard from "../../components/IncidentReports/IncidentFilesCard";
import IncidentsCommentCard from "../../components/IncidentReports/IncidentCommentsCard";
import IncidentActionBar from "../../components/IncidentReports/IncidentActionBar";
import IncidentResolutionDetails from "../../components/IncidentReports/IncidentResolutionDetails";
import IncidentPartsCard from "../../components/IncidentReports/IncidentPartsCard";

export default function Incident() {
  const params = useParams();
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [incident, setIncident] = useState<IncidentReport>();
  const [, setImageLoading] = useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    getIncidentById(selectedClient.id, selectedShip.id, params.incidentId).then(
      (res) => {
        setIncident(res);
      }
    );
  }, [selectedClient.id, selectedShip.id, params.incidentId]);
  
  useEffect(() => {
    incident?.images?.forEach((i) => {
      getIncidentImageBlob(incident.id, i.id, selectedClient.id).then((res) => {
        setImageLoading(true);
        var reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onloadend = function () {
          var base64data = reader.result;
          var base64string = `${base64data}`;
          i.imageUrl = `data:image/png;base64,${base64string.split(",")[1]}`;
          i.loaded = true;
          setImageLoading(false);
        };
      });
    });
  }, [incident?.id, incident?.images, selectedClient.id]);

  return (
    <Container>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${selectedShip.id}/logbook`)}
          startIcon={<LocalPolice sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Logbook
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <LocalPolice sx={{ mr: 0.5 }} fontSize="inherit" />
          {incident?.title}
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign="end">
          <IncidentActionBar incident={incident} setIncident={setIncident} />
        </Grid>
        <Grid item xs={12}>
          <IncidentDetailsCard incident={incident} setIncident={setIncident} />
        </Grid>
        {incident?.status === 1 || incident?.status === 2 ? (
          <Grid item xs={12}>
            <IncidentResolutionDetails incident={incident} />
          </Grid>
        ) : (
          <Box />
        )}
        <Grid item xs={12}>
          <IncidentSummaryCard incident={incident} setIncident={setIncident} />
        </Grid>
        <Grid item xs={12}>
          <IncidentPartsCard incident={incident} setIncident={setIncident}/>
        </Grid>
        <Grid item xs={12}>
          <IncidentImagesCard setIncident={setIncident} incident={incident} />
        </Grid>
        <Grid item xs={12}>
          <IncidentFilesCard setIncident={setIncident} incident={incident} />
        </Grid>
        <Grid item xs={12}>
          <IncidentsCommentCard setIncident={setIncident} incident={incident} />
        </Grid>
      </Grid>
    </Container>
  );
}
