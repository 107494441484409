import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Ship, ShipUserRole } from "../../../types/Ship";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../../Recoil/atoms/ClientsAtom";
import { ShipTodoList, ShipTodoListItem } from "../../../types/TodoList";
import { SelectedShipState } from "../../../Recoil/atoms/ShipsAtom";
import { userManagementState } from "../../../Recoil/atoms/UserManagementAtom";
import {
  getShip,
  getShipUserRoles,
} from "../../../services/shipService";
import {
  getShipTodoList,
} from "../../../services/todoListService";
import { getClientUsers } from "../../../services/userservice";
import {
  CheckBox,
  DirectionsBoat,
  ManageSearch,
} from "@mui/icons-material";
import TodoListReviewOverview from "../../../components/TodoList/TodoListReview/TodoListReviewOverview";
import TodoListReviewUserOverview from "../../../components/TodoList/TodoListReview/TodoListReviewUserOverview";
import TodoListReviewDateOverview from "../../../components/TodoList/TodoListReview/TodoListReviewDateOverview";
import TodoListReviewAssignedUserInfo from "../../../components/TodoList/TodoListReview/TodoListReviewAssignedUserInfo";
import SendBackModal from "../../../components/TodoList/TodoListReview/SendBackModal";
import SkippedItemsReview from "../../../components/TodoList/TodoListReview/SkippedItemsReview";
import TodoListGroupOverview from "../../../components/TodoList/TodoListReview/TodoListGroupOverview";
import CompletedItemsReview from "../../../components/TodoList/TodoListReview/CompletedItemsReview";
import TodoListReviewActionButtonGroup from "../../../components/TodoList/TodoListReview/TodoListReviewActionButtonGroup";

export default function TodoListReview() {
  const params = useParams();
  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [todoList, setTodoList] = useState<ShipTodoList>();
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [roles, setRoles] = useState<ShipUserRole[]>([]);
  const [users, setUsers] = useRecoilState(userManagementState);
  const [open, setOpen] = useState(false);
  const [selectedTodoListItem, setSelectedTodoListItem] =
    useState<ShipTodoListItem>();

  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
    });
    getShipTodoList(params.todolistid, selectedClient.id).then((response) => {
      setTodoList(response);
    });
    getClientUsers(selectedClient.id).then((res) => {
      setUsers(res);
    });
    getShipUserRoles(selectedClient.id, params.id).then((res) => setRoles(res));
  }, [selectedClient, params.id, params.todolistid, setUsers]);

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <SendBackModal
        open={open}
        onClose={() => setOpen(false)}
        todoList={todoList}
        setTodoList={setTodoList}
        todoListItem={selectedTodoListItem}
        setTodoListItem={setSelectedTodoListItem}
      />
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}/todolists`)}
          startIcon={<CheckBox sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          To Do Lists
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <ManageSearch sx={{ mr: 0.5 }} fontSize="inherit" />
          {todoList?.name} Review
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item>
              <Stack direction="row" alignItems="center">
                <ManageSearch sx={{ fontSize: 64 }} />
                <Typography variant="h4">{todoList?.name} Review</Typography>
              </Stack>
            </Grid>
            <Grid item alignItems="center">
              <TodoListReviewActionButtonGroup
                selectedClientId={selectedClient.id}
                shipId={selectedShip.id}
                todoList={todoList}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {todoList?.userRoleToggle === "role" ? (
            <TodoListGroupOverview
              roles={roles}
              users={users}
              todoList={todoList}
            />
          ) : (
            <Box />
          )}
        </Grid>
        <Grid item xs={12}>
          <TodoListReviewDateOverview todoList={todoList} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TodoListReviewOverview todoList={todoList} />
        </Grid>
        {todoList?.userRoleToggle === "role" ? (
          <Grid item xs={12} sm={12} md={6}>
            <TodoListReviewUserOverview todoList={todoList} />
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={6}>
            <TodoListReviewAssignedUserInfo todoList={todoList} />
          </Grid>
        )}

        <Grid item xs={12}>
          {todoList?.items !== undefined &&
          todoList.items.filter((item) => item.status === 3).length > 0 ? (
            <SkippedItemsReview
              todoList={todoList}
              shipId={selectedShip.id}
              setOpen={setOpen}
              setSelectedTodoListItem={setSelectedTodoListItem}
            />
          ) : (
            <Box />
          )}
        </Grid>
        <Grid item xs={12}>
          <CompletedItemsReview todoList={todoList} shipId={selectedShip.id} />
        </Grid>
      </Grid>
    </Container>
  );
}
