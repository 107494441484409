import {
  Delete,
  Edit,
  ScheduleSend,
  SupervisedUserCircle,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import moment from "moment";
import { useRecoilState, useSetRecoilState } from "recoil";
import { userManagementState } from "../Recoil/atoms/UserManagementAtom";
import { ClientsState, SelectedClientState } from "../Recoil/atoms/ClientsAtom";
import { useEffect, useState } from "react";
import {
  deleteUserFromClient,
  getClientUsers,
  getUserProfile,
  updateUserRole,
} from "../services/userservice";
import { useFormik } from "formik";
import { DeleteUser } from "../types/DeleteUser";
import { userProfileState } from "../Recoil/atoms/UserProfileAtom";
import {
  createInvite,
  getInvites,
  getPendingInvites,
  getRoles,
} from "../services/invitationservice";
import { getClients } from "../services/adminservice";
import {
  InvitationsState,
  PendingInvitationsState,
} from "../Recoil/atoms/InvitationsAtom";
import { RolesState } from "../Recoil/atoms/RolesAtom";
import { UserUpdateRole } from "../types/UserUpdateRole";
import PaperSection from "../components/PaperSection";
import BillingQouta from "../components/Billing/BillingQouta";
import { InvitationCreateDTO } from "../types/Invitation";
import IsUserElevated from "../utilities/userUtils";

export default function UserManagement() {
  const [users, setUsers] = useRecoilState(userManagementState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [userProfile] = useRecoilState(userProfileState);
  const setUserProfile = useSetRecoilState(userProfileState);
  const setPendingInvites = useSetRecoilState(PendingInvitationsState);
  const setClients = useSetRecoilState(ClientsState);
  const setInvitations = useSetRecoilState(InvitationsState);
  const [roles, setRoles] = useRecoilState(RolesState);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");
  const [openUserEdit, setOpenUserEdit] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (selectedClient !== undefined) {
      getClientUsers(selectedClient.id).then((res) => {
        setUsers(res);
      });
    }
  }, [selectedClient, setUsers]);
  const formik = useFormik({
    initialValues: {
      role: "",
    },
    onSubmit: (values) => {
      var updateRole: UserUpdateRole = {
        clientId: selectedClient.id,
        userId: selectedUserId,
        newRoleId: values.role,
      };
      updateUserRole(updateRole).then((res) => {
        getUserProfile()
          .then((response) => {
            setUserProfile(response);
          })
          .then(() => {
            getInvites().then((response) => setInvitations(response));
            getClients().then((response) => setClients(response));
            getRoles().then((response) => setRoles(response));
            getClientUsers(selectedClient.id).then((res) => {
              setUsers(res);
            });
            setSelectedUserId("");
            setSelectedUserName("");
            setOpenUserEdit(false);
          });
      });
    },
  });

  const formikInvite = useFormik({
    initialValues: {
      email: "",
      role: "",
    },
    onSubmit: (values) => {
      var invite: InvitationCreateDTO = {
        clientId: selectedClient.id,
        roleId: values.role,
        emailAddress: values.email,
      };
      createInvite(invite).then((response) => {
        getUserProfile()
          .then((response) => {
            setUserProfile(response);
          })
          .then(() => {
            getInvites().then((response) => setInvitations(response));
            if (IsUserElevated(userProfile, selectedClient.id)) {
              getPendingInvites(selectedClient.id).then((response) =>
                setPendingInvites(response)
              );
            }
            getClients().then((response) => setClients(response));
            getRoles().then((response) => setRoles(response));
            values.email = "";
            values.role = "";
          });
      });
      setOpen(false);
    },
  });

  const handleRoleChange = (params: any) => () => {
    setSelectedUserId(params.row.id);
    setSelectedUserName(params.row.userName);
    formik.values.role = params.row.role.id;
    setOpenUserEdit(true);
  };

  const handleDeleteUser = (params: any) => () => {
    var deleteUser: DeleteUser = {
      clientId: selectedClient.id,
      userId: params.id,
    };
    deleteUserFromClient(deleteUser).then((response) => {
      getUserProfile()
        .then((response) => {
          setUserProfile(response);
        })
        .then(() => {
          getInvites().then((response) => setInvitations(response));
          getClients().then((response) => setClients(response));
          getRoles().then((response) => setRoles(response));
          getClientUsers(selectedClient.id).then((res) => {
            setUsers(res);
          });
        });
    });
  };
  const columns = [
    { field: "email", headerName: "Email Address", width: 300 },
    { field: "userName", headerName: "User Name", width: 300 },
    {
      field: "role",
      headerName: "Role",
      valueGetter: (params: any) => {
        return params.row.role.name;
      },
      width: 300,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      valueFormatter: (params: any) => {
        return moment(params.value).format("MM/DD/YYYY hh:mm A");
      },
      width: 300,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      width: 50,
      cellClassName: "actions",
      getActions: (params: GridRowParams): any => {
        return [
          <GridActionsCellItem
            icon={<Edit />}
            label="Change Role"
            onClick={handleRoleChange(params)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete User"
            onClick={handleDeleteUser(params)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Container maxWidth={"xl"} sx={{ mt: 4 }}>
      <Modal open={openUserEdit} onClose={() => setOpenUserEdit(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            sx={{ mb: 3 }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Update Role for {selectedUserName}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              value={formik.values.role}
              select
              name="role"
              label="Role"
              onBlur={formik.handleBlur}
              error={formik.touched.role && Boolean(formik.errors.role)}
              helperText={formik.touched.role && formik.errors.role}
              onChange={formik.handleChange}
              sx={{ mb: 2 }}
            >
              {roles.map((r) => (
                <MenuItem value={r.id} key={r.id}>
                  {r.name}
                </MenuItem>
              ))}
            </TextField>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            sx={{ mb: 3 }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Create New Invite
          </Typography>
          <form onSubmit={formikInvite.handleSubmit}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="User Email"
              value={formikInvite.values.email}
              onChange={formikInvite.handleChange}
              onBlur={formikInvite.handleBlur}
              error={
                formikInvite.touched.email && Boolean(formikInvite.errors.email)
              }
              helperText={
                formikInvite.touched.email && formikInvite.errors.email
              }
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              value={formikInvite.values.role}
              select
              name="role"
              label="Role"
              onBlur={formikInvite.handleBlur}
              error={
                formikInvite.touched.role && Boolean(formikInvite.errors.role)
              }
              helperText={formikInvite.touched.role && formikInvite.errors.role}
              onChange={formikInvite.handleChange}
              sx={{ mb: 2 }}
            >
              {roles.map((r) => (
                <MenuItem value={r.id} key={r.id}>
                  {r.name}
                </MenuItem>
              ))}
            </TextField>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <SupervisedUserCircle sx={{ mr: 0.5 }} fontSize="inherit" />
          User Management
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {selectedClient.billingTier === 0 ? (
            <BillingQouta
              metric={users.length}
              maxMetric={5}
              metricName={"user"}
              displayMetricName={false}
              pluralMetricName={"users"}
              costPerAdditional={15}
            />
          ) : (
            <Box />
          )}
        </Grid>
        <Grid item xs={12}>
          <PaperSection
            title="User Management"
            icon={<SupervisedUserCircle />}
            button={
              <Button variant="contained" endIcon={<ScheduleSend />} onClick={() => setOpen(true)}>
                New Invite
              </Button>
            }
          >
            <DataGrid
              sx={{ height: "80%", minHeight: "500px" }}
              columns={columns}
              rows={users}
            />
          </PaperSection>
        </Grid>
      </Grid>
    </Container>
  );
}
