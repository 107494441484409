import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getEquipment, getShip } from "../../services/shipService";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { Ship } from "../../types/Ship";
import {
  Add,
  Construction,
  DirectionsBoat,
  Error,
  NoteAdd,
  Warning,
} from "@mui/icons-material";
import { DataGrid, GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import { Equipment } from "../../types/Equipment";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import moment from "moment";
import PaperSection from "../../components/PaperSection";
import BillingQouta from "../../components/Billing/BillingQouta";

export default function EquipmentHome() {
  const params = useParams();
  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [equipment, setEquipment] = useState<Equipment[]>([]);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [selectedShip] = useRecoilState(SelectedShipState);
  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
      getEquipment(selectedClient.id, params.id).then((response) => {
        setEquipment(response);
      });
    });
  }, [selectedClient, params.id]);
  const columns = [
    {
      field: "name",
      headerName: "Name",
      valueGetter: (params: any) => {
        return params.row.name;
      },
      width: 150,
    },
    {
      field: "serialNumber",
      headerName: "Serial Number",
      valueGetter: (params: any) => {
        return params.row.serialNumber;
      },

      width: 150,
    },
    {
      field: "actualUsageMeter",
      headerName: "Actual Usage",
      valueGetter: (params: any) => {
        return params.row.actualUsageMeter;
      },

      width: 150,
    },
    {
      field: "usageUnits",
      headerName: "Usage Units",
      valueGetter: (params: any) => {
        return params.row.usageUnits;
      },

      width: 150,
    },
    {
      field: "updateFrequency",
      headerName: "Update Frequency",
      valueGetter: (params: any) => {
        return params.row.updateFrequency;
      },

      width: 150,
    },
    {
      field: "daysSinceLastUpdate",
      headerName: "Days since last update",
      width: 150,
      valueGetter: (params: any) => {
        if (params.row.daysSinceLastUpdate > 1000) {
          return "No Logs";
        }
        return params.row.daysSinceLastUpdate;
      },
    },
    {
      field: "warrantyExpiry",
      headerName: "Warranty Expiration Date",
      width: 150,
      valueGetter: (params: any) => {
        if (params.row.warrantyExpiry === null) {
          return "N/A";
        }
        return moment(params.row.warrantyExpiry).format("MM/DD/YYYY");
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Log",
      flex: 1,
      width: 25,
      cellClassName: "actions",
      getActions: (params: GridRowParams): any => {
        if (params.row.daysSinceLastUpdate >= params.row.updateFrequency) {
          return [
            <GridActionsCellItem
              icon={<Error sx={{ color: "red" }} />}
              label="Log Usage"
              onClick={() => {
                navigate(
                  `/Ships/${ship?.id}/equipment/${params.row.id}/newlog`
                );
              }}
              color="inherit"
            />,
          ];
        } else if (
          params.row.daysSinceLastUpdate / params.row.updateFrequency >=
          0.75
        ) {
          return [
            <GridActionsCellItem
              icon={<Warning sx={{ color: "yellow" }} />}
              label="Log Usage"
              onClick={() => {
                navigate(
                  `/Ships/${ship?.id}/equipment/${params.row.id}/newlog`
                );
              }}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<NoteAdd />}
            label="Log Usage"
            onClick={() => {
              navigate(`/Ships/${ship?.id}/equipment/${params.row.id}/newlog`);
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Construction sx={{ mr: 0.5 }} fontSize="inherit" />
          Equipment
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        {selectedClient.billingTier === 0 ? (
          <Grid item xs={12}>
            <BillingQouta
              metric={equipment.length}
              maxMetric={10}
              displayMetricName={false}
              metricName={"equipment"}
              pluralMetricName={"equipment"}
              costPerAdditional={5}
            />
          </Grid>
        ) : (
          <Box />
        )}
        <Grid item xs={12}>
          <PaperSection
            icon={<Construction />}
            title="Equipment"
            button={
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate(`/Ships/${ship?.id}/equipment/newequipment`);
                }}
                startIcon={<Add />}
              >
                New Equipment
              </Button>
            }
          >
            <DataGrid
              sx={{ height: "80%", minHeight: "500px" }}
              onRowClick={(value) =>
                navigate(`/Ships/${ship?.id}/equipment/${value.id}`)
              }
              columns={columns}
              rows={equipment}
            />
          </PaperSection>
        </Grid>
      </Grid>
    </Container>
  );
}
