import {
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import ZSailLogo from "../ZSailLogo";
import React, { useEffect, useState } from "react";
import {
  AdminPanelSettings,
  ArrowDropDown,
  AutoAwesome,
  Category,
  CheckBox,
  Checklist,
  Construction,
  Dashboard,
  DirectionsBoat,
  LocalPolice,
  Logout,
  Mail,
  Map,
  Menu as MenuIcon,
  MonetizationOn,
  Person,
  Sailing,
  Security,
  Settings,
  SupervisedUserCircle,
  Support,
  TaskAlt,
} from "@mui/icons-material";
import { SelectedShipState, ShipsState } from "../../Recoil/atoms/ShipsAtom";
import { useRecoilState } from "recoil";
import {
  ClientsState,
  SelectedClientState,
} from "../../Recoil/atoms/ClientsAtom";
import { userProfileState } from "../../Recoil/atoms/UserProfileAtom";
import { InvitationsState } from "../../Recoil/atoms/InvitationsAtom";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import IsUserElevated from "../../utilities/userUtils";
export default function ShipAppBar() {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElShips, setAnchorElShips] = useState<null | HTMLElement>(null);
  const { logout } = useAuth0();
  const [invitations] = useRecoilState(InvitationsState);
  const [userProfile] = useRecoilState(userProfileState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [selectedShip, setSelelectedShip] = useRecoilState(SelectedShipState);
  const [clients] = useRecoilState(ClientsState);
  const [ships] = useRecoilState(ShipsState);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenShipsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElShips(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseShipsMenu = () => {
    setAnchorElShips(null);
  };

  useEffect(() => {
    if (
      ships.length > 0 &&
      (selectedShip === undefined || selectedShip.id === "")
    ) {
      var prefs = userProfile.preferences?.filter(
        (pref) => pref.clientId === selectedClient.id
      );
      if (prefs === undefined || prefs?.length < 1) {
        setSelelectedShip(ships[0]);
      } else {
        var prefShipId = userProfile.preferences?.filter(
          (pref) => pref.clientId === selectedClient.id
        )[0].preferredShipId;
        if (prefShipId !== undefined) {
          setSelelectedShip(ships.filter((s) => s.id === prefShipId)[0]);
        } else {
          setSelelectedShip(ships[0]);
        }
      }
    }
  }, [
    ships,
    userProfile,
    selectedClient.id,
    setSelelectedShip,
    selectedShip,
    selectedShip.id,
  ]);

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <ZSailLogo
            sx={{ display: { xs: "none", md: "none", lg: "flex" }, mr: 1 }}
            height={30}
            width={30}
          />
          <Stack direction="column">
            <Typography
              variant="h5"
              noWrap
              fontFamily="Baskervville"
              sx={{
                mr: 0.5,
                display: { xs: "none", md: "none", lg: "flex" },
                color: "inherit",
                textDecoration: "none",
              }}
            >
              ZeaFarer
            </Typography>
            {/* <Typography
              variant="caption"
              fontFamily="Baskervville"
              noWrap
              sx={{
                mr: 0.5,
                display: { xs: "none", md: "none", lg: "flex" },
                color: "inherit",
                textDecoration: "none",
              }}
            >
              Ship Manager
            </Typography> */}
          </Stack>
          {clients.length > 0 ? (
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "flex", lg: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "block", lg: "none" },
                }}
              >
                <MenuItem
                  key="194-dashboard"
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate(`/`);
                  }}
                >
                  <DirectionsBoat sx={{ mr: 2 }} /> {selectedShip.name}
                </MenuItem>
                {selectedClient.billingTier !== 0 ? (
                  <MenuItem
                    key="194234-trips"
                    onClick={() => {
                      handleCloseNavMenu();
                      navigate(`/ships/${selectedShip.id}/trips`);
                    }}
                  >
                    <Map sx={{ mr: 2 }} />
                    Trips
                  </MenuItem>
                ) : (
                  <Box />
                )}

                {IsUserElevated(userProfile, selectedClient.id) ? (
                  <MenuItem
                    key="194234"
                    onClick={() => {
                      handleCloseNavMenu();
                      navigate(`/ships/${selectedShip.id}/todolists`);
                    }}
                  >
                    <Checklist sx={{ mr: 2 }} /> Todo Lists
                  </MenuItem>
                ) : (
                  <Box />
                )}

                <MenuItem
                  key="19423433"
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate(`/ships/${selectedShip.id}`);
                  }}
                >
                  <TaskAlt sx={{ mr: 2 }} /> Tasks
                </MenuItem>
                <MenuItem
                  key="194277433"
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate(`/ships/${selectedShip.id}/equipment`);
                  }}
                >
                  <Construction sx={{ mr: 2 }} /> Equipment
                </MenuItem>
                <MenuItem
                  key="190077433"
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate(`/ships/${selectedShip.id}/parts`);
                  }}
                >
                  <Category sx={{ mr: 2 }} /> Inventory
                </MenuItem>
                <MenuItem
                  key="199-mylists"
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate(`/mylists`);
                  }}
                >
                  <CheckBox sx={{ mr: 2 }} /> My Work
                </MenuItem>
                <MenuItem
                  key="incident0-1828"
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate(`/ships/${selectedShip.id}/logbook`);
                  }}
                >
                  <LocalPolice sx={{ mr: 2 }} /> Logbook
                </MenuItem>
                {ships !== undefined && ships.length > 0 ? (
                  <Box>
                    <Divider />
                    <Typography>Fleet</Typography>
                    <Divider />
                    {ships.map((ship) => {
                      return (
                        <MenuItem
                          key={`nav-${ship.id}`}
                          onClick={() => {
                            handleCloseNavMenu();
                            setSelelectedShip(ship);
                            navigate(`/`);
                          }}
                        >
                          <DirectionsBoat sx={{ mr: 2 }} /> {ship.name}
                        </MenuItem>
                      );
                    })}
                    <Divider />
                  </Box>
                ) : (
                  <Box />
                )}
              </Menu>
            </Box>
          ) : (
            <Box />
          )}

          <ZSailLogo
            sx={{ display: { xs: "flex", md: "flex", lg: "none" }, mr: 1 }}
            height={24}
            width={24}
          />
          <Typography
            variant="h5"
            noWrap
            fontFamily="Baskervville"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "flex", lg: "none" },
              flexGrow: 1,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            ZeaFarer
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "none", lg: "flex" },
            }}
          >
            {ships !== undefined && ships.length > 0 ? (
              <Box>
                <Button
                  key="1-selectedShip"
                  startIcon={<DirectionsBoat />}
                  onClick={() => navigate(`/`)}
                  sx={{ my: 2, color: "white" }}
                >
                  {selectedShip.name}
                </Button>
                {ships.length > 1 ? (
                  <Button
                    size="small"
                    onClick={handleOpenShipsMenu}
                    sx={{ my: 2, color: "white" }}
                  >
                    <ArrowDropDown sx={{ m: 0, p: -2 }} />
                  </Button>
                ) : (
                  <Box />
                )}

                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElShips}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElShips)}
                  onClose={handleCloseShipsMenu}
                >
                  {ships.map((ship) => {
                    return (
                      <MenuItem
                        key={ship.id}
                        onClick={() => {
                          handleCloseShipsMenu();
                          setSelelectedShip(ship);
                          navigate(`/`);
                        }}
                      >
                        <DirectionsBoat sx={{ mr: 2 }} /> {ship.name}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </Box>
            ) : (
              <Button
                key="22"
                sx={{ my: 2, color: "white" }}
                onClick={() => navigate("/")}
                startIcon={<Dashboard />}
              >
                Dashboard
              </Button>
            )}
            {ships !== undefined && ships.length > 0 ? (
              <Box>
                {selectedClient.billingTier !== 0 ? (
                  <Button
                    key="3038811994"
                    sx={{ my: 2, color: "white" }}
                    onClick={() => navigate(`/ships/${selectedShip.id}/trips`)}
                    startIcon={<Map />}
                  >
                    Trips
                  </Button>
                ) : (
                  <Box />
                )}

                {IsUserElevated(userProfile, selectedClient.id) ? (
                  <Button
                    key="1-selectedShip-todolists"
                    sx={{ my: 2, color: "white" }}
                    startIcon={<Checklist />}
                    onClick={() =>
                      navigate(`/ships/${selectedShip.id}/todolists`)
                    }
                  >
                    To Do Lists
                  </Button>
                ) : (
                  <Box />
                )}
                <Button
                  key="399033347"
                  sx={{ my: 2, color: "white" }}
                  onClick={() => navigate(`/ships/${selectedShip.id}`)}
                  startIcon={<TaskAlt />}
                >
                  Tasks
                </Button>
                <Button
                  key="39907"
                  sx={{ my: 2, color: "white" }}
                  onClick={() =>
                    navigate(`/ships/${selectedShip.id}/equipment`)
                  }
                  startIcon={<Construction />}
                >
                  Equipment
                </Button>
                <Button
                  key="39903333"
                  sx={{ my: 2, color: "white" }}
                  onClick={() => navigate(`/ships/${selectedShip.id}/parts`)}
                  startIcon={<Category />}
                >
                  Inventory
                </Button>
                <Button
                  key="39903334343"
                  sx={{ my: 2, color: "white" }}
                  onClick={() => navigate(`/mylists`)}
                  startIcon={<CheckBox />}
                >
                  My Work
                </Button>
                <Button
                  key="399-incidents"
                  sx={{ my: 2, color: "white" }}
                  onClick={() => navigate(`/ships/${selectedShip.id}/logbook`)}
                  startIcon={<LocalPolice />}
                >
                  Logbook
                </Button>
              </Box>
            ) : (
              <Box />
            )}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Invitations" sx={{ mr: 2 }}>
              <IconButton onClick={() => navigate("/invitations")}>
                <Badge color="primary" badgeContent={invitations.length}>
                  <Mail />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Settings />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {IsUserElevated(userProfile, selectedClient.id) ? (
                <MenuItem
                  key="191-usermanagement"
                  onClick={() => {
                    handleCloseUserMenu();
                    navigate(`/usermanagement`);
                  }}
                >
                  <SupervisedUserCircle sx={{ mr: 2 }} /> User Management
                </MenuItem>
              ) : (
                <Box />
              )}
              {IsUserElevated(userProfile, selectedClient.id) ? (
                <MenuItem
                  key="191-role-management"
                  onClick={() => {
                    handleCloseUserMenu();
                    navigate(`/ships/${selectedShip.id}/roles`);
                  }}
                >
                  <Security sx={{ mr: 2 }} /> {selectedShip.name} Roles
                </MenuItem>
              ) : (
                <Box />
              )}
              {IsUserElevated(userProfile, selectedClient.id) ? (
                <MenuItem
                  key="34"
                  onClick={() => {
                    handleCloseUserMenu();
                    navigate("/ships");
                  }}
                >
                  <Sailing sx={{ mr: 2 }} />
                  Fleet Management
                </MenuItem>
              ) : (
                <Box />
              )}
              {ships.length > 0 ? (
                <MenuItem
                  key="192-preferences"
                  onClick={() => {
                    handleCloseUserMenu();
                    navigate(`/preferences`);
                  }}
                >
                  <AutoAwesome sx={{ mr: 2 }} /> Preferences
                </MenuItem>
              ) : (
                <Box />
              )}
              {IsUserElevated(userProfile, selectedClient.id)  && selectedClient.billingTier === 0? (
                <MenuItem
                  key="191-billing"
                  onClick={() => {
                    handleCloseUserMenu();
                    navigate(`/billing`);
                  }}
                >
                  <MonetizationOn sx={{ mr: 2 }} /> Billing
                </MenuItem>
              ) : (
                <Box />
              )}
              {userProfile.isSystemAdministrator ? (
                <MenuItem
                  key="193-admin"
                  onClick={() => {
                    handleCloseUserMenu();
                    navigate(`/admin`);
                  }}
                >
                  <AdminPanelSettings sx={{ mr: 2 }} /> Admin
                </MenuItem>
              ) : (
                <Box />
              )}
              <Divider />
              {clients.length === 1 ? (
                <MenuItem key="292-client" onClick={() => {}}>
                  {selectedClient.name}
                </MenuItem>
              ) : (
                <Box />
              )}
              <MenuItem key="192-profile" onClick={() => {}}>
                <Person sx={{ mr: 2 }} /> {userProfile.email}
              </MenuItem>
              <MenuItem
                key="supportLink"
                onClick={() => {
                  window.open(
                    "https://philo.atlassian.net/servicedesk/customer/portal/1",
                    "_blank"
                  );
                }}
              >
                <Support sx={{ mr: 2 }} /> Support
              </MenuItem>
              <MenuItem
                key="193-logout"
                onClick={() => {
                  logout({
                    logoutParams: { returnTo: window.location.origin },
                  });
                }}
              >
                <Logout sx={{ mr: 2 }} /> Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
