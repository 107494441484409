import { Grid, Stack } from "@mui/material";
import { ShipTodoListItemComment } from "../../types/TodoList";
import TodoItemStatusChip from "./TodoItemStatusChip";
import { ArrowRightAlt } from "@mui/icons-material";

export type TodoCommentStatusHeaderProps ={
    todoListComment: ShipTodoListItemComment
}

export default function TodoCommentStatusHeader(props: TodoCommentStatusHeaderProps) {
    const {todoListComment} = props;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Stack sx={{display: { xs: "none", md: "flex", lg: "flex" }}} direction="row" spacing={1} alignItems="center">
            <TodoItemStatusChip status={todoListComment.todoItemPriorStatus}/>
            <ArrowRightAlt/>
            <TodoItemStatusChip status={todoListComment.todoItemNewStatus}/>
        </Stack>
        <Stack sx={{display: { xs: "flex", md: "none"}}} direction="column" spacing={1} alignItems="center">
            <TodoItemStatusChip status={todoListComment.todoItemPriorStatus}/>
            <ArrowRightAlt/>
            <TodoItemStatusChip status={todoListComment.todoItemNewStatus}/>
        </Stack>
      </Grid>
    </Grid>
  );
}
