import { Box, Grid } from "@mui/material";
import FixedFormRatingControl from "./FixedFormRatingControl";

export type ConditionalFormRatingControlProps = {
  isHidden: boolean;
  label: string;
  name: string;
  onChange: (event: React.SyntheticEvent, value: number | null) => void;
  value?: number;
};

export default function ConditionalFixedFormRatingControl(
  props: ConditionalFormRatingControlProps
) {
  const { isHidden, label, name, onChange, value } = props;
  if (isHidden) {
    return <Box />;
  } else {
    return (
        <Grid item xs={12} md={6}>

      <FixedFormRatingControl
          label={label}
          name={name}
          onChange={onChange}
          value={value} precision={1}      />
      </Grid>
    );
  }
}
