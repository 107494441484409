import axios from "axios"
import { Client } from "../types/Client"

export const getClients = () =>{
    return axios.get<Client[]>("https://api.zsail.io/Clients")
                .then((response) => {return response.data} );
}

export const getBilling = (clientId?: string) => {
    return axios.get(`https://api.zsail.io/Clients/billing?clientId=${clientId}`)
                .then((response) => {return response.data});
}

export const createClient = (client: Client) => {
    return axios.post<Client>("https://api.zsail.io/Clients", client)
                .then((response) => {return response.data});
}