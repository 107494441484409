import { IconGlassCocktail } from "@tabler/icons-react";
import { ShipDrinkInformationSummary } from "../../../types/PassengerTypes";
import AccordianPaperSection from "../../AccordianPaperSection";
import { Scrollbar } from "react-scrollbars-custom";
import { Paper, Stack, Typography } from "@mui/material";

export type MixerRequestsCardProps = {
  drinks: ShipDrinkInformationSummary;
};

export default function MixerRequestsCard(
  props: MixerRequestsCardProps
) {
  const { drinks } = props;

  return (
    <AccordianPaperSection
      title="Mixer Requests"
      icon={<IconGlassCocktail />}
    >
        <Scrollbar style={{ height: 300 }}>
        {drinks.mixerRequests?.map((mh) => (
          <Paper
            sx={{
              p: 1,
              borderLeftColor: "white",
              borderTopWidth: "0px",
              borderStyle: "solid",
              borderRightWidth: "0px",
              borderLeftWidth: "4px",
              borderBottomWidth: "0",
              mr: 1,
              mb: 2
            }}
            elevation={24}
          >
            <Stack direction="row" spacing={2}>
              <IconGlassCocktail />
              <Typography variant="body1" fontWeight="bold">{`${mh.firstName} ${mh.lastName}:`}</Typography>
              <Typography variant="body1">{`${mh.request}`}</Typography>

            </Stack>
          </Paper>
        ))}
        </Scrollbar>

    </AccordianPaperSection>
  );
}
