import { Divider, Grid, Paper, Typography } from "@mui/material";
import { ShipTodoList } from "../../../types/TodoList";
import CompletedTodoItemReview from "./CompletedTodoItemReview";

export type CompletedItemsReviewProps ={
    todoList: ShipTodoList | undefined,
    shipId: string | undefined
}
export default function CompletedItemsReview(props: CompletedItemsReviewProps){
    const {todoList, shipId} = props;
        return <Paper
        sx={{
          p: 2,
          borderTopColor: "green",
          borderTopWidth: "4px",
          borderStyle: "solid",
          borderRightWidth: "0",
          borderLeftWidth: "0",
          borderBottomWidth: "0",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Completed Items</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {todoList?.items
              ?.filter((item) => item.status === 2)
              .map((item) => (
                <CompletedTodoItemReview item={item} shipId={shipId}/>
              ))}
          </Grid>
        </Grid>
      </Paper>;
}