import { Divider, Grid, Paper, Typography } from "@mui/material";
import { PieChart, PieValueType } from "@mui/x-charts";
import { MakeOptional } from "@mui/x-date-pickers/internals";
import { ShipTodoList } from "../../../types/TodoList";

export type TodoListReviewUserOverviewProps = {
  todoList: ShipTodoList | undefined;
};

export default function TodoListReviewUserOverview(
  props: TodoListReviewUserOverviewProps
) {
  const { todoList } = props;
  function getTodoListUserDistributionData(): MakeOptional<
    PieValueType,
    "id"
  >[] {
    var uniqueUsers: any[] = [];
    todoList?.items?.forEach((item) => {
      if (!uniqueUsers.includes(item.assigneeUserName?.toString())) {
        if(item.assigneeUserName === undefined || item.assigneeUserName === ""){
            uniqueUsers.push("Unassigned");
        }else{
            uniqueUsers.push(item.assigneeUserName);
        }
      }
    });
    var pieChartData: MakeOptional<PieValueType, "id">[] = [];
    uniqueUsers.forEach((user, index) => {
        if(user === "Unassigned"){
            let unassignedPieData: MakeOptional<PieValueType, "id"> = {
                id: `${user}-${index}`,
                value:
                  todoList !== undefined && todoList.items !== undefined
                    ? todoList.items.filter((item) => item.assigneeUserName === "" || item.assigneeUserName === undefined)
                        .length
                    : 0,
                label: user,
              };
            pieChartData.push(unassignedPieData);
        } else{
            let pieData: MakeOptional<PieValueType, "id"> = {
                id: `${user}-${index}`,
                value:
                  todoList !== undefined && todoList.items !== undefined
                    ? todoList.items.filter((item) => item.assigneeUserName === user)
                        .length
                    : 0,
                label: user,
              };
              pieChartData.push(pieData);
        }

    });
    return pieChartData;
  }
  return (
    <Paper sx={{       p: 2,
      borderTopColor: "gray",
      borderTopWidth: "4px",
      borderStyle: "solid",
      borderRightWidth: "0",
      borderLeftWidth: "0",
      borderBottomWidth: "0", }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Crew Summary</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <PieChart
          margin={{ right: 0, top: 0, bottom: 40 }}
          slotProps={{

            legend: {
              padding: { top: 40 },
              labelStyle: {
                tableLayout: "auto",
              },
              direction: "row",
              position: {
                horizontal: "middle",
                vertical: "bottom",
              },
            },
          }}
            series={[
              {
                data: getTodoListUserDistributionData(),
                highlightScope: {
                  faded: "global",
                  highlighted: "item",
                },
                faded: {
                  innerRadius: 15,
                  additionalRadius: -15,
                  color: "gray",
                },
              },
            ]}
            width={500}
            height={200}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
