import {
  Assignment,
  CalendarMonth,
  ChatBubble,
  CheckBox,
  Done,
  Person,
  SkipNext,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Ship } from "../../types/Ship";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { ShipTodoList, ShipTodoListItem } from "../../types/TodoList";
import { getShip } from "../../services/shipService";
import {
  getShipTodoList,
  updateTodoListItemStatus,
} from "../../services/todoListService";
import { getTodoListStatus } from "../../utilities/todoListUtils";
import moment from "moment";
import TodoListItemCommentModal from "../../components/TodoList/TodoListItemCommentModal";
import SkipModal from "../../components/TodoList/SkipModal";
import TodoListPriorityIcon from "../../components/TodoList/TodoListPriorityIcon";
import PriorityIcon from "../../components/TodoList/PriorityIcon";

export default function MyListView() {
  const params = useParams();

  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [todoList, setTodoList] = useState<ShipTodoList>();
  const [skipTodoListItem, setSkipTodoListItem] = useState<ShipTodoListItem>();
  const [commentTodoListItem, setCommentTodoListItem] =
    useState<ShipTodoListItem>();
  const [skipModalOpen, setSkipModalOpen] = useState(false);
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  useEffect(() => {
    getShip(params.shipid, selectedClient.id).then((data) => {
      setShip(data);
    });
    getShipTodoList(params.todolistid, selectedClient.id).then((response) => {
      setTodoList(response);
    });
  }, [selectedClient.id, params.shipid, params.todolistid]);

  function comparePriority(a: ShipTodoListItem, b: ShipTodoListItem) {
    if (a.priority !== undefined && b.priority !== undefined) {
      return b.priority - a.priority;
    }
    return 0;
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <SkipModal
        open={skipModalOpen}
        onClose={setSkipModalOpen}
        todoList={todoList}
        setTodoList={setTodoList}
        todoListItem={skipTodoListItem}
        setTodoListItem={setSkipTodoListItem}
      />
      <TodoListItemCommentModal
        open={commentModalOpen}
        onClose={setCommentModalOpen}
        todoListItem={commentTodoListItem}
        setShipTodoListItem={setCommentTodoListItem}
      />
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/mylists")}
          startIcon={<CheckBox sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          My Work
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <CheckBox sx={{ mr: 0.5 }} fontSize="inherit" />
          {todoList?.name}
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={8} sm={8} md={4} lg={4} xl={4}>
          <Typography variant="h5">{todoList?.name}:</Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
          <Box display="flex" justifyContent="flex-end">
            {todoList?.status === 0 ? (
              <Chip
                label={getTodoListStatus(todoList?.status)}
                color="secondary"
              />
            ) : todoList?.status === 1 ? (
              <Chip
                label={getTodoListStatus(todoList?.status)}
                color="primary"
              />
            ) : todoList?.status === 2 ? (
              <Chip
                label={getTodoListStatus(todoList?.status)}
                color="success"
              />
            ) : todoList?.status === 3 ? (
              <Chip
                label={getTodoListStatus(todoList?.status)}
                color="warning"
              />
            ) : (
              <Box />
            )}
          </Box>
        </Grid>
        {todoList?.dueDate !== undefined && todoList.dueDate !== null ? (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box display="flex" justifyContent="flex-end">
              <Chip
                icon={<CalendarMonth />}
                label={`Due ${moment(todoList?.dueDate).format("MM/DD/YYYY")}`}
              />
            </Box>
          </Grid>
        ) : (
          <Box />
        )}

        <Grid item xs={12}>
          <Divider sx={{ mt: 2, mb: 2 }} variant="fullWidth">
            <Chip label="TO DO" size="medium" color="primary" />
          </Divider>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {todoList?.items?.length === 0 ? (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 10 }}
            >
              <Typography variant="h5">No Items Found</Typography>
            </Stack>
          ) : (
            <Grid container spacing={1}>
              {todoList?.items
                ?.filter((item) => item.status !== 2 && item.status !== 3)
                .sort(comparePriority)
                .map((todoListItem) => {
                  return (
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={1}>
                          <Assignment />
                        </Grid>
                        <Grid item xs={12} sm={7} md={4} lg={4} xl={4}>
                          {todoListItem.isTask ? (
                            <Button
                              variant="text"
                              sx={{ pt: 0, pl: 0 }}
                              onClick={() => {
                                navigate(
                                  `/Ships/${ship?.id}/tasks/time/${todoListItem.taskId}`
                                );
                              }}
                            >
                              {todoListItem.taskName}
                            </Button>
                          ) : (
                            todoListItem.value
                          )}
                          {todoListItem.assigneeUserId !== "" &&
                          todoListItem.assigneeUserId !== null ? (
                            <Chip
                              icon={<Person />}
                              sx={{ ml: 2 }}
                              label={todoListItem.assigneeUserName}
                            />
                          ) : (
                            <Box />
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={5}
                          md={6}
                          lg={6}
                          xl={6}
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <TodoListPriorityIcon
                            priority={todoListItem.priority}
                          />
                          {todoListItem.comments !== undefined &&
                          todoListItem.comments?.length > 0 ? (
                            <IconButton
                              sx={{ pt: 0.5, pb: 0.5 }}
                              onClick={() => {
                                setCommentTodoListItem(todoListItem);
                                setCommentModalOpen(true);
                              }}
                            >
                              <ChatBubble />
                            </IconButton>
                          ) : (
                            <Box />
                          )}
                          {todoListItem.status === 0 &&
                          todoList.status !== 0 ? (
                            <ButtonGroup>
                              <Button
                                variant="contained"
                                color="success"
                                onClick={() => {
                                  updateTodoListItemStatus(
                                    todoList.id,
                                    todoListItem.id,
                                    selectedClient.id,
                                    1
                                  ).then((res) => {
                                    getShipTodoList(
                                      params.todolistid,
                                      selectedClient.id
                                    ).then((response) => {
                                      setTodoList(response);
                                    });
                                  });
                                }}
                              >
                                Start
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  updateTodoListItemStatus(
                                    todoList.id,
                                    todoListItem.id,
                                    selectedClient.id,
                                    2
                                  ).then((res) => {
                                    getShipTodoList(
                                      params.todolistid,
                                      selectedClient.id
                                    ).then((response) => {
                                      setTodoList(response);
                                    });
                                  });
                                }}
                              >
                                Done
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                  setSkipTodoListItem(todoListItem);
                                  setSkipModalOpen(true);
                                }}
                              >
                                Skip
                              </Button>
                            </ButtonGroup>
                          ) : todoListItem.status === 1 ? (
                            <ButtonGroup>
                              <Button
                                variant="outlined"
                                color="success"
                                onClick={() => {
                                  updateTodoListItemStatus(
                                    todoList.id,
                                    todoListItem.id,
                                    selectedClient.id,
                                    0
                                  ).then((res) => {
                                    getShipTodoList(
                                      params.todolistid,
                                      selectedClient.id
                                    ).then((response) => {
                                      setTodoList(response);
                                    });
                                  });
                                }}
                              >
                                To Do
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  updateTodoListItemStatus(
                                    todoList.id,
                                    todoListItem.id,
                                    selectedClient.id,
                                    2
                                  ).then((res) => {
                                    getShipTodoList(
                                      params.todolistid,
                                      selectedClient.id
                                    ).then((response) => {
                                      setTodoList(response);
                                    });
                                  });
                                }}
                              >
                                Done
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                  setSkipTodoListItem(todoListItem);
                                  setSkipModalOpen(true);
                                }}
                              >
                                Skip
                              </Button>
                            </ButtonGroup>
                          ) : todoListItem.status === 2 ? (
                            <Stack direction="row" spacing={1}>
                              <Done sx={{ color: "green" }} />
                              <Typography variant="body1">Completed</Typography>
                            </Stack>
                          ) : todoListItem.status === 3 ? (
                            <Stack direction="row" spacing={1}>
                              <SkipNext sx={{ color: "red" }} />
                              <Typography variant="body1">Skipped</Typography>
                            </Stack>
                          ) : (
                            <Box />
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          )}
        </Grid>
        {todoList?.items !== undefined &&
        todoList?.items?.filter((item) => item.status === 3).length > 0 ? (
          <Grid item xs={12}>
            <Divider sx={{ mt: 2, mb: 2 }} variant="fullWidth">
              <Chip label="Skipped" size="medium" color="error" />
            </Divider>
          </Grid>
        ) : (
          <Box />
        )}
        {todoList?.items !== undefined &&
        todoList?.items?.filter((item) => item.status === 3).length > 0 ? (
          todoList?.items
            ?.filter((item) => item.status === 3)
            .map((todoListItem) => {
              return (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={1}>
                      <Assignment />
                    </Grid>
                    <Grid item xs={10} sm={6} md={4} lg={4} xl={4}>
                      {todoListItem.isTask ? (
                        <Button
                          variant="text"
                          sx={{ pt: 0, pl: 0 }}
                          onClick={() => {
                            navigate(
                              `/Ships/${ship?.id}/tasks/time/${todoListItem.taskId}`
                            );
                          }}
                        >
                          {todoListItem.taskName}
                        </Button>
                      ) : (
                        todoListItem.value
                      )}
                      {todoListItem.assigneeUserId !== "" &&
                      todoListItem.assigneeUserId !== null ? (
                        <Chip
                          icon={<Person />}
                          sx={{ ml: 2 }}
                          label={todoListItem.assigneeUserName}
                        />
                      ) : (
                        <Box />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={6}
                      lg={6}
                      xl={6}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Stack direction="row" spacing={1} alignItems={"center"}>
                        <PriorityIcon priority={todoListItem.priority} />
                        {todoListItem.comments !== undefined &&
                        todoListItem.comments?.length > 0 ? (
                          <IconButton
                            sx={{ pt: 0.5, pb: 0.5 }}
                            onClick={() => {
                              setCommentTodoListItem(todoListItem);
                              setCommentModalOpen(true);
                            }}
                          >
                            <ChatBubble />
                          </IconButton>
                        ) : (
                          <Box />
                        )}
                        <SkipNext sx={{ color: "red" }} />
                        <Typography variant="body1">Skipped</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })
        ) : (
          <Box />
        )}
        {todoList?.items !== undefined &&
        todoList?.items?.filter((item) => item.status === 2).length > 0 ? (
          <Grid item xs={12}>
            <Divider sx={{ mt: 2, mb: 2 }} variant="fullWidth">
              <Chip label="Completed" size="medium" color="success" />
            </Divider>
          </Grid>
        ) : (
          <Box />
        )}
        {todoList?.items !== undefined &&
        todoList?.items?.filter((item) => item.status === 2).length > 0 ? (
          todoList?.items
            ?.filter((item) => item.status === 2)
            .map((todoListItem) => {
              return (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={1}>
                      <Assignment />
                    </Grid>
                    <Grid item xs={10} sm={6} md={4} lg={4} xl={4}>
                      {todoListItem.isTask ? (
                        <Button
                          variant="text"
                          sx={{ pt: 0, pl: 0 }}
                          onClick={() => {
                            navigate(
                              `/Ships/${ship?.id}/tasks/time/${todoListItem.taskId}`
                            );
                          }}
                        >
                          {todoListItem.taskName}
                        </Button>
                      ) : (
                        todoListItem.value
                      )}
                      {todoListItem.assigneeUserId !== "" &&
                      todoListItem.assigneeUserId !== null ? (
                        <Chip
                          icon={<Person />}
                          sx={{ ml: 2 }}
                          label={todoListItem.assigneeUserName}
                        />
                      ) : (
                        <Box />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={6}
                      lg={6}
                      xl={6}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Stack direction="row" spacing={1} alignItems={"center"}>
                        <PriorityIcon priority={todoListItem.priority} />
                        <Done sx={{ color: "green" }} />
                        <Typography variant="body1">Completed</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })
        ) : (
          <Box />
        )}
      </Grid>
    </Container>
  );
}
