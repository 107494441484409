import { Chip, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { IncidentReportComment } from "../../types/IncidentReport";
import { IconMessage } from "@tabler/icons-react";
import { CalendarMonth, Computer, Person } from "@mui/icons-material";
import moment from "moment";
import { getIncidentStatus } from "../../utilities/incidentUtils";

export type IncidentCommentCardProps = {
  comment?: IncidentReportComment;
};

export default function IncidentCommentCard(props: IncidentCommentCardProps) {
  const { comment } = props;
  return (
    <Paper
      sx={{
        p: 1,
        borderLeftColor: comment?.commentStatus === 7 ? "yellow" : "#7fdcfd",
        borderTopWidth: "0px",
        borderStyle: "solid",
        borderRightWidth: "0px",
        borderLeftWidth: "4px",
        borderBottomWidth: "0",
        mb: 2,
      }}
      elevation={12}
    >
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <Stack
            direction="column"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <IconMessage height={32} width={32} />
          </Stack>
        </Grid>
        <Grid item xs={11}>
          <Grid container spacing={1}>
            <Grid item xs={12} textAlign="start">
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Chip
                    icon={
                      comment?.commentStatus === 7 ? <Computer /> : <Person />
                    }
                    label={
                      comment?.commentStatus === 7
                        ? "System"
                        : comment?.user?.userName
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} textAlign="end">
                  <Chip
                    label={`Fault Status: ${getIncidentStatus(
                      comment?.commentStatus
                    )}`}
                    sx={{ mr: 2 }}
                  />
                  <Chip
                    icon={<CalendarMonth />}
                    label={moment(comment?.createdDate)
                      .local()
                      .format("MM/DD/YYYY HH:MM")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">{comment?.comment}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
