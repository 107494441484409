import { useEffect, useState } from "react";
import { getEquipment } from "../../services/shipService";
import { useRecoilState, useSetRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { Equipment } from "../../types/Equipment";
import {
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import { Construction } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ShipsState } from "../../Recoil/atoms/ShipsAtom";
import { SideBarSelectedState } from "../../Recoil/atoms/SideBarSelectedAtom";
import { Scrollbar } from "react-scrollbars-custom";

export type EquipmentStatusType = {
  selectedShipId?: string;
};

export default function EquipmentStatus(props: EquipmentStatusType) {
  const [ships] = useRecoilState(ShipsState);
  const setSelected = useSetRecoilState(SideBarSelectedState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [localLoading, setLocalLoading] = useState(false);
  const [equipment, setEquipment] = useState<Equipment[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.selectedShipId !== undefined && selectedClient.id !== undefined) {
      setLocalLoading(true);
      getEquipment(selectedClient.id, props.selectedShipId).then((response) => {
        setEquipment(response);
        setLocalLoading(false);
      });
    }
  }, [selectedClient.id, props.selectedShipId]);

  function compareEquipment(equipmentA: Equipment, equipmentB: Equipment) {
    if (
      equipmentA.daysSinceLastUpdate !== undefined &&
      equipmentB.daysSinceLastUpdate !== undefined
    ) {
      return (
        equipmentA.updateFrequency -
        equipmentA.daysSinceLastUpdate -
        (equipmentB.updateFrequency - equipmentB.daysSinceLastUpdate)
      );
    }
    return 0;
  }

  return (
    <Card elevation={2}>
      <CardContent sx={{ background: "rgba(255, 255, 255, 0.12)" }}>
        <Typography variant="h5">Equipment Status</Typography>
        <Scrollbar style={{ height: 200 }}>
          {localLoading ? (
            <Box sx={{ width: 450 }}>
              <Skeleton height={50} animation="wave" />
              <Skeleton height={50} animation="wave" />
              <Skeleton height={50} animation="wave" />
              <Skeleton height={50} animation="wave" />
              <Skeleton height={50} animation="wave" />
            </Box>
          ) : (
            <List sx={{ height: 200 }} disablePadding dense>
              {equipment.sort(compareEquipment).map((eq) => {
                return (
                  <ListItem key={eq.id} divider>
                    <ListItemButton
                      onClick={() => {
                        var name = ships.filter(
                          (sh) => sh.id === props.selectedShipId
                        )[0].name;
                        setSelected(name);
                        navigate(
                          `/Ships/${props.selectedShipId}/equipment/${eq.id}`
                        );
                      }}
                    >
                      <ListItemAvatar>
                        {eq.daysSinceLastUpdate !== undefined &&
                        eq.daysSinceLastUpdate > eq.updateFrequency ? (
                          <Construction sx={{ color: "red" }} />
                        ) : eq.daysSinceLastUpdate !== undefined &&
                          eq.daysSinceLastUpdate === eq.updateFrequency ? (
                          <Construction sx={{ color: "red" }} />
                        ) : eq.daysSinceLastUpdate !== undefined &&
                          eq.daysSinceLastUpdate / eq.updateFrequency >=
                            0.75 ? (
                          <Construction sx={{ color: "yellow" }} />
                        ) : (
                          <Construction />
                        )}
                      </ListItemAvatar>
                      {eq.daysSinceLastUpdate !== undefined &&
                      eq.daysSinceLastUpdate > eq.updateFrequency ? (
                        <ListItemText
                          primary={eq.name}
                          color="red"
                          secondary={`${
                            eq.daysSinceLastUpdate - eq.updateFrequency
                          } days overdue`}
                        ></ListItemText>
                      ) : eq.daysSinceLastUpdate !== undefined &&
                        eq.daysSinceLastUpdate === eq.updateFrequency ? (
                        <ListItemText
                          primary={eq.name}
                          secondary={`Upate due today`}
                        ></ListItemText>
                      ) : eq.daysSinceLastUpdate !== undefined &&
                        eq.daysSinceLastUpdate / eq.updateFrequency >= 0.75 ? (
                        <ListItemText
                          primary={eq.name}
                          secondary={`Upate due in ${
                            eq.updateFrequency - eq.daysSinceLastUpdate
                          } day(s)`}
                        ></ListItemText>
                      ) : (
                        <ListItemText
                          primary={eq.name}
                          secondary={`Update due in ${
                            eq.updateFrequency -
                            (eq.daysSinceLastUpdate !== undefined
                              ? eq.daysSinceLastUpdate
                              : 0)
                          } days`}
                        ></ListItemText>
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          )}
        </Scrollbar>
      </CardContent>
    </Card>
  );
}
