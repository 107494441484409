import {
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { getShipParts } from "../../services/shipService";
import { Part } from "../../types/Part";

export type LowInventoryPartsPropsType = {
  selectedShipId?: string;
};

export default function LowInventoryParts(props: LowInventoryPartsPropsType) {
  const navigate = useNavigate();
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [lowInventoryPartsCount, setLowInventoryPartsCount] = useState(0);

  useEffect(() => {
    if (props.selectedShipId !== undefined && selectedClient.id !== undefined) {
      getShipParts(props.selectedShipId, selectedClient.id).then(
        (parts: Part[]) =>
          setLowInventoryPartsCount(
            parts.filter((part) => part.quantity <= part.minimumInventory)
              .length
          )
      );
    }
  }, [selectedClient.id, props.selectedShipId]);

  return (
    <Paper
    sx={{
      p: 2,
      height: "100%",
      width: "100%",
      borderTopColor: "#b47f49",
      borderTopWidth: "4px",
      borderStyle: "solid",
      borderRightWidth: "0",
      borderLeftWidth: "0",
      borderBottomWidth: "0",
    }}
    elevation={24}
    component={Button}
    onClick={() => navigate(`/ships/${props.selectedShipId}/parts`)}
  >
    <Grid container>
      <Grid item xs={12}>
        <Stack direction="column" alignItems="center">
        <Typography variant="h6">Low Inventory</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column" alignItems="center">
        <Typography variant="h1">{lowInventoryPartsCount}</Typography>
        </Stack>
      </Grid>
    </Grid>
  </Paper>
  );
}
