import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { DateTime } from "luxon";
import { DateTimePicker } from "@mui/x-date-pickers";
import { PassengerProps } from "../../../types/PassengerTypes";

export default function TravelInfo(props: PassengerProps) {
  const { passenger } = props;
  return passenger.passengerTravelInformation !== undefined ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 4,
            borderTopColor: "#b47f49",
            borderTopWidth: "4px",
            borderStyle: "solid",
            borderRightWidth: "0px",
            borderLeftWidth: "0px",
            borderBottomWidth: "0",
          }}
          elevation={12}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4">Travel Information</Typography>
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Formik
                initialValues={passenger.passengerTravelInformation}
                onSubmit={() => {}}
              >
                {({
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Stack direction="column">
                          <Typography variant="h6">Arrival Flight</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack>
                          <DateTimePicker
                            readOnly
                            label="Flight Time"
                            value={DateTime.fromISO(
                              values.arrivalFlightTime !== undefined
                                ? values.arrivalFlightTime.toString()
                                : DateTime.now().toString()
                            )}
                            onChange={(value: any) =>
                              setFieldValue(
                                "arrivalFlightTime",
                                DateTime.fromISO(value),
                                true
                              )
                            }
                            sx={{ mb: 2 }}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="arrivalFlightNumber"
                          name="arrivalFlightNumber"
                          label={`Flight Number`}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{shrink: true,}}
                          value={values.arrivalFlightNumber}
                          required
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.arrivalFlightNumber &&
                            Boolean(errors.arrivalFlightNumber)
                          }
                          helperText={
                            touched.arrivalFlightNumber &&
                            errors.arrivalFlightNumber
                          }
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="arrivalFlightAirport"
                          name="arrivalFlightAirport"
                          label={`Airport`}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{shrink: true,}}
                          value={values.arrivalFlightAirport}
                          required
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.arrivalFlightAirport &&
                            Boolean(errors.arrivalFlightAirport)
                          }
                          helperText={
                            touched.arrivalFlightAirport &&
                            errors.arrivalFlightAirport
                          }
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.arrivalTransportationRequired}
                              readOnly
                            />
                          }
                          disabled
                          label="Do you need us to arrange transportation for your arrival?"
                          name="arrivalTransportationRequired"
                          onChange={handleChange}
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction="column">
                          <Typography variant="h6">Return Flight</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack>
                          <DateTimePicker
                            readOnly
                            label="Flight Time"
                            value={DateTime.fromISO(
                                values.returnFlightTime !== undefined
                                  ? values.returnFlightTime.toString()
                                  : DateTime.now().toString())}
                            onChange={(value: any) =>
                              setFieldValue(
                                "returnFlightTime",
                                DateTime.fromISO(value),
                                true
                              )
                            }
                            sx={{ mb: 2 }}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="returnFlightNumber"
                          name="returnFlightNumber"
                          label={`Flight Number`}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{shrink: true,}}
                          value={values.returnFlightNumber}
                          required
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.returnFlightNumber &&
                            Boolean(errors.returnFlightNumber)
                          }
                          helperText={
                            touched.returnFlightNumber &&
                            errors.returnFlightNumber
                          }
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="returnFlightAirport"
                          name="returnFlightAirport"
                          label={`Airport`}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{shrink: true,}}

                          value={values.returnFlightAirport}
                          required
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.returnFlightAirport &&
                            Boolean(errors.returnFlightAirport)
                          }
                          helperText={
                            touched.returnFlightAirport &&
                            errors.returnFlightAirport
                          }
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.returnTransportationRequired}
                              readOnly
                            />
                          }
                          disabled
                          label="Do you need us to arrange transportation for your return?"
                          name="returnTransportationRequired"
                          onChange={handleChange}
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  ) : (
    <Box />
  );
}
