import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { Trip } from "../../types/Trip";
import { getTrip, getTripActivityInformation, getTripMedicalInformation } from "../../services/tripservice";
import { DirectionsBoat, Map, Route, Summarize } from "@mui/icons-material";
import AccordianPaperSection from "../../components/AccordianPaperSection";
import TripCard from "../../components/Trip/TripCard";
import TripMedicalSummaryCard from "../../components/Trip/TripMedicalSummaryCard";
import { PassengerActivitySummary, PassengerMedicalInfo } from "../../types/PassengerTypes";
import TripAquaticSummaryCard from "../../components/Trip/TripAquaticSummaryCard";
import TripWildlifeSummaryCard from "../../components/Trip/TripWildlifeSummaryCard";
import TripActivitySummaryCard from "../../components/Trip/TripActivitySummaryCard";
import DrinkSummary from "../../components/Trip/DrinkSummary";
import FoodSummary from "../../components/Trip/FoodSummary";

export default function TripSummary() {
  const navigate = useNavigate();
  const params = useParams();
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [trip, setTrip] = useState<Trip>();
  const [medical, setMedical] = useState<PassengerMedicalInfo[]>([]);
  const [value, setValue] = useState(0);
  const [data] = useState<number[]>([]);
  const [labels] = useState<string[]>([]);

  useEffect(() => {
    getTrip(selectedClient.id, selectedShip.id, params.tripid).then((res) => {
      setTrip(res);
      getTripMedicalInformation(
        selectedClient.id,
        selectedShip.id,
        params.tripid
      ).then((res) => {
        setMedical(res);
      });
      getTripActivityInformation(
        selectedClient.id,
        selectedShip.id,
        params.tripid
      ).then((res: PassengerActivitySummary[]) => {
        if (res !== undefined) {
          res.forEach((e: PassengerActivitySummary) => {
            if (e.count !== undefined && e.activityName !== undefined) {
              data.push(e.count);
              labels.push(e.activityName);
            }
          });
        }
      });
    });
  }, [selectedClient, selectedShip, params.tripid, data, labels]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${selectedShip.id}/trips`)}
          startIcon={<Map sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Trips
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() =>
            navigate(`/ships/${selectedShip.id}/trips/${params.tripid}`)
          }
          startIcon={<Route sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Trip
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Summarize sx={{ mr: 0.5 }} fontSize="inherit" />
          Summary
        </Typography>
      </Breadcrumbs>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="General" {...a11yProps(0)} />
          <Tab label="Drinks" {...a11yProps(1)} />
          <Tab label="Food" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <Grid container spacing={2}>
            <Grid item xs={12}>
              <AccordianPaperSection
                useHeader={true}
                header={<TripCard trip={trip} isClickable={false} />}
                isStatic={true}
              >
                <Box />
              </AccordianPaperSection>
            </Grid>
            {medical.length > 0 ? (
              <Grid item xs={12}>
                <TripMedicalSummaryCard medical={medical} />
              </Grid>
            ) : (
              <Box />
            )}
            <Grid item xs={12}>
              <TripAquaticSummaryCard />
            </Grid>
            <Grid item xs={12} md={6}>
              <TripWildlifeSummaryCard />
            </Grid>
            <Grid item xs={12} md={6}>
              <TripActivitySummaryCard data={data} labels={labels} />
            </Grid>
          </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <DrinkSummary/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <FoodSummary/>
      </CustomTabPanel>
    </Container>
  );
}
