import { atom } from "recoil";
import { Ship } from "../../types/Ship";
import { DateTime } from "luxon";

export const ShipsState = atom<Ship[]>({
  key: "ships",
  default: [],
});

export const SelectedShipState = atom<Ship>({
  key: "selectedShip",
  default: {
    id: "",
    clientId: "",
    name: "",
    make: "",
    model: "",
    year: 0,
    sizeInFeet: 0,
    purchasePrice: 0,
    status: 0,
    type: "",
    purchaseDate: DateTime.now(),
    createdDate: DateTime.now(),
    roles: [],
  },
});
