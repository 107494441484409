import {
  Breadcrumbs,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createPart, getShip } from "../../services/shipService";
import * as yup from "yup";
import { Ship } from "../../types/Ship";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { Formik } from "formik";
import { Part } from "../../types/Part";
import {
  Add,
  Category,
  DirectionsBoat,
} from "@mui/icons-material";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { Editor } from "@tinymce/tinymce-react";

export default function NewPart() {
  const params = useParams();
  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [selectedShip] = useRecoilState(SelectedShipState);

  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
    });
  }, [selectedClient, params.id]);
  const validationSchema = yup.object({
    name: yup.string().required("Part name is required."),
    description: yup.string().required("Part description is required."),
    maker: yup.string().required("Part maker is required."),
    modelNumber: yup.string().required("Part model number is requied."),
    marketsPartNumber: yup
      .string()
      .required("Part Markets Part Number is required."),
    minimumInventory: yup
      .number()
      .positive()
      .required("Part Minimum Inventory is required."),
    storageLocation: yup.string().required("Part storage location is requied."),
    quantity: yup.number().positive().required("Part quantity is required."),
  });

  const initialState = {
    name: "",
    description: "",
    maker: "",
    modelNumber: "",
    marketsPartNumber: "",
    minimumInventory: 0,
    storageLocation: "",
    quantity: 0,
  };
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}/parts`)}
          startIcon={<Category sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Parts
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Add sx={{ mr: 0.5 }} fontSize="inherit" />
          New Part
        </Typography>
      </Breadcrumbs>
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          var part: Part = {
            name: values.name,
            description: values.description,
            maker: values.maker,
            modelNumber: values.modelNumber,
            marketsPartNumber: values.marketsPartNumber,
            minimumInventory: values.minimumInventory,
            storageLocation: values.storageLocation,
            quantity: values.quantity,
          };
          createPart(part, ship?.id, selectedClient.id).then((response) => {
            navigate(`/ships/${ship?.id}/Parts`);
          });
        }}
      >
        {({
          errors,
          handleSubmit,
          handleChange,
          setFieldValue,
          handleBlur,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              id="name"
              name="name"
              label="Name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Typography variant="subtitle1">Description:</Typography>
              <Editor
                apiKey="s7hflnfnqq2c0nxj75udyor3kobji9x4jv2xgc4xvsdhtju5"
                init={{
                  height: 200,
                  menubar: true,
                  skin: "oxide-dark",
                  content_css: "dark",
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent| " +
                    "removeformat",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                textareaName="description"
                onEditorChange={(stringifiedHTML) => {
                  setFieldValue("description", stringifiedHTML);
                }}
              />
            <TextField
              id="maker"
              name="maker"
              label="Maker"
              value={values.maker}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.maker && Boolean(errors.maker)}
              helperText={touched.maker && errors.maker}
              fullWidth
              sx={{ mb: 2, mt: 2 }}
            />
            <TextField
              id="modelNumber"
              name="modelNumber"
              label="Model Number"
              value={values.modelNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.modelNumber && Boolean(errors.modelNumber)}
              helperText={touched.modelNumber && errors.modelNumber}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              id="marketsPartNumber"
              name="marketsPartNumber"
              label="Markets Part Number"
              value={values.marketsPartNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.marketsPartNumber && Boolean(errors.marketsPartNumber)
              }
              helperText={touched.marketsPartNumber && errors.marketsPartNumber}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              id="minimumInventory"
              name="minimumInventory"
              label="Minimum Inventory"
              value={values.minimumInventory}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.minimumInventory && Boolean(errors.minimumInventory)
              }
              helperText={touched.minimumInventory && errors.minimumInventory}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              id="storageLocation"
              name="storageLocation"
              label="Storage Location"
              value={values.storageLocation}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.storageLocation && Boolean(errors.storageLocation)}
              helperText={touched.storageLocation && errors.storageLocation}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              id="quantity"
              name="quantity"
              label="Quantity"
              value={values.quantity}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.quantity && Boolean(errors.quantity)}
              helperText={touched.quantity && errors.quantity}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Button color="primary" variant="contained" fullWidth type="submit">
              Submit
            </Button>
          </form>
        )}
      </Formik>
    </Container>
  );
}
