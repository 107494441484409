import { Avatar, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { ShipTodoList } from "../../../types/TodoList";

export type TodoListReviewAssignedUserInfoProps = {
  todoList: ShipTodoList | undefined;
};
export default function TodoListReviewAssignedUserInfo(
  props: TodoListReviewAssignedUserInfoProps
) {
  const { todoList } = props;
  return (
    <Paper sx={{       p: 2,
        borderTopColor: "gray",
        borderTopWidth: "4px",
        borderStyle: "solid",
        borderRightWidth: "0",
        borderLeftWidth: "0",
        borderBottomWidth: "0", }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Crew Member</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} height={216}>
          <Stack
            direction="row"
            alignItems="center"
            height="100%"
            justifyContent="center"
            spacing={1}
          >
            <Avatar sx={{ width: 66, height: 66 }} />
            <Stack direction="column">
              <Typography variant="h5">{todoList?.assigneeUserName}</Typography>
              <Typography variant="h5">{todoList?.assigneeEmail}</Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}
