import {
  Breadcrumbs,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Ship } from "../../../types/Ship";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../../Recoil/atoms/ClientsAtom";
import { getShip } from "../../../services/shipService";
import * as yup from "yup";
import {
  Add,
  CheckBox,
  DirectionsBoat,
  HistoryEdu,
} from "@mui/icons-material";
import { Formik } from "formik";
import { ShipTodoListTemplate } from "../../../types/TodoList";
import { createTodoListTemplate } from "../../../services/todoListTemplateService";
import { SelectedShipState } from "../../../Recoil/atoms/ShipsAtom";

export default function NewTodoTemplate() {
  const params = useParams();
  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [selectedShip] = useRecoilState(SelectedShipState);


  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
    });
  }, [selectedClient, params.id]);
  const validationSchema = yup.object({
    name: yup.string().required("name is required."),
    description: yup.string().required("description is required."),
  });

  const initialState = {
    name: "",
    description: "",
  };
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}/todolists`)}
          startIcon={<CheckBox sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Todo Lists
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}/todolists/templates`)}
          startIcon={<HistoryEdu sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Todo Templates
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Add sx={{ mr: 0.5 }} fontSize="inherit" />
          New Template
        </Typography>
      </Breadcrumbs>
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          var todoTemplate: ShipTodoListTemplate = {
            name: values.name,
            shipId: ship?.id,
            description: values.description
          };
          createTodoListTemplate(ship?.id, selectedClient.id, todoTemplate).then((res) => {
            navigate(`/ships/${ship?.id}/todolists/templates/${res}`);
          });
        }}
      >
        {({
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              id="name"
              name="name"
              label="Name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              id="description"
              name="description"
              label="Description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.description && Boolean(errors.description)}
              helperText={touched.description && errors.description}
              fullWidth
              sx={{ mb: 2 }}
            />

            {values.description !== "" ? (
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            ) : (
              <Button
                color="primary"
                variant="contained"
                disabled
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            )}
          </form>
        )}
      </Formik>
    </Container>
  );
}
