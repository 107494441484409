import { Add, CheckBox, DirectionsBoat, HistoryEdu } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Chip,
  Container,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Ship, ShipUserRole } from "../../types/Ship";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { getShip, getShipUserRoles } from "../../services/shipService";
import { getTodoLists } from "../../services/todoListService";
import { ShipTodoList } from "../../types/TodoList";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import TodoListCard from "../../components/TodoList/TodoListCard";
import { userManagementState } from "../../Recoil/atoms/UserManagementAtom";
import { getClientUsers } from "../../services/userservice";
import moment from "moment";

export default function ShipTodoLists() {
  const params = useParams();
  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [todoLists, setTodoLists] = useState<ShipTodoList[]>([]);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [roles, setRoles] = useState<ShipUserRole[]>([]);
  const [users, setUsers] = useRecoilState(userManagementState);

  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
      getTodoLists(params.id, selectedClient.id).then((res) => {
        setTodoLists(res);
      });
      getClientUsers(selectedClient.id).then((res) => {
        setUsers(res);
      });
      getShipUserRoles(selectedClient.id, params.id).then((res) =>
        setRoles(res)
      );
    });
  }, [selectedClient, params.id, setUsers]);

  function compareDates(a: ShipTodoList, b: ShipTodoList) {
    if (a.daysTillDue !== undefined && b.daysTillDue !== undefined) {
      return a.daysTillDue - b.daysTillDue;
    }
    return 0;
  }

  function compareCompletedDate(a: ShipTodoList, b: ShipTodoList) {
    if (a.completedDate !== undefined && b.completedDate !== undefined) {
      return (
        moment(b.completedDate).valueOf() - moment(a.completedDate).valueOf()
      );
    }
    return 0;
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <CheckBox sx={{ mr: 0.5 }} fontSize="inherit" />
          To Do Lists
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography variant="h5">{ship?.name} To Do Lists:</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box display="flex" justifyContent="flex-end">
            <ButtonGroup>
              {selectedClient.billingTier !== 0 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate(`/Ships/${ship?.id}/todolists/templates`);
                  }}
                  startIcon={<HistoryEdu />}
                >
                  Templates
                </Button>
              ) : (
                <Tooltip title="Contact Sales To Learn More!">
                  <span>
                    <Button
                      variant="contained"
                      disabled
                      color="primary"
                      onClick={() => {
                        navigate(`/Ships/${ship?.id}/todolists/templates`);
                      }}
                      startIcon={<HistoryEdu />}
                    >
                      Templates
                    </Button>
                  </span>
                </Tooltip>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate(`/Ships/${ship?.id}/todolists/new`);
                }}
                startIcon={<Add />}
              >
                New To do List
              </Button>
            </ButtonGroup>
          </Box>
        </Grid>
        {todoLists.filter((tl) => tl.status === 4).length > 0 ? (
          <Grid item xs={12}>
            <Divider sx={{ mt: 2, mb: 2 }} variant="fullWidth">
              <Chip label="IN REVIEW" size="medium" color="info" />
            </Divider>
          </Grid>
        ) : (
          <Box />
        )}
        {todoLists
          .filter((tl) => tl.status === 4)
          .sort(compareDates)
          .map((todoList) => {
            return (
              <TodoListCard
                ship={ship}
                todoList={todoList}
                roles={roles}
                completed={false}
                users={users}
              />
            );
          })}
        {todoLists.filter(
          (tl) =>
            tl.status !== 2 &&
            tl.status !== 3 &&
            tl.status !== 4 &&
            tl.daysTillDue !== undefined &&
            tl.daysTillDue < 0
        ).length > 0 ? (
          <Grid item xs={12}>
            <Divider sx={{ mt: 2, mb: 2 }} variant="fullWidth">
              <Chip label="OVERDUE" size="medium" color="warning" />
            </Divider>
          </Grid>
        ) : (
          <Box />
        )}
        {todoLists
          .filter(
            (tl) =>
              tl.status !== 2 &&
              tl.status !== 3 &&
              tl.status !== 4 &&
              tl.daysTillDue !== undefined &&
              tl.daysTillDue < 0
          )
          .sort(compareDates)
          .map((todoList) => {
            return (
              <TodoListCard
                ship={ship}
                todoList={todoList}
                roles={roles}
                completed={false}
                users={users}
              />
            );
          })}
        <Grid item xs={12}>
          <Divider sx={{ mt: 2, mb: 2 }} variant="fullWidth">
            <Chip label="IN PROGRESS" size="medium" color="primary" />
          </Divider>
        </Grid>
        {todoLists?.length === 0 ? (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 10 }}
            >
              <Typography variant="h5">No Lists Found</Typography>
            </Stack>
          </Grid>
        ) : (
          todoLists
            .filter(
              (tl) =>
                tl.status !== 2 &&
                tl.status !== 3 &&
                tl.status !== 4 &&
                tl.daysTillDue !== undefined &&
                tl.daysTillDue >= 0
            )
            .sort(compareDates)
            .map((todoList) => {
              return (
                <TodoListCard
                  ship={ship}
                  todoList={todoList}
                  roles={roles}
                  completed={false}
                  users={users}
                />
              );
            })
        )}
        <Grid item xs={12}>
          <Divider sx={{ mt: 2, mb: 2 }} variant="fullWidth">
            <Chip label="COMPLETE" size="medium" color="success" />
          </Divider>
        </Grid>
        {todoLists.filter((tl) => tl.status === 2).length > 0 ? (
          todoLists
            .filter((tl) => tl.status === 2)
            .sort(compareCompletedDate)
            .map((todoList) => {
              return (
                <TodoListCard
                  ship={ship}
                  todoList={todoList}
                  roles={roles}
                  completed={true}
                  users={users}
                />
              );
            })
        ) : (
          <Box />
        )}
      </Grid>
    </Container>
  );
}
