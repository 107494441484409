import { Alert, Box, Grid, Typography } from "@mui/material";
import { Gauge, gaugeClasses } from "@mui/x-charts";
import { getGaugeColor } from "../../utilities/UsageUtilityGauge";

export type BillingQoutaProps = {
  metric: number;
  maxMetric: number;
  metricName: string;
  pluralMetricName: string;
  costPerAdditional: number;
  displayMetricName: boolean;
};

export default function BillingQouta(props: BillingQoutaProps) {
  const {
    metric,
    displayMetricName,
    maxMetric,
    metricName,
    pluralMetricName,
    costPerAdditional,
  } = props;
  return (
    <Grid container spacing={1}>
      {displayMetricName ? (
        <Grid item xs={12}>
          <Typography sx={{ textTransform: "uppercase" }} variant="h6">
            {metricName} Usage:
          </Typography>
        </Grid>
      ) : (
        <Box />
      )}

      <Grid item xs={12} md={6}>
        <Gauge
          value={metric}
          height={200}
          width={200}
          cornerRadius="50%"
          valueMax={maxMetric}
          sx={(theme) => ({
            fontSize: 32,
            [`& .${gaugeClasses.valueArc}`]: {
              fill: getGaugeColor(theme, maxMetric, metric),
            },
          })}
          text={({ value, valueMax }) => `${value} / ${valueMax}`}
        ></Gauge>
      </Grid>
      <Grid item xs={12} md={6} alignContent="center">
        {(metric / maxMetric) * 100 >= 75 &&
        (metric / maxMetric) * 100 < 100 ? (
          <Alert
            severity="info"
            variant="outlined"
            sx={{ color: "white" }}
            title="lorem ipsum"
          >
            {`You are about to reach the maximum number of ${pluralMetricName}. Each additional
        ${metricName} will be billed at a rate of $${costPerAdditional} per ${metricName} per month. Additional ${metricName} bundles are available.`}
          </Alert>
        ) : (
          <Box />
        )}
        {(metric / maxMetric) * 100 >= 100 ? (
          <Alert
            severity="warning"
            variant="outlined"
            sx={{ color: "white" }}
            title="lorem ipsum"
          >
            {`You have reached the maximum number of ${pluralMetricName}. Each additional
        ${metricName} will be billed at a rate of $${costPerAdditional} per ${metricName} per month. Additional ${metricName} bundles are available.`}
          </Alert>
        ) : (
          <Box />
        )}
      </Grid>
    </Grid>
  );
}
