import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Input,
  LinearProgress,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Ship } from "../../types/Ship";
import { Equipment } from "../../types/Equipment";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import {
  downloadEquipmentFile,
  getEquipmentById,
  getShip,
  uploadEquipmentFile,
} from "../../services/shipService";
import moment from "moment";
import {
  Add,
  CloudUpload,
  Construction,
  DirectionsBoat,
  Download,
  Edit,
  LocalPolice,
  Note,
  Policy,
  Warning,
  Error,
  NoteAdd,
  CalendarMonth,
} from "@mui/icons-material";
import { LineChart } from "@mui/x-charts";
import {
  DataGrid,
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import parse from "html-react-parser";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import AccordianPaperSection from "../../components/AccordianPaperSection";
import PaperSection from "../../components/PaperSection";
import { IconEngine, IconFile, IconList } from "@tabler/icons-react";

export default function EquipmentItem() {
  const params = useParams();
  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [equipment, setEquipment] = useState<Equipment>();
  const [note, setNote] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [file, setFile] = useState<any>();
  const [helperName, setHelperName] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [selectedShip] = useRecoilState(SelectedShipState);

  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
      getEquipmentById(selectedClient.id, params.id, params.equipmentid).then(
        (equipmentRes) => {
          setEquipment(equipmentRes);
        }
      );
    });
  }, [selectedClient, params.id, params.equipmentid]);

  const columns = [
    {
      field: "lastUpdated",
      headerName: "Last Updated",
      valueFormatter: (params: any) => {
        return moment(params.value).format("MM/DD/YYYY HH:MM:SS");
      },
      width: 150,
    },
    {
      field: "value",
      headerName: `Value (${equipment?.usageUnits})`,
      valueGetter: (params: any) => {
        return params.row.value;
      },
      width: 150,
    },
    {
      field: "userName",
      headerName: `User Name`,
      valueGetter: (params: any) => {
        return params.row.userName;
      },
      width: 150,
    },
    {
      field: "notes",
      type: "actions",
      headerName: "Notes",
      flex: 1,
      width: 50,
      cellClassName: "actions",
      getActions: (params: GridRowParams): any => {
        return [
          <GridActionsCellItem
            icon={<Note />}
            label="View"
            onClick={() => {
              setNote(params.row.notes);
              setOpen(true);
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const columnsFile = [
    {
      field: "helperName",
      headerName: `Name`,
      valueGetter: (params: any) => {
        return params.row.helperName;
      },
      width: 150,
    },
    {
      field: "userName",
      headerName: `Uploaded By`,
      valueGetter: (params: any) => {
        return params.row.userName;
      },
      width: 150,
    },
    {
      field: "createdDate",
      headerName: "Upload Date",
      valueFormatter: (params: any) => {
        return moment(params.value).format("MM/DD/YYYY HH:MM:SS");
      },
      width: 150,
    },
    {
      field: "download",
      type: "actions",
      headerName: "Download",
      flex: 1,
      width: 50,
      cellClassName: "actions",
      getActions: (params: GridRowParams): any => {
        return [
          <GridActionsCellItem
            icon={<Download />}
            label="Download"
            onClick={() => {
              setDownloading(true);
              downloadEquipmentFile(params.row.id, selectedClient.id).then(
                (response) => {
                  setDownloading(false);
                }
              );
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const columnsFault = [
    {
      field: "isEmergency",
      headerName: `Priority`,
      width: 100,
      valueGetter: (params: any) => {
        return params.row.isEmergency;
      },
      renderCell: (params: GridRenderCellParams<any, Date>) =>
        params.row.isEmergency ? (
          <LocalPolice sx={{ color: "red" }} />
        ) : (
          <Policy />
        ),
    },
    {
      field: "status",
      headerName: `Status`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.status;
      },
      renderCell: (params: GridRenderCellParams<any, Date>) =>
        params.row.status === 0 ? (
          <Chip label="Open" />
        ) : params.row.status === 1 ? (
          <Chip label="In Review" />
        ) : params.row.status === 2 ? (
          <Chip label="Closed" />
        ) : (
          <Chip label="Deferred" />
        ),
    },
    {
      field: "title",
      headerName: `Title`,
      width: 300,
      valueGetter: (params: any) => {
        return params.row.title;
      },
    },
    {
      field: "location",
      headerName: `Location`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.location;
      },
    },
    {
      field: "userName",
      headerName: `Reporter`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.creator.userName;
      },
    },
    {
      field: "createdDate",
      headerName: `Created Date`,
      width: 150,
      valueGetter: (params: any) => {
        return moment(params.row.createdDate)
          .utc()
          .local()
          .format("MM/DD/YYYY");
      },
    },
    {
      field: "resolutionTime",
      headerName: `Resolution Date`,
      width: 150,
      valueGetter: (params: any) => {
        if (params.row.status !== 2) {
          return "N/A";
        }
        return moment(params.row.resolutionTime)
          .utc()
          .local()
          .format("MM/DD/YYYY");
      },
    },
  ];
  const columnsUsageBased = [
    {
      field: "name",
      headerName: "Name",
      valueGetter: (params: any) => {
        return params.row.name;
      },
      width: 150,
    },
    {
      field: "priority",
      headerName: "Priority",
      valueGetter: (params: any) => {
        var priority = params.row.priority;
        if (priority === 1) {
          return "Critical";
        } else if (priority === 2) {
          return "Important";
        } else {
          return "Required";
        }
      },
      width: 150,
    },
    {
      field: "lastUsageUpdate",
      headerName: "Last Performed Date",
      valueFormatter: (params: any) => {
        if (params.value === "0001-01-01T00:00:00") {
          return "Never";
        }
        return moment(params.value).format("MM/DD/YYYY");
      },
      width: 150,
    },
    {
      field: "lastPerformedUsage",
      headerName: "Last Performed Usage",
      valueGetter: (params: any) => {
        if (params.row.lastPerformedUsage === 0) {
          return "No Completed Tasks";
        } else {
          return params.row.lastPerformedUsage;
        }
      },
      width: 150,
    },
    {
      field: "unitFrequencyInterval",
      headerName: "Unit Frequency Interval",
      valueGetter: (params: any) => {
        return `${params.row.unitFrequencyInterval} (${equipment?.usageUnits})`;
      },
      width: 150,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Log",
      width: 25,
      cellClassName: "actions",
      getActions: (params: GridRowParams): any => {
        if (
          params.row.usageSinceLastUpdate >= params.row.unitFrequencyInterval ||
          params.row.lastPerformedUsage === 0
        ) {
          return [
            <GridActionsCellItem
              icon={<Error sx={{ color: "red" }} />}
              label="Log Usage"
              onClick={() => {
                navigate(
                  `/Ships/${ship?.id}/tasks/equipment/${params.row.id}/complete`
                );
              }}
              color="inherit"
            />,
          ];
        } else if (
          params.row.usageSinceLastUpdate / params.row.unitFrequencyInterval >=
          0.75
        ) {
          return [
            <GridActionsCellItem
              icon={<Warning sx={{ color: "yellow" }} />}
              label="Log Usage"
              onClick={() => {
                navigate(
                  `/Ships/${ship?.id}/tasks/equipment/${params.row.id}/complete`
                );
              }}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<NoteAdd />}
            label="Log Usage"
            onClick={() => {
              navigate(
                `/Ships/${ship?.id}/tasks/equipment/${params.row.id}/complete`
              );
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const columnsDateBased = [
    {
      field: "name",
      headerName: "Name",
      valueGetter: (params: any) => {
        return params.row.name;
      },
      width: 150,
    },
    {
      field: "priority",
      headerName: "Priority",
      valueGetter: (params: any) => {
        var priority = params.row.priority;
        if (priority === 1) {
          return "Critical";
        } else if (priority === 2) {
          return "Important";
        } else {
          return "Required";
        }
      },
      width: 150,
    },
    {
      field: "dateFrequencyInterval",
      headerName: "Date Frequency Interval",
      valueGetter: (params: any) => {
        if (params.row.dateFrequencyInterval === 1) {
          return `Set Interval`;
        } else if (params.row.dateFrequencyInterval === 2) {
          return `Monthly`;
        } else {
          return "Not Date Based";
        }
      },
      width: 150,
    },
    {
      field: "dateFrequency",
      headerName: "Date Frequency",
      valueGetter: (params: any) => {
        return params.row.dateFrequency;
      },
      width: 150,
    },
    {
      field: "lastUsageUpdate",
      headerName: "Last Performed Date",
      valueFormatter: (params: any) => {
        if (params.value === "0001-01-01T00:00:00") {
          return "Never";
        }
        return moment(params.value).format("MM/DD/YYYY");
      },
      width: 150,
    },
    {
      field: "daysUntilNextUpdate",
      headerName: "Days Until Next Update",
      valueGetter: (params: any) => {
        if (params.row.daysUntilNextUpdate < 0) {
          return 0;
        }
        return params.row.daysUntilNextUpdate;
      },
      width: 150,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Log",
      width: 25,
      cellClassName: "actions",
      getActions: (params: GridRowParams): any => {
        if (params.row.daysUntilNextUpdate <= 0) {
          return [
            <GridActionsCellItem
              icon={<Error sx={{ color: "red" }} />}
              label="Log Usage"
              onClick={() => {
                navigate(
                  `/Ships/${ship?.id}/tasks/time/${params.row.id}/complete`
                );
              }}
              color="inherit"
            />,
          ];
        } else if (
          params.row.daysUntilNextUpdate / params.row.dateFrequency <=
          0.25
        ) {
          return [
            <GridActionsCellItem
              icon={<Warning sx={{ color: "yellow" }} />}
              label="Log Usage"
              onClick={() => {
                navigate(
                  `/Ships/${ship?.id}/tasks/time/${params.row.id}/complete`
                );
              }}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<NoteAdd />}
            label="Log Usage"
            onClick={() => {
              navigate(
                `/Ships/${ship?.id}/tasks/time/${params.row.id}/complete`
              );
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const onUploadProgress = (progressevent: ProgressEvent) => {
    const percentCompleted = Math.round(
      (progressevent.loaded * 100) / progressevent.total
    );
    setUploadProgress(percentCompleted);
    if (percentCompleted === 100) {
      getShip(params.id, selectedClient.id).then((data) => {
        setShip(data);
        getEquipmentById(selectedClient.id, params.id, params.equipmentid).then(
          (equipmentRes) => {
            setEquipment(equipmentRes);
            setUploadProgress(0);
            setUploading(false);
            setUploadOpen(false);
            setHelperName("");
            setFile(null);
          }
        );
      });
    }
  };

  function handleFileChange(event: any) {
    setFile(event.target.files[0]);
  }

  function handleUploadSubmit(event: any) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.Name);
    setUploading(true);
    uploadEquipmentFile(
      onUploadProgress,
      selectedClient.id,
      ship?.id,
      equipment?.id,
      formData,
      helperName
    ).then((res) => {
      getShip(params.id, selectedClient.id).then((data) => {
        setShip(data);
        getEquipmentById(selectedClient.id, params.id, params.equipmentid).then(
          (equipmentRes) => {
            setEquipment(equipmentRes);
            setUploading(false);
            setUploadOpen(false);
            setHelperName("");
            setFile(null);
            setUploadProgress(0);
          }
        );
      });
    });
  }

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Modal
        open={uploadOpen}
        onClose={() => {
          setUploadOpen(false);
          setUploading(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Card sx={{ mb: 4 }}>
            <CardContent sx={{ background: "rgba(255, 255, 255, 0.12)" }}>
              {!uploading ? (
                <Box>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Upload New File:
                  </Typography>
                  <form onSubmit={handleUploadSubmit}>
                    <TextField
                      label="Name"
                      value={helperName}
                      onChange={(event) => setHelperName(event.target.value)}
                      fullWidth
                    />
                    <Input
                      sx={{ mb: 2 }}
                      type="file"
                      onChange={handleFileChange}
                    />
                    <Button type="submit" variant="contained" fullWidth>
                      Upload
                    </Button>
                  </form>
                </Box>
              ) : (
                <Box>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Uploading...{" "}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress}
                  />
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
      </Modal>

      <Modal open={downloading} onClose={() => {}}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            height: 200,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h5" sx={{ mb: 4 }}>
            Downloading...
          </Typography>
          <LinearProgress />
        </Box>
      </Modal>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Card sx={{ mb: 4 }}>
            <CardContent sx={{ background: "rgba(255, 255, 255, 0.12)" }}>
              <Typography variant="h5">Notes:</Typography>
              {parse(note)}
            </CardContent>
          </Card>
        </Box>
      </Modal>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}/equipment`)}
          startIcon={<Construction sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Equipment
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Construction sx={{ mr: 0.5 }} fontSize="inherit" />
          {equipment?.name}
        </Typography>
      </Breadcrumbs>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AccordianPaperSection
            title={equipment?.name}
            icon={<Construction />}
            button={
              <Button
                variant="contained"
                onClick={() =>
                  navigate(`/Ships/${ship?.id}/equipment/${equipment?.id}/edit`)
                }
                startIcon={<Edit />}
              >
                Edit
              </Button>
            }
            collapsed={true}
            collapsedText="Equipment Information"
          >
            <Grid container spacing={2} sx={{ p: 4 }}>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Manufacturer: </Typography>
                  <Typography>{equipment?.manufacturer}</Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Model Number: </Typography>
                  <Typography>{equipment?.modelNumber}</Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Manufactured Date: </Typography>
                  <Typography>
                    {moment(equipment?.manufacturedDate).format("MM/DD/YYYY")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Serial Number: </Typography>
                  <Typography>{equipment?.serialNumber}</Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Location Installed: </Typography>
                  <Typography>{equipment?.locationInstalled}</Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Warranty: </Typography>
                  <Typography>{equipment?.warranty}</Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">
                    Warranty Expiration Date:{" "}
                  </Typography>
                  <Typography>
                    {equipment?.warrantyExpiry === null
                      ? ""
                      : moment(equipment?.warrantyExpiry).format("MM/DD/YYYY")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Notes: </Typography>
                  <Typography>{equipment?.notes}</Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Usage Units: </Typography>
                  <Typography>{equipment?.usageUnits}</Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Created Date: </Typography>
                  <Typography>
                    {moment(equipment?.createdDate).format("MM/DD/YYYY")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Update Frequency: </Typography>
                  <Typography>
                    {equipment?.updateFrequency + " Days"}
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Actual Usage Meter: </Typography>
                  <Typography>{`${equipment?.actualUsageMeter} ${equipment?.usageUnits}`}</Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Last Usage Updated On: </Typography>
                  <Typography>
                    {moment(equipment?.lastUsageUpdatedOn).format("MM/DD/YYYY")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Days Since Last Update: </Typography>
                  <Typography>
                    {equipment?.daysSinceLastUpdate + " Days"}
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Daily Usage In Port: </Typography>
                  <Typography>
                    {equipment?.dailyUsageInPort +
                      ` (In ${equipment?.usageUnits})`}
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Daily Usage In Transit: </Typography>
                  <Typography>
                    {equipment?.dailyUsageInTransit +
                      ` (In ${equipment?.usageUnits})`}
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">
                    Daily Usage In Dry Dock:{" "}
                  </Typography>
                  <Typography>
                    {equipment?.dailyUsageDryDock +
                      ` (In ${equipment?.usageUnits})`}
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">
                    Daily Usage While Chartered:{" "}
                  </Typography>
                  <Typography>
                    {equipment?.dailyUsageChartered +
                      ` (In ${equipment?.usageUnits})`}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </AccordianPaperSection>
        </Grid>
        <Grid item xs={12}>
          <PaperSection
            title="Log"
            icon={<IconList />}
            button={
              <Button
                variant="contained"
                onClick={() =>
                  navigate(
                    `/Ships/${ship?.id}/equipment/${equipment?.id}/newlog`
                  )
                }
                startIcon={<Add />}
              >
                New Log
              </Button>
            }
          >
            {equipment?.log === undefined ? (
              <Box />
            ) : (
              <Stack alignItems="center" justifyContent="center">
                <LineChart
                  width={1000}
                  height={300}
                  series={[
                    {
                      data: equipment?.log?.map((eq) =>
                        eq.value === undefined ? 0 : eq.value
                      ),
                      label: `${equipment?.usageUnits}`,
                    },
                  ]}
                  xAxis={[
                    {
                      scaleType: "point",
                      data: equipment?.log?.map<any>((eq) =>
                        moment(eq.lastUpdated).format("MM/DD/YYYY")
                      ),
                    },
                  ]}
                />
              </Stack>
            )}
            {equipment?.log === undefined ? (
              <Box />
            ) : (
              <DataGrid
                sx={{ height: "300px", minHeight: "300px" }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "lastUpdated", sort: "desc" }],
                  },
                }}
                columns={columns}
                rows={equipment?.log}
              />
            )}
          </PaperSection>
        </Grid>
        <Grid item xs={12} md={6}>
          <PaperSection title="Usage Tasks" icon={<IconEngine />}>
            {equipment?.tasks === undefined ? (
              <Box />
            ) : (
              <DataGrid
                sx={{ height: "300px", minHeight: "300px" }}
                onRowClick={(value) =>
                  navigate(`/Ships/${ship?.id}/tasks/equipment/${value.id}`)
                }
                columns={columnsUsageBased}
                rows={equipment.tasks.filter(
                  (t) => t.dateFrequencyInterval === 0
                )}
              />
            )}
          </PaperSection>
        </Grid>
        <Grid item xs={12} md={6}>
          <PaperSection title="Date Tasks" icon={<CalendarMonth />}>
            {equipment?.tasks === undefined ? (
              <Box />
            ) : (
              <DataGrid
                sx={{ minHeight: "300px", maxHeight: "300px" }}
                onRowClick={(value) =>
                  navigate(`/Ships/${ship?.id}/tasks/time/${value.id}`)
                }
                columns={columnsDateBased}
                rows={equipment.tasks.filter((t) => t.dateFrequencyInterval !== 0)}
              />
            )}
          </PaperSection>
        </Grid>
        <Grid item xs={12}>
          <PaperSection title="Faults">
            {equipment?.incidents === undefined ? (
              <Box />
            ) : (
              <DataGrid
                rows={equipment.incidents}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "status", sort: "asc" }],
                  },
                }}
                sx={{ minHeight: "300px", height: "300px" }}
                onRowClick={(params) =>
                  navigate(`/ships/${selectedShip.id}/logbook/${params.row.id}`)
                }
                columns={columnsFault}
              />
            )}
          </PaperSection>
        </Grid>
        <Grid item xs={12}>
          <PaperSection
            title="Files"
            icon={<IconFile />}
            button={
              <Button
                variant="contained"
                onClick={() => setUploadOpen(true)}
                startIcon={<CloudUpload />}
              >
                Upload
              </Button>
            }
          >
            {equipment?.files !== undefined ? (
              <DataGrid
                sx={{ height: "300px", minHeight: "300px" }}
                columns={columnsFile}
                rows={equipment?.files}
              />
            ) : (
              <Box />
            )}
          </PaperSection>
        </Grid>
      </Grid>

      <Box />
    </Container>
  );
}
