import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  completeEquipmentTask,
  getShip,
  getTask,
} from "../../services/shipService";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { Ship } from "../../types/Ship";
import { EquipmentTaskDTO, PartConsumption, Task } from "../../types/Task";
import * as yup from "yup";
import { Formik } from "formik";
import React from "react";
import {
  ArrowRightAlt,
  DirectionsBoat,
  Error,
  TaskAlt,
  Task as TaskIcon,
  Warning,
} from "@mui/icons-material";
import parse from "html-react-parser";
import { Editor } from "@tinymce/tinymce-react";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";

export default function CompleteEquipmentTask() {
  const params = useParams();
  const navigate = useNavigate();
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [ship, setShip] = useState<Ship>();
  const [task, setTask] = useState<Task>();
  const [selectedShip] = useRecoilState(SelectedShipState);

  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
      getTask(selectedClient.id, params.id, params.taskid).then((taskRes) => {
        setTask(taskRes);
      });
    });
  }, [selectedClient, selectedClient.id, params.id, params.taskid]);
  const validationSchema = yup.object({
    value: yup
      .number()
      .moreThan(0)
      .required("Usage cannot be lower than zero."),
  });
  const initialState = {
    value: task?.equipment?.actualUsageMeter,
    notes: "",
  };
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}`)}
          startIcon={<TaskAlt sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Tasks
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() =>
            navigate(`/ships/${ship?.id}/tasks/equipment/${task?.id}`)
          }
          startIcon={<TaskIcon sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {task?.name}
        </Button>

        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <TaskAlt sx={{ mr: 0.5 }} fontSize="inherit" />
          Log Completion
        </Typography>
      </Breadcrumbs>
      <Stack direction="column" spacing={0}>
        {task?.instructions !== undefined && task?.instructions !== "" ? (
          <React.Fragment>
            <Typography variant="h4">Instructions:</Typography>

            <Card sx={{ mb: 4 }}>{parse(task.instructions)}</Card>
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}

        {task?.requiresPart === true ? (
          <React.Fragment>
            <Typography variant="h4">Parts Needed:</Typography>
            <Card sx={{ p: 4, mb: 2 }}>
              {task?.parts?.map((p) => {
                return (
                  <Typography variant="h5">
                    {p.part?.name}, Amount Required: {p.partQuantity} items
                  </Typography>
                );
              })}
            </Card>
            <Typography variant="h4">Inventory Changes:</Typography>
            <Card sx={{ p: 4, mb: 2 }}>
              {task?.parts?.map((p) => {
                if (
                  p.part?.quantity !== undefined &&
                  p.partQuantity !== undefined
                ) {
                  if (p.part?.quantity <= p.partQuantity) {
                    return (
                      <Typography variant="h5">
                        {p.part?.name}, Current Inventory: {p.part?.quantity}{" "}
                        <ArrowRightAlt /> New Inventory:{" "}
                        {p.part?.quantity - p.partQuantity < 0
                          ? 0
                          : p.part?.quantity - p.partQuantity}
                        <Typography variant="h5" sx={{ color: "red" }}>
                          <Error /> Current Inventory is below minimum of{" "}
                          {p.partQuantity} required
                        </Typography>
                      </Typography>
                    );
                  }
                  return (
                    <Typography variant="h5">
                      {p.part?.name}, Current Inventory: {p.part?.quantity}{" "}
                      <ArrowRightAlt /> New Inventory:{" "}
                      {p.part?.quantity - p.partQuantity < 0
                        ? 0
                        : p.part?.quantity - p.partQuantity}
                      {p.part?.quantity - p.partQuantity <
                      p.part.minimumInventory ? (
                        <Typography variant="h5" sx={{ color: "yellow" }}>
                          <Warning /> New Inventory for {p.part.name} is below
                          minimum of {p.part.minimumInventory}
                        </Typography>
                      ) : (
                        <React.Fragment />
                      )}
                    </Typography>
                  );
                }
                return <React.Fragment />;
              })}
            </Card>
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
        <Typography variant="h4" sx={{ mb: 2 }}>
          Update Usage for {task?.equipment?.name}:
        </Typography>
      </Stack>
      {task?.forEquipment === true ? (
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            var parts: PartConsumption[] = [];
            task.parts?.forEach((p) => {
              let pc: PartConsumption = {
                partId: p.shipPartId,
                amount: p.partQuantity
              };
              parts.push(pc);
            });
            var taskComplete: EquipmentTaskDTO = {
              taskId: params.taskid,
              equipmentId: task.equipmentId,
              notes: values.notes,
              equipmentValue: values.value,
              partsUsed: parts
            };
            console.log(taskComplete);
            completeEquipmentTask(
              selectedClient.id,
              ship?.id,
              taskComplete
            ).then((res) => navigate(`/ships/${ship?.id}`));
          }}
        >
          {({
            errors,
            handleSubmit,
            handleChange,
            handleBlur,
            touched,
            setFieldValue,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                id="value"
                name="value"
                label={`Update Usage (in ${task?.equipment?.usageUnits})`}
                value={values.value}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.value && Boolean(errors.value)}
                helperText={touched.value && errors.value}
                fullWidth
                sx={{ mb: 2 }}
              />
              {task?.requiresPart ? (
                task.parts?.map((part) => (
                  <TextField
                    id={part.id}
                    name={part.part?.name}
                    label={`Amount of ${part.part?.name} used`}
                    fullWidth
                    value={part.partQuantity}
                    onChange={(e) => {
                      part.partQuantity = Number(e.target.value);
                      setTask(JSON.parse(JSON.stringify(task)));
                    }}
                    sx={{ mb: 2 }}
                  />
                ))
              ) : (
                <Box />
              )}
              <Typography variant="subtitle1">Notes:</Typography>
              <Editor
                apiKey="s7hflnfnqq2c0nxj75udyor3kobji9x4jv2xgc4xvsdhtju5"
                init={{
                  height: 500,
                  menubar: true,
                  skin: "oxide-dark",
                  content_css: "dark",
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
                textareaName="notes"
                onEditorChange={(stringifiedHTML) => {
                  setFieldValue("notes", stringifiedHTML);
                }}
              />
              <Button
                color="primary"
                variant="contained"
                fullWidth
                sx={{ mt: 2 }}
                type="submit"
              >
                Complete Task
              </Button>
            </form>
          )}
        </Formik>
      ) : (
        <React.Fragment />
      )}
    </Container>
  );
}
