import {
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { getEquipment } from "../../services/shipService";
import { Equipment } from "../../types/Equipment";

export type EquipmentDueCounterPropsType = {
  selectedShipId?: string;
};

export default function EquipmentDueCounter(
  props: EquipmentDueCounterPropsType
) {
  const navigate = useNavigate();
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [overdueEquipmentCount, setOverdueEquipment] = useState(0);

  useEffect(() => {
    if (props.selectedShipId !== undefined && selectedClient.id !== undefined) {
      getEquipment(selectedClient.id, props.selectedShipId).then(
        (eq: Equipment[]) => {
          setOverdueEquipment(
            eq.filter(
              (equip) =>
                equip.daysSinceLastUpdate !== undefined &&
                equip.daysSinceLastUpdate > equip.updateFrequency
            ).length
          );
        }
      );
    }
  }, [selectedClient.id, props.selectedShipId]);

  return (
    <Paper
      sx={{
        p: 2,
        height: "100%",
        width: "100%",
        borderTopColor: "#b47f49",
        borderTopWidth: "4px",
        borderStyle: "solid",
        borderRightWidth: "0",
        borderLeftWidth: "0",
        borderBottomWidth: "0",
      }}
      elevation={24}
      component={Button}
      onClick={() => navigate(`/ships/${props.selectedShipId}/equipment`)}
    >
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="column" alignItems="center">
            <Typography variant="body1">Equipment Log Required</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="column" alignItems="center">
            <Typography variant="h1">{overdueEquipmentCount}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}
