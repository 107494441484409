import { Breadcrumbs, Button, Container, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Formik } from "formik";
import { DateTime } from "luxon";
import { Ship } from "../../types/Ship";
import * as yup from "yup";
import { useRecoilState, useSetRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { editShip, getShip, getShips } from "../../services/shipService";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ShipsState } from "../../Recoil/atoms/ShipsAtom";
import { DirectionsBoat, Edit } from "@mui/icons-material";

export default function EditShip() {
  const params = useParams();
  const [selectedClient] = useRecoilState(SelectedClientState);
  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const setShips = useSetRecoilState(ShipsState);
  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
    });
  }, [selectedClient, selectedClient.id, params.id]);


    const validationSchema = yup.object({
      name: yup.string().required("Ship name is required."),
      make: yup.string().required("Ship make is required."),
      model: yup.string().required("Ship model is required."),
      year: yup
        .number()
        .positive()
        .lessThan(DateTime.now().year + 1)
        .required("Ship year is required."),
      size: yup.number().required("Size is required"),
      purchasePrice: yup.number().required("Ship purchase price is required"),
      type: yup.string().required("Ship type is required"),
      purchaseDate: yup.date().required("Ship purchase date is required"),
    });

  const initialState = {
    name: ship !== undefined ? ship.name : "",
    make: ship !== undefined ? ship.make : "",
    model: ship !== undefined ? ship.model : "",
    year: ship !== undefined ? ship.year : DateTime.now().year,
    size: ship !== undefined ? ship.sizeInFeet.toString() : "",
    purchasePrice: ship !== undefined ? ship.purchasePrice : 0,
    purchaseDate: ship !== undefined ? DateTime.fromISO(ship.purchaseDate.toString()) : DateTime.now(),
    type: ship !== undefined ? ship.type : "",
  };
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>

<Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/`)}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {ship?.name}
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Edit sx={{ mr: 0.5 }} fontSize="inherit" />
          Edit
        </Typography>
      </Breadcrumbs>
        <Formik
          initialValues={initialState}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values) => {
            var shipto: Ship = {
              id: ship?.id,
              name: values.name,
              make: values.make,
              model: values.model,
              year: values.year,
              sizeInFeet: parseInt(values.size),
              purchasePrice: values.purchasePrice,
              purchaseDate: values.purchaseDate,
              type: values.type,
              clientId: selectedClient.id,
              createdDate: ship?.createdDate,
            };
            editShip(shipto).then((res) =>{
              getShips(selectedClient.id)
              .then((response) => {
                setShips(response);
                navigate(`/`);
              });
            });
          }}
        >
          {({
            errors,
            handleSubmit,
            handleChange,
            handleBlur,
            touched,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                id="name"
                name="name"
                label="Name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                id="make"
                name="make"
                label="Make"
                value={values.make}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.make && Boolean(errors.make)}
                helperText={touched.make && errors.make}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                id="model"
                name="model"
                label="Model"
                value={values.model}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.model && Boolean(errors.model)}
                helperText={touched.model && errors.model}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                id="year"
                name="year"
                label="Year"
                value={values.year}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.year && Boolean(errors.year)}
                helperText={touched.year && errors.year}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                id="size"
                name="size"
                label="Size (In Feet)"
                value={values.size}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.size && Boolean(errors.size)}
                helperText={touched.size && errors.size}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                id="type"
                name="type"
                label="Type"
                value={values.type}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.type && Boolean(errors.type)}
                helperText={touched.type && errors.type}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                id="purchasePrice"
                name="purchasePrice"
                label="Purchase Price"
                value={values.purchasePrice}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.purchasePrice && Boolean(errors.purchasePrice)}
                helperText={touched.purchasePrice && errors.purchasePrice}
                fullWidth
                sx={{ mb: 2 }}
              />
              <DatePicker
                disableFuture
                label="purchaseDate"
                value={values.purchaseDate}
                onChange={(value: any) =>
                  setFieldValue("purchaseDate", DateTime.fromISO(value), true)
                }
                sx={{ mb: 2 }}
              />

              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </form>
          )}
        </Formik>
    </Container>
  );
}
