import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useRecoilState, useSetRecoilState } from "recoil";
import { userProfileState } from "../../Recoil/atoms/UserProfileAtom";
import { SelectedShipState, ShipsState } from "../../Recoil/atoms/ShipsAtom";
import { Formik } from "formik";
import { PreferredShipDTO } from "../../types/UserProfile";
import {
  addPreferredShip,
  getUserProfile,
  setPreferredClient,
  updateUserName,
} from "../../services/userservice";
import { useNavigate } from "react-router-dom";
import { SideBarSelectedState } from "../../Recoil/atoms/SideBarSelectedAtom";
import {
  ClientsState,
  SelectedClientState,
} from "../../Recoil/atoms/ClientsAtom";
import { AutoAwesome, Business, Person, Sailing } from "@mui/icons-material";
import { getPendingInvites } from "../../services/invitationservice";
import { getShips } from "../../services/shipService";
import { DateTime } from "luxon";
import { PendingInvitationsState } from "../../Recoil/atoms/InvitationsAtom";
import IsUserElevated from "../../utilities/userUtils";
import PaperSection from "../../components/PaperSection";
import { useState } from "react";

export default function Preferences() {
  const [userProfile, setUserProfile] = useRecoilState(userProfileState);
  const [ships, setShips] = useRecoilState(ShipsState);
  const navigate = useNavigate();
  const setPendingInvites = useSetRecoilState(PendingInvitationsState);
  const [selectedClient, setSelectedClient] =
    useRecoilState(SelectedClientState);
  const setSelected = useSetRecoilState(SideBarSelectedState);
  const [clients] = useRecoilState(ClientsState);
  const setSelelectedShip = useSetRecoilState(SelectedShipState);
  const [openProfileSnackbar, setOpenProfileSnackbar] = useState(false);

  const initialValues = {
    preferredShipId:
      userProfile.preferences !== undefined &&
      userProfile.preferences?.filter(
        (pref) => pref.clientId === selectedClient.id
      ).length > 0
        ? userProfile.preferences?.filter(
            (pref) => pref.clientId === selectedClient.id
          )[0].preferredShipId
        : "",
  };
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <AutoAwesome sx={{ mr: 0.5 }} fontSize="inherit" />
          Preferences
        </Typography>
      </Breadcrumbs>
      <Snackbar
        open={openProfileSnackbar}
        autoHideDuration={1000}
        onClose={() => setOpenProfileSnackbar(false)}
        message="Username Updated"
      />
      <Typography variant="h4">Preferences</Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PaperSection title={"User Information"} icon={<Person />}>
            <Formik
              initialValues={userProfile}
              enableReinitialize
              onSubmit={(values) => {
                updateUserName(values.id, values.auth0Id, values.userName).then(
                  (res) => {
                    getUserProfile().then((response) => {
                      setUserProfile(response);
                      setOpenProfileSnackbar(true);
                    });
                  }
                );
              }}
            >
              {({
                handleSubmit,
                values,
                handleChange,
                handleBlur,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    id="userName"
                    name="userName"
                    label="Username"
                    value={values.userName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.userName && Boolean(errors.userName)}
                    helperText={touched.userName && errors.userName}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                </form>
              )}
            </Formik>
          </PaperSection>
        </Grid>
        {clients.length > 1 ? (
          <Grid item xs={12} md={6}>
            <PaperSection icon={<AutoAwesome />} title="Preferred Client">
              <TextField
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AutoAwesome />
                    </InputAdornment>
                  ),
                }}
                select
                value={userProfile.preferredClient}
                sx={{ height: "50px", width: "200px", mr: 2 }}
              >
                {clients.map((c) => (
                  <MenuItem
                    id={c.id}
                    key={c.id}
                    value={c.id}
                    onClick={() => {
                      setPreferredClient(c.id);
                      getUserProfile().then((res) => setUserProfile(res));
                    }}
                  >
                    {c.name}
                  </MenuItem>
                ))}
              </TextField>
            </PaperSection>
          </Grid>
        ) : (
          <Box />
        )}
        {clients.length > 1 ? (
          <Grid item xs={12} md={6}>
            <PaperSection icon={<Business />} title="Client Selection">
              <TextField
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Business />
                    </InputAdornment>
                  ),
                }}
                select
                value={selectedClient.id}
                sx={{ height: "50px", width: "200px", mr: 2 }}
              >
                {clients.map((c) => (
                  <MenuItem
                    id={c.id}
                    key={c.id}
                    value={c.id}
                    onClick={() => {
                      setSelectedClient(c);
                      getShips(c.id).then((response) => {
                        setShips(response);
                        setSelelectedShip({
                          id: "",
                          clientId: "",
                          name: "",
                          make: "",
                          model: "",
                          year: 0,
                          sizeInFeet: 0,
                          purchasePrice: 0,
                          status: 0,
                          type: "",
                          purchaseDate: DateTime.now(),
                          createdDate: DateTime.now(),
                          roles: [],
                        });
                        if (IsUserElevated(userProfile, selectedClient.id)) {
                          getPendingInvites(c.id).then((res) =>
                            setPendingInvites(res)
                          );
                        }
                        navigate("/");
                      });
                    }}
                  >
                    {c.name}
                  </MenuItem>
                ))}
              </TextField>
            </PaperSection>
          </Grid>
        ) : (
          <Box />
        )}
        <Grid item xs={12}>
          <PaperSection title={"Preferred Ship"} icon={<Sailing />}>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={(values) => {
                console.log(values);
                var preferredShipDTO: PreferredShipDTO = {
                  id: values.preferredShipId,
                };
                addPreferredShip(preferredShipDTO, selectedClient.id).then(
                  (res) => {
                    getUserProfile().then((response) => {
                      setUserProfile(response);
                      setSelected("Dashboard");
                      navigate("/");
                    });
                  }
                );
              }}
            >
              {({ handleSubmit, values, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl fullWidth sx={{ mb: 4, mt: 4 }}>
                    <InputLabel id="demo-simple-select-label">
                      Primary Ship
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.preferredShipId}
                      onChange={(event) => {
                        setFieldValue("preferredShipId", event.target.value);
                      }}
                    >
                      {ships.map((s) => (
                        <MenuItem id={s.id} key={s.id} value={s.id}>
                          {s.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                </form>
              )}
            </Formik>
          </PaperSection>
        </Grid>
      </Grid>
    </Container>
  );
}
