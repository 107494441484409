import { atom } from "recoil";
import { Invitation } from "../../types/Invitation";

export const InvitationsState = atom<Invitation[]>({
    key: "invitations",
    default: []
})

export const PendingInvitationsState = atom<Invitation[]>({
    key: "pendingInvitations",
    default: []
});