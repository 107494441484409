import { Breadcrumbs, Button, Container, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getShip } from "../../../services/shipService";
import { useNavigate, useParams } from "react-router-dom";
import { Ship } from "../../../types/Ship";
import { ShipTodoListTemplate } from "../../../types/TodoList";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../../Recoil/atoms/ClientsAtom";
import { getTodoListTemplates } from "../../../services/todoListTemplateService";
import {
    Add,
  CheckBox,
  DirectionsBoat,
  HistoryEdu,
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { SelectedShipState } from "../../../Recoil/atoms/ShipsAtom";
import moment from "moment";

export default function TodoListTemplates() {
  const params = useParams();
  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [todoListTemplates, setTodoListTemplates] = useState<
    ShipTodoListTemplate[]
  >([]);
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
      getTodoListTemplates(params.id, selectedClient.id).then((res) => {
        setTodoListTemplates(res);
      });
    });
  }, [selectedClient, params.id]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      valueGetter: (params: any) => {
        return params.row.name;
      },
      flex: 1,
      width: 300,
    },
    {
      field: "description",
      headerName: "Description",
      valueGetter: (params: any) => {
        return params.row.description;
      },
      flex: 1,
      width: 300,
    },
    {
      field: "itemCount",
      headerName: "Item Count",
      valueGetter: (params: any) => {
        return params.row.items.length;
      },
      flex: 1,
      width: 300,
    },
    {
      field: "dateLastAssigned",
      headerName: "Date Last Assigned",
      valueGetter: (params: any) =>{
        if(moment(params.row.dateLastAssigned).year() === 1806){
          return "Never";
        }else{
          return moment.utc(params.row.dateLastAssigned).local().format("MM/DD/YYYY");
        }
      },
      flex: 1,
      width: 300,
    }
  ];

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}/todolists`)}
          startIcon={<CheckBox sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Todo Lists
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <HistoryEdu sx={{ mr: 0.5 }} fontSize="inherit" />
          Templates
        </Typography>
      </Breadcrumbs>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Todo List Templates
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate(`/ships/${ship?.id}/todolists/templates/new`);
          }}
          startIcon={<Add />}
        >
          New Template
        </Button>
      </Stack>
      <DataGrid sx={{ height: "80%", minHeight: "300px" }} onRowClick={(row) => navigate(`/ships/${ship?.id}/todolists/templates/${row.id}`)} columns={columns} rows={todoListTemplates}/>
    </Container>
  );
}
