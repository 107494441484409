import axios from "axios";
import { ShipTodoList, ShipTodoListItem, ShipTodoListItemComment } from "../types/TodoList";

export const getTodoLists = (shipId?: string, clientId?: string) => {
  return axios
    .get(
      `https://api.zsail.io/shiptodolist?clientId=${clientId}&shipId=${shipId}`
    )
    .then((response) => {
      return response.data;
    });
};

export const getMyTodoLists = (clientId?: string) => {
  return axios
    .get(
      `https://api.zsail.io/shiptodolist/mylist?clientId=${clientId}`
    )
    .then((response) => {
      return response.data;
    });
};

export const createTodoList = (
  shipId?: string,
  clientId?: string,
  shipTodoList?: ShipTodoList
) => {
  return axios
    .post(
      `https://api.zsail.io/shiptodolist?clientId=${clientId}&shipId=${shipId}`,
      shipTodoList
    )
    .then((response) => {
      return response.data;
    });
};

export const updateTodoListStatus = (
    shipTodoList?: string,
    clientId?: string,
    status?: number
  ) => {
    return axios
      .put(
        `https://api.zsail.io/shiptodolist?clientId=${clientId}&todoListId=${shipTodoList}&status=${status}`
      )
      .then((response) => {
        return response.data;
      });
  };

  export const getShipTodoList = (todoListId?: string, clientId?: string) => {
    return axios
      .get(
        `https://api.zsail.io/shiptodolist/${todoListId}/items?clientId=${clientId}`
      )
      .then((response) => {
        return response.data;
      });
  };

  export const createTodoListItem = (todoListId?: string, clientId?: string, todoListItem?: ShipTodoListItem) => {
    return axios
      .post(
        `https://api.zsail.io/shiptodolist/${todoListId}/items?clientId=${clientId}`, todoListItem
      )
      .then((response) => {
        return response.data;
      });
  };

  export const updateTodoListItem = (todoListId?: string, clientId?: string, todoListItem?: ShipTodoListItem) => {
    return axios
      .post(
        `https://api.zsail.io/shiptodolist/${todoListId}/items/${todoListItem?.id}?clientId=${clientId}`, todoListItem
      )
      .then((response) => {
        return response.data;
      });
  };
  export const deleteTodoListItem = (todoListId?: string, clientId?: string, todoListItemId?: string) => {
    return axios
      .delete(
        `https://api.zsail.io/shiptodolist/${todoListId}/items/${todoListItemId}?clientId=${clientId}`,
      )
      .then((response) => {
        return response.data;
      });
  };

  export const updateTodoListItemStatus = (todoListId?: string, todoListItemId?: string,  clientId?: string, status?: number, comment?: ShipTodoListItemComment) => {
    if(comment === undefined){
      return axios
      .put(
        `https://api.zsail.io/shiptodolist/${todoListId}/items?clientId=${clientId}&todoListItemId=${todoListItemId}&status=${status}`
      )
      .then((response) => {
        return response.data;
      });
    }
    return axios
      .put(
        `https://api.zsail.io/shiptodolist/${todoListId}/items?clientId=${clientId}&todoListItemId=${todoListItemId}&status=${status}`, comment
      )
      .then((response) => {
        return response.data;
      });
  };
