import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Ship } from "../../types/Ship";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { getPartById, getShip } from "../../services/shipService";
import moment from "moment";
import { Add, Category, DirectionsBoat, Edit, Note } from "@mui/icons-material";
import {
  ChartContainer,
  ChartsReferenceLine,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  MarkPlot,
} from "@mui/x-charts";
import { DataGrid, GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import { Part } from "../../types/Part";
import parse from "html-react-parser";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import AccordianPaperSection from "../../components/AccordianPaperSection";
import PaperSection from "../../components/PaperSection";
import { IconList } from "@tabler/icons-react";

export default function PartItem() {
  const params = useParams();
  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [part, setPart] = useState<Part>();
  const [note, setNote] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [selectedShip] = useRecoilState(SelectedShipState);

  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
      getPartById(selectedClient.id, params.id, params.partid).then(
        (partRes) => {
          setPart(partRes);
        }
      );
    });
  }, [selectedClient, params.id, params.partid]);
  const columns = [
    {
      field: "lastUpdated",
      headerName: "Last Updated",
      valueFormatter: (params: any) => {
        return moment(params.value).format("MM/DD/YYYY HH:MM:SS");
      },
      width: 150,
    },
    {
      field: "quantity",
      headerName: `Quantity`,
      valueGetter: (params: any) => {
        return params.row.quantity;
      },
      width: 150,
    },
    {
      field: "userName",
      headerName: `User Name`,
      valueGetter: (params: any) => {
        return params.row.userName;
      },
      width: 150,
    },
    {
      field: "notes",
      type: "actions",
      headerName: "Notes",
      width: 150,
      cellClassName: "actions",
      getActions: (params: GridRowParams): any => {
        return [
          <GridActionsCellItem
            icon={<Note />}
            label="View"
            onClick={() => {
              setNote(params.row.notes);
              setOpen(true);
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Card sx={{ mb: 4 }}>
            <CardContent sx={{ background: "rgba(255, 255, 255, 0.12)" }}>
              <Typography variant="h5">Notes:</Typography>
              {parse(note)}
            </CardContent>
          </Card>
        </Box>
      </Modal>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}/parts`)}
          startIcon={<Category sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Parts
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Category sx={{ mr: 0.5 }} fontSize="inherit" />
          {part?.name}
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AccordianPaperSection
            title={part?.name}
            icon={<Category />}
            button={
              <Button
                variant="contained"
                onClick={() =>
                  navigate(`/Ships/${ship?.id}/parts/${part?.id}/edit`)
                }
                startIcon={<Edit />}
              >
                Edit
              </Button>
            }
            collapsed={true}
            collapsedText="Part Information"
          >
            <Grid container spacing={2} sx={{ p: 4 }}>
              <Grid xs={12} item>
                <Stack>
                  <Typography variant="h6">Description: </Typography>
                  {part?.description !== undefined ? (
                    <Card sx={{ mb: 4 }}>
                      <CardContent
                        sx={{ background: "rgba(255, 255, 255, 0.12)" }}
                      >
                        {parse(part.description)}
                      </CardContent>
                    </Card>
                  ) : (
                    <Box />
                  )}

                  <Typography>{part?.description}</Typography>
                </Stack>
              </Grid>
              <Grid xs={6} item>
                <Stack>
                  <Typography variant="h6">Maker: </Typography>
                  <Typography>{part?.maker}</Typography>
                </Stack>
              </Grid>
              <Grid xs={6} item>
                <Stack>
                  <Typography variant="h6">Model Number: </Typography>
                  <Typography>{part?.modelNumber}</Typography>
                </Stack>
              </Grid>
              <Grid xs={6} item>
                <Stack>
                  <Typography variant="h6">Markets Part Number: </Typography>
                  <Typography>{part?.marketsPartNumber}</Typography>
                </Stack>
              </Grid>
              <Grid xs={6} item>
                <Stack>
                  <Typography variant="h6">Minimum Inventory: </Typography>
                  <Typography>{part?.minimumInventory}</Typography>
                </Stack>
              </Grid>
              <Grid xs={6} item>
                <Stack>
                  <Typography variant="h6">Storage Location: </Typography>
                  <Typography>{part?.storageLocation}</Typography>
                </Stack>
              </Grid>
              <Grid xs={6} item>
                <Stack>
                  <Typography variant="h6">Quantity: </Typography>
                  <Typography>{part?.quantity}</Typography>
                </Stack>
              </Grid>
            </Grid>
          </AccordianPaperSection>
        </Grid>
        <Grid item xs={12}>
          <PaperSection
            title="Log"
            icon={<IconList />}
            button={
              <Button
                variant="contained"
                onClick={() =>
                  navigate(`/Ships/${ship?.id}/parts/${part?.id}/newlog`)
                }
                startIcon={<Add />}
              >
                New Log
              </Button>
            }
          >
            {part?.log === undefined ? (
              <Box />
            ) : (
              <Stack alignItems="center" justifyContent="center">
                <ChartContainer
                  width={1000}
                  height={300}
                  series={[
                    {
                      data: part?.log?.map((eq) =>
                        eq.quantity === undefined ? 0 : eq.quantity
                      ),
                      type: "line",
                      area: true,
                    },
                  ]}
                  xAxis={[
                    {
                      scaleType: "point",
                      data: part?.log?.map<any>((eq) =>
                        moment(eq.lastUpdated).format("MM/DD/YYYY")
                      ),
                    },
                  ]}
                >
                  <LinePlot />
                  <MarkPlot />
                  <ChartsXAxis />
                  <ChartsYAxis />
                  {part?.minimumInventory !== undefined ? (
                    <ChartsReferenceLine
                      y={part?.minimumInventory}
                      label="Minimum Inventory"
                      lineStyle={{ stroke: "red" }}
                    />
                  ) : (
                    <Box />
                  )}
                </ChartContainer>
              </Stack>
            )}
            {part?.log === undefined ? (
              <Box />
            ) : (
              <DataGrid
                sx={{ height: "300px", minHeight: "300px" }}
                columns={columns}
                rows={part?.log}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "lastUpdated", sort: "desc" }],
                  },
                }}
              />
            )}
          </PaperSection>
        </Grid>
      </Grid>
    </Container>
  );
}
