import shipSvg from "./ship.svg";
import waveSvg from "./wave.svg";
import "./ShipLoader.css";
import { Box } from "@mui/material";
export default function ShipLoader() {
  return (
    <Box className="ship-loader">
      <Box className="ocean">
        <Box className="ship">
          <img src={shipSvg} alt="Ship" className="ship-icon" />
        </Box>
        <img src={waveSvg} alt="Wave" className="wave" />
        <img src={waveSvg} alt="Wave" className="wave2" />
        <img src={waveSvg} alt="Wave" className="wave3" />
        <img src={waveSvg} alt="Wave" className="wave4" />
      </Box>
    </Box>
  );
}
