import { useRecoilState } from "recoil";
import PaperSection from "../PaperSection";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { useEffect, useState } from "react";
import { IncidentReport } from "../../types/IncidentReport";
import { getDeferredIncidents } from "../../services/incidentService";
import IncidentCard from "./IncidentCard";
import moment from "moment";
import { Chip, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IconSearch } from "@tabler/icons-react";
import { CalendarMonth } from "@mui/icons-material";

export default function DeferredIncidents() {
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [incidents, setIncidents] = useState<IncidentReport[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    getDeferredIncidents(selectedClient.id, selectedShip.id).then((res) => {
      setIncidents(res);
    });
  }, [selectedClient.id, selectedShip.id, setIncidents]);


  return (
    <PaperSection title="Deferred Faults" icon={<IconSearch/>}>
      {incidents.map((i) => (
        <IncidentCard title={i.title} isEmergency={i.isEmergency} onClick={() => navigate(`/ships/${selectedShip.id}/logbook/${i.id}`)}>
          <Grid container>
            <Grid item xs={12} md={8} textAlign="start">
              <Typography variant="body1">{`Reported by ${
                i.creator?.userName
              } on ${moment(i.createdDate)
                .local()
                .format("MM/DD/YYYY")}`}</Typography>
            </Grid>
            <Grid item xs={12} md={2} textAlign="end">
              <Chip label={i.location} />
            </Grid>
            <Grid item xs={12} md={2} textAlign="end">
              <Chip icon={<CalendarMonth/>} label={moment(i.deferredDate).local().format("MM/DD/YYYY")} />
            </Grid>
          </Grid>
        </IncidentCard>
      ))}
    </PaperSection>
  );
}
