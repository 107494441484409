import {
  Button,
  Chip,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Trip } from "../../types/Trip";
import TripTitle from "./TripTitle";
import TripDateRange from "./TripDateRange";
import TripCardIcon from "./TripCardIcon";
import { useNavigate } from "react-router-dom";
import { getTripTypeColor } from "../../utilities/shipUtils";
import { Group } from "@mui/icons-material";

export type TripCardProps = {
  trip?: Trip;
  isClickable: boolean;
};

export default function TripCard(props: TripCardProps) {
  const { trip, isClickable } = props;
  const navigate = useNavigate();
  if (isClickable) {
    return (
      <Paper
        sx={{
          p: 1,
          borderLeftColor: getTripTypeColor(trip?.type),
          borderTopWidth: "0px",
          borderStyle: "solid",
          borderRightWidth: "0px",
          borderLeftWidth: "4px",
          borderBottomWidth: "0",
        }}
        component={Button}
        fullWidth
        elevation={24}
        onClick={() => navigate(`/ships/${trip?.shipId}/trips/${trip?.id}`)}
      >
        <Grid container spacing={1}>
          <Grid item xs={1}>
            <Stack
              direction="column"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <TripCardIcon iconSize={44} type={trip?.type} />
            </Stack>
          </Grid>
          <Grid item xs={10}>
            <Grid container spacing={1}>
              <Grid item xs={12} textAlign="start">
                {trip?.name !== null && trip?.name !== "" ? (
                  <Stack direction="row" spacing={2}>
                    <Typography variant="h5">{trip?.name} - </Typography>{" "}
                    <TripTitle trip={trip} />
                  </Stack>
                ) : (
                  <TripTitle trip={trip} />
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={3} sm={3} md={1}>
                  <Chip icon={<Group />} label={trip?.passengers?.length} />
              </Grid>
              <Grid item xs={9} sm={9} md={11}>
                <TripDateRange trip={trip} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  } else {
    return (
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <Stack
            direction="column"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <TripCardIcon iconSize={44} type={trip?.type} />
          </Stack>
        </Grid>
        <Grid item xs={11}>
          <Grid container spacing={1}>
            <Grid item xs={12} textAlign="start">
              {trip?.name !== null && trip?.name !== "" ? (
                <Stack direction="row" spacing={2}>
                  <Typography variant="h5">{trip?.name} - </Typography>{" "}
                  <TripTitle trip={trip} />
                </Stack>
              ) : (
                <TripTitle trip={trip} />
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={1}>
                <Stack direction="row">
                  <Chip icon={<Group />} label={trip?.passengers?.length} />
                </Stack>
              </Grid>
              <Grid item xs={11}>
                <TripDateRange trip={trip} />
              </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
