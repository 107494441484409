import { Group, Person } from "@mui/icons-material";
import { Chip, Grid, Paper, Stack, Typography } from "@mui/material";
import { ShipUserRole } from "../../../types/Ship";
import { ShipTodoList } from "../../../types/TodoList";
import { getShipUserRoleValue } from "../../../utilities/shipUtils";
import { User } from "../../../types/User";

export type TodoListGroupOverviewProps = {
  roles: ShipUserRole[];
  users: User[];
  todoList: ShipTodoList | undefined;
};

export default function TodoListGroupOverview(
  props: TodoListGroupOverviewProps
) {
  const { roles, todoList, users } = props;
  return (
    <Paper sx={{      p: 2,
        borderTopColor: "gray",
        borderTopWidth: "4px",
        borderStyle: "solid",
        borderRightWidth: "0",
        borderLeftWidth: "0",
        borderBottomWidth: "0", }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3}>
          <Stack direction="row" alignItems="center">
            <Group sx={{ fontSize: 32, mr: 2 }} />
            <Typography variant="h5">
              {getShipUserRoleValue(todoList?.assignedRoleId)}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <Grid container spacing={2} alignItems="center" height="100%">
            {todoList?.assignedRoleId === 0
              ? users.map((u) => {
                  return (
                    <Grid item xs={12} sm={6} md="auto" lg="auto">
                      <Chip icon={<Person />} label={u.userName} />
                    </Grid>
                  );
                })
              : roles
                  .filter((role) => role.role === todoList?.assignedRoleId)
                  .map((roleUser) => {
                    return <Chip icon={<Person />} label={roleUser.userName} />;
                  })}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
