import {
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { getTodoLists } from "../../services/todoListService";
import { ShipTodoList } from "../../types/TodoList";

export type TodoListCounterWidgetPropsType = {
  selectedShipId?: string;
};

export default function TodoListCounterWidget(
  props: TodoListCounterWidgetPropsType
) {
  const navigate = useNavigate();
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [activeTodoListCount, setActiveTodoListCount] = useState(0);

  useEffect(() => {
    if (props.selectedShipId !== undefined && selectedClient.id !== undefined) {
      getTodoLists(props.selectedShipId, selectedClient.id).then(
        (res: ShipTodoList[]) => {
          setActiveTodoListCount(
            res.filter((tl) => tl.status !== 2 && tl.status !== 3).length
          );
        }
      );
    }
  }, [selectedClient.id, props.selectedShipId]);

  return (
    <Paper
      sx={{
        p: 2,
        height: "100%",
        width: "100%",
        borderTopColor: "#b47f49",
        borderTopWidth: "4px",
        borderStyle: "solid",
        borderRightWidth: "0",
        borderLeftWidth: "0",
        borderBottomWidth: "0",
      }}
      elevation={24}
      component={Button}
      onClick={() => navigate(`/ships/${props.selectedShipId}/todolists`)}
    >
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="column" alignItems="center">
            <Typography variant="h6">Active To do Items</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="column" alignItems="center">
            <Typography variant="h1">{activeTodoListCount}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}
