import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Trip, TripPassenger } from "../../types/Trip";
import {
  ArrowRightAlt,
  ContentCopy,
  Delete,
  Edit,
  Person,
  Send,
} from "@mui/icons-material";
import {
  deleteTripPassenger,
  getTrip,
  sendPassengerEmail,
  updateTripPassenger,
} from "../../services/tripservice";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { Formik } from "formik";
import PassengerDetailItem from "./PassengerDetailItem";
import LinearProgressWithLabel from "../Progress/LinearProgressWithLabel";
import IsUserElevated from "../../utilities/userUtils";
import { userProfileState } from "../../Recoil/atoms/UserProfileAtom";
import { useNavigate } from "react-router-dom";

export type PassengerCardProps = {
  passenger: TripPassenger;
  trip: Trip;
  setTrip: Function;
  key: React.Key | null | undefined;
};

export default function PassengerCard(props: PassengerCardProps) {
  const { passenger, trip, setTrip, key } = props;
  const [passengersModalOpen, setPassengersModalOpen] = useState(false);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [userProfile] = useRecoilState(userProfileState);
  const [sendEmailDialog, setSendEmailDialog] = useState(false);
  const [passengerDialog, setPassengerDialog] = useState<TripPassenger>();
  const navigate = useNavigate();
  return (
    <Box key={key}>
      <Modal
        open={passengersModalOpen}
        onClose={() => {
          setPassengersModalOpen(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
          }}
        >
          <Paper
            sx={{
              p: 2,
              borderTopColor: "#b47f49",
              borderTopWidth: "4px",
              borderStyle: "solid",
              borderRightWidth: "0",
              borderLeftWidth: "0",
              borderBottomWidth: "0",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">Edit Passenger</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Formik
                  enableReinitialize
                  initialValues={passenger}
                  onSubmit={(values) => {
                    console.log(values);
                    var passenger: TripPassenger = {
                      id: values.id,
                      firstName: values.firstName,
                      lastName: values.lastName,
                      email: values.email,
                      notes: values.notes,
                    };
                    updateTripPassenger(
                      selectedClient.id,
                      selectedShip.id,
                      trip.id,
                      passenger
                    ).then((createRes) => {
                      getTrip(selectedClient.id, selectedShip.id, trip.id).then(
                        (res) => {
                          setTrip(res);
                          setPassengersModalOpen(false);
                        }
                      );
                    });
                  }}
                >
                  {({
                    errors,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    touched,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Stack direction="column">
                        <TextField
                          id="firstName"
                          name="firstName"
                          label="First Name"
                          required
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.firstName && Boolean(errors.firstName)}
                          helperText={touched.firstName && errors.firstName}
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          id="lastName"
                          name="lastName"
                          label="Last Name"
                          required
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.lastName && Boolean(errors.lastName)}
                          helperText={touched.lastName && errors.lastName}
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          id="email"
                          name="email"
                          label="Email Address"
                          required
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          id="notes"
                          name="notes"
                          label="Notes"
                          value={values.notes}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.notes && Boolean(errors.notes)}
                          helperText={touched.notes && errors.notes}
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                        <Button
                          color="primary"
                          variant="contained"
                          fullWidth
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Stack>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Modal>
      <Dialog open={sendEmailDialog} onClose={() => setSendEmailDialog(false)}>
        <DialogTitle id="alert-dialog-title">Send Email</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to send ${passengerDialog?.firstName} ${passengerDialog?.lastName} an email to set their preferences for this trip?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPassengerDialog(undefined);
              setSendEmailDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              sendPassengerEmail(
                selectedClient.id,
                selectedShip.id,
                trip.id,
                passengerDialog?.id
              ).then((res) => {
                setSendEmailDialog(false);
                setPassengerDialog(undefined);
              });
            }}
            autoFocus
            endIcon={<Send />}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
      <Paper
        sx={{
          p: 1,
          borderLeftColor: "white",
          borderTopWidth: "0px",
          borderStyle: "solid",
          borderRightWidth: "0px",
          borderLeftWidth: "4px",
          borderBottomWidth: "0",
          mb: 2,
        }}
        elevation={20}
      >
        <Grid container spacing={1}>
          <Grid item xs={1}>
            <Stack
              direction="column"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Person sx={{ fontSize: 44 }} />
            </Stack>
          </Grid>
          <Grid item xs={11}>
            <Grid container spacing={1}>
              <Grid item xs={12} textAlign="start">
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="column">
                    <Typography variant="h5">
                      {passenger.firstName} {passenger.lastName}
                    </Typography>
                  </Stack>

                  <Stack direction="row">
                    <IconButton
                      onClick={() => {
                        setPassengersModalOpen(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        deleteTripPassenger(
                          selectedClient.id,
                          selectedShip.id,
                          trip.id,
                          passenger.id
                        ).then((delRes) => {
                          getTrip(
                            selectedClient.id,
                            selectedShip.id,
                            trip.id
                          ).then((res) => {
                            setTrip(res);
                          });
                        });
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <PassengerDetailItem
                      title="Join Code"
                      value={passenger.joinCode}
                      actionButton={
                        <IconButton
                          color="secondary"
                          onClick={() =>
                            navigator.clipboard.writeText(
                              `${passenger?.joinCode}`
                            )
                          }
                        >
                          <ContentCopy />
                        </IconButton>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PassengerDetailItem
                      title="Email"
                      value={passenger.email}
                      actionButton={
                        IsUserElevated(userProfile, selectedClient.id) ? (
                          <IconButton
                            color="secondary"
                            onClick={() => {
                              setPassengerDialog(passenger);
                              setSendEmailDialog(true);
                            }}
                          >
                            <Send />
                          </IconButton>
                        ) : (
                          <Box />
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PassengerDetailItem
                      title="Notes"
                      value={passenger.notes}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {passenger.onBoardingStatus === 7 ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        endIcon={<ArrowRightAlt />}
                        onClick={() =>
                          navigate(
                            `/ships/:id/trips/${trip.id}/passengers/${passenger.id}`
                          )
                        }
                      >
                        Results
                      </Button>
                    ) : (
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} md={8}>
                          <LinearProgressWithLabel
                            color="secondary"
                            value={
                              passenger.onBoardingStatus !== undefined
                                ? (passenger?.onBoardingStatus / 7) * 100
                                : 0
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          {passenger.onBoardingStatus !== undefined &&
                          passenger.onBoardingStatus > 0 ? (
                            <Button
                              fullWidth
                              variant="contained"
                              color="secondary"
                              endIcon={<ArrowRightAlt />}
                              onClick={() =>
                                navigate(
                                  `/ships/:id/trips/${trip.id}/passengers/${passenger.id}`
                                )
                              }
                            >
                              Results
                            </Button>
                          ) : (
                            <Box />
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
