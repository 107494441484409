import {
  CheckBox,
  EditNote,
  ListAlt,
  SkipNext,
} from "@mui/icons-material";
import { Box, Chip } from "@mui/material";
import { getTodoListItemStatus } from "../../utilities/todoListUtils";

export type TodoItemStatusChipProps = {
  status: number | undefined;
};
export default function TodoItemStatusChip(props: TodoItemStatusChipProps) {
  const { status } = props;
  switch (status) {
    case 0:
      return (
        <Chip
          icon={< ListAlt/>}
          label={getTodoListItemStatus(status)}
        />
      );
    case 1:
      return <Chip icon={<EditNote />} color="info" label={getTodoListItemStatus(status)} />;
    case 2:
      return <Chip icon={<CheckBox />} label={getTodoListItemStatus(status)} />;
    case 3:
      return <Chip icon={<SkipNext/>} color="error" label={getTodoListItemStatus(status)} />;
    default:
      return <Box />;
  }
}
