import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Ship } from "../../types/Ship";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { getShip } from "../../services/shipService";
import * as yup from "yup";
import {
  Add,
  CheckBox,
  DirectionsBoat,
  Group,
  Person,
} from "@mui/icons-material";
import { Formik } from "formik";
import { getClientUsers } from "../../services/userservice";
import { User } from "../../types/User";
import { ShipTodoList, ShipTodoListTemplate } from "../../types/TodoList";
import { createTodoList } from "../../services/todoListService";
import { getTodoListTemplates } from "../../services/todoListTemplateService";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { DateTime } from "luxon";
import { DatePicker } from "@mui/x-date-pickers";
import { getShipUserRoleValue } from "../../utilities/shipUtils";

export default function NewTodoList() {
  const params = useParams();
  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [users, setUsers] = useState<User[]>([]);
  const [templates, setTemplates] = useState<ShipTodoListTemplate[]>([]);
  const [selectedShip] = useRecoilState(SelectedShipState);

  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
      getClientUsers(selectedClient.id).then((response) => setUsers(response));
      getTodoListTemplates(params.id, selectedClient.id).then((res) => {
        setTemplates(res);
      });
    });
  }, [selectedClient, params.id]);
  const validationSchema = yup.object({
    name: yup.string().required("name is required."),
    assigneeId: yup.string(),
    isTemplate: yup.bool().required(""),
    templateId: yup.string(),
  });

  const initialState = {
    name: "",
    assigneeId: "",
    assignedRoleId: 0,
    isTemplate: false,
    userRoleToggle: "role",
    dueDate: DateTime.now(),
    templateId: "",
  };
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}/todolists`)}
          startIcon={<CheckBox sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          To Do Lists
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Add sx={{ mr: 0.5 }} fontSize="inherit" />
          New To Do List
        </Typography>
      </Breadcrumbs>
      <Formik
        enableReinitialize
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (values.userRoleToggle === "user") {
            if (values.isTemplate) {
              var todoListWithTemplate: ShipTodoList = {
                assigneeId: values.assigneeId,
                userRoleToggle: values.userRoleToggle,
                name: values.name,
                dueDate: values.dueDate,
                isTemplate: values.isTemplate,
                templateId: values.templateId,
              };
              createTodoList(
                ship?.id,
                selectedClient.id,
                todoListWithTemplate
              ).then((response) => {
                navigate(`/ships/${ship?.id}/todolists/${response}`);
              });
            } else {
              var todoList: ShipTodoList = {
                assigneeId: values.assigneeId,
                userRoleToggle: values.userRoleToggle,
                dueDate: values.dueDate,
                name: values.name,
              };
              createTodoList(ship?.id, selectedClient.id, todoList).then(
                (response) => {
                  navigate(`/ships/${ship?.id}/todolists/${response}`);
                }
              );
            }
          } else {
            if (values.isTemplate) {
              var todoListWithTemplateRole: ShipTodoList = {
                assignedRoleId: values.assignedRoleId,
                userRoleToggle: values.userRoleToggle,
                name: values.name,
                dueDate: values.dueDate,
                isTemplate: values.isTemplate,
                templateId: values.templateId,
              };
              createTodoList(
                ship?.id,
                selectedClient.id,
                todoListWithTemplateRole
              ).then((response) => {
                navigate(`/ships/${ship?.id}/todolists/${response}`);
              });
            } else {
              var todoListRole: ShipTodoList = {
                assignedRoleId: values.assignedRoleId,
                userRoleToggle: values.userRoleToggle,
                dueDate: values.dueDate,
                name: values.name,
              };
              createTodoList(ship?.id, selectedClient.id, todoListRole).then(
                (response) => {
                  navigate(`/ships/${ship?.id}/todolists/${response}`);
                }
              );
            }
          }
        }}
      >
        {({
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              id="name"
              name="name"
              label="Name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              fullWidth
              sx={{ mb: 2 }}
            />
            <DatePicker
              disablePast
              label="Due Date"
              value={values.dueDate}
              onChange={(value: any) =>
                setFieldValue("dueDate", DateTime.fromISO(value), true)
              }
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <ToggleButtonGroup
                value={values.userRoleToggle}
                exclusive
                onChange={(e: any) => {
                  setFieldValue("userRoleToggle", e.target.value, true);
                }}
              >
                <ToggleButton value="role">
                  <Group sx={{ mr: 2 }} /> Role
                </ToggleButton>
                <ToggleButton value="user">
                  <Person sx={{ mr: 2 }} /> User
                </ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
            {values.userRoleToggle === "role" ? (
              <Box>
                <InputLabel id="roleId">Role</InputLabel>
                <Select
                  labelId="roleId"
                  id="roleSelect"
                  value={values.assignedRoleId}
                  onChange={(e) => {
                    setFieldValue("assignedRoleId", e.target.value);
                  }}
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  <MenuItem value={0}>{getShipUserRoleValue(0)}</MenuItem>
                  <MenuItem value={1}>{getShipUserRoleValue(1)}</MenuItem>
                  <MenuItem value={2}>{getShipUserRoleValue(2)}</MenuItem>
                  <MenuItem value={3}>{getShipUserRoleValue(3)}</MenuItem>
                  <MenuItem value={4}>{getShipUserRoleValue(4)}</MenuItem>
                </Select>
              </Box>
            ) : (
              <Box>
                <InputLabel id="userId">User</InputLabel>
                <Select
                  labelId="userId"
                  id="userSelect"
                  value={values.assigneeId}
                  onChange={(e) => {
                    setFieldValue("assigneeId", e.target.value);
                  }}
                  error={touched.assigneeId && Boolean(errors.assigneeId)}
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  {users.map((u) => {
                    return <MenuItem value={u.id}>{u.userName}</MenuItem>;
                  })}
                </Select>
              </Box>
            )}
            {selectedClient.billingTier !== 0 ? (
              <FormControlLabel
                control={<Checkbox checked={values.isTemplate} />}
                label="Apply To Do List Template?"
                name="isTemplate"
                onChange={handleChange}
                sx={{ mb: 2 }}
              />
            ) : (
              <Box />
            )}
            {values.isTemplate ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="demo-simple-select-label">Template</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.templateId}
                  onChange={(event) => {
                    setFieldValue("templateId", event.target.value);
                  }}
                >
                  {templates.map((t) => (
                    <MenuItem id={t.id} key={t.id} value={t.id}>
                      {t.name} - {t.items?.length} items
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Box />
            )}
            {values.assigneeId !== "" || values.assignedRoleId !== -1 ? (
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            ) : (
              <Button
                color="primary"
                variant="contained"
                disabled
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            )}
          </form>
        )}
      </Formik>
    </Container>
  );
}
