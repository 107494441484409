import {
  IconPlane,
  IconSquare,
  IconSquareCheckFilled,
} from "@tabler/icons-react";
import { Trip } from "../../types/Trip";
import AccordianPaperSection from "../AccordianPaperSection";
import moment from "moment";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { getTripTravelInformation } from "../../services/tripservice";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { useRecoilState } from "recoil";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { PassengerTravelInfo } from "../../types/PassengerTypes";

export type ShipTravelCardProps = {
  trip?: Trip;
};

export default function ShipTravelCard(props: ShipTravelCardProps) {
  const { trip } = props;
  const [travelInfo, setTravelInfo] = useState<PassengerTravelInfo[]>([]);
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  useEffect(() => {
    getTripTravelInformation(selectedClient.id, selectedShip.id, trip?.id).then(
      (res) => {
        setTravelInfo(res);
      }
    );
  }, [setTravelInfo, selectedClient.id, selectedShip.id, trip?.id]);

  const columns = [
    {
      field: "firstName",
      headerName: `First Name`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.firstName;
      },
    },
    {
      field: "lastName",
      headerName: `Last Name`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.lastName;
      },
    },
    {
      field: "arrivalFlightTime",
      headerName: `Arrival Flight Time`,
      width: 150,
      valueGetter: (params: any) => {
        return moment(params.row.arrivalFlightTime)
          .utc()
          .local()
          .format("MM/DD/YYYY HH:MM");
      },
    },
    {
      field: "arrivalFlightNumber",
      headerName: `Arrival Flight Number`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.arrivalFlightNumber;
      },
    },
    {
      field: "arrivalFlightAirport",
      headerName: `Arrival Airport`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.arrivalFlightAirport;
      },
    },
    {
      field: "arrivalTransportationRequired",
      headerName: `Arrival Transportation Required`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.arrivalTransportationRequired;
      },
      renderCell: (params: GridRenderCellParams<any, Date>) =>
        params.row.arrivalTransportationRequired ? (
          <IconSquareCheckFilled style={{ color: "green" }} />
        ) : (
          <IconSquare />
        ),
    },
    {
      field: "returnFlightTime",
      headerName: `Return Flight Time`,
      width: 150,
      valueGetter: (params: any) => {
        return moment(params.row.returnFlightTime)
          .utc()
          .local()
          .format("MM/DD/YYYY HH:MM");
      },
    },
    {
      field: "returnFlightNumber",
      headerName: `Return Flight Number`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.returnFlightNumber;
      },
    },
    {
      field: "returnFlightAirport",
      headerName: `Return Airport`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.returnFlightAirport;
      },
    },
    {
      field: "returnTransportationRequired",
      headerName: `Return Transportation Required`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.returnTransportationRequired;
      },
      renderCell: (params: GridRenderCellParams<any, Date>) =>
        params.row.returnTransportationRequired ? (
          <IconSquareCheckFilled style={{ color: "green" }} />
        ) : (
          <IconSquare />
        ),
    },
  ];
  return (
    <AccordianPaperSection
      collapsed={false}
      elevation={12}
      icon={<IconPlane size={24} />}
      title="Travel Information"
    >
       <DataGrid
        rows={travelInfo}
        initialState={{
            sorting: {
              sortModel: [{ field: 'arrivalFlightTime', sort: 'asc' }],
            },
          }}
        sx={{ minHeight: "300px", mb: 4 }}
        columns={columns}
      />
    </AccordianPaperSection>
  );
}
