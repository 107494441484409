import {
  AccessTime,
  Add,
  CalendarMonth,
  DirectionsBoat,
  Map,
  ViewList,
} from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { useNavigate } from "react-router-dom";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { useEffect, useState } from "react";
import { getTrips } from "../../services/tripservice";
import { Trip } from "../../types/Trip";
import { DateTime } from "luxon";
import moment from "moment";
import TripCard from "../../components/Trip/TripCard";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import {
  getTripType,
  getTripTypeColor,
  getTripTypeTextColor,
} from "../../utilities/shipUtils";
export default function Trips() {
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [trips, setTrips] = useState<Trip[]>([]);
  const [displayType, setDisplayType] = useState("list");
  const navigate = useNavigate();

  useEffect(() => {
    getTrips(selectedClient.id, selectedShip.id).then((res) => {
      setTrips(res);
    });
  }, [selectedClient, selectedShip]);

  function getEvents() {
    var events: any[] = [];
    trips.forEach((cTrip) => {
      var event = {
        id: cTrip.id,
        title: `${getTripType(cTrip.type)} - ${cTrip.startLocation} -> ${
          cTrip.endLocation
        }`,
        start: moment.utc(cTrip.startDate).local().format("YYYY-MM-DD"),
        end: moment.utc(cTrip.endDate).add(1, "days").local().format("YYYY-MM-DD"),
        color: getTripTypeColor(cTrip.type),
        textColor: getTripTypeTextColor(cTrip.type),
        borderColor: "white",
      };
      events.push(event);
    });
    return events;
  }

  function comparePastTrips(a: Trip, b: Trip) {
    if (a.startDate !== undefined && b.startDate !== undefined) {
      return moment(b.startDate).valueOf() - moment(a.startDate).valueOf();
    }
    return 0;
  }
  
  function compareTrips(a: Trip, b: Trip) {
    if (a.startDate !== undefined && b.startDate !== undefined) {
      return moment(a.startDate).valueOf() - moment(b.startDate).valueOf();
    }
    return 0;
  }
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <Map sx={{ mr: 0.5 }} fontSize="inherit" />
          Trips
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row-reverse" justifyContent="space-between">
            <Button
              variant="contained"
              onClick={() => navigate(`/ships/${selectedShip.id}/trips/new`)}
              startIcon={<Add />}
            >
              New Trip
            </Button>
            <ToggleButtonGroup
              color="primary"
              value={displayType}
              onChange={(event, value) => setDisplayType(value)}
              exclusive
            >
              <ToggleButton value="list">
                <ViewList sx={{ mr: 2 }} /> List View
              </ToggleButton>
              <ToggleButton value="calendar">
                <CalendarMonth sx={{ mr: 2 }} /> Calendar View
              </ToggleButton>
              <ToggleButton value="past">
                <AccessTime sx={{ mr: 2 }} /> Past View
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {displayType === "list" ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    p: 2,
                    borderTopColor: "#b47f49",
                    borderTopWidth: "4px",
                    borderStyle: "solid",
                    borderRightWidth: "0",
                    borderLeftWidth: "0",
                    borderBottomWidth: "0",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h4">Current Trip</Typography>
                      <Divider sx={{ mt: 2, mb: 2 }} />
                      <Grid container spacing={1}>
                        {trips
                          .filter((trip) => {
                            if (
                              trip.endDate !== undefined &&
                              trip.startDate !== undefined &&
                              Math.floor(moment.utc(trip.startDate).local().valueOf()) <=
                                DateTime.now().valueOf() &&
                              Math.floor(moment.utc(trip.endDate).local().valueOf()) >=
                                DateTime.now().valueOf()
                            ) {
                              return true;
                            }
                            return false;
                          })
                          .map((trip) => (
                            <Grid item xs={12}>
                              <TripCard trip={trip} isClickable={true} />
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    p: 2,
                    borderTopColor: "#b47f49",
                    borderTopWidth: "4px",
                    borderStyle: "solid",
                    borderRightWidth: "0",
                    borderLeftWidth: "0",
                    borderBottomWidth: "0",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h4">Upcoming Trips</Typography>
                      <Divider sx={{ mt: 2, mb: 2 }} />
                      <Grid container spacing={1}>
                        {trips
                          .filter((trip) => {
                            if (
                              trip.endDate !== undefined &&
                              trip.startDate !== undefined &&
                              Math.floor(moment.utc(trip.startDate).local().valueOf()) >=
                                DateTime.now().valueOf()
                            ) {
                              return true;
                            }
                            return false;
                          }).sort(compareTrips)
                          .map((trip) => (
                            <Grid item xs={12}>
                              <TripCard trip={trip} isClickable={true} />
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          ) : displayType === "calendar" ? (
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              eventClick={(arg) => {navigate(`/ships/${selectedShip.id}/trips/${arg.event.id}`)}}
              timeZone="America/New_York"
              eventColor="blue"
              events={getEvents()}
            />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    p: 2,
                    borderTopColor: "#b47f49",
                    borderTopWidth: "4px",
                    borderStyle: "solid",
                    borderRightWidth: "0",
                    borderLeftWidth: "0",
                    borderBottomWidth: "0",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h4">Past Trips</Typography>
                      <Divider sx={{ mt: 2, mb: 2 }} />
                      <Grid container spacing={1}>
                        {trips
                          .filter((trip) => {
                            if (
                              trip.endDate !== undefined &&
                              trip.startDate !== undefined &&
                              Math.floor(moment(trip.endDate).valueOf()) <=
                                DateTime.now().valueOf()
                            ) {
                              return true;
                            }
                            return false;
                          }).sort(comparePastTrips)
                          .map((trip) => (
                            <Grid item xs={12}>
                              <TripCard trip={trip} isClickable={true} />
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
