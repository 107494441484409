import { Box, Grid } from "@mui/material";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { ShipDrinkInformationSummary } from "../../types/PassengerTypes";
import { useEffect, useState } from "react";
import { getTripDrinkInformation } from "../../services/tripservice";
import { useParams } from "react-router-dom";
import NonAlcoholicMustHaveCard from "./Drinks/NonAlcoholicMustHaveCard";
import NonAlcoholicCannotHaveCard from "./Drinks/NonAlcoholicCannotHaveCard";
import NonAlcoholicRequestsCard from "./Drinks/NonAlcoholicRequestsCard";
import NonAlcoholicScoresCard from "./Drinks/NonAlcoholicScoresCard";
import DoNotServeAlcoholCard from "./Drinks/DoNotServerAlcoholCard";
import AlcoholicMustHaveCard from "./Drinks/AlcoholicDrinksMustHaveCard";
import AlcoholicCannotHaveCard from "./Drinks/AlcoholicDrinksCannotHaveCard";
import AlcoholicScoresCard from "./Drinks/AlcoholicDrinksScoresCard";
import AlcoholicRequestsCard from "./Drinks/AlcoholicDrinksRequestsCard";
import MixerMustHaveCard from "./Drinks/MixerDrinksMustHaveCard";
import MixerCannotHaveCard from "./Drinks/MixerDrinksCannotHaveCard";
import MixerScoresCard from "./Drinks/MixerDrinksScoresCard";
import MixerRequestsCard from "./Drinks/MixerDrinksRequestsCard";
import CocktailRequestsCard from "./Drinks/CocktailRequestsCard";

export default function DrinkSummary() {
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [drinks, setDrinks] = useState<ShipDrinkInformationSummary>();
  const params = useParams();

  useEffect(() => {
    getTripDrinkInformation(
      selectedClient.id,
      selectedShip.id,
      params.tripid
    ).then((res) => {
      setDrinks(res);
    });
  }, [selectedClient.id, selectedShip.id, params.tripid]);
  return (
    <Box>
      {drinks !== undefined &&
      drinks.mustHaveNonAlcoholic !== undefined &&
      drinks.cannotHaveNonAlcoholic !== undefined &&
      drinks.nonAlcoholicRequests !== undefined ? (
        <Grid spacing={2} container>
          {drinks.doesNotDrink?.length > 0 ? (
            <Grid item xs={12}>
              <DoNotServeAlcoholCard drinks={drinks} />
            </Grid>
          ) : (
            <Box />
          )}
          {drinks.mustHaveNonAlcoholic?.length > 0 ? (
            <Grid item xs={12} md={6}>
              <NonAlcoholicMustHaveCard drinks={drinks} />
            </Grid>
          ) : (
            <Box />
          )}
          {drinks.cannotHaveNonAlcoholic?.length > 0 ? (
            <Grid item xs={12} md={6}>
              <NonAlcoholicCannotHaveCard drinks={drinks} />
            </Grid>
          ) : (
            <Box />
          )}
          {drinks.nonAlcoholicRequests?.length > 0 ? (
            <Grid item xs={12} md={6}>
              <NonAlcoholicRequestsCard drinks={drinks} />
            </Grid>
          ) : (
            <Box />
          )}
          {drinks.nonAlcoholicScores?.length > 0 ? (
            <Grid item xs={12} md={6}>
              <NonAlcoholicScoresCard drinks={drinks} />
            </Grid>
          ) : (
            <Box />
          )}
          {drinks.cocktailRequests?.length > 0 ? (
            <Grid item xs={12} md={6}>
              <CocktailRequestsCard drinks={drinks} />
            </Grid>
          ) : (
            <Box />
          )}
          {drinks.mustHaveAlcoholic?.length > 0 ? (
            <Grid item xs={12} md={6}>
              <AlcoholicMustHaveCard drinks={drinks} />
            </Grid>
          ) : (
            <Box />
          )}
          {drinks.cannotHaveAlcoholic?.length > 0 ? (
            <Grid item xs={12} md={6}>
              <AlcoholicCannotHaveCard drinks={drinks} />
            </Grid>
          ) : (
            <Box />
          )}
          {drinks.alcoholicScores?.length > 0 ? (
            <Grid item xs={12} md={6}>
              <AlcoholicScoresCard drinks={drinks} />
            </Grid>
          ) : (
            <Box />
          )}
          {drinks.alcoholicRequests?.length > 0 ? (
            <Grid item xs={12} md={6}>
              <AlcoholicRequestsCard drinks={drinks} />
            </Grid>
          ) : (
            <Box />
          )}
          {drinks.mustHaveMixers?.length > 0 ? (
            <Grid item xs={12} md={6}>
              <MixerMustHaveCard drinks={drinks} />
            </Grid>
          ) : (
            <Box />
          )}
          {drinks.cannotHaveMixers?.length > 0 ? (
            <Grid item xs={12} md={6}>
              <MixerCannotHaveCard drinks={drinks} />
            </Grid>
          ) : (
            <Box />
          )}
          {drinks.mixerScores?.length > 0 ? (
            <Grid item xs={12} md={6}>
              <MixerScoresCard drinks={drinks} />
            </Grid>
          ) : (
            <Box />
          )}
          {drinks.mixerRequests?.length > 0 ? (
            <Grid item xs={12} md={6}>
              <MixerRequestsCard drinks={drinks} />
            </Grid>
          ) : (
            <Box />
          )}
        </Grid>
      ) : (
        <Box />
      )}
    </Box>
  );
}
