import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  TextField,
} from "@mui/material";
import { IncidentReport } from "../../types/IncidentReport";
import {
  IconArrowLeft,
  IconCircleCheckFilled,
  IconSquareCheck,
} from "@tabler/icons-react";
import { useState } from "react";
import PaperSection from "../PaperSection";
import { Formik } from "formik";
import {
  closeIncident,
  deferIncident,
  failIncident,
  getIncidentById,
  resumeIncident,
  updateIncident,
} from "../../services/incidentService";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { useRecoilState } from "recoil";
import IsUserElevated from "../../utilities/userUtils";
import { userProfileState } from "../../Recoil/atoms/UserProfileAtom";
import { useNavigate } from "react-router-dom";
import { CalendarMonth, PlayArrow } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

export type IncidentActionBarProps = {
  incident?: IncidentReport;
  setIncident: Function;
};
export default function IncidentActionBar(props: IncidentActionBarProps) {
  const { incident, setIncident } = props;
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [openResolution, setOpenResolution] = useState(false);
  const [openDefer, setOpenDefer] = useState(false);
  const [openResume, setOpenResume] = useState(false);
  const [userProfile] = useRecoilState(userProfileState);
  const [openClose, setOpenClose] = useState(false);
  const navigate = useNavigate();
  return (
    <Box>
      <Modal
        open={openDefer}
        onClose={() => {
          setOpenDefer(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <PaperSection title="Set Deferral" icon={<IconSquareCheck />}>
            {incident !== undefined ? (
              <Formik
                initialValues={incident}
                onSubmit={(values) => {
                  deferIncident(
                    values.id,
                    selectedClient.id,
                    values.deferredDate
                  ).then((res) => {
                    setOpenDefer(false);
                    getIncidentById(
                      selectedClient.id,
                      selectedShip.id,
                      incident.id
                    ).then((res) => {
                      setIncident(res);
                    });
                  });
                }}
              >
                {({ values, handleSubmit, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <DatePicker
                      disablePast
                      label="Deferral Date"
                      value={DateTime.fromISO(
                        values.deferredDate !== undefined &&
                          values.deferredDate !== null
                          ? values.deferredDate.toString()
                          : DateTime.now().toString()
                      )}
                      onChange={(value: any) =>
                        setFieldValue(
                          "deferredDate",
                          DateTime.fromISO(value),
                          true
                        )
                      }
                      sx={{ mb: 2 }}
                    />
                    <Button
                      sx={{ mt: 2 }}
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Submit
                    </Button>
                  </form>
                )}
              </Formik>
            ) : (
              <Box />
            )}
          </PaperSection>
        </Box>
      </Modal>
      <Modal
        open={openResolution}
        onClose={() => {
          setOpenResolution(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <PaperSection title="Set Resolution" icon={<IconSquareCheck />}>
            {incident !== undefined ? (
              <Formik
                initialValues={incident}
                onSubmit={(values) => {
                  values.status = 1;
                  updateIncident(
                    selectedClient.id,
                    selectedShip.id,
                    values
                  ).then((res) => {
                    setOpenResolution(false);
                    getIncidentById(
                      selectedClient.id,
                      selectedShip.id,
                      incident.id
                    ).then((res) => {
                      setIncident(res);
                      if (!IsUserElevated(userProfile, selectedClient.id)) {
                        navigate(`/ships/${selectedShip.id}/logbook`);
                      }
                    });
                  });
                }}
              >
                {({
                  values,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  errors,
                  touched,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      id="resolution"
                      name="resolution"
                      label={`Provide a statement as to how the incident was resolved`}
                      value={values.resolution}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.resolution && Boolean(errors.resolution)}
                      helperText={touched.resolution && errors.resolution}
                      fullWidth
                      sx={{ mb: 2 }}
                    />

                    <Button
                      sx={{ mt: 2 }}
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Submit
                    </Button>
                  </form>
                )}
              </Formik>
            ) : (
              <Box />
            )}
          </PaperSection>
        </Box>
      </Modal>
      <Dialog
        open={openClose}
        onClose={() => {
          setOpenClose(false);
        }}
      >
        <DialogTitle>Close Fault</DialogTitle>
        <DialogContent>
          <Alert variant="outlined" severity="warning">
            Once closed a fault cannot be opened.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenClose(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              closeIncident(incident?.id, selectedClient.id).then((res) => {
                getIncidentById(
                  selectedClient.id,
                  selectedShip.id,
                  incident?.id
                ).then((inRes) => {
                  setIncident(inRes);
                  setOpenClose(false);
                });
              });
            }}
            autoFocus
          >
            Close Fault
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openResume}
        onClose={() => {
          setOpenResume(false);
        }}
      >
        <DialogTitle>Resume Fault</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenResume(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              resumeIncident(incident?.id, selectedClient.id).then((res) => {
                getIncidentById(
                  selectedClient.id,
                  selectedShip.id,
                  incident?.id
                ).then((inRes) => {
                  setIncident(inRes);
                  setOpenResume(false);
                });
              });
            }}
            autoFocus
          >
            Resume
          </Button>
        </DialogActions>
      </Dialog>
      <ButtonGroup>
      {incident?.status === 0 ? (
        <Button
          startIcon={<CalendarMonth />}
          color="warning"
          variant="contained"
          onClick={() => setOpenDefer(true)}
        >
          Defer
        </Button>
      ) : (
        <Box />
      )}
            {incident?.status === 3 ? (
        <Button
          startIcon={<PlayArrow />}
          color="success"
          variant="contained"
          onClick={() => setOpenResume(true)}
        >
          Resume Work
        </Button>
      ) : (
        <Box />
      )}
      {incident?.status === 0 || incident?.status === 3 ? (
        <Button
          startIcon={<IconSquareCheck />}
          color="secondary"
          variant="contained"
          onClick={() => setOpenResolution(true)}
        >
          Resolve
        </Button>
      ) : (
        <Box />
      )}
      {incident?.status === 1 ? (
        <Button
          startIcon={<IconArrowLeft />}
          color="secondary"
          variant="contained"
          onClick={() => {
            failIncident(incident.id, selectedClient.id).then((res) => {
              getIncidentById(
                selectedClient.id,
                selectedShip.id,
                incident.id
              ).then((res) => {
                setIncident(res);
              });
            });
          }}
        >
          Send Back
        </Button>
      ) : (
        <Box />
      )}
      {incident?.status === 1 ? (
        <Button
          startIcon={<IconCircleCheckFilled />}
          color="primary"
          variant="contained"
          onClick={() => setOpenClose(true)}
        >
          Close
        </Button>
      ) : (
        <Box />
      )}
      </ButtonGroup>
    </Box>
  );
}
