import { Chip, Grid } from "@mui/material";
import { Trip } from "../../types/Trip";
import { ArrowRightAlt, CalendarMonth } from "@mui/icons-material";
import moment from "moment";

export type TripDateRangeProps = {
  trip?: Trip;
};

export default function TripDateRange(props: TripDateRangeProps) {
  const { trip } = props;
  return (
    <Grid container alignItems="center">
      <Grid item xs={12} md={3} lg={2}>
        <Chip
          sx={{ p: 1 }}
          icon={<CalendarMonth />}
          label={moment.utc(trip?.startDate).local().format("MM/DD/YYYY")}
        />
      </Grid>
      <Grid item xs={12} md={1}>
        <ArrowRightAlt sx={{ fontSize: 32, ml: 1.5 }} />
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        <Chip
          sx={{ p: 1 }}
          icon={<CalendarMonth />}
          label={moment.utc(trip?.endDate).local().format("MM/DD/YYYY")}
        />
      </Grid>
    </Grid>
  );
}
