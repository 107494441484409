import { useAuth0 } from "@auth0/auth0-react";
import LoginPage from "./Pages/Login/LoginPage";
import LoadingScreen from "./Pages/LoadingScreen/LoadingScreen";
import axios from "axios";
import { getUserProfile } from "./services/userservice";
import { useRecoilState, useSetRecoilState } from "recoil";
import { userProfileState } from "./Recoil/atoms/UserProfileAtom";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import Admin from "./Pages/Admin/Admin";
import { getClients } from "./services/adminservice";
import { ClientsState, SelectedClientState } from "./Recoil/atoms/ClientsAtom";
import { Box, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import ClientManagement from "./Pages/Admin/ClientManagement";
import Invitations from "./Pages/Invitations";
import {
  InvitationsState,
  PendingInvitationsState,
} from "./Recoil/atoms/InvitationsAtom";
import {
  getInvites,
  getPendingInvites,
  getRoles,
} from "./services/invitationservice";
import { RolesState } from "./Recoil/atoms/RolesAtom";
import UserManagement from "./Pages/UserManagement";
import Ships from "./Pages/Ships/Ships";
import NewShip from "./Pages/Ships/NewShip";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { getShips } from "./services/shipService";
import { SelectedShipState, ShipsState } from "./Recoil/atoms/ShipsAtom";
import Parts from "./Pages/Parts/Parts";
import NewPart from "./Pages/Parts/NewPart";
import EquipmentHome from "./Pages/Equipment/EquipmentHome";
import NewEquipment from "./Pages/Equipment/NewEquipment";
import NewEquipmentLog from "./Pages/Equipment/NewEquipmentLog";
import EquipmentItem from "./Pages/Equipment/EquipmentItem";
import PartItem from "./Pages/Parts/PartItem";
import NewPartLog from "./Pages/Parts/NewPartLog";
import NewTask from "./Pages/Tasks/NewTask";
import CompleteEquipmentTask from "./Pages/Tasks/CompleteEquipmentTask";
import CompleteTimeTask from "./Pages/Tasks/CompleteTimeTask";
import EquipmentTask from "./Pages/Tasks/EquipmentTask";
import TimeTask from "./Pages/Tasks/TimeTask";
import EditEquipmentItem from "./Pages/Equipment/EditEquipmentitem";
import EditPartItem from "./Pages/Parts/EditPartItem";
import EditShip from "./Pages/Ships/EditShip";
import EditTask from "./Pages/Tasks/EditTask";
import Preferences from "./Pages/Preferences/Preferences";
import { Scrollbar } from "react-scrollbars-custom";
import IsUserElevated from "./utilities/userUtils";
import ShipRoles from "./Pages/Ships/ShipRole";
import ShipTodoLists from "./Pages/TodoLists/ShipTodoLists";
import NewTodoList from "./Pages/TodoLists/newTodoList";
import TodoList from "./Pages/TodoLists/todoList";
import MyLists from "./Pages/TodoLists/MyLists";
import MyListView from "./Pages/TodoLists/MyListView";
import { getMyTodoLists } from "./services/todoListService";
import { MyListsState } from "./Recoil/atoms/MyListsAtom";
import TodoListTemplates from "./Pages/TodoLists/TodoListTemplates/TodoListTemplates";
import NewTodoTemplate from "./Pages/TodoLists/TodoListTemplates/NewTemplate";
import TodoListTemplate from "./Pages/TodoLists/TodoListTemplates/TodoListTemplate";
import { LoadingState } from "./Recoil/atoms/LoadingAtom";
import ShipAppBar from "./components/AppBar/ShipAppBar";
import Tasks from "./Pages/Ships/Tasks";
import { FullStory, init } from "@fullstory/browser";
import TodoListReview from "./Pages/TodoLists/Review/TodoListReview";
import Trips from "./Pages/Trips/Trips";
import NewTrip from "./Pages/Trips/NewTrip";
import TripView from "./Pages/Trips/TripView";
import IncidentHome from "./Pages/Incidents/IncidentHome";
import NewIncident from "./Pages/Incidents/NewIncident";
import Incident from "./Pages/Incidents/Incident";
import TripSummary from "./Pages/Trips/TripSummary";
import PassengerSummary from "./Pages/Trips/PassengerSummary";
import IntercomComponent from "./components/Intercom/IntercomComponent";
import Billing from "./Pages/Billing/Billing";
import Notifications from "./Pages/Notifications";

export default function App() {
  const { isAuthenticated, user, isLoading, getAccessTokenSilently } =
    useAuth0();
  const [userProfile, setUserProfile] = useRecoilState(userProfileState);
  const [clients, setClients] = useRecoilState(ClientsState);
  const setMyTodoLists = useSetRecoilState(MyListsState);
  const [invitations, setInvitations] = useRecoilState(InvitationsState);
  const [pendingInvites, setPendingInvites] = useRecoilState(
    PendingInvitationsState
  );
  const [roles, setRoles] = useRecoilState(RolesState);
  const [selectedClient, setSelectedClient] =
    useRecoilState(SelectedClientState);
  const setSelectedShip = useSetRecoilState(SelectedShipState);
  const [ships, setShips] = useRecoilState(ShipsState);
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#b47f49",
      },
      secondary: {
        main: "#7fdcfd",
      },
      background: {
        default: "#011828",
        paper: "#011828",
      },
    },
  });
  const setLoading = useSetRecoilState(LoadingState);
  init({ orgId: "o-1YNJJ8-na1" });
  useEffect(() => {
    if (user !== undefined) {
      axios.interceptors.request.use(async function (config) {
        var accessToken = await getAccessTokenSilently();
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
      });
      if (userProfile.auth0Id === "") {
        setLoading(true);
        getUserProfile().then((response) => {
          setUserProfile(response);
          var userP = response;
          getClients().then((response) => {
            setClients(response);
            if (response.length > 0) {
              if (
                userP.preferredClient !== null &&
                userP.preferredClient !== undefined
              ) {
                var prefC = response.filter(
                  (c) => c.id === userP.preferredClient
                )[0];
                setSelectedClient(prefC);
                getMyTodoLists(prefC.id).then((mytlres) =>
                  setMyTodoLists(mytlres)
                );
                if (
                  IsUserElevated(userProfile, prefC.id) ||
                  selectedClient !== undefined
                ) {
                  getPendingInvites(prefC.id).then((response) =>
                    setPendingInvites(response)
                  );
                }
                getShips(prefC.id).then((response) => {
                  setShips(response);
                  setLoading(false);
                });
              } else {
                setSelectedClient(response[0]);
                getMyTodoLists(response[0].id).then((mytlres) =>
                  setMyTodoLists(mytlres)
                );
                if (
                  IsUserElevated(userProfile, response[0].id) ||
                  selectedClient !== undefined
                ) {
                  getPendingInvites(response[0].id).then((response) =>
                    setPendingInvites(response)
                  );
                }
                getShips(response[0].id).then((response) => {
                  setShips(response);
                  setLoading(false);
                });
              }

              FullStory("setIdentity", {
                uid: userP.id,
                properties: {
                  displayName: userP.userName,
                  email: userP.email,
                },
              });
            }
          });
          getInvites().then((response) => {
            setInvitations(response);
            setLoading(false);
          });

          getRoles().then((response) => setRoles(response));
        });
      }
    }
  }, [
    user,
    getAccessTokenSilently,
    setUserProfile,
    setClients,
    userProfile,
    userProfile.auth0Id,
    clients,
    setInvitations,
    ships,
    setShips,
    invitations,
    roles,
    setRoles,
    selectedClient,
    setSelectedClient,
    pendingInvites,
    setPendingInvites,
    setMyTodoLists,
    setLoading,
    setSelectedShip,
  ]);

  if (!isAuthenticated && user === undefined && !isLoading) {
    return <LoginPage />;
  } else if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <ThemeProvider theme={darkTheme}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <CssBaseline />
          <Box
            sx={{ display: "flex", minHeight: "100dvh", minWidth: "100dvw" }}
          >
            <IntercomComponent/>
            <ShipAppBar />
            <Scrollbar style={{ height: "100dvh" }}>
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  mt: "100px",
                }}
              >
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/billing" element={<Billing/>}/>
                  <Route path="/invitations" element={<Invitations />} />
                  <Route path="/notifications" element={<Notifications />} />
                  <Route path="/preferences" element={<Preferences />} />
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/usermanagement" element={<UserManagement />} />
                  <Route
                    path="/clientmanagement"
                    element={<ClientManagement />}
                  />
                  <Route path="/mylists" element={<MyLists />} />
                  <Route
                    path="/mylists/:shipid/:todolistid"
                    element={<MyListView />}
                  />
                  <Route path="/ships" element={<Ships />} />
                  <Route path="/newShip" element={<NewShip />} />
                  <Route path="/ships/:id" element={<Tasks />} />
                  <Route path="/ships/:id/logbook" element={<IncidentHome />} />
                  <Route
                    path="/ships/:id/logbook/new"
                    element={<NewIncident />}
                  />
                  <Route
                    path="/ships/:id/logbook/:incidentId"
                    element={<Incident />}
                  />
                  <Route
                    path="/ships/:id/todolists"
                    element={<ShipTodoLists />}
                  />
                  <Route
                    path="/ships/:id/todolists/templates"
                    element={<TodoListTemplates />}
                  />
                  <Route
                    path="/ships/:id/todolists/templates/new"
                    element={<NewTodoTemplate />}
                  />
                  <Route
                    path="/ships/:id/todolists/templates/:todolisttemplateid"
                    element={<TodoListTemplate />}
                  />
                  <Route
                    path="/ships/:id/todolists/:todolistid"
                    element={<TodoList />}
                  />
                  <Route
                    path="/ships/:id/todolists/:todolistid/review"
                    element={<TodoListReview />}
                  />
                  <Route
                    path="/ships/:id/todolists/new"
                    element={<NewTodoList />}
                  />
                  <Route path="/ships/:id/roles" element={<ShipRoles />} />
                  <Route path="/ships/:id/edit" element={<EditShip />} />
                  <Route path="/ships/:id/newtask" element={<NewTask />} />
                  <Route
                    path="/ships/:id/tasks/equipment/:taskid"
                    element={<EquipmentTask />}
                  />
                  <Route
                    path="/ships/:id/tasks/time/:taskid"
                    element={<TimeTask />}
                  />
                  <Route
                    path="/ships/:id/tasks/equipment/:taskid/edit"
                    element={<EditTask />}
                  />
                  <Route
                    path="/ships/:id/tasks/time/:taskid/edit"
                    element={<EditTask />}
                  />
                  <Route
                    path="/ships/:id/tasks/equipment/:taskid/complete"
                    element={<CompleteEquipmentTask />}
                  />
                  <Route
                    path="/ships/:id/tasks/time/:taskid/complete"
                    element={<CompleteTimeTask />}
                  />
                  <Route path="/ships/:id/parts" element={<Parts />} />
                  <Route
                    path="/ships/:id/parts/newpart"
                    element={<NewPart />}
                  />
                  <Route
                    path="/ships/:id/parts/:partid"
                    element={<PartItem />}
                  />
                  <Route
                    path="/ships/:id/parts/:partid/edit"
                    element={<EditPartItem />}
                  />
                  <Route
                    path="/ships/:id/parts/:partid/newlog"
                    element={<NewPartLog />}
                  />
                  <Route
                    path="/ships/:id/equipment"
                    element={<EquipmentHome />}
                  />
                  <Route
                    path="/ships/:id/equipment/newequipment"
                    element={<NewEquipment />}
                  />
                  <Route
                    path="/ships/:id/equipment/:equipmentid"
                    element={<EquipmentItem />}
                  />
                  <Route
                    path="/ships/:id/equipment/:equipmentid/edit"
                    element={<EditEquipmentItem />}
                  />
                  <Route
                    path="/ships/:id/equipment/:equipmentid/newlog"
                    element={<NewEquipmentLog />}
                  />
                  <Route path="/ships/:id/trips" element={<Trips />} />
                  <Route path="/Ships/:id/trips/new" element={<NewTrip />} />
                  <Route
                    path="/Ships/:id/trips/:tripid"
                    element={<TripView />}
                  ></Route>
                  <Route
                    path="/ships/:id/trips/:tripid/summary"
                    element={<TripSummary />}
                  />
                  <Route
                    path="/ships/:id/trips/:tripid/passengers/:passengerid"
                    element={<PassengerSummary />}
                  />
                </Routes>
              </Box>
            </Scrollbar>
          </Box>
        </LocalizationProvider>
      </ThemeProvider>
    );
  }
}
