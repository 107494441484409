import {
  Alert,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getShip,
  getShipUserRoles,
  getTasks,
} from "../../services/shipService";
import { useNavigate, useParams } from "react-router-dom";
import { Ship, ShipUserRole } from "../../types/Ship";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import {
  createTodoListItem,
  deleteTodoListItem,
  getShipTodoList,
  updateTodoListItem,
  updateTodoListItemStatus,
  updateTodoListStatus,
} from "../../services/todoListService";
import { ShipTodoList, ShipTodoListItem } from "../../types/TodoList";
import {
  Add,
  Assignment,
  CalendarMonth,
  ChatBubble,
  CheckBox,
  Delete,
  DirectionsBoat,
  Done,
  Edit,
  Group,
  Person,
  Publish,
  SkipNext,
} from "@mui/icons-material";
import { getTodoListStatus } from "../../utilities/todoListUtils";
import IsUserElevated from "../../utilities/userUtils";
import { userProfileState } from "../../Recoil/atoms/UserProfileAtom";
import { Formik } from "formik";
import { Task } from "../../types/Task";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import moment from "moment";
import { getShipUserRoleValue } from "../../utilities/shipUtils";
import { userManagementState } from "../../Recoil/atoms/UserManagementAtom";
import { getClientUsers } from "../../services/userservice";
import SkipModal from "../../components/TodoList/SkipModal";
import TodoListItemCommentModal from "../../components/TodoList/TodoListItemCommentModal";
import TodoListPriorityIcon from "../../components/TodoList/TodoListPriorityIcon";
import PriorityIcon from "../../components/TodoList/PriorityIcon";

export default function TodoList() {
  const params = useParams();

  const navigate = useNavigate();
  const [ship, setShip] = useState<Ship>();
  const [newTodoModalOpen, setnewTodoModalOpen] = useState(false);
  const [editTodoModalOpen, setEditTodoModalOpen] = useState(false);
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const [skipModalOpen, setSkipModalOpen] = useState(false);
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [todoList, setTodoList] = useState<ShipTodoList>();
  const [skipTodoListItem, setSkipTodoListItem] = useState<ShipTodoListItem>();
  const [commentTodoListItem, setCommentTodoListItem] =
    useState<ShipTodoListItem>();
  const [editTodoListItem, setEditTodoListItem] = useState<ShipTodoListItem>();
  const [userProfile] = useRecoilState(userProfileState);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [roles, setRoles] = useState<ShipUserRole[]>([]);
  const [users, setUsers] = useRecoilState(userManagementState);

  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
    });
    getTasks(selectedClient.id, params.id).then((taskres) => {
      setTasks(taskres);
    });
    getShipTodoList(params.todolistid, selectedClient.id).then((response) => {
      setTodoList(response);
    });
    getClientUsers(selectedClient.id).then((res) => {
      setUsers(res);
    });
    getShipUserRoles(selectedClient.id, params.id).then((res) => setRoles(res));
  }, [selectedClient, params.id, params.todolistid, setUsers]);

  function comparePriority(a: ShipTodoListItem, b: ShipTodoListItem) {
    if (a.priority !== undefined && b.priority !== undefined) {
      return b.priority - a.priority;
    }
    return 0;
  }

  const initialState = {
    isTask: false,
    assigneeId: "",
    taskId: "",
    priority: 1,
    value: "",
  };
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <SkipModal
        open={skipModalOpen}
        onClose={setSkipModalOpen}
        todoList={todoList}
        setTodoList={setTodoList}
        todoListItem={skipTodoListItem}
        setTodoListItem={setSkipTodoListItem}
      />
      <TodoListItemCommentModal
        open={commentModalOpen}
        onClose={setCommentModalOpen}
        todoListItem={commentTodoListItem}
        setShipTodoListItem={setCommentTodoListItem}
      />
      <Modal open={publishModalOpen} onClose={() => setPublishModalOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            sx={{ mb: 3 }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Publish Todo List
          </Typography>
          <Alert variant="filled" severity="warning" sx={{ mb: 4 }}>
            Once published, this action cannot be undone.
          </Alert>
          <Stack direction="row-reverse" spacing={1}>
            <Button
              variant="outlined"
              onClick={() => setPublishModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (todoList !== undefined) {
                  updateTodoListStatus(todoList.id, selectedClient.id, 1).then(
                    (res) => {
                      getShipTodoList(
                        params.todolistid,
                        selectedClient.id
                      ).then((response) => {
                        setTodoList(response);
                        setPublishModalOpen(false);
                      });
                    }
                  );
                }
              }}
            >
              Publish
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={newTodoModalOpen}
        onClose={() => {
          setnewTodoModalOpen(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            sx={{ mb: 3 }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Create New Todo Item
          </Typography>
          <Formik
            initialValues={initialState}
            onSubmit={(values) => {
              if (values.isTask) {
                var todoListItem: ShipTodoListItem = {
                  value: values.value,
                  isTask: values.isTask,
                  priority: values.priority,
                  taskId: values.taskId,
                  assigneeUserId: todoList?.assigneeId,
                };
                createTodoListItem(
                  todoList?.id,
                  selectedClient.id,
                  todoListItem
                ).then(() => {
                  getShipTodoList(params.todolistid, selectedClient.id).then(
                    (response) => {
                      setTodoList(response);
                      setnewTodoModalOpen(false);
                    }
                  );
                });
              } else {
                var todoListItemNoTask: ShipTodoListItem = {
                  value: values.value,
                  isTask: values.isTask,
                  priority: values.priority,
                  assigneeUserId: todoList?.assigneeId,
                };
                createTodoListItem(
                  todoList?.id,
                  selectedClient.id,
                  todoListItemNoTask
                ).then(() => {
                  getShipTodoList(params.todolistid, selectedClient.id).then(
                    (response) => {
                      setTodoList(response);
                      setnewTodoModalOpen(false);
                    }
                  );
                });
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                {!values.isTask ? (
                  <TextField
                    id="value"
                    name="value"
                    label="Name"
                    value={values.value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    multiline
                    sx={{ mb: 2 }}
                  />
                ) : (
                  <Box />
                )}
                <FormControlLabel
                  control={<Checkbox checked={values.isTask} />}
                  label="Is this an existing task?"
                  name="isTask"
                  onChange={handleChange}
                  sx={{ mb: 2 }}
                />
                {values.isTask ? (
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="demo-simple-select-label">Task</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.taskId}
                      onChange={(event) => {
                        setFieldValue("taskId", event.target.value);
                      }}
                    >
                      {tasks.map((t) => (
                        <MenuItem id={t.id} key={t.id} value={t.id}>
                          {t.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <React.Fragment />
                )}
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="priority-simple-select-label">
                    Priority
                  </InputLabel>
                  <Select
                    labelId="priority-simple-select-label"
                    id="priority-simple-select"
                    value={values.priority}
                    onChange={(event) => {
                      setFieldValue("priority", event.target.value);
                    }}
                  >
                    <MenuItem
                      id={"priority-0"}
                      key={"todo-priority-0"}
                      value={0}
                    >
                      {"Low"}
                    </MenuItem>
                    <MenuItem
                      id={"priority-1"}
                      key={"todo-priority-1"}
                      value={1}
                    >
                      {"Normal"}
                    </MenuItem>
                    <MenuItem
                      id={"priority-2"}
                      key={"todo-priority-2"}
                      value={2}
                    >
                      {"High"}
                    </MenuItem>
                  </Select>
                </FormControl>
                {values.value === "" && !values.isTask ? (
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled
                    type="submit"
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </Button>
                )}
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
      <Modal
        open={editTodoModalOpen}
        onClose={() => {
          setEditTodoModalOpen(false);
          setEditTodoListItem(undefined);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            sx={{ mb: 3 }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Edit Todo Item
          </Typography>
          {editTodoListItem !== undefined ? (
            <Formik
              enableReinitialize
              initialValues={editTodoListItem}
              onSubmit={(values) => {
                if (values.isTask) {
                  var todoListItem: ShipTodoListItem = {
                    id: values.id,
                    value: values.value,
                    priority: values.priority,
                    isTask: values.isTask,
                    taskId: values.taskId,
                    assigneeUserId: todoList?.assigneeId,
                  };
                  updateTodoListItem(
                    todoList?.id,
                    selectedClient.id,
                    todoListItem
                  ).then(() => {
                    getShipTodoList(params.todolistid, selectedClient.id).then(
                      (response) => {
                        setEditTodoListItem(undefined);
                        setTodoList(response);
                        setEditTodoModalOpen(false);
                      }
                    );
                  });
                } else {
                  var todoListItemNoTask: ShipTodoListItem = {
                    id: values.id,
                    value: values.value,
                    isTask: values.isTask,
                    priority: values.priority,
                    assigneeUserId: todoList?.assigneeId,
                  };
                  updateTodoListItem(
                    todoList?.id,
                    selectedClient.id,
                    todoListItemNoTask
                  ).then(() => {
                    getShipTodoList(params.todolistid, selectedClient.id).then(
                      (response) => {
                        setEditTodoListItem(undefined);
                        setTodoList(response);
                        setEditTodoModalOpen(false);
                      }
                    );
                  });
                }
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  {!values.isTask ? (
                    <TextField
                      id="value"
                      name="value"
                      label="Name"
                      value={values.value}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      multiline
                      sx={{ mb: 2 }}
                    />
                  ) : (
                    <Box />
                  )}
                  <FormControlLabel
                    control={<Checkbox checked={values.isTask} />}
                    label="Is this an existing task?"
                    name="isTask"
                    onChange={handleChange}
                    sx={{ mb: 2 }}
                  />
                  {values.isTask ? (
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel id="demo-simple-select-label">
                        Task
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.taskId}
                        onChange={(event) => {
                          setFieldValue("taskId", event.target.value);
                        }}
                      >
                        {tasks.map((t) => (
                          <MenuItem id={t.id} key={t.id} value={t.id}>
                            {t.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <React.Fragment />
                  )}
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="priority-simple-select-label">
                      Priority
                    </InputLabel>
                    <Select
                      labelId="priority-simple-select-label"
                      id="priority-simple-select"
                      value={values.priority}
                      onChange={(event) => {
                        setFieldValue("priority", event.target.value);
                      }}
                    >
                      <MenuItem
                        id={"priority-0"}
                        key={"todo-priority-0"}
                        value={0}
                      >
                        {"Low"}
                      </MenuItem>
                      <MenuItem
                        id={"priority-1"}
                        key={"todo-priority-1"}
                        value={1}
                      >
                        {"Normal"}
                      </MenuItem>
                      <MenuItem
                        id={"priority-2"}
                        key={"todo-priority-2"}
                        value={2}
                      >
                        {"High"}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {values.value === "" && !values.isTask ? (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      disabled
                      type="submit"
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      Submit
                    </Button>
                  )}
                </form>
              )}
            </Formik>
          ) : (
            <Box />
          )}
        </Box>
      </Modal>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}/todolists`)}
          startIcon={<CheckBox sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          To Do Lists
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <CheckBox sx={{ mr: 0.5 }} fontSize="inherit" />
          {todoList?.name}
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={8} sm={8} md={3} lg={3} xl={3}>
          <Typography variant="h5">{todoList?.name}:</Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={3} lg={2} xl={2}>
          <Box display="flex" justifyContent="flex-end">
            {todoList?.status === 0 ? (
              <Chip
                label={getTodoListStatus(todoList?.status)}
                color="secondary"
              />
            ) : todoList?.status === 1 ? (
              <Chip
                label={getTodoListStatus(todoList?.status)}
                color="primary"
              />
            ) : todoList?.status === 2 ? (
              <Chip
                label={getTodoListStatus(todoList?.status)}
                color="success"
              />
            ) : todoList?.status === 3 ? (
              <Chip
                label={getTodoListStatus(todoList?.status)}
                color="warning"
              />
            ) : todoList?.status === 4 ? (
              <Chip label={getTodoListStatus(todoList?.status)} color="info" />
            ) : (
              <Box />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
          {todoList?.status === 0 &&
          IsUserElevated(userProfile, selectedClient.id) ? (
            <Box display="flex" justifyContent="flex-end">
              <ButtonGroup size="small" variant="contained">
                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    setnewTodoModalOpen(true);
                  }}
                  startIcon={<Add />}
                >
                  New Item
                </Button>
                {todoList.items !== undefined && todoList.items?.length > 0 ? (
                  <Button
                    color="primary"
                    onClick={() => {
                      setPublishModalOpen(true);
                    }}
                    startIcon={<Publish />}
                  >
                    Publish
                  </Button>
                ) : (
                  <Button color="primary" disabled startIcon={<Publish />}>
                    Publish
                  </Button>
                )}
              </ButtonGroup>
            </Box>
          ) : (
            <React.Fragment />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12} sm={6} md={6}>
              {todoList?.userRoleToggle === "user" ? (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Avatar />
                  <Stack direction="column">
                    <Typography variant="body1">
                      {todoList?.assigneeUserName}
                    </Typography>
                    <Typography variant="body1">
                      {todoList?.assigneeEmail}
                    </Typography>
                  </Stack>
                </Stack>
              ) : (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Tooltip
                    sx={{ zIndex: 999 }}
                    title={
                      <Stack direction="column">
                        {todoList?.assignedRoleId === 0
                          ? users.map((u) => {
                              return (
                                <Typography variant="body1">
                                  {u.userName}
                                </Typography>
                              );
                            })
                          : roles
                              .filter(
                                (role) => role.role === todoList?.assignedRoleId
                              )
                              .map((roleUser) => {
                                return (
                                  <Typography variant="body1">
                                    {roleUser.userName}
                                  </Typography>
                                );
                              })}
                      </Stack>
                    }
                  >
                    <Avatar>
                      <Group />
                    </Avatar>
                  </Tooltip>
                  <Stack direction="column">
                    <Typography variant="body1">
                      {getShipUserRoleValue(todoList?.assignedRoleId)}
                    </Typography>
                  </Stack>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box display="flex" justifyContent="flex-end">
                <Chip
                  icon={<CalendarMonth />}
                  label={`Due ${moment(todoList?.dueDate).format(
                    "MM/DD/YYYY"
                  )}`}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mt: 2, mb: 2 }} variant="fullWidth">
            <Chip label="TO DO" size="medium" color="primary" />
          </Divider>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {todoList?.items?.length === 0 ? (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 10 }}
            >
              <Typography variant="h5">No Items Found</Typography>
            </Stack>
          ) : (
            <Grid container spacing={1}>
              {todoList?.items
                ?.filter((item) => item.status !== 2 && item.status !== 3).sort(comparePriority)
                .map((todoListItem) => {
                  return (
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={1}>
                          <Assignment />
                        </Grid>
                        <Grid item xs={10} sm={6} md={4} lg={4} xl={4}>
                          {todoListItem.isTask ? (
                            <Button
                              variant="text"
                              sx={{ pt: 0, pl: 0 }}
                              onClick={() => {
                                navigate(
                                  `/Ships/${ship?.id}/tasks/time/${todoListItem.taskId}`
                                );
                              }}
                            >
                              {todoListItem.taskName}
                            </Button>
                          ) : (
                            todoListItem.value
                          )}
                          {todoListItem.assigneeUserId !== "" &&
                          todoListItem.assigneeUserId !== null ? (
                            <Chip
                              icon={<Person />}
                              sx={{ ml: 2 }}
                              label={todoListItem.assigneeUserName}
                            />
                          ) : (
                            <Box />
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={5}
                          md={6}
                          lg={6}
                          xl={6}
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <TodoListPriorityIcon priority={todoListItem.priority} />
                          {todoListItem.comments !== undefined &&
                          todoListItem.comments?.length > 0 ? (
                            <IconButton
                              sx={{ pt: 0.5, pb: 0.5 }}
                              onClick={() => {
                                setCommentTodoListItem(todoListItem);
                                setCommentModalOpen(true);
                              }}
                            >
                              <ChatBubble />
                            </IconButton>
                          ) : (
                            <Box />
                          )}
                          {todoListItem.status === 0 &&
                          todoList.status !== 0 ? (
                            <ButtonGroup>
                              <Button
                                variant="contained"
                                color="success"
                                onClick={() => {
                                  updateTodoListItemStatus(
                                    todoList.id,
                                    todoListItem.id,
                                    selectedClient.id,
                                    1
                                  ).then((res) => {
                                    getShipTodoList(
                                      params.todolistid,
                                      selectedClient.id
                                    ).then((response) => {
                                      setTodoList(response);
                                    });
                                  });
                                }}
                              >
                                Start
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  updateTodoListItemStatus(
                                    todoList.id,
                                    todoListItem.id,
                                    selectedClient.id,
                                    2
                                  ).then((res) => {
                                    getShipTodoList(
                                      params.todolistid,
                                      selectedClient.id
                                    ).then((response) => {
                                      if (response.status === 4) {
                                        navigate(
                                          `/ships/${selectedShip.id}/todolists/${response.id}/review`
                                        );
                                      }
                                      setTodoList(response);
                                    });
                                  });
                                }}
                              >
                                Done
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                  setSkipTodoListItem(todoListItem);
                                  setSkipModalOpen(true);
                                }}
                              >
                                Skip
                              </Button>
                            </ButtonGroup>
                          ) : todoListItem.status === 1 ? (
                            <ButtonGroup>
                             
                              <Button
                                variant="outlined"
                                color="success"
                                onClick={() => {
                                  updateTodoListItemStatus(
                                    todoList.id,
                                    todoListItem.id,
                                    selectedClient.id,
                                    0
                                  ).then((res) => {
                                    getShipTodoList(
                                      params.todolistid,
                                      selectedClient.id
                                    ).then((response) => {
                                      setTodoList(response);
                                    });
                                  });
                                }}
                              >
                                To Do
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  updateTodoListItemStatus(
                                    todoList.id,
                                    todoListItem.id,
                                    selectedClient.id,
                                    2
                                  ).then((res) => {
                                    getShipTodoList(
                                      params.todolistid,
                                      selectedClient.id
                                    ).then((response) => {
                                      if (response.status === 4) {
                                        navigate(
                                          `/ships/${selectedShip.id}/todolists/${response.id}/review`
                                        );
                                      }
                                      setTodoList(response);
                                    });
                                  });
                                }}
                              >
                                Done
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                  setSkipTodoListItem(todoListItem);
                                  setSkipModalOpen(true);
                                }}
                              >
                                Skip
                              </Button>
                            </ButtonGroup>
                          ) : todoListItem.status === 2 ? (
                            <Stack direction="row" spacing={1}>
                              <Done sx={{ color: "green" }} />
                              <Typography variant="body1">Completed</Typography>
                            </Stack>
                          ) : todoListItem.status === 3 ? (
                            <Stack direction="row" spacing={1}>
                              <SkipNext sx={{ color: "red" }} />
                              <Typography variant="body1">Skipped</Typography>
                            </Stack>
                          ) : (
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <IconButton
                                onClick={() => {
                                  setEditTodoListItem(todoListItem);
                                  setEditTodoModalOpen(true);
                                }}
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  deleteTodoListItem(
                                    todoList.id,
                                    selectedClient.id,
                                    todoListItem.id
                                  ).then((res) => {
                                    getShipTodoList(
                                      params.todolistid,
                                      selectedClient.id
                                    ).then((response) => {
                                      setTodoList(response);
                                    });
                                  });
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </Stack>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          )}
        </Grid>
        {todoList?.items !== undefined &&
        todoList?.items?.filter((item) => item.status === 3).length > 0 ? (
          <Grid item xs={12}>
            <Divider sx={{ mt: 2, mb: 2 }} variant="fullWidth">
              <Chip label="Skipped" size="medium" color="error" />
            </Divider>
          </Grid>
        ) : (
          <Box />
        )}
        {todoList?.items !== undefined &&
        todoList?.items?.filter((item) => item.status === 3).length > 0 ? (
          todoList?.items
            ?.filter((item) => item.status === 3)
            .map((todoListItem) => {
              return (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={1}>
                      <Assignment />
                    </Grid>
                    <Grid item xs={10} sm={6} md={4} lg={4} xl={4}>
                      {todoListItem.isTask ? (
                        <Button
                          variant="text"
                          sx={{ pt: 0, pl: 0 }}
                          onClick={() => {
                            navigate(
                              `/Ships/${ship?.id}/tasks/time/${todoListItem.taskId}`
                            );
                          }}
                        >
                          {todoListItem.taskName}
                        </Button>
                      ) : (
                        todoListItem.value
                      )}
                      {todoListItem.assigneeUserId !== "" &&
                      todoListItem.assigneeUserId !== null ? (
                        <Chip
                          icon={<Person />}
                          sx={{ mr: 2 }}
                          label={todoListItem.assigneeUserName}
                        />
                      ) : (
                        <Box />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={6}
                      lg={6}
                      xl={6}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Stack direction="row" spacing={1} alignItems={"center"}>
                        <PriorityIcon priority={todoListItem.priority}/>
                        {todoListItem.comments !== undefined &&
                        todoListItem.comments?.length > 0 ? (
                          <IconButton
                            sx={{ pt: 0.5, pb: 0.5 }}
                            onClick={() => {
                              setCommentTodoListItem(todoListItem);
                              setCommentModalOpen(true);
                            }}
                          >
                            <ChatBubble />
                          </IconButton>
                        ) : (
                          <Box />
                        )}
                        <SkipNext sx={{ color: "red" }} />
                        <Typography variant="body1">Skipped</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })
        ) : (
          <Box />
        )}
        {todoList?.items !== undefined &&
        todoList?.items?.filter((item) => item.status === 2).length > 0 ? (
          <Grid item xs={12}>
            <Divider sx={{ mt: 2, mb: 2 }} variant="fullWidth">
              <Chip label="Completed" size="medium" color="success" />
            </Divider>
          </Grid>
        ) : (
          <Box />
        )}
        {todoList?.items !== undefined &&
        todoList?.items?.filter((item) => item.status === 2).length > 0 ? (
          todoList?.items
            ?.filter((item) => item.status === 2)
            .map((todoListItem) => {
              return (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={1}>
                      <Assignment />
                    </Grid>
                    <Grid item xs={12} sm={7} md={4} lg={4} xl={4}>
                      {todoListItem.isTask ? (
                        <Button
                          variant="text"
                          sx={{ pt: 0, pl: 0 }}
                          onClick={() => {
                            navigate(
                              `/Ships/${ship?.id}/tasks/time/${todoListItem.taskId}`
                            );
                          }}
                        >
                          {todoListItem.taskName}
                        </Button>
                      ) : (
                        todoListItem.value
                      )}
                      {todoListItem.assigneeUserId !== "" &&
                      todoListItem.assigneeUserId !== null ? (
                        <Chip
                          icon={<Person />}
                          sx={{ mr: 2 }}
                          label={todoListItem.assigneeUserName}
                        />
                      ) : (
                        <Box />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={6}
                      lg={6}
                      xl={6}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Stack direction="row" spacing={1} alignItems={"center"}>
                        <PriorityIcon priority={todoListItem.priority}/>
                        {todoListItem.comments !== undefined &&
                        todoListItem.comments?.length > 0 ? (
                          <IconButton
                            sx={{ pt: 0.5, pb: 0.5 }}
                            onClick={() => {
                              setCommentTodoListItem(todoListItem);
                              setCommentModalOpen(true);
                            }}
                          >
                            <ChatBubble />
                          </IconButton>
                        ) : (
                          <Box />
                        )}
                        <Done sx={{ color: "green" }} />
                        <Typography variant="body1">Completed</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })
        ) : (
          <Box />
        )}
      </Grid>
    </Container>
  );
}
