import { ClientsState } from "../../Recoil/atoms/ClientsAtom";
import { useRecoilState } from "recoil";
import { DataGrid, GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Container,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import * as yup from "yup";
import { Add } from "@mui/icons-material";
import { useFormik } from "formik";
import { Client } from "../../types/Client";
import { createClient, getClients } from "../../services/adminservice";
export default function ClientManagement() {
  const [clients, setClients] = useRecoilState(ClientsState);
  const [open, setOpen] = useState(false);
  const handleDeleteClick = (id: any) => () => {
    alert(id);
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .min(2, "Client Name should be minimum of 2 characters length")
      .required("Client Name is Required"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      billingTier: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      var client: Client = {
        name: values.name,
        billingTier: values.billingTier
      };
      createClient(client).then(() =>
        getClients().then((clientsResponse) => setClients(clientsResponse))
      );
      setOpen(false);
    },
  });
  const columns = [
    { field: "name", headerName: "Name", width: 300 },
    { field: "createdDate", headerName: "Created Date", flex: 1, width: 300 },
    {
      field: "billingTier",
      headerName: "Billing Tier",
      valueGetter: (params: any) => {
        if(params.row.billingTier === 0){
          return "Cruiser";
        }else{
          return "Charter";
        }
      },
      width: 300,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 0.2,
      width: 50,
      cellClassName: "actions",
      getActions: (params: GridRowParams): any => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(params.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            sx={{ mb: 3 }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Create New Client
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Client Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              sx={{ mb: 2 }}
            />
            <InputLabel id="billingTier-label">Ship Status</InputLabel>
            <Select
              labelId="billingTier-label"
              id="billingTier"
              value={formik.values.billingTier}
              onChange={(e) => {
                formik.setFieldValue("billingTier", e.target.value);
              }}
              fullWidth
              sx={{ mb: 2 }}
            >
              <MenuItem id={"status-0"} key={0} value={0}>
                Cruiser
              </MenuItem>
              <MenuItem id={"status-1"} key={1} value={1}>
                Charter
              </MenuItem>
            </Select>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
      <Container sx={{ mt: 4 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Client Management
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
            startIcon={<Add />}
          >
            New Client
          </Button>
        </Stack>
        <DataGrid sx={{ height: "80%" }} rows={clients} columns={columns} />
      </Container>
    </React.Fragment>
  );
}
