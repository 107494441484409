import { Box, Button, Divider, Grid, Modal, Paper, Stack, TextField, Typography } from "@mui/material";
import AccordianPaperSection from "../AccordianPaperSection";
import TripInfoCard from "./TripInfoCard";
import { Edit } from "@mui/icons-material";
import TripCard from "./TripCard";
import { useState } from "react";
import { Trip } from "../../types/Trip";
import { Formik } from "formik";
import { getTrip, updateTrip } from "../../services/tripservice";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { useRecoilState } from "recoil";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

export type TripInformationSectionProps = {
    trip?: Trip
    setTrip: Function
}

export default function TripInformationSection(props: TripInformationSectionProps){
    const {trip, setTrip}= props;
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedShip] = useRecoilState(SelectedShipState);
    const [selectedClient] = useRecoilState(SelectedClientState);
     return (          
     <Box>
      <Modal
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
          }}
        >
          <Paper
            sx={{
              p: 2,
              borderTopColor: "#b47f49",
              borderTopWidth: "4px",
              borderStyle: "solid",
              borderRightWidth: "0",
              borderLeftWidth: "0",
              borderBottomWidth: "0",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">Edit</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                {trip !== undefined ? (
                  <Formik
                    enableReinitialize
                    initialValues={trip}
                    onSubmit={(values: Trip) => {
                      updateTrip(
                        selectedClient.id,
                        selectedShip.id,
                        values
                      ).then((res) => {
                        getTrip(
                          selectedClient.id,
                          selectedShip.id,
                          trip.id
                        ).then((res) => {
                          setTrip(res);
                          setEditModalOpen(false);
                        });
                      });
                    }}
                  >
                    {({
                      errors,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      touched,
                      values,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        {trip.type === 0 ? (
                          <Stack direction="column">
                                                  <TextField
                            id="name"
                            name="name"
                            label="Name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                            <TextField
                              id="startLocation"
                              name="startLocation"
                              label="Location"
                              value={values.startLocation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.startLocation &&
                                Boolean(errors.startLocation)
                              }
                              helperText={
                                touched.startLocation && errors.startLocation
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                            <DatePicker
                              label="Start Date"
                              value={DateTime.fromISO(
                                values.startDate !== undefined
                                  ? values.startDate.toString()
                                  : DateTime.now().toString()
                              )}
                              onChange={(value: any) => {
                                setFieldValue(
                                  "startDate",
                                  DateTime.fromISO(value, { zone: "utc" }),
                                  true
                                );
                              }}
                              sx={{ mb: 2 }}
                            />
                            <DatePicker
                              label="End Date"
                              value={DateTime.fromISO(
                                values.endDate !== undefined
                                  ? values.endDate.toString()
                                  : DateTime.now().toString()
                              )}
                              onChange={(value: any) =>
                                setFieldValue(
                                  "endDate",
                                  DateTime.fromISO(value, { zone: "utc" }),
                                  true
                                )
                              }
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              id="notes"
                              name="notes"
                              label="Notes"
                              value={values.notes}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.notes && Boolean(errors.notes)}
                              helperText={touched.notes && errors.notes}
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                            <Button
                              color="primary"
                              variant="contained"
                              fullWidth
                              type="submit"
                            >
                              Submit
                            </Button>
                          </Stack>
                        ) : (
                          <Box />
                        )}
                        {trip.type === 1 ? (
                          <Stack direction="column">
                                                                              <TextField
                            id="name"
                            name="name"
                            label="Name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                            <DatePicker
                              label="Start Date"
                              value={DateTime.fromISO(
                                values.startDate !== undefined
                                  ? values.startDate.toString()
                                  : DateTime.now().toString()
                              )}
                              onChange={(value: any) =>
                                setFieldValue(
                                  "startDate",
                                  DateTime.fromISO(value, { zone: "utc" }),
                                  true
                                )
                              }
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              id="startLocation"
                              name="startLocation"
                              label="Start Location"
                              value={values.startLocation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.startLocation &&
                                Boolean(errors.startLocation)
                              }
                              helperText={
                                touched.startLocation && errors.startLocation
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                            <DatePicker
                              label="End Date"
                              value={DateTime.fromISO(
                                values.endDate !== undefined
                                  ? values.endDate.toString()
                                  : DateTime.now().toString()
                              )}
                              onChange={(value: any) =>
                                setFieldValue(
                                  "endDate",
                                  DateTime.fromISO(value, { zone: "utc" }),
                                  true
                                )
                              }
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              id="endLocation"
                              name="endLocation"
                              label="End Location"
                              value={values.endLocation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.endLocation &&
                                Boolean(errors.endLocation)
                              }
                              helperText={
                                touched.endLocation && errors.endLocation
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              id="nauticalMiles"
                              name="nauticalMiles"
                              label="Nautical Miles"
                              value={values.nauticalMiles}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.nauticalMiles &&
                                Boolean(errors.nauticalMiles)
                              }
                              helperText={
                                touched.nauticalMiles && errors.nauticalMiles
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              id="notes"
                              name="notes"
                              label="Notes"
                              value={values.notes}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.notes && Boolean(errors.notes)}
                              helperText={touched.notes && errors.notes}
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                            <Button
                              color="primary"
                              variant="contained"
                              fullWidth
                              type="submit"
                            >
                              Submit
                            </Button>
                          </Stack>
                        ) : (
                          <Box />
                        )}
                        {trip.type === 2 ? (
                          <Stack direction="column">
                                                                              <TextField
                            id="name"
                            name="name"
                            label="Name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                            <DatePicker
                              label="Start Date"
                              value={DateTime.fromISO(
                                values.startDate !== undefined
                                  ? values.startDate.toString()
                                  : DateTime.now().toString()
                              )}
                              onChange={(value: any) =>
                                setFieldValue(
                                  "startDate",
                                  DateTime.fromISO(value, { zone: "utc" }),
                                  true
                                )
                              }
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              id="startLocation"
                              name="startLocation"
                              label="Start Location"
                              value={values.startLocation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.startLocation &&
                                Boolean(errors.startLocation)
                              }
                              helperText={
                                touched.startLocation && errors.startLocation
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                            <DatePicker
                              label="End Date"
                              value={DateTime.fromISO(
                                values.endDate !== undefined
                                  ? values.endDate.toString()
                                  : DateTime.now().toString()
                              )}
                              onChange={(value: any) =>
                                setFieldValue(
                                  "endDate",
                                  DateTime.fromISO(value, { zone: "utc" }),
                                  true
                                )
                              }
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              id="endLocation"
                              name="endLocation"
                              label="End Location"
                              value={values.endLocation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.endLocation &&
                                Boolean(errors.endLocation)
                              }
                              helperText={
                                touched.endLocation && errors.endLocation
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              id="nauticalMiles"
                              name="nauticalMiles"
                              label="Nautical Miles"
                              value={values.nauticalMiles}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.nauticalMiles &&
                                Boolean(errors.nauticalMiles)
                              }
                              helperText={
                                touched.nauticalMiles && errors.nauticalMiles
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              id="notes"
                              name="notes"
                              label="Notes"
                              value={values.notes}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.notes && Boolean(errors.notes)}
                              helperText={touched.notes && errors.notes}
                              fullWidth
                              sx={{ mb: 2 }}
                            />

                            <Button
                              color="primary"
                              variant="contained"
                              fullWidth
                              type="submit"
                            >
                              Submit
                            </Button>
                          </Stack>
                        ) : (
                          <Box />
                        )}
                        {trip.type === 3 ? (
                          <Stack direction="column">
                                                                              <TextField
                            id="name"
                            name="name"
                            label="Name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name && errors.name}
                            fullWidth
                            sx={{ mb: 2 }}
                          />
                            <TextField
                              id="startLocation"
                              name="startLocation"
                              label="Location"
                              value={values.startLocation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.startLocation &&
                                Boolean(errors.startLocation)
                              }
                              helperText={
                                touched.startLocation && errors.startLocation
                              }
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                            <DatePicker
                              label="Start Date"
                              value={DateTime.fromISO(
                                values.startDate !== undefined
                                  ? values.startDate.toString()
                                  : DateTime.now().toString()
                              )}
                              onChange={(value: any) => {
                                setFieldValue(
                                  "startDate",
                                  DateTime.fromISO(value, { zone: "utc" }),
                                  true
                                );
                              }}
                              sx={{ mb: 2 }}
                            />
                            <DatePicker
                              label="End Date"
                              value={DateTime.fromISO(
                                values.endDate !== undefined
                                  ? values.endDate.toString()
                                  : DateTime.now().toString()
                              )}
                              onChange={(value: any) =>
                                setFieldValue(
                                  "endDate",
                                  DateTime.fromISO(value, { zone: "utc" }),
                                  true
                                )
                              }
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              id="notes"
                              name="notes"
                              label="Notes"
                              value={values.notes}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.notes && Boolean(errors.notes)}
                              helperText={touched.notes && errors.notes}
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                            <Button
                              color="primary"
                              variant="contained"
                              fullWidth
                              type="submit"
                            >
                              Submit
                            </Button>
                          </Stack>
                        ) : (
                          <Box />
                        )}
                      </form>
                    )}
                  </Formik>
                ) : (
                  <Box />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Modal>
     <AccordianPaperSection
     useHeader={true}
     elevation={12}
     isStatic={trip?.type === 1 || trip?.type === 2 ? false : true}
     header={<TripCard trip={trip} isClickable={false} />}
     collapsedText="Information"
     button={
       <Button
         variant="contained"
         startIcon={<Edit />}
         onClick={() => {
           setEditModalOpen(true);
         }}
       >
         Edit
       </Button>
     }
     collapsed={true}
   >
     <Grid container spacing={2}>
       {trip?.type === 1 || trip?.type === 2 ? (
         <Grid item xs={12}>
           <TripInfoCard trip={trip} />
         </Grid>
       ) : (
         <Box />
       )}
     </Grid>
   </AccordianPaperSection>
   </Box>
     )
}