import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Delete, Route } from "@mui/icons-material";
import { Trip, TripStop } from "../../types/Trip";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import { IconBeach, IconSailboat } from "@tabler/icons-react";
import moment from "moment";
import { Formik } from "formik";
import { DateTime } from "luxon";
import { useState } from "react";
import {
  createTripStop,
  deleteTripStop,
  getTrip,
} from "../../services/tripservice";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { DatePicker } from "@mui/x-date-pickers";
import AccordianPaperSection from "../AccordianPaperSection";

export type TripStopsCardProps = {
  trip: Trip;
  setTrip: Function;
};
export default function TripStopsCard(props: TripStopsCardProps) {
  const { trip, setTrip } = props;
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [stopsModalOpen, setStopsModalOpen] = useState(false);
  const initialStopValues = {
    location: "",
    startDate: DateTime.now(),
    endDate: DateTime.now(),
  };

  function compareStops(a: TripStop, b: TripStop) {
    if (a.startDate !== undefined && b.startDate !== undefined) {
      return moment(a.startDate).valueOf() - moment(b.startDate).valueOf();
    }
    return 0;
  }

  return (
    <Box>
      <Modal
        open={stopsModalOpen}
        onClose={() => {
          setStopsModalOpen(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
          }}
        >
          <Paper
            sx={{
              p: 2,
              borderTopColor: "#b47f49",
              borderTopWidth: "4px",
              borderStyle: "solid",
              borderRightWidth: "0",
              borderLeftWidth: "0",
              borderBottomWidth: "0",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">New Stop</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Formik
                  initialValues={initialStopValues}
                  onSubmit={(values) => {
                    console.log(values);
                    var stop: TripStop = {
                      location: values.location,
                      startDate: values.startDate,
                      endDate: values.endDate,
                    };
                    createTripStop(
                      selectedClient.id,
                      selectedShip.id,
                      trip.id,
                      stop
                    ).then((createRes) => {
                      getTrip(selectedClient.id, selectedShip.id, trip.id).then(
                        (res) => {
                          setTrip(res);
                          setStopsModalOpen(false);
                        }
                      );
                    });
                  }}
                >
                  {({
                    errors,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Stack direction="column">
                        <TextField
                          id="location"
                          name="location"
                          label="Location"
                          value={values.location}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.location && Boolean(errors.location)}
                          helperText={touched.location && errors.location}
                          fullWidth
                          sx={{ mb: 2 }}
                        />
                        <DatePicker
                          disablePast
                          label="Start Date"
                          value={values.startDate}
                          onChange={(value: any) =>
                            setFieldValue(
                              "startDate",
                              DateTime.fromISO(value),
                              true
                            )
                          }
                          sx={{ mb: 2 }}
                        />
                        <DatePicker
                          disablePast
                          label="End Date"
                          value={values.endDate}
                          onChange={(value: any) =>
                            setFieldValue(
                              "endDate",
                              DateTime.fromISO(value),
                              true
                            )
                          }
                          sx={{ mb: 2 }}
                        />
                        <Button
                          color="primary"
                          variant="contained"
                          fullWidth
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Stack>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Modal>
      <AccordianPaperSection
        title="Stops"
        elevation={12}
        collapsed={false}
        icon={<Route sx={{ fontSize: 24 }} />}
        button={
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              setStopsModalOpen(true);
            }}
          >
            Add
          </Button>
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              <TimelineItem key={`start-${trip.id}`}>
                <TimelineSeparator>
                  <TimelineDot color="primary">
                    <IconSailboat />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: "12px", px: 2 }}>
                  <Typography variant="h6" component="span">
                    {trip.startLocation}
                  </Typography>
                  <Typography variant="body1">
                    {moment(trip.startDate).format("MM/DD/YYYY")}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              {trip.stops?.sort(compareStops).map((stop) => (
                <TimelineItem key={`${stop.id}`}>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="primary">
                      <IconBeach />
                    </TimelineDot>
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: "12px", px: 2 }}>
                    <Stack direction="row" justifyContent="space-between">
                      <Stack direction="column">
                        <Typography variant="h6" component="span">
                          {stop.location}
                        </Typography>
                        <Typography variant="body1">
                          {moment(stop.startDate).format("MM/DD/YYYY")} -{" "}
                          {moment(stop.endDate).format("MM/DD/YYYY")}
                        </Typography>
                      </Stack>
                      <IconButton
                        onClick={() => {
                          deleteTripStop(
                            selectedClient.id,
                            selectedShip.id,
                            trip.id,
                            stop.id
                          ).then((res) => {
                            getTrip(
                              selectedClient.id,
                              selectedShip.id,
                              trip.id
                            ).then((tripRes) => setTrip(tripRes));
                          });
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Stack>
                  </TimelineContent>
                </TimelineItem>
              ))}
              <TimelineItem key={`finish-${trip.id}`}>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot color="primary">
                    <IconSailboat />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent sx={{ py: "12px", px: 2 }}>
                  <Typography variant="h6" component="span">
                    {trip.endLocation}
                  </Typography>
                  <Typography variant="body1">
                    {moment(trip.endDate).format("MM/DD/YYYY")}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Grid>
        </Grid>
      </AccordianPaperSection>
    </Box>
  );
}
