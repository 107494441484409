import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Input,
  LinearProgress,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { useEffect, useState } from "react";
import { Ship } from "../../types/Ship";
import { Task } from "../../types/Task";
import {
  downloadTaskFile,
  downloadTaskImage,
  getShip,
  getTask,
  uploadTaskFile,
} from "../../services/shipService";
import {
  Add,
  CloudUpload,
  DirectionsBoat,
  Download,
  Edit,
  Note,
  TaskAlt,
  Task as TaskIcon,
  Warning,
} from "@mui/icons-material";
import moment from "moment";
import { LineChart } from "@mui/x-charts";
import { DataGrid, GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import { getPriority } from "../../utilities/taskUtils";
import parse from "html-react-parser";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import TaskImages from "../../components/Tasks/TaskImages";
import PaperSection from "../../components/PaperSection";
import { IconFile, IconList } from "@tabler/icons-react";
import AccordianPaperSection from "../../components/AccordianPaperSection";

export default function EquipmentTask() {
  const params = useParams();
  const navigate = useNavigate();
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [ship, setShip] = useState<Ship>();
  const [task, setTask] = useState<Task>();
  const [note, setNote] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [file, setFile] = useState<any>();
  const [helperName, setHelperName] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [, setImageLoading] = useState(true);

  useEffect(() => {
    getShip(params.id, selectedClient.id).then((data) => {
      setShip(data);
      getTask(selectedClient.id, params.id, params.taskid).then((taskRes) => {
        setTask(taskRes);
      });
    });
  }, [selectedClient, selectedClient.id, params.id, params.taskid]);

  useEffect(() => {
    task?.images?.forEach((i) => {
      downloadTaskImage(i.id, selectedShip.id, task.id, selectedClient.id).then(
        (res) => {
          setImageLoading(true);
          var reader = new FileReader();
          reader.readAsDataURL(res);
          reader.onloadend = function () {
            var base64data = reader.result;
            var base64string = `${base64data}`;
            i.imageUrl = `data:image/png;base64,${base64string.split(",")[1]}`;
            i.loaded = true;
            setImageLoading(false);
          };
        }
      );
    });
  }, [task?.id, task?.images, selectedClient.id, selectedShip.id]);
  const columns = [
    {
      field: "lastPerformedDate",
      headerName: "Last Performed Date",
      valueFormatter: (params: any) => {
        return moment(params.value).format("MM/DD/YYYY HH:MM:SS");
      },
      flex: 1,
      width: 300,
    },
    {
      field: "lastPerformedUsage",
      headerName: `Last Performed Usage (${task?.equipment?.usageUnits})`,
      valueGetter: (params: any) => {
        return params.row.lastPerformedUsage;
      },
      flex: 1,
      width: 50,
    },
    {
      field: "userName",
      headerName: `User Name`,
      valueGetter: (params: any) => {
        return params.row.userName;
      },
      flex: 1,
      width: 50,
    },
    {
      field: "notes",
      type: "actions",
      headerName: "Notes",
      flex: 1,
      width: 50,
      cellClassName: "actions",
      getActions: (params: GridRowParams): any => {
        return [
          <GridActionsCellItem
            icon={<Note />}
            label="View"
            onClick={() => {
              setNote(params.row.notes);
              setOpen(true);
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const columnsFile = [
    {
      field: "helperName",
      headerName: `Name`,
      valueGetter: (params: any) => {
        return params.row.helperName;
      },
      flex: 1,
      width: 50,
    },
    {
      field: "userName",
      headerName: `Uploaded By`,
      valueGetter: (params: any) => {
        return params.row.userName;
      },
      flex: 1,
      width: 50,
    },
    {
      field: "createdDate",
      headerName: "Upload Date",
      valueFormatter: (params: any) => {
        return moment(params.value).format("MM/DD/YYYY HH:MM:SS");
      },
      flex: 1,
      width: 300,
    },
    {
      field: "download",
      type: "actions",
      headerName: "Download",
      flex: 1,
      width: 50,
      cellClassName: "actions",
      getActions: (params: GridRowParams): any => {
        return [
          <GridActionsCellItem
            icon={<Download />}
            label="Download"
            onClick={() => {
              setDownloading(true);
              downloadTaskFile(params.row.id, selectedClient.id).then(
                (response) => {
                  setDownloading(false);
                }
              );
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const onUploadProgress = (progressevent: ProgressEvent) => {
    const percentCompleted = Math.round(
      (progressevent.loaded * 100) / progressevent.total
    );
    setUploadProgress(percentCompleted);
    if (percentCompleted === 100) {
      getShip(params.id, selectedClient.id).then((data) => {
        setShip(data);
        getTask(selectedClient.id, params.id, params.taskid).then((taskRes) => {
          setTask(taskRes);
          setUploadProgress(0);
          setUploading(false);
          setUploadOpen(false);
          setHelperName("");
          setFile(null);
        });
      });
    }
  };

  function handleFileChange(event: any) {
    setFile(event.target.files[0]);
  }

  function handleUploadSubmit(event: any) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.Name);
    setUploading(true);
    uploadTaskFile(
      onUploadProgress,
      selectedClient.id,
      ship?.id,
      task?.id,
      formData,
      helperName
    ).then((res) => {
      getShip(params.id, selectedClient.id).then((data) => {
        setShip(data);
        getTask(selectedClient.id, params.id, params.taskid).then((taskRes) => {
          setTask(taskRes);
          setUploadProgress(0);
          setUploading(false);
          setUploadOpen(false);
          setHelperName("");
          setFile(null);
        });
      });
    });
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Modal
        open={uploadOpen}
        onClose={() => {
          setUploadOpen(false);
          setUploading(false);
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Card sx={{ mb: 4 }}>
            <CardContent sx={{ background: "rgba(255, 255, 255, 0.12)" }}>
              {!uploading ? (
                <Box>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Upload New File:
                  </Typography>
                  <form onSubmit={handleUploadSubmit}>
                    <TextField
                      label="Name"
                      value={helperName}
                      onChange={(event) => setHelperName(event.target.value)}
                      fullWidth
                    />
                    <Input
                      sx={{ mb: 2 }}
                      type="file"
                      onChange={handleFileChange}
                    />
                    <Button type="submit" variant="contained" fullWidth>
                      Upload
                    </Button>
                  </form>
                </Box>
              ) : (
                <Box>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Uploading...{" "}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress}
                  />
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
      </Modal>

      <Modal open={downloading} onClose={() => {}}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            height: 200,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h5" sx={{ mb: 4 }}>
            Downloading...
          </Typography>
          <LinearProgress />
        </Box>
      </Modal>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Card sx={{ mb: 4 }}>
            <CardContent sx={{ background: "rgba(255, 255, 255, 0.12)" }}>
              <Typography variant="h5">Notes:</Typography>
              {parse(note)}
            </CardContent>
          </Card>
        </Box>
      </Modal>
      <Breadcrumbs sx={{ mb: 4 }}>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate("/")}
          startIcon={<DirectionsBoat sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          {selectedShip.name}
        </Button>
        <Button
          sx={{ display: "flex", alignItems: "center" }}
          color="inherit"
          onClick={() => navigate(`/ships/${ship?.id}`)}
          startIcon={<TaskAlt sx={{ mr: 0.5 }} fontSize="inherit" />}
        >
          Tasks
        </Button>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="text.primary"
        >
          <TaskIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {task?.name}
        </Typography>
      </Breadcrumbs>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AccordianPaperSection
            title={task?.name}
            icon={<TaskIcon />}
            button={
              <Button
                variant="contained"
                onClick={() =>
                  navigate(
                    `/Ships/${ship?.id}/tasks/equipment/${task?.id}/edit`
                  )
                }
                startIcon={<Edit />}
              >
                Edit
              </Button>
            }
            collapsedText="Task Information"
            collapsed={true}
          >
            <Grid container spacing={2} sx={{ p: 4 }}>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Description: </Typography>
                  <Typography>{task?.description}</Typography>
                </Stack>
              </Grid>
              {task?.forEquipment ? (
                <Grid xs={6}>
                  <Stack>
                    <Typography variant="h6">Equipment: </Typography>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        navigate(
                          `/ships/${ship?.id}/equipment/${task.equipment?.id}`
                        )
                      }
                    >
                      {task.equipment?.name}
                    </Button>
                  </Stack>
                </Grid>
              ) : (
                <Box />
              )}
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Priority: </Typography>
                  <Typography>{getPriority(task?.priority)}</Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Unit Frequency:</Typography>
                  <Typography>
                    Every {task?.unitFrequencyInterval}{" "}
                    {task?.equipment?.usageUnits}
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Last Performed Usage:</Typography>
                  <Typography>{task?.lastPerformedUsage}</Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Usage Since Last Update:</Typography>
                  <Typography>{task?.usageSinceLastUpdate}</Typography>
                </Stack>
              </Grid>
              <Grid xs={6}>
                <Stack>
                  <Typography variant="h6">Last Usage Update: </Typography>
                  <Typography>
                    {moment(task?.lastUsageUpdate).format("MM/DD/YYYY")}
                  </Typography>
                </Stack>
              </Grid>
              {task?.requiresPart ? (
                <Grid xs={6}>
                  <Typography variant="h6">Required Parts:</Typography>
                  <ul>
                    {task?.parts?.map((pt) => {
                      return (
                        <li>
                          <Typography>
                            {pt.partQuantity}
                            {" - "}
                            {pt.part?.name}
                          </Typography>
                        </li>
                      );
                    })}
                  </ul>
                </Grid>
              ) : (
                <Box />
              )}
            </Grid>
          </AccordianPaperSection>
        </Grid>
        <Grid item xs={12}>
          <PaperSection title="Instructions" icon={<IconList />}>
            {task?.instructions !== undefined && task?.instructions !== "" ? (
              <Card>
                <CardContent sx={{ background: "rgba(255, 255, 255, 0.12)" }}>
                  <Typography variant="h5">Instructions</Typography>
                  {parse(task.instructions)}
                </CardContent>
              </Card>
            ) : (
              <Alert
                variant="filled"
                icon={<Warning />}
                severity="warning"
              >
                No Instructions found! Please edit the task to add.
              </Alert>
            )}
          </PaperSection>
        </Grid>
        <Grid item xs={12}>
          <PaperSection
            title="Log"
            icon={<TaskIcon />}
            button={
              <Button
                variant="contained"
                onClick={() =>
                  navigate(
                    `/Ships/${ship?.id}/tasks/equipment/${task?.id}/complete`
                  )
                }
                startIcon={<Add />}
              >
                New Log
              </Button>
            }
          >
            <Grid container spacing={0}>
              <Grid item xs={12}>
                {task?.log === undefined ? (
                  <Box />
                ) : (
                  <Stack alignItems="center" justifyContent="center">
                    <LineChart
                      width={1000}
                      height={300}
                      series={[
                        {
                          data: task?.log?.map((tsk) =>
                            tsk.lastPerformedUsage === undefined
                              ? 0
                              : tsk.lastPerformedUsage
                          ),
                          label: `${task?.equipment?.usageUnits}`,
                        },
                      ]}
                      xAxis={[
                        {
                          scaleType: "point",
                          data: task?.log?.map<any>((tsk) =>
                            moment(tsk.lastPerformedDate).format("MM/DD/YYYY")
                          ),
                        },
                      ]}
                    />
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                {task?.log === undefined ? (
                  <Box />
                ) : (
                  <DataGrid
                    sx={{ height: "250px", minHeight: "250px" }}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: "lastPerformedDate", sort: "desc" }],
                      },
                    }}
                    columns={columns}
                    rows={task?.log}
                  />
                )}
              </Grid>
            </Grid>
          </PaperSection>
        </Grid>
        <Grid item xs={12}>
          <TaskImages task={task} setTask={setTask} />
        </Grid>
        <Grid item xs={12}>
          <PaperSection
            title="Files"
            icon={<IconFile />}
            button={
              <Button
                variant="contained"
                onClick={() => setUploadOpen(true)}
                startIcon={<CloudUpload />}
              >
                Upload
              </Button>
            }
          >
            {task?.files !== undefined ? (
              <DataGrid
                sx={{ height: "40%", minHeight: "300px" }}
                columns={columnsFile}
                rows={task?.files}
              />
            ) : (
              <Box />
            )}
          </PaperSection>
        </Grid>
      </Grid>
    </Container>
  );
}
