import { IconGlassCocktail } from "@tabler/icons-react";
import { DrinkScores, ShipDrinkInformationSummary } from "../../../types/PassengerTypes";
import AccordianPaperSection from "../../AccordianPaperSection";
import { Scrollbar } from "react-scrollbars-custom";
import { Paper, Stack } from "@mui/material";
import FixedFormRatingControl from "../FixedFormRatingControl";

export type MixerScoresCardProps = {
  drinks: ShipDrinkInformationSummary;
};

export default function MixerScoresCard(
  props: MixerScoresCardProps
) {
  const { drinks } = props;
    function compareAverage(a: DrinkScores, b: DrinkScores){
        if(a.average >= b.average){
            return -1;
        }
        else{
            return 1;
        }
    }
  return (
    <AccordianPaperSection
      title="Mixer Drink Scores"
      icon={<IconGlassCocktail />}
    >
        <Scrollbar style={{ height: 300 }}>
        {drinks.mixerScores?.sort(compareAverage).map((mh) => (
          <Paper
            sx={{
              p: 1,
              borderLeftColor: "white",
              borderTopWidth: "0px",
              borderStyle: "solid",
              borderRightWidth: "0px",
              borderLeftWidth: "4px",
              borderBottomWidth: "0",
              mr: 1,
              mb: 2
            }}
            elevation={24}
          >
            <Stack direction="row" spacing={2}>
              <IconGlassCocktail />
              <FixedFormRatingControl name={mh.name} value={mh.average} label={mh.name} precision={0.2}/>
            </Stack>
          </Paper>
        ))}
        </Scrollbar>

    </AccordianPaperSection>
  );
}
