import { Divider, Grid, Paper, Typography } from "@mui/material";
import SkipTodoItemReview from "./SkipTodoItemReview";
import { ShipTodoList } from "../../../types/TodoList";

export type SkippedItemsReviewProps = {
    todoList: ShipTodoList | undefined,
    setOpen: Function,
    setSelectedTodoListItem: Function,
    shipId: string | undefined,
}

export default function SkippedItemsReview(props: SkippedItemsReviewProps){
    const {todoList, shipId, setOpen, setSelectedTodoListItem} = props;
    return <Paper
    sx={{
      p: 2,
      borderTopColor: "red",
      borderTopWidth: "4px",
      borderStyle: "solid",
      borderRightWidth: "0",
      borderLeftWidth: "0",
      borderBottomWidth: "0",
    }}
  >
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">Skipped Items</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {todoList?.items
          ?.filter((item) => item.status === 3)
          .map((item) => (
            <SkipTodoItemReview item={item} shipId={shipId} setOpen={setOpen} setSelectedTodoListItem={setSelectedTodoListItem}/>
          ))}
      </Grid>
    </Grid>
  </Paper>;
}