import PaperSection from "../PaperSection";
import { useRecoilState } from "recoil";
import { SelectedShipState } from "../../Recoil/atoms/ShipsAtom";
import { SelectedClientState } from "../../Recoil/atoms/ClientsAtom";
import { useEffect, useState } from "react";
import { IncidentReport } from "../../types/IncidentReport";
import { useNavigate } from "react-router-dom";
import { getClosedIncidents } from "../../services/incidentService";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { Gavel, LocalPolice, Policy } from "@mui/icons-material";
import moment from "moment";

export default function ClosedIncidents() {
  const [selectedShip] = useRecoilState(SelectedShipState);
  const [selectedClient] = useRecoilState(SelectedClientState);
  const [incidents, setIncidents] = useState<IncidentReport[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    getClosedIncidents(selectedClient.id, selectedShip.id).then((res) => {
      setIncidents(res);
    });
  }, [selectedClient.id, selectedShip.id, setIncidents]);
  const columns = [
    {
      field: "isEmergency",
      headerName: `Priority`,
      width: 100,
      valueGetter: (params: any) => {
        return params.row.isEmergency;
      },
      renderCell: (params: GridRenderCellParams<any, Date>) =>
        params.row.isEmergency ? (
          <LocalPolice sx={{ color: "red" }} />
        ) : (
          <Policy />
        ),
    },
    {
      field: "title",
      headerName: `Title`,
      width: 300,
      valueGetter: (params: any) => {
        return params.row.title;
      },
    },
    {
      field: "location",
      headerName: `Location`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.location;
      },
    },
    {
      field: "userName",
      headerName: `Reporter`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.creator.userName;
      },
    },
    {
      field: "createdDate",
      headerName: `Created Date`,
      width: 150,
      valueGetter: (params: any) => {
        return moment(params.row.createdDate)
          .utc()
          .local()
          .format("MM/DD/YYYY");
      },
    },
    {
      field: "resolutionTime",
      headerName: `Resolution Date`,
      width: 150,
      valueGetter: (params: any) => {
        return moment(params.row.resolutionTime)
          .utc()
          .local()
          .format("MM/DD/YYYY");
      },
    },
    {
      field: "manHours",
      headerName: `Man Hours`,
      width: 150,
      valueGetter: (params: any) => {
        return params.row.manHours;
      },
    },
  ];
  return (
    <PaperSection title="Closed Faults" icon={<Gavel />}>
      <DataGrid
        rows={incidents}
        initialState={{
          sorting: {
            sortModel: [{ field: "resolutionTime", sort: "desc" }],
          },
        }}
        sx={{ minHeight: "300px", mb: 4 }}
        onRowClick={(params) =>
          navigate(`/ships/${selectedShip.id}/logbook/${params.row.id}`)
        }
        columns={columns}
      />
    </PaperSection>
  );
}
