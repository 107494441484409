import { Grid, Typography } from "@mui/material";
import { NoteAlt } from "@mui/icons-material";
import { Trip } from "../../types/Trip";
import AccordianPaperSection from "../AccordianPaperSection";

export type TripNotesCardProps = {
  trip?: Trip;
};
export default function TripNotesCard(props: TripNotesCardProps) {
  const { trip } = props;
  return (
    <AccordianPaperSection
      title="Notes"
      elevation={12}
      icon={<NoteAlt sx={{ fontSize: 24 }} />}
      isStatic={true}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1">{trip?.notes}</Typography>
        </Grid>
      </Grid>
    </AccordianPaperSection>
  );
}
