import { UserProfile } from "../types/UserProfile";

export default function IsUserElevated(userProfile: UserProfile, selectedClientId?: string){
        if(userProfile.isSystemAdministrator){
            return true;
        }
        var elevated: boolean = false;
        userProfile.roles.forEach(role => {
            if(role.clientId === selectedClientId){
                if(role.roleId === 'a61c40be-f73b-4177-12ac-08db8be34379' || role.roleId === '0e227c34-37d1-41ca-12ad-08db8be34379'){
                    elevated = true;
                }
            }
        });
        return elevated;
}